import React from 'react';
import './Offers.css';
import * as AiIcons from 'react-icons/ai';
import * as FaIcons from 'react-icons/fa';
import { Trans, useTranslation } from 'react-i18next';
import OffertablePage from './Offers_table';

class Offers extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fee_table_data:{
                fee_id:[],
                fee_name:[],
                fee_percentage_fee:[],
                fee_fixed_fee:[],
                additional_percentage_fee:[],
                additional_fixed_fee:[],
                fee_status:[],
            },
            payment_data:[],
            count_data: 0,
        }
    }
    async componentDidMount() {
        const formData = new URLSearchParams();
        formData.append('zone', 'all_fleets');
        formData.append('status', 'all');
        await fetch("https://admin.connect-ink.com/API/payment_admin/fees_table.php",
            {
                method: 'POST',
                headers: {
                    'Content-Type': "application/x-www-form-urlencoded;charset=UTF-8"
                },
                body: formData.toString()
            }
        )
            .then(res => res.json())
            .then(
                (data) => {
                    this.setState({
                        fee_table_data:{
                            fee_id:data.fee_id,
                            fee_name:data.fee_name,
                            fee_percentage_fee:data.percentage_fee,
                            fee_fixed_fee:data.fixed_fee,
                            additional_percentage_fee:data.additional_fee_percent,
                            additional_fixed_fee:data.additional_fixed_fee,
                            fee_status:data.status,
            },
                    })
                })
    }
    
    render() {
        const feeListTableData = [
            this.state.fee_table_data.fee_id,
            this.state.fee_table_data.fee_name,
            this.state.fee_table_data.fee_percentage_fee,
            this.state.fee_table_data.fee_fixed_fee,
            this.state.fee_table_data.additional_percentage_fee,
            this.state.fee_table_data.additional_fixed_fee,
            this.state.fee_table_data.fee_status,
        ];
        return (
            <div className='offers_contain'>
                <p className='offers_title'><Trans>Fees List</Trans></p>
                <table className='offers_table'>
                    <tr>
                        <td className='offers_rightside_menu'>
                            <button className="btn_add_offers"><AiIcons.AiOutlinePlus /> <Trans>Add Offers</Trans></button>
                            {/*<select className="offers_fleets_select">
                                <option>All Zones</option>
                                <option>Hoi An</option>
                                <option>Hanoi 1</option>
                                <option>Hanoi 2</option>
                                <option>HoChiMinh City</option>
                            </select>*/}
                        </td>
                    </tr>
                </table>
                    <OffertablePage tableData={feeListTableData}/>
                <div>
                    
                </div>
                
            </div>
        );
    }
}

export default Offers;