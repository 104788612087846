import "./Map.css";
import React, { Component } from "react";
import { MapContainer, Marker, Popup, TileLayer, useMap } from "react-leaflet";
import { Icon } from 'leaflet';
import onRideMarker from "../../img/marker2.png";
import forcedStopMarker from "../../img/marker4.png";
import unlockFailMarker from "../../img/marker2.png";
import BikeGeotenceAlertMarker from "../../img/marker5.png";
import unlockWithoutRideMarker from "../../img/marker6.png";
import maintenanceMarker from "../../img/marker9.png";
import availableMarker from "../../img/marker.png";
import unavailableMarker from "../../img/marker14.png";
import serviceOnMarker from "../../img/marker10.png";
import serviceOffMarker from "../../img/marker13.png";
import missingMarker from "../../img/marker11.png";
import StolenMarker from "../../img/marker12.png";
import batteryCriticalMarker from "../../img/marker8.png";
import noDataMarker from "../../img/nodata.png";

function SetTimeOut() {
  const map = useMap();
  setTimeout(() => {
    map.invalidateSize();
  }, 250);
  return null;
}

function ChangeMapView({ coords}) {
  const map = useMap();
  setTimeout(() => {
    map.invalidateSize();
  }, 250);
  map.setView(coords, map.getZoom());
  return null;
}

export default class MapLeaflet extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lat: 37.303173,
      lng: -121.807734,
    };
  }
  componentDidMount() {
    this.setState({
      lat: this.props.position[0],
      lng: this.props.position[1],
      zoom: this.props.zoom,
    })
  }

  componentWillReceiveProps(nextProps) {
    if (this.state.lat == nextProps.position[0] && this.state.lng == nextProps.position[1]) {
    }
    else {
      this.setState({
        lat: nextProps.position[0],
        lng: nextProps.position[1],
        zoom: nextProps.zoom,
      })
      const { map } = this.state;
      if (map) {
        map.flyTo(nextProps.position, nextProps.zoom);
      }
    }
    console.log(nextProps.position);
    console.log(nextProps.zoom);
  }
  render() {
    const locationArray = [this.state.lat, this.state.lng];
    const position = [parseFloat(this.props.locationValue[0]), parseFloat(this.props.locationValue[1])];
    console.log("LatLocation: " + locationArray[0]);
    console.log("LngLocation: " + locationArray[1]);
    const zoomValue = parseFloat(this.props.locationValue[2]);
    const vehicleIdArray = this.props.vehiclePosition[0];
    const vehicleLatArray = this.props.vehiclePosition[1];
    const vehicleLngArray = this.props.vehiclePosition[2];
    const vehicleConnectionArray = this.props.vehiclePosition[3];
    const vehicleTypeArray = this.props.vehiclePosition[4];
    const vehicleLengthArray = vehicleIdArray.length;
    var markerIconPng = [];
    var userType = [];
    
    for (var j = 0; j < vehicleLengthArray; j++) {
      if(vehicleTypeArray[j] == "artist"){
           userType[j] = "Artist";
            if(vehicleConnectionArray[j] == "enable"){
                markerIconPng[j] = unlockFailMarker;
            }else {
                markerIconPng[j] = unavailableMarker;
            }
        } else if(vehicleTypeArray[j] == "client"){
            userType[j] = "Client";
            if(vehicleConnectionArray[j] == "Verify"){
                markerIconPng[j] = serviceOnMarker;
            }else{
                markerIconPng[j] = serviceOffMarker;
            }
        }
    }

    const rowsData = [];
    for (var i = 0; i < vehicleLengthArray; i++) {
      rowsData[i] = [vehicleLatArray[i], vehicleLngArray[i], vehicleIdArray[i], markerIconPng[i], userType[i]];
    }
    return (
      <>
        <MapContainer style={{ height: "70vh" }} center={position} zoom={15} whenCreated={map => this.setState({ map })}>
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        />
        {rowsData.map((m,index) => {
          return (
            <Marker position={[m[0], m[1]]} icon={new Icon({ iconUrl: m[3], iconSize: [24, 36], iconAnchor: [12, 41] })}>
              <Popup>
                {m[4]}
                <br />
                {m[2]}
              </Popup>
            </Marker>
          )
        }
        )}
        <ChangeMapView coords={position}/>
      </MapContainer>
      </>
    );
  }
}

