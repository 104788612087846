import React from 'react';
import './RestrictedAreas.css';
import './Parkings.css';
import * as FaIcons from 'react-icons/fa';
import Toggle from './Toggle';
import RestrictedAreastablePage from './RestrictedAreas_table';
import Switch from "react-switch";
import { Trans } from 'react-i18next';
import AddRestrictedAreasMap from './AddRestrictedAreasMap';
import EditRestrictedAreasMap from './EditRestrictedAreasMap';
import Cookies from 'universal-cookie';

class RestrictedAreas extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fleetName: [],
            fleetNameAddRestrictedZone: [],
            fleetNameEditParkings: [],
            locationName: [],
            fleetFilterParam: "all_fleet",
            activeFilterparam: "all",
            activeFilterState: false,
            restrictedZoneTable: true,
            addRestrictedZone: false,
            editRestrictedZone: false,
            addRestrictedZoneFormConfirm: false,
            addRestrictedZoneFormFooter: true,
            restrictedZoneData: {
                restrictedZoneId: [],
                restrictedZoneName: [],
                restrictedZoneFleet: [],
                restrictedZoneStatus: [],
                restrictedZoneCreatedDate: [],
                restrictedZoneModifyDate: [],
            },
            editrestrictedZoneData: {
                editRestrictedZoneId: [],
                editRestrictedZoneName: [],
                editRestrictedZoneFleet: [],
                editRestrictedZoneLatArray: [10.77080777782741, 10.770965939767235, 10.770681248215716, 10.770570534761893, 10.770586350972078],
                editRestrictedZoneLngArray: [106.6917888056399, 106.69198733018773, 106.69221804790547, 106.69206244758419, 106.69197123360274],
                editRestrictedZoneDescription: [],
            },
            onEditState: true,
            temporaryAreaLat: [],
            temporaryAreaLng: [],
            confirmAreaLat: [],
            confirmAreaLng: [],
            popupParkingsAreaConfirm: false,
            add_restricted_zone_name: null,
            confirmUseArea: false,
            confirmUseEditArea: false,
            temporaryEditAreaLat: [],
            temporaryEditAreaLng: [],
            confirmEditAreaLat: [],
            confirmEditAreaLng: [],
            onEditAreaDrawState: true,
            editRestrictedZoneFormConfirm: false,
            editRestrictedZoneFormFooter: true,
            deleteZoneFormConfirm: false,
            deleteZoneId: null,
            mapAddLat: 16.51632472900294,
            mapAddLng: 107.64006418629653,
        }
        this.selectFleetFilter = this.selectFleetFilter.bind(this);
        this.handleActiveChange = this.handleActiveChange.bind(this);
        this.handleOpenAddRestrictedZoneForm = this.handleOpenAddRestrictedZoneForm.bind(this);
        //=====================================================================================
        this.removePolygon = React.createRef();
        this.removeEditPolygon = React.createRef();
        this.handleCancelAddRestrictedZone = this.handleCancelAddRestrictedZone.bind(this);
        this._onRestrictedZoneCreate = this._onRestrictedZoneCreate.bind(this);
        this.removePolygonFunction = this.removePolygonFunction.bind(this);
        this.handlelAddRestrictedZoneSubmit = this.handlelAddRestrictedZoneSubmit.bind(this);
        this.confirmPolygonFunction = this.confirmPolygonFunction.bind(this);
        this.handleConfirmAddRestrictedZone = this.handleConfirmAddRestrictedZone.bind(this);
        this.getFleetLocationMap = this.getFleetLocationMap.bind(this);
        this.changeLocation = this.changeLocation.bind(this);
        //=====================================================================================
        this.editRestrictedZoneOpen = this.editRestrictedZoneOpen.bind(this);
        this.handleCancelEditRestrictedZone = this.handleCancelEditRestrictedZone.bind(this);
        this.handlelEditRestrictedZoneSubmit = this.handlelEditRestrictedZoneSubmit.bind(this);
        this.confirmEditPolygonFunction = this.confirmEditPolygonFunction.bind(this);
        this.removeEditPolygonFunction = this.removeEditPolygonFunction.bind(this);
        this._onCreateEdit = this._onCreateEdit.bind(this);
        this.handleConfirmEditRestrictedZone = this.handleConfirmEditRestrictedZone.bind(this);
        //=========================================================================
        this.deleteZone = this.deleteZone.bind(this);
        this.deleteZoneconfirm = this.deleteZoneconfirm.bind(this);
        this.deleteZonecancel = this.deleteZonecancel.bind(this);
    }
    handleOpenAddRestrictedZoneForm() {
        this.setState({
            addRestrictedZone: true,
            restrictedZoneTable: false,
        })
    }
    async handleActiveChange() {
        if (this.state.activeFilterState === false) {
            this.setState({
                activeFilterState: true,
            });
            var activeFilterParam = "Active";
        } else {
            this.setState({
                activeFilterState: false,
            });
            activeFilterParam = "all";
        }
        const formRestrictedAreaFilterData = new URLSearchParams();
        formRestrictedAreaFilterData.append('fleet_filter_param', this.state.fleetFilterParam);
        formRestrictedAreaFilterData.append('active_filter_param', activeFilterParam);
        await fetch("http://api.elevate-mobility.com/api/parking_point/elevate_restricted_zone.php",
            {
                method: 'POST',
                headers: {
                    'Content-Type': "application/x-www-form-urlencoded;charset=UTF-8"
                },
                body: formRestrictedAreaFilterData.toString()
            }
        )
            .then(res => res.json())
            .then(
                (data) => {
                    this.setState({
                        activeFilterparam: activeFilterParam,
                        restrictedZoneData: {
                            restrictedZoneId: data.restricted_zone_id,
                            restrictedZoneName: data.restricted_zone_name,
                            restrictedZoneFleet: data.restricted_zone_fleet,
                            restrictedZoneStatus: data.restricted_zone_status,
                            restrictedZoneCreatedDate: data.restricted_zone_created_date,
                            restrictedZoneModifyDate: data.restricted_zone_modify_date,
                        }
                    })
                }
            )
    }
    async selectFleetFilter(e) {
        const fleetFiltervalue = e.target.value;
        const formRestrictedAreaFilterData = new URLSearchParams();
        formRestrictedAreaFilterData.append('fleet_filter_param', fleetFiltervalue);
        formRestrictedAreaFilterData.append('active_filter_param', this.state.activeFilterparam);
        await fetch("http://api.elevate-mobility.com/api/parking_point/elevate_restricted_zone.php",
            {
                method: 'POST',
                headers: {
                    'Content-Type': "application/x-www-form-urlencoded;charset=UTF-8"
                },
                body: formRestrictedAreaFilterData.toString()
            }
        )
            .then(res => res.json())
            .then(
                (data) => {
                    this.setState({
                        fleetFilterParam: fleetFiltervalue,
                        restrictedZoneData: {
                            restrictedZoneId: data.restricted_zone_id,
                            restrictedZoneName: data.restricted_zone_name,
                            restrictedZoneFleet: data.restricted_zone_fleet,
                            restrictedZoneStatus: data.restricted_zone_status,
                            restrictedZoneCreatedDate: data.restricted_zone_created_date,
                            restrictedZoneModifyDate: data.restricted_zone_modify_date,
                        }
                    })
                }
            )
    }
    async _onRestrictedZoneCreate(e) {
        const corArray = e.layer._latlngs;
        const areaLatArray = [];
        const areaLngArray = [];
        var arrayLength = corArray[0].length;
        for (var i = 0; i < arrayLength; i++) {
            areaLatArray[i] = corArray[0][i].lat;
            areaLngArray[i] = corArray[0][i].lng;
        }
        await this.setState({
            temporaryAreaLat: areaLatArray,
            temporaryAreaLng: areaLngArray,
            onEditState: false,
            confirmUseArea: true
        });
    }
    handleCancelAddRestrictedZone() {
        this.setState({
            restrictedZoneTable: true,
            addRestrictedZone: false,
            addRestrictedZoneFormConfirm: false,
            addRestrictedZoneFormFooter: true,
        })
    }
    removePolygonFunction() {
        this.setState({
            confirmUseArea: false,
            confirmAreaLat: [],
            confirmAreaLng: [],
        });
        var polygon = this.removePolygon.current;
        var layers = polygon._layers;
        var layer_ids = Object.keys(layers);
        var layer;
        layer_ids.forEach(id => {
            layer = layers[id]
            polygon.removeLayer(layer);
        })
        this.setState({
            onEditState: true,
        })
    }
    async confirmPolygonFunction() {
        await this.setState({
            confirmAreaLat: this.state.temporaryAreaLat,
            confirmAreaLng: this.state.temporaryAreaLng,
        })
        alert("This area has been added to restricted zone.")
    }
    async handlelAddRestrictedZoneSubmit(e) {
        e.preventDefault();
        const cookies = new Cookies();
        const data = new FormData(e.target);
        const arrayAreaLat = data.get('area_lat');
        const arrayAreaLng = data.get('area_lng')
        const arrayLat = arrayAreaLat.toString();
        const arrayLng = arrayAreaLng.toString();
        if (arrayLat) {
            const formAddParkingPoint = new URLSearchParams();
            formAddParkingPoint.append('restricted_zone_fleet', data.get('restrictedZoneFleet'));
            formAddParkingPoint.append('restricted_zone_name', data.get('restrictedZoneName'));
            formAddParkingPoint.append('restricted_zone_description', data.get('restrictedZoneDescription'));
            formAddParkingPoint.append('restricted_zone_area_lat', arrayLat);
            formAddParkingPoint.append('restricted_zone_area_lng', arrayLng);
            formAddParkingPoint.append('admin_name', cookies.get('adminName'));
            await fetch("http://api.elevate-mobility.com/api/parking_point/elevate_add_restricted_zone.php",
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': "application/x-www-form-urlencoded;charset=UTF-8"
                    },
                    body: formAddParkingPoint.toString()
                }
            )
                .then(res => res.json())
                .then(
                    (data) => {
                        if (data.success === 1) {
                            this.setState({
                                add_restricted_zone_name: data.add_restricted_zone_name,
                                addRestrictedZoneFormConfirm: true,
                                addRestrictedZoneFormFooter: false,
                            })
                        } else {
                            alert("Fail to add new restricted zone !");
                        }

                    }
                )
        } else {
            alert("Please draw and confirm parking area on map !")
        }
    }
    async handleConfirmAddRestrictedZone() {
        const formParkingFilterData = new URLSearchParams();
        formParkingFilterData.append('fleet_filter_param', this.state.fleetFilterParam);
        formParkingFilterData.append('active_filter_param', this.state.activeFilterparam);
        await fetch("http://api.elevate-mobility.com/api/parking_point/elevate_restricted_zone.php",
            {
                method: 'POST',
                headers: {
                    'Content-Type': "application/x-www-form-urlencoded;charset=UTF-8"
                },
                body: formParkingFilterData.toString()
            }
        )
            .then(res => res.json())
            .then(
                (data) => {
                    this.setState({
                        addRestrictedZoneFormConfirm: false,
                        addRestrictedZoneFormFooter: true,
                        addRestrictedZone: false,
                        restrictedZoneTable: true,
                        restrictedZoneData: {
                            restrictedZoneId: data.restricted_zone_id,
                            restrictedZoneName: data.restricted_zone_name,
                            restrictedZoneFleet: data.restricted_zone_fleet,
                            restrictedZoneStatus: data.restricted_zone_status,
                            restrictedZoneCreatedDate: data.restricted_zone_created_date,
                            restrictedZoneModifyDate: data.restricted_zone_modify_date,
                        }
                    })
                }
            )
    }
    async getFleetLocationMap(e) {
        const fleetNameRequest = e.target.value;
        const formFleetRequestData = new URLSearchParams();
        formFleetRequestData.append('fleet_request_param', fleetNameRequest);
        console.log("Fleet: " + formFleetRequestData);
        await fetch("http://api.elevate-mobility.com/api/parking_point/elevate_request_fleet_location.php",
            {
                method: 'POST',
                headers: {
                    'Content-Type': "application/x-www-form-urlencoded;charset=UTF-8"
                },
                body: formFleetRequestData.toString()
            })
            .then(res => res.json())
            .then(
                (data) => {
                    this.setState({
                        mapAddLat: data.fleet_name_lat,
                        mapAddLng: data.fleet_name_lng,
                    })
                })
    }
    changeLocation(data) {
        this.setState({
            mapAddLat: data[0],
            mapAddLng: data[1],
        })
    }
    //=====================================================================================================
    async editRestrictedZoneOpen(e) {
        //Clear all polygon on map before display edit restricted area
        var polygon = this.removeEditPolygon.current;
        var layers = polygon._layers;
        var layer_ids = Object.keys(layers);
        var layer;
        layer_ids.forEach(id => {
            layer = layers[id]
            polygon.removeLayer(layer);
        })

        const zoneId = e.target.value;
        const formEditServiceAreaData = new URLSearchParams();
        formEditServiceAreaData.append('zone_id', zoneId);
        await fetch("http://api.elevate-mobility.com/api/parking_point/elevate_get_restricted_zone.php",
            {
                method: 'POST',
                headers: {
                    'Content-Type': "application/x-www-form-urlencoded;charset=UTF-8"
                },
                body: formEditServiceAreaData.toString()
            }
        )
            .then(res => res.json())
            .then(
                (data) => {
                    this.setState({
                        editRestrictedZone: true,
                        restrictedZoneTable: false,
                        editRestrictedZoneFormFooter: true,
                        editRestrictedZoneFormConfirm: false,
                        editrestrictedZoneData: {
                            editRestrictedZoneId: data.restricted_zone_id,
                            editRestrictedZoneName: data.restricted_zone_name,
                            editRestrictedZoneFleet: data.restricted_zone_fleet,
                            editRestrictedZoneLatArray: data.restricted_zone_lat_array,
                            editRestrictedZoneLngArray: data.restricted_zone_lng_array,
                            editRestrictedZoneDescription: data.restricted_zone_description,
                        },
                        confirmEditAreaLat: data.restricted_zone_lat_array,
                        confirmEditAreaLng: data.restricted_zone_lng_array,
                        onEditAreaDrawState: true,
                    })
                }
            )
    }
    handleCancelEditRestrictedZone() {
        this.setState({
            restrictedZoneTable: true,
            editRestrictedZone: false,
            editRestrictedZoneFormConfirm: false,
            editRestrictedZoneFormFooter: true,
        })
    }
    _onCreateEdit(e) {
        const corArray = e.layer._latlngs;
        const areaLatArray = [];
        const areaLngArray = [];
        var arrayLength = corArray[0].length;
        for (var i = 0; i < arrayLength; i++) {
            areaLatArray[i] = corArray[0][i].lat;
            areaLngArray[i] = corArray[0][i].lng;
        }
        this.setState({
            temporaryEditAreaLat: areaLatArray,
            temporaryEditAreaLng: areaLngArray,
            onEditState: false,
            confirmUseEditArea: true
        })
    }
    removeEditPolygonFunction() {
        this.setState({
            confirmUseEditArea: false,
            confirmEditAreaLat: [],
            confirmEditAreaLng: [],
        });
        //var layerContainer = this.removePolygon.options.edit.featureGroup;
        var polygon = this.removeEditPolygon.current;
        var layers = polygon._layers;
        var layer_ids = Object.keys(layers);
        var layer;
        layer_ids.forEach(id => {
            layer = layers[id]
            polygon.removeLayer(layer);
        })
    }
    async confirmEditPolygonFunction() {
        await this.setState({
            confirmEditAreaLat: this.state.temporaryEditAreaLat,
            confirmEditAreaLng: this.state.temporaryEditAreaLng,
            onEditAreaDrawState: false,
        });
        alert("This area has been assign to parking point information.")
    }
    async handlelEditRestrictedZoneSubmit(e) {
        const cookies = new Cookies();
        e.preventDefault();
        const data = new FormData(e.target);
        const arrayAreaLat = data.get('edit_area_lat');
        const arrayAreaLng = data.get('edit_area_lng');
        const arrayLat = arrayAreaLat.toString();
        const arrayLng = arrayAreaLng.toString();
        if (arrayLat) {
            const formAddParkingPoint = new URLSearchParams();
            formAddParkingPoint.append('restricted_zone_id', data.get('edit_area_id'));
            formAddParkingPoint.append('restricted_zone_fleet', data.get('restrictedZoneFleet'));
            formAddParkingPoint.append('restricted_zone_name', data.get('restrictedZoneName'));
            formAddParkingPoint.append('restricted_zone_description', data.get('restrictedZoneDescription'));
            formAddParkingPoint.append('restricted_zone_area_lat', arrayLat);
            formAddParkingPoint.append('restricted_zone_area_lng', arrayLng);
            formAddParkingPoint.append('admin_name', cookies.get('adminName'));
            await fetch("http://api.elevate-mobility.com/api/parking_point/elevate_edit_restricted_zone.php",
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': "application/x-www-form-urlencoded;charset=UTF-8"
                    },
                    body: formAddParkingPoint.toString()
                }
            )
                .then(res => res.json())
                .then(
                    async (data) => {
                        if (data.success === 1) {
                            await this.setState({
                                edit_zone_name: data.edit_restricted_zone_name,
                                edit_zone_id: data.edit_restricted_zone_id,
                                editRestrictedZoneFormConfirm: true,
                                editRestrictedZoneFormFooter: false,
                                confirmUseEditArea: false
                            })
                        } else {
                            alert("Fail to update restricted zone information !");
                        }
                    }
                )
        } else {
            alert(<Trans>Please draw and confirm restricted area on map !</Trans>)
        }
    }
    async handleConfirmEditRestrictedZone() {
        const formRestrictedAreaFilterData = new URLSearchParams();
        formRestrictedAreaFilterData.append('fleet_filter_param', this.state.fleetFilterParam);
        formRestrictedAreaFilterData.append('active_filter_param', this.state.activeFilterparam);
        await fetch("http://api.elevate-mobility.com/api/parking_point/elevate_restricted_zone.php",
            {
                method: 'POST',
                headers: {
                    'Content-Type': "application/x-www-form-urlencoded;charset=UTF-8"
                },
                body: formRestrictedAreaFilterData.toString()
            }
        )
            .then(res => res.json())
            .then(
                (data) => {
                    this.setState({
                        editRestrictedZone: false,
                        restrictedZoneTable: true,
                        editRestrictedZoneFormFooter: true,
                        editRestrictedZoneFormConfirm: false,
                        restrictedZoneData: {
                            restrictedZoneId: data.restricted_zone_id,
                            restrictedZoneName: data.restricted_zone_name,
                            restrictedZoneFleet: data.restricted_zone_fleet,
                            restrictedZoneStatus: data.restricted_zone_status,
                            restrictedZoneCreatedDate: data.restricted_zone_created_date,
                            restrictedZoneModifyDate: data.restricted_zone_modify_date,
                        }
                    })
                }
            )
    }
    //===================================================================================================
    deleteZone(e) {
        const deleteStationValue = e.target.value;
        this.setState({
            deleteZoneFormConfirm: true,
            deleteZoneId: deleteStationValue,
        })
    }
    async deleteZoneconfirm() {
        const cookies = new Cookies();
        const formZoneFilterData = new URLSearchParams();
        formZoneFilterData.append('fleet_filter_param', this.state.fleetFilterParam);
        formZoneFilterData.append('active_filter_param', this.state.activeFilterparam);
        formZoneFilterData.append('zone_id', this.state.deleteZoneId);
        formZoneFilterData.append('admin_name', cookies.get('adminName'));
        await fetch("http://api.elevate-mobility.com/api/parking_point/elevate_delete_restricted_area.php",
            {
                method: 'POST',
                headers: {
                    'Content-Type': "application/x-www-form-urlencoded;charset=UTF-8"
                },
                body: formZoneFilterData.toString()
            }
        )
            .then(res => res.json())
            .then(
                (data) => {
                    if (data.success === 1) {
                        this.setState({
                            deleteZoneFormConfirm: false,
                            restrictedZoneTable: true,
                            restrictedZoneData: {
                                restrictedZoneId: data.restricted_zone_id,
                                restrictedZoneName: data.restricted_zone_name,
                                restrictedZoneFleet: data.restricted_zone_fleet,
                                restrictedZoneStatus: data.restricted_zone_status,
                                restrictedZoneCreatedDate: data.restricted_zone_created_date,
                                restrictedZoneModifyDate: data.restricted_zone_modify_date,
                            }
                        })
                    } else {
                        this.setState({
                            deleteZoneFormConfirm: false,
                            restrictedZoneTable: true,
                        });
                        alert("The restricted area was not deleted completly !")
                    }
                }
            )
    }
    deleteZonecancel() {
        this.setState({
            deleteZoneFormConfirm: false,
            restrictedZoneTable: true,
            deleteZoneId: null,
        })
    }
    async componentDidMount() {
        const formRestrictedAreaFilterData = new URLSearchParams();
        formRestrictedAreaFilterData.append('fleet_filter_param', this.state.fleetFilterParam);
        formRestrictedAreaFilterData.append('active_filter_param', this.state.activeFilterparam);
        await fetch("http://api.elevate-mobility.com/api/parking_point/elevate_restricted_zone.php",
            {
                method: 'POST',
                headers: {
                    'Content-Type': "application/x-www-form-urlencoded;charset=UTF-8"
                },
                body: formRestrictedAreaFilterData.toString()
            }
        )
            .then(res => res.json())
            .then(
                (data) => {
                    this.setState({
                        fleetName: data.fleet_name,
                        fleetNameAddRestrictedZone: data.fleet_name,
                        restrictedZoneData: {
                            restrictedZoneId: data.restricted_zone_id,
                            restrictedZoneName: data.restricted_zone_name,
                            restrictedZoneFleet: data.restricted_zone_fleet,
                            restrictedZoneStatus: data.restricted_zone_status,
                            restrictedZoneCreatedDate: data.restricted_zone_created_date,
                            restrictedZoneModifyDate: data.restricted_zone_modify_date,
                        },
                        mapAddLat: data.first_fleet_name_lat,
                        mapAddLng: data.first_fleet_name_lng,
                    })
                }
            )
    }
    render() {
        let restrictedZoneTable = this.state.restrictedZoneTable ? "show_parkings_table" : "hide_parkings_table";
        let addRestrictedZone = this.state.addRestrictedZone ? "add_parkings_show" : "add_parkings_hide";
        let addRestrictedZoneFormConfirm = this.state.addRestrictedZoneFormConfirm ? "confirm_add_parkings_show" : "confirm_add_parkings_hide";
        let addRestrictedZoneFormFooter = this.state.addRestrictedZoneFormFooter ? "add_parkings_footer" : "hide_add_parkings_footer";
        let confirmUseArea = this.state.confirmUseArea ? "show_confirm_use_area" : "hide_confirm_use_area";
        let addMapPosition = [this.state.mapAddLat, this.state.mapAddLng];
        //==================================================================================================================================
        let editRestrictedZone = this.state.editRestrictedZone ? "add_parkings_show" : "add_parkings_hide";
        let editRestrictedZoneFormConfirm = this.state.editRestrictedZoneFormConfirm ? "confirm_add_parkings_show" : "confirm_add_parkings_hide";
        let editRestrictedZoneFormFooter = this.state.editRestrictedZoneFormFooter ? "add_parkings_footer" : "hide_add_parkings_footer";
        let confirmUseEditArea = this.state.confirmUseEditArea ? "show_confirm_use_area" : "hide_confirm_use_area";
        //===================================================================================================================================
        let deleteZoneFormConfirm = this.state.deleteZoneFormConfirm ? "confirm_add_parkings_show" : "confirm_add_parkings_hide";
        const tableData = [
            this.state.restrictedZoneData.restrictedZoneId,
            this.state.restrictedZoneData.restrictedZoneName,
            this.state.restrictedZoneData.restrictedZoneFleet,
            this.state.restrictedZoneData.restrictedZoneStatus,
            this.state.restrictedZoneData.restrictedZoneCreatedDate,
            this.state.restrictedZoneData.restrictedZoneModifyDate,
        ];
        return (
            <div className='restricted_areas_contain'>
                <div className={restrictedZoneTable}>
                    <table className="restricted_areas_menu_filter">
                        <tbody>
                            <tr>
                                <td className='restricted_areas_leftside_menu'>
                                    <div>
                                        <p className="restricted_areas_title"><Trans>Restricted Areas</Trans></p>
                                    </div>
                                </td>
                                <td className='restricted_areas_rightside_menu'>
                                    <button className="btn_add_restricted_areas" type="button" onClick={this.handleOpenAddRestrictedZoneForm}><FaIcons.FaPlus /> <Trans>Add Restricted Area</Trans> </button>
                                    <select className="fleets_select" onChange={this.selectFleetFilter}>
                                        <option value="all_fleet">All Fleets</option>
                                        {
                                            this.state.fleetName.map((x) =>
                                                <option value={x}>{x}</option>)
                                        }
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <td className='restricted_areas_leftside_menu'>
                                    <div>

                                    </div>
                                </td>
                                <td className='restricted_areas_rightside_menu'>
                                    <table className='restricted_areas_rightside_button'>
                                        <tr>
                                            <td><label><Trans>Active</Trans></label></td>
                                            <td><Switch onChange={this.handleActiveChange} checked={this.state.activeFilterState} /></td>
                                        </tr>
                                    </table>

                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div className='restricted_areas_table'>
                        <RestrictedAreastablePage tableData={tableData} editRestrictedZone={this.editRestrictedZoneOpen} deleteZone={this.deleteZone} />
                    </div>
                </div>
                {/*===================================================================================================================================================*/}
                <div className={addRestrictedZone}>
                    <div className="addParkingsForm">
                        <div className="add_parkings_form_header"><b><Trans>Add Restricted Zone</Trans></b></div>
                        <form onSubmit={this.handlelAddRestrictedZoneSubmit}>
                            <div className="parkings-form">
                                <div style={{ display: "flex" }}>
                                    <div className="form-group-left-side">
                                        <div className="form-group">
                                            <label><Trans>Fleet</Trans></label>
                                            <select name="restrictedZoneFleet" onChange={this.getFleetLocationMap}>
                                                {this.state.fleetName.map((x) =>
                                                    <option value={x}>{x}</option>)}
                                            </select>
                                        </div>
                                        <div className="form-group">
                                            <label><Trans>Restricted Zone Name</Trans></label>
                                            <input type="text" name="restrictedZoneName" placeHolder="Enter Restricted Zone Name" required />
                                        </div>
                                    </div>
                                    <div className="form-group-right-side">
                                        <div className="form-group">
                                            <label><Trans>Description</Trans></label>
                                            <input type="text" name="restrictedZoneDescription" />
                                        </div>
                                        <div style={{ display: "none" }} className="form-group">
                                            <input type="text" name="area_lat" value={this.state.confirmAreaLat} />
                                        </div>
                                        <div style={{ display: "none" }} className="form-group">
                                            <input type="text" name="area_lng" value={this.state.confirmAreaLng} />
                                        </div>
                                    </div>
                                </div>
                                <div className={addRestrictedZoneFormFooter}>
                                    <button type="button" className="btn_cancel_adding" onClick={this.handleCancelAddRestrictedZone}>
                                        <Trans>Cancel</Trans>
                                    </button>
                                    <button type="submit" className="btn_submit_adding" >
                                        <Trans>Add Restricted Zone</Trans>
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className="addParkingsMap">
                        <div className="fleetMap">
                            <AddRestrictedAreasMap refFeatureGroup={this.removePolygon} getAreaData={this._onRestrictedZoneCreate} editState={this.state.onEditState}  position={addMapPosition} clickPosition={this.changeLocation}/>
                            <div className={confirmUseArea}>
                                <i style={{ color: "red" }}> * <Trans>Use this area for this restricted zone ?</Trans></i> <button className="add_area_btn" onClick={this.removePolygonFunction}><Trans>No</Trans></button><button onClick={this.confirmPolygonFunction} className="add_area_btn"><Trans>Yes</Trans></button>
                            </div>
                        </div>
                    </div>
                    <div className={addRestrictedZoneFormConfirm}>
                        <p className="add_parkings_confirm_title"><Trans>The restricted zone </Trans> {this.state.add_restricted_zone_name} <Trans>has been added recently </Trans> !</p>
                        <button className="add_fleet_confirm_button" type="button" onClick={this.handleConfirmAddRestrictedZone}>
                            OK
                        </button>
                    </div>
                </div>
                {/*===================================================================================================================================*/}
                <div className={editRestrictedZone}>
                    <div className="addParkingsForm">
                        <div className="add_parkings_form_header"><b><Trans>Edit Restricted Zone</Trans></b></div>
                        <form onSubmit={this.handlelEditRestrictedZoneSubmit}>
                            <div className="parkings-form">
                                <div style={{ display: "flex" }}>
                                    <div className="form-group-left-side">
                                        <div className="form-group">
                                            <label><Trans>Fleet</Trans></label>
                                            <select name="restrictedZoneFleet">
                                                {this.state.fleetName.map((x) =>
                                                    <option selected={x === this.state.editrestrictedZoneData.editRestrictedZoneFleet} value={x}>{x}</option>)}
                                            </select>
                                        </div>
                                        <div className="form-group">
                                            <label><Trans>Restricted Zone Name</Trans></label>
                                            <input type="text" name="restrictedZoneName" defaultValue={this.state.editrestrictedZoneData.editRestrictedZoneName} required />
                                        </div>
                                    </div>
                                    <div className="form-group-right-side">
                                        <div className="form-group">
                                            <label><Trans>Description</Trans></label>
                                            <input type="text" name="restrictedZoneDescription" defaultValue={this.state.editrestrictedZoneData.editRestrictedZoneDescription} />
                                        </div>
                                        <div style={{ display: "none" }} className="form-group">
                                            <input type="text" name="edit_area_lat" value={this.state.confirmEditAreaLat} />
                                        </div>
                                        <div style={{ display: "none" }} className="form-group">
                                            <input type="text" name="edit_area_lng" value={this.state.confirmEditAreaLng} />
                                        </div>
                                        <div style={{ display: "none" }} className="form-group">
                                            <input type="text" name="edit_area_id" value={this.state.editrestrictedZoneData.editRestrictedZoneId} />
                                        </div>
                                    </div>
                                </div>
                                <div className={editRestrictedZoneFormFooter}>
                                    <button type="button" className="btn_cancel_adding" onClick={this.handleCancelEditRestrictedZone}>
                                        <Trans>Cancel</Trans>
                                    </button>
                                    <button type="submit" className="btn_submit_adding" >
                                        <Trans>Save Changes</Trans>
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className="addParkingsMap">
                        <div className="fleetMap">
                            <EditRestrictedAreasMap refFeatureGroup={this.removeEditPolygon} getAreaData={this._onCreateEdit} editState={this.state.onEditAreaDrawState} latArray={this.state.editrestrictedZoneData.editRestrictedZoneLatArray} lngArray={this.state.editrestrictedZoneData.editRestrictedZoneLngArray} />
                            <div className={confirmUseEditArea}>
                                <i style={{ color: "red" }}> * Use this area for this restricted zone ?</i> <button className="add_area_btn" onClick={this.removeEditPolygonFunction}>No</button><button onClick={this.confirmEditPolygonFunction} className="add_area_btn">Yes</button>
                            </div>
                        </div>
                    </div>
                    <div className={editRestrictedZoneFormConfirm}>
                        <p className="add_parkings_confirm_title"><Trans>The Restricted Zone</Trans> {this.state.edit_zone_name} ( ID: {this.state.edit_zone_id}) <Trans>has been edited recently </Trans> !</p>
                        <button className="add_fleet_confirm_button" type="button" onClick={this.handleConfirmEditRestrictedZone}>
                            OK
                        </button>
                    </div>
                </div>
                {/*==================================================================================================================*/}
                <div className={deleteZoneFormConfirm}>
                    <p className="add_parkings_confirm_title"><Trans>The restricted area will be deleted !</Trans> !</p>
                    <button style={{ backgroundColor: "gray", borderColor: "gray" }} className="add_fleet_confirm_button" type="button" onClick={this.deleteZonecancel}>
                        Cancle
                    </button>
                    <button style={{ marginLeft: "10px" }} className="add_fleet_confirm_button" type="button" onClick={this.deleteZoneconfirm}>
                        OK
                    </button>
                </div>
            </div>
        )
    }
}
export default RestrictedAreas;