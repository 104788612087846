import React from 'react';
import './Map.css';
import "leaflet/dist/leaflet.css";
import MapLeaflet from './MapLeaflet';
import SearchMapLeaflet from './SearchMapLeaflet';
import onRideMarker from "../../img/marker2.png";
import forcedStopMarker from "../../img/marker4.png";
import unlockFailMarker from "../../img/marker2.png";
import BikeGeotenceAlertMarker from "../../img/marker5.png";
import unlockWithoutRideMarker from "../../img/marker6.png";
import maintenanceMarker from "../../img/marker9.png";
import availableMarker from "../../img/marker.png";
import unavailableMarker from "../../img/marker14.png";
import serviceOnMarker from "../../img/marker10.png";
import serviceOffMarker from "../../img/marker13.png";
import missingMarker from "../../img/marker11.png";
import StolenMarker from "../../img/marker12.png";
import batteryCriticalMarker from "../../img/marker8.png";
import { Trans, useTranslation } from 'react-i18next';

function MyComponent(x) {
    const { t } = useTranslation('myNamespace');

    return <Trans t={t}>{x}</Trans>;
}

class Map extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            searchIdFilter: [],
            searchNameFilter: [],
            fleetFilter: [],
            fleetFilterparam: "all_fleet",
            searchFilterParam: "all_vehicle",
            vehicleFiletparam: "all_type",
            vehicleLatArray: [],
            vehicleLngArray: [],
            vehicleIdArray: [],
            vehicleTypeArray: [],
            vehicleConnectionArray: [],
            mapLatCenter: 38.91254086886275,
            mapLngCenter: -77.01691019125292,
            mapZoom: 5,
            searchVehicleId: 0,
            searchVehicleName: 0,
            searchVehicleConnection: "no data",
            searchVehicleLat: 0,
            searchVehicleLng: 0,
            searchMap: false,
            noVehicleFound: false,
            userType:[],
        }
        this.locationMap = React.createRef();
        this.handleFleetChange = this.handleFleetChange.bind(this);
        this.handleSelectVehicleType = this.handleSelectVehicleType.bind(this);
        this.handleSearchVehicle = this.handleSearchVehicle.bind(this);
        this.confirmNoVehicleFound = this.confirmNoVehicleFound.bind(this);
        this.inputVehicleCheck = this.inputVehicleCheck.bind(this);


    }
    confirmNoVehicleFound() {
        this.setState({
            noVehicleFound: false,
        })
    }
    async inputVehicleCheck(e) {
        const inputValue = e.target.value;
        if (e.key) {
            if (!inputValue) {
                const formData = new URLSearchParams();
                formData.append('fleet_filter_param', this.state.fleetFilterparam);
                formData.append('vehicle_type_param', this.state.vehicleFiletparam);
                await fetch("https://admin.connect-ink.com/API/elevate_map/map_page_filer.php",
                    {
                        method: 'POST',
                        headers: {
                            'Content-Type': "application/x-www-form-urlencoded;charset=UTF-8"
                        },
                        body: formData.toString()
                    }
                )
                    .then(res => res.json())
                    .then(
                        (data) => {
                            this.setState({
                                vehicleLatArray: data.vehicle_lat,
                                vehicleLngArray: data.vehicle_lng,
                                vehicleIdArray: data.vehicle_id,
                                vehicleTypeArray: data.map_user_type,
                                vehicleConnectionArray: data.vehicle_connection,
                                mapLatCenter: data.select_fleet_lat,
                                mapLngCenter: data.select_fleet_lng,
                                mapZoom: data.select_fleet_zoom,
                                searchMap: false,
                            });
                        }
                    );
            }
        }
    }
    async handleSearchVehicle(e) {
        e.preventDefault();
        const data = new FormData(e.target);
        const formData = new URLSearchParams();
        formData.append('vehicle_search_data', data.get('vehicle_info_input'));
        formData.append('fleet_search_data', this.state.fleetFilterparam);
        await fetch("https://admin.connect-ink.com/API/elevate_map/map_search_vehicle.php",
            {
                method: 'POST',
                headers: {
                    'Content-Type': "application/x-www-form-urlencoded;charset=UTF-8"
                },
                body: formData.toString()
            }
        )
            .then(res => res.json())
            .then(
                (data) => {
                    const locationLat = parseFloat(data.search_vehicle_info_lat);
                    const locationLng = parseFloat(data.search_vehicle_info_lng);
                    if (data.search_vehicle_info_connection !== "no data") {
                        this.setState({
                            vehicleLatArray: data.search_vehicle_info_lat,
                            vehicleLngArray: data.search_vehicle_info_lng,
                            vehicleIdArray: data.search_vehicle_info_id,
                            vehicleTypeArray: data.search_vehicle_info_type,
                            vehicleConnectionArray: data.search_vehicle_info_connection,
                            mapLatCenter: locationLat,
                            mapLngCenter: locationLng,
                            mapZoom: data.search_vehicle_zoom,
                            searchMap: false,
                        })
                    } else {
                        this.setState({
                            vehicleLatArray: 0,
                            vehicleLngArray: 0,
                            vehicleIdArray: 0,
                            vehicleTypeArray: "artist",
                            vehicleConnectionArray: "enable",
                            mapLatCenter: 0,
                            mapLngCenter: 0,
                            mapZoom: 5,
                            searchMap: false,
                            noVehicleFound: true,
                        });
                    }
                }
            )
    }
    async handleSelectVehicleType(e) {
        const typeValue = e.target.value;
        const formData = new URLSearchParams();
        formData.append('fleet_filter_param', this.state.fleetFilterparam);
        formData.append('search_filter_param', this.state.searchFilterParam);
        formData.append('vehicle_type_param', typeValue);
        await fetch("https://admin.connect-ink.com/API/elevate_map/map_page_filer.php",
            {
                method: 'POST',
                headers: {
                    'Content-Type': "application/x-www-form-urlencoded;charset=UTF-8"
                },
                body: formData.toString()
            }
        )
            .then(res => res.json())
            .then(
                (data) => {
                    this.setState({
                        vehicleFiletparam: typeValue,
                        fleetFilter: data.fleet_name,
                        vehicleLatArray: data.vehicle_lat,
                        vehicleLngArray: data.vehicle_lng,
                        vehicleIdArray: data.vehicle_id,
                        vehicleTypeArray: data.map_user_type,
                        vehicleConnectionArray: data.vehicle_connection,
                        searchMap: false,
                    });
                }
            );
    }
    async handleFleetChange(event) {
        const fleetValue = event.target.value;
        const formData = new URLSearchParams();
        formData.append('fleet_filter_param', fleetValue);
        formData.append('search_filter_param', this.state.searchFilterParam);
        formData.append('vehicle_type_param', this.state.vehicleFiletparam);
        await fetch("https://admin.connect-ink.com/API/elevate_map/map_page_filer.php",
            {
                method: 'POST',
                headers: {
                    'Content-Type': "application/x-www-form-urlencoded;charset=UTF-8"
                },
                body: formData.toString()
            }
        )
            .then(res => res.json())
            .then(
                (data) => {
                    this.setState({
                        fleetFilterparam: fleetValue,
                        fleetFilter: data.fleet_name,
                        vehicleLatArray: data.vehicle_lat,
                        vehicleLngArray: data.vehicle_lng,
                        vehicleIdArray: data.vehicle_id,
                        vehicleTypeArray: data.map_user_type,
                        vehicleConnectionArray: data.vehicle_connection,
                        mapLatCenter: data.select_fleet_lat,
                        mapLngCenter: data.select_fleet_lng,
                        mapZoom: data.select_fleet_zoom,
                        searchMap: false,
                    });
                }
            );
    }
    async componentDidMount() {
        const formData = new URLSearchParams();
        formData.append('fleet_filter_param', this.state.fleetFilterparam);
        formData.append('search_filter_param', this.state.searchFilterParam);
        formData.append('vehicle_type_param', this.state.vehicleFiletparam);
        await fetch("https://admin.connect-ink.com/API/elevate_map/map_page_filer.php",
            {
                method: 'POST',
                headers: {
                    'Content-Type': "application/x-www-form-urlencoded;charset=UTF-8"
                },
                body: formData.toString()
            }
        )
            .then(res => res.json())
            .then(
                (data) => {
                    this.setState({
                        searchIdFilter: data.vehicle_search_info_id,
                        searchNameFilter: data.vehicle_search_info_name,
                        fleetFilter: data.fleet_name,
                        vehicleLatArray: data.vehicle_lat,
                        vehicleLngArray: data.vehicle_lng,
                        vehicleIdArray: data.vehicle_id,
                        vehicleTypeArray: data.map_user_type,
                        vehicleConnectionArray: data.vehicle_connection,
                        mapLatCenter: data.select_fleet_lat,
                        mapLngCenter: data.select_fleet_lng,
                        mapZoom: data.select_fleet_zoom,
                        searchMap: false,
                    });

                }
            );
    }
    render() {
        let noVehicleFound = this.state.noVehicleFound ? "show_no_vehicle_found" : "hide_no_vehicle_found";
        let all_map = this.state.searchMap ? "hide_all_map" : "show_all_map";
        let search_map = this.state.searchMap ? "show_search_map" : "hide_search_map";
        const searchMapVehicleInfo = [  this.state.searchVehicleId, 
                                        this.state.searchVehicleName, 
                                        this.state.searchVehicleConnection, 
                                        this.state.searchVehicleLat, 
                                        this.state.searchVehicleLng, 
                                        this.state.vehicleTypeArray, 
                                        this.state.vehicleLatArray, 
                                        this.state.vehicleLngArray  
                                      ];
        const mapVehicleInfo = [this.state.vehicleIdArray, this.state.vehicleLatArray, this.state.vehicleLngArray, this.state.vehicleConnectionArray, this.state.vehicleTypeArray];
        const mapSetView = [this.state.mapLatCenter, this.state.mapLngCenter, this.state.mapZoom];
        const searchIdArray = this.state.searchIdFilter;
        const searchArray = searchIdArray.concat(this.state.searchNameFilter);
        let allMapPosition = [this.state.mapLatCenter, this.state.mapLngCenter];
        let allMapZoom = this.state.mapZoom;
        let searchMapPosition = [this.state.searchVehicleLat, this.state.searchVehicleLng];
        let serchTitle = "Search by User ID, User Name";
        return (
            <div className='map_contain'>
                <div>
                    <b className="title_map"><Trans>Map</Trans></b>
                    <table className="table_map">
                        <tbody>
                            <tr>
                                <td>
                                    <table className='menubar_table'>
                                        <tr>
                                            <td>
                                                {/* <div className="menubar_left">
                                                    <select className="map_location_select" onChange={this.handleFleetChange} value={this.state.value}>
                                                        <option value="all_fleet" ><Trans>All Zones</Trans></option>
                                                        {this.state.fleetFilter.map((x) =>
                                                            <option value={x}>{x}</option>)}
                                                    </select>
                                                </div> */}
                                            </td>
                                            <td>
                                                <div className='menubar_right'>
                                                    <div className="search_bicycle_filter">
                                                        <form onSubmit={this.handleSearchVehicle}>
                                                            <input type="search" list="suggestions" name="vehicle_info_input" placeholder="Search by User ID, User Name" className="vehicle_search" onKeyUp={this.inputVehicleCheck} />
                                                            <datalist id="suggestions">
                                                                {searchArray.map((x) =>
                                                                    <option value={x} />)}
                                                            </datalist>
                                                            <button type="submit" className="search_vehicle_enter"><Trans>Find</Trans></button>
                                                        </form>
                                                    </div>
                                                    <div className="bicycle_type_filter">
                                                        <select className="select_vehicle" onChange={this.handleSelectVehicleType}>
                                                            <option value="all_type"><Trans>All</Trans></option>
                                                            <option value="Artist"><Trans>Artist</Trans></option>
                                                            <option value="Client"><Trans>Client</Trans></option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </table>
                                </td>
                                <td>
                                    <b><Trans>User Flag Information</Trans></b>
                                </td>

                            </tr>
                            <tr>
                                <td className='td-map'>
                                    <div className={all_map}>
                                        <MapLeaflet locationValue={mapSetView} vehiclePosition={mapVehicleInfo} ref={this.locationMap} position={allMapPosition} zoom={allMapZoom} />
                                    </div>
                                    <div className={search_map}>
                                        <SearchMapLeaflet searchVehicleInfo={searchMapVehicleInfo} position={searchMapPosition} />
                                    </div>
                                </td>
                                <td className='td-vehicle-info'>
                                    <table style={{ marginTop: "20px", borderSpacing: "5px" }}>
                                        <tbody>
                                            <tr>
                                                <td style={{ width: "60px" }}>
                                                    <img src={unlockFailMarker} alt="Logo" className="icon_marker" />
                                                </td>
                                                <td>
                                                    <p><Trans>Artist Enable</Trans></p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{ width: "60px" }}>
                                                    <img src={unavailableMarker} alt="Logo" className="icon_marker" />
                                                </td>
                                                <td>
                                                    <p><Trans>Artist Invisible</Trans></p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{ width: "60px" }}>
                                                    <img src={serviceOnMarker} alt="Logo" className="icon_marker" />
                                                </td>
                                                <td>
                                                    <p><Trans>Client Enable</Trans></p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{ width: "60px" }}>
                                                    <img src={serviceOffMarker} alt="Logo" className="icon_marker" />
                                                </td>
                                                <td>
                                                    <p><Trans>Client Invisible</Trans></p>
                                                </td>
                                            </tr>

                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className={noVehicleFound}>
                    <p>No User Found</p>
                    <button className="no_vehicle_found_button" onClick={this.confirmNoVehicleFound}>OK</button>
                </div>
            </div>
        )
    }
}
export default Map;