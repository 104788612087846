import React from 'react';
import { MDBDataTable } from 'mdbreact';
import './Promos.css';
import PromoBatchesTableCSV from './Promos_batches_csv';
import { Trans } from 'react-i18next';

class PromosBatchestablePage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    render() {
        const rowData = this.props.tableData;
        const rowDataId = rowData[0];
        const rowDataName = rowData[1];
        const rowDataType = rowData[2];
        const rowDataEvent = rowData[3];
        const rowDataCode = rowData[4];
        const rowDataFleet = rowData[5];
        const rowDataStartDate = rowData[6];
        const rowDataExpiredDate = rowData[7];
        const numRows = rowDataId.length;
        const name_array = Array.from(new Set(rowDataName));
        const name_array_length = name_array.length;

        console.log(name_array);
        const rowsData = [];

        for (var j = 0; j < name_array_length; j++) {
            const valueindex = {
                batch_name: name_array[j],
            };
            var code_array = [];
            console.log("tefgeyudgf:");
            console.log(code_array);
            for (var i = 0; i < numRows; i++) {
                if (rowDataName[i] == name_array[j]) {
                    code_array.push(rowDataCode[i]);
                    const code_array_length = code_array.length;
                    valueindex.promo_type = rowDataType[i];
                    valueindex.total = code_array_length;
                    valueindex.promo_csv = <button className="promo_batch_csv" type="button"><PromoBatchesTableCSV
                        tableCSVData={code_array}
                        batchName={rowDataName[i]}
                        batchFleet={rowDataFleet[i]}
                        batchStartDate={rowDataStartDate[i]}
                        batchExpiredDate={rowDataExpiredDate[i]}
                    />
                    </button>;
                    
                }

            }
            rowsData.push(valueindex);
        }




        const data = {
            columns: [
                {
                    label: <Trans>Name</Trans>,
                    field: 'batch_name',
                    sort: 'asc',
                    width: 400
                },
                {
                    label: <Trans>Type</Trans>,
                    field: 'promo_type',
                    sort: 'asc',
                    width: 200
                },
                {
                    label: <Trans>Total</Trans>,
                    field: 'total',
                    sort: 'asc',
                    width: 200
                },
                {
                    label: <Trans>CSV</Trans>,
                    field: 'promo_csv',
                    sort: 'asc',
                    width: 200
                },
            ],
            rows: rowsData,
        };
        return (
            <div className='fleets_table' >
                <MDBDataTable
                    hover
                    scrollX
                    maxHeight='50vh'
                    entriesOptions={[5, 10, 20]}
                    entries={5}
                    data={data}
                    disableRetreatAfterSorting={true}
                />
            </div>
        );
    }
}

export default PromosBatchestablePage;