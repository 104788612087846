import React from 'react';
import './Role_list.css';
import * as AiIcons from 'react-icons/ai';
import RoletablePage from './Role_table';
import { Trans } from 'react-i18next';
import Cookies from 'universal-cookie';

class Role_list extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            role_id: [],
            role_permission: [],
            role_name: [],
            add_role_button: false,
            add_role_form_footer: true,
            role_table: true,
            add_role_data: {
                add_role_id: null,
                add_role_name: null,
                add_role_permission: null,
            },
            confirm_add_role: false,
            editFormState: false,
            editFormConfirmState: false,
            editRoleId: null,
            editRoleName: null,
            editRoleLevel: null,
            edit_role_form_footer: true,
            deleteRoleConfirmState: false,
            delete_role_id: null,
            delete_role_name: null
        }
        this.addRole = this.addRole.bind(this);
        this.handleCancelAddRole = this.handleCancelAddRole.bind(this);
        this.handlelAddRoleSubmit = this.handlelAddRoleSubmit.bind(this);
        this.handleConfirmAddRole = this.handleConfirmAddRole.bind(this);
        this.openEditRoleForm = this.openEditRoleForm.bind(this);
        this.handleCancelEditRole = this.handleCancelEditRole.bind(this);
        this.handlelEditRoleSubmit = this.handlelEditRoleSubmit.bind(this);
        this.handleDeleteRoleForm = this.handleDeleteRoleForm.bind(this);
        this.handleCancelDeleteRole = this.handleCancelDeleteRole.bind(this);
        this.handleConfirmDeleteRole = this.handleConfirmDeleteRole.bind(this);

    }
    render() {
        let editForm = this.state.editFormState ? "showEditRoleForm" : "hideEditRoleForm";
        let editRoleFormFooter = this.state.edit_role_form_footer ? "editFormFooter" : "editFormFooter_hide";
        let editRoleConfirm = this.state.editFormConfirmState ? "edit_role_show" : "edit_role_hide";
        let deleteRoleConfirm = this.state.deleteRoleConfirmState ? "delete_role_show" : "delete_role_hide";
        const roleTableData = [this.state.role_permission, this.state.role_name, this.state.role_id];
        let addRoleForm = this.state.add_role_button ? "add_role_show" : "add_role_hide";
        let addRoleFormFooter = this.state.add_role_form_footer ? "add_role_footer" : "add_role_footer_hide";
        let roleTable = this.state.role_table ? "role_contain" : "role_contain_hide";
        let addRoleConfirm = this.state.confirm_add_role ? "confirm_add_role_show" : "confirm_add_role_hide";
        return (
            <>
                <div className={roleTable}>
                    <div>
                        <p className="role_title"><Trans>Roles</Trans></p>
                    </div>
                    <div>
                        <table className="role_menu_bar">
                            <tbody>
                                <tr>
                                    <td className='rightside_menu'>
                                        <button className="btn_add_role" onClick={this.addRole}><AiIcons.AiOutlinePlus /> <Trans>Add Role</Trans></button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <RoletablePage dataTable={roleTableData} openEditRoleForm={this.openEditRoleForm} openDeleteRoleForm={this.handleDeleteRoleForm} />
                    </div>
                </div>
                <div className={editForm}>
                    <div className="add_location_form_header"><b><Trans>Edit Role</Trans></b></div>
                    <form onSubmit={this.handlelEditRoleSubmit}>
                        <div className="form">
                            <div className="form-group">
                                <label><Trans>Role ID</Trans></label>
                                <input type="text" name="editRoleId" value={this.state.editRoleId} required />
                            </div>
                            <div className="form-group">
                                <label><Trans>Role Name</Trans></label>
                                <input type="text" name="editRoleName" defaultValue={this.state.editRoleName} required />
                            </div>
                            <div className="form-group">
                                <label><Trans>Role Level</Trans></label>
                                <select name="editRoleLevel">
                                    <option>1</option>
                                    <option>2</option>
                                    <option>3</option>
                                    <option>4</option>
                                    <option>5</option>
                                </select>
                            </div>
                        </div>
                        <div className={editRoleFormFooter}>
                            <button type="button" className="btn_cancel_adding" onClick={this.handleCancelEditRole}>
                                <Trans>Cancel</Trans>
                            </button>
                            <button type="submit" className="btn_cancel_adding">
                                <Trans>Edit Role</Trans>
                            </button>
                        </div>
                    </form>
                </div>
                <div className={editRoleConfirm}>
                    <p className="add_role_confirm_title"><Trans>The Role</Trans> {this.state.editRoleName} <Trans>has been edited recently</Trans> !</p>
                    <button className="add_role_confirm_button" type="button" onClick={this.handleConfirmAddRole} >
                        OK
                    </button>
                </div>
                <div className={deleteRoleConfirm}>
                    <form onSubmit={this.handleConfirmDeleteRole}>
                        <div style={{ display: "none" }}>
                            <div>
                                <input type="text" name="deleteRoleName" value={this.state.delete_role_id} required />
                            </div>

                        </div>
                        <p className="add_role_confirm_title"><Trans>The Role</Trans> {this.state.delete_role_name} <Trans>will be deleted</Trans> !</p>
                        <button type="button" className="add_role_cancel_button" onClick={this.handleCancelDeleteRole}>
                            <Trans>Cancel</Trans>
                        </button>
                        <button type="submit" className="add_role_confirm_button">
                            <Trans>Delete</Trans>
                        </button>
                    </form>
                </div>
                <div className={addRoleForm}>
                    <div className="add_location_form_header"><b><Trans>Add Role</Trans></b></div>
                    <form onSubmit={this.handlelAddRoleSubmit}>
                        <div className="form">
                            <div className="form-group">
                                <label><Trans>Role Name</Trans></label>
                                <input type="text" name="roleName" placeholder="Role Name" required />
                            </div>
                            <div className="form-group">
                                <label><Trans>Role Level</Trans></label>
                                <select name="addRoleLevel">
                                    <option>1</option>
                                    <option>2</option>
                                    <option>3</option>
                                    <option>4</option>
                                    <option>5</option>
                                </select>
                            </div>
                        </div>
                        <div className={addRoleFormFooter}>
                            <button type="button" className="btn_cancel_adding" onClick={this.handleCancelAddRole}>
                                <Trans>Cancel</Trans>
                            </button>
                            <button type="submit" className="btn_cancel_adding">
                                <Trans>Add new role</Trans>
                            </button>
                        </div>
                    </form>
                </div>
                <div className={addRoleConfirm}>
                    <p className="add_role_confirm_title"><Trans>The Role</Trans> {this.state.add_role_data.add_role_name} <Trans>has been added recently</Trans> !</p>
                    <button className="add_role_confirm_button" type="button" onClick={this.handleConfirmAddRole}>
                        OK
                    </button>
                </div>

            </>
        )
    }
    addRole() {
        this.setState({
            add_role_button: true,
            role_table: false,
            add_role_form_footer: true,
        })
    }
    handleCancelAddRole() {
        this.setState({
            add_role_button: false,
            role_table: true,
        })
    }
    async handlelAddRoleSubmit(e) {
        const cookies = new Cookies();
        e.preventDefault();
        const data = new FormData(e.target);
        const formAddRoleData = new URLSearchParams();
        formAddRoleData.append('add_role_name', data.get('roleName'));
        formAddRoleData.append('add_role_level', data.get('addRoleLevel'));
        formAddRoleData.append('admin_name', cookies.get('adminName'));
        await fetch("https://admin.connect-ink.com/API/organisations/org_add_role.php",
            {
                method: 'POST',
                headers: {
                    'Content-Type': "application/x-www-form-urlencoded;charset=UTF-8"
                },
                body: formAddRoleData.toString()
            }
        )
            .then(res => res.json())
            .then(
                (data) => {
                    this.setState({
                        add_role_data: {
                            add_role_id: data.role_id,
                            add_role_name: data.role_name,
                            add_role_permission: data.permission,
                        },
                        confirm_add_role: true,
                        add_role_form_footer: false,
                    });
                }
            );
    }
    async handleConfirmAddRole() {
        await fetch("https://admin.connect-ink.com/API/organisations/org_role_list.php")
            .then(res => res.json())
            .then(
                (data) => {
                    this.setState({
                        role_id: data.role_id,
                        role_permission: data.role_permission,
                        role_name: data.role_name,
                        add_role_button: false,
                        confirm_add_role: false,
                        editFormState: false,
                        editFormConfirmState: false,
                        confirm_add_role: false,
                        role_table: true,
                    });
                }
            );
    }
    async handlelEditRoleSubmit(e) {
        e.preventDefault();
        const cookies = new Cookies();
        const data = new FormData(e.target);
        const formAddRoleData = new URLSearchParams();
        formAddRoleData.append('edit_role_name', data.get('editRoleName'));
        formAddRoleData.append('edit_role_level', data.get('editRoleLevel'));
        formAddRoleData.append('edit_role_id', data.get('editRoleId'));
        formAddRoleData.append('admin_name', cookies.get('adminName'));
        await fetch("https://admin.connect-ink.com/API/organisations/org_edit_role.php",
            {
                method: 'POST',
                headers: {
                    'Content-Type': "application/x-www-form-urlencoded;charset=UTF-8"
                },
                body: formAddRoleData.toString()
            }
        )
            .then(res => res.json())
            .then(
                (data) => {
                    this.setState({
                        editFormState: false,
                        editFormConfirmState: true,
                        editRoleName: data.role_name,
                        editRoleLevel: data.permission,
                    });
                }
            );
    }
    handleCancelEditRole() {
        this.setState({
            editFormState: false,
        });
    }
    openEditRoleForm(e) {
        const editRoleInfo = e.currentTarget.value.split(",");
        this.setState({
            editFormState: true,
            editRoleName: editRoleInfo[0],
            editRoleLevel: editRoleInfo[1],
            editRoleId: editRoleInfo[2]
        });
    }
    handleDeleteRoleForm(e) {
        const deleteRoleInfo = e.currentTarget.value.split(",");
        this.setState({
            deleteRoleConfirmState: true,
            editFormState: false,
            delete_role_id: deleteRoleInfo[1],
            delete_role_name: deleteRoleInfo[0]
        });
    }
    handleCancelDeleteRole() {
        this.setState({
            role_table: true,
            deleteRoleConfirmState: false,
        });
    }
    async handleConfirmDeleteRole(e) {
        const cookies = new Cookies();
        e.preventDefault();
        const data = new FormData(e.target);
        const formDeleteRoleData = new URLSearchParams();
        formDeleteRoleData.append('delete_role_id', data.get('deleteRoleName'));
        formDeleteRoleData.append('admin_name', cookies.get('adminName'));
        await fetch("https://admin.connect-ink.com/API/organisations/org_delete_role.php",
            {
                method: 'POST',
                headers: {
                    'Content-Type': "application/x-www-form-urlencoded;charset=UTF-8"
                },
                body: formDeleteRoleData.toString()
            }
        )
            .then(res => res.json())
            .then(
                (data) => {
                    this.setState({
                        role_table: true,
                        deleteRoleConfirmState: false,
                        editFormState: false,
                        role_id: data.role_id,
                        role_name: data.role_name,
                        role_permission: data.permission,
                    });

                }
            );
    }
    async componentDidMount() {
        await fetch("https://admin.connect-ink.com/API/organisations/org_role_list.php")
            .then(res => res.json())
            .then(
                (data) => {
                    this.setState({
                        role_id: data.role_id,
                        role_permission: data.role_permission,
                        role_name: data.role_name
                    });
                    console.log("A:");
                    console.log(data.role_id);
                    console.log("B:");
                    console.log(data.role_permission);
                    console.log("C:");
                    console.log(data.role_name);

                }
            );
    }
}
export default Role_list;