import React from 'react';
import './History.css';
import HistoryTable from './History_table';
import HistoryTableCSV from './History_csv';
import { Trans, useTranslation } from 'react-i18next';

class History extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            adminFilter: [],
            adminFilterValue: "all_admin",
            timeFilterValue: "all_time",
            historyAdminId: [],
            historyAdminName: [],
            historyVehicleId: [],
            historyVehicleName: [],
            historyVehicleType: [],
            historyVehicleQrCode: [],
            historyVehicleEmei: [],
            historyAction: [],
            historyVehicleLastConnected: [],
            historyVehicleLastLockStatusUpdate: [],
            historyVehicleLastRideTime: [],
            historyDate: [],
            historyVehicleLat: [],
            historyVehicleLng: [],
            dataMapLat: 0,
            dataMapLng: 0,
        }
        this.handleAdminFilter = this.handleAdminFilter.bind(this);
        this.handleTimeFilter = this.handleTimeFilter.bind(this);
    }
    async handleTimeFilter(e){
        const timeFilterValue = e.target.value;
        const formData = new URLSearchParams();
        formData.append('admin_filter_param', this.state.adminFilterValue);
        formData.append('time_filter_param', timeFilterValue);
        await fetch("http://api.elevate-mobility.com/api/vehicle/history_table.php",
            {
                method: 'POST',
                headers: {
                    'Content-Type': "application/x-www-form-urlencoded;charset=UTF-8"
                },
                body: formData.toString()
            }
        )
            .then(res => res.json())
            .then(
                (data) => {
                    this.setState({
                        timeFilterValue: timeFilterValue,
                        historyAdminId: data.admin_id,
                        historyAdminName: data.admin_name,
                        historyVehicleId: data.vehicle_id,
                        historyVehicleName: data.vehicle_name,
                        historyVehicleType: data.vehicle_type,
                        historyVehicleQrCode: data.qr_code,
                        historyVehicleEmei: data.vehicle_emei,
                        historyAction: data.action,
                        historyVehicleLastConnected: data.last_connected,
                        historyVehicleLastLockStatusUpdate: data.last_lock_status_update,
                        historyVehicleLastRideTime: data.last_ride_time,
                        historyDate: data.date,
                        historyVehicleLat: data.vehicle_lat,
                        historyVehicleLng: data.vehicle_lng,
                        historyMessage: data.message,
                    });
                    console.log("Time filter data: " + data.date)
                }
            );

    }
    async handleAdminFilter(e) {
        const adminFilterValue = e.target.value;
        const formData = new URLSearchParams();
        formData.append('admin_filter_param', adminFilterValue);
        formData.append('time_filter_param', this.state.timeFilterValue);
        await fetch("http://api.elevate-mobility.com/api/vehicle/history_table.php",
            {
                method: 'POST',
                headers: {
                    'Content-Type': "application/x-www-form-urlencoded;charset=UTF-8"
                },
                body: formData.toString()
            }
        )
            .then(res => res.json())
            .then(
                (data) => {
                    this.setState({
                        adminFilterValue: adminFilterValue,
                        historyAdminId: data.admin_id,
                        historyAdminName: data.admin_name,
                        historyVehicleId: data.vehicle_id,
                        historyVehicleName: data.vehicle_name,
                        historyVehicleType: data.vehicle_type,
                        historyVehicleQrCode: data.qr_code,
                        historyVehicleEmei: data.vehicle_emei,
                        historyAction: data.action,
                        historyVehicleLastConnected: data.last_connected,
                        historyVehicleLastLockStatusUpdate: data.last_lock_status_update,
                        historyVehicleLastRideTime: data.last_ride_time,
                        historyDate: data.date,
                        historyVehicleLat: data.vehicle_lat,
                        historyVehicleLng: data.vehicle_lng,
                        historyMessage: data.message,
                    });
                }
            );
    }
    async componentDidMount() {
        const formData = new URLSearchParams();
        formData.append('admin_filter_param', this.state.adminFilterValue);
        formData.append('time_filter_param', this.state.timeFilterValue);
        await fetch("http://api.elevate-mobility.com/api/vehicle/history_table.php",
            {
                method: 'POST',
                headers: {
                    'Content-Type': "application/x-www-form-urlencoded;charset=UTF-8"
                },
                body: formData.toString()
            }
        )
            .then(res => res.json())
            .then(
                (data) => {
                    this.setState({
                        adminFilter: data.admin_name_filter,
                        historyAdminId: data.admin_id,
                        historyAdminName: data.admin_name,
                        historyVehicleId: data.vehicle_id,
                        historyVehicleName: data.vehicle_name,
                        historyVehicleType: data.vehicle_type,
                        historyVehicleQrCode: data.qr_code,
                        historyVehicleEmei: data.vehicle_emei,
                        historyAction: data.action,
                        historyVehicleLastConnected: data.last_connected,
                        historyVehicleLastLockStatusUpdate: data.last_lock_status_update,
                        historyVehicleLastRideTime: data.last_ride_time,
                        historyDate: data.date,
                        historyVehicleLat: data.vehicle_lat,
                        historyVehicleLng: data.vehicle_lng,
                        historyMessage: data.message,
                    });
                }
            );
    }
    render() { 
        const dataHistoryTable = [
            this.state.historyAdminId,
            this.state.historyAdminName,
            this.state.historyVehicleId,
            this.state.historyVehicleName,
            this.state.historyVehicleType,
            this.state.historyVehicleQrCode,
            this.state.historyVehicleEmei,
            this.state.historyAction,
            this.state.historyVehicleLastConnected,
            this.state.historyVehicleLastLockStatusUpdate,
            this.state.historyVehicleLastRideTime,
            this.state.historyDate,
            this.state.historyVehicleLat,
            this.state.historyVehicleLng,
            this.state.historyMessage
        ];
        return (
            <div className='history_contain'>
                <div>
                    <table className="history_menu_filter">
                        <tbody>
                            <tr>
                                <td>
                                    <div>
                                        <p className="history_title"><Trans>Vehicle History</Trans></p>
                                    </div>
                                </td>
                                <td className='history_rightside_menu'>

                                </td>
                            </tr>
                            <tr>
                                <td className='history_leftside_menu'>

                                </td>
                                <td className='history_rightside_menu'>
                                    <div className='history_button_div'>
                                        <button className="history_btn_get_csv" type="button"><HistoryTableCSV tableCSVData={dataHistoryTable} /></button>
                                        <select className="history_btn_filter" onChange={this.handleTimeFilter}>
                                            <option value={"all_time"}>Filter</option>
                                            <option value={"Today"}>Today</option>
                                            <option value={"Yesterday"}>Yesterday</option>
                                            <option value={"Last 7 days"}>Last 7 days</option>
                                            <option value={"Last 30 days"}>Last 30 days</option>
                                            <option value={"This Month"}>This Month</option>
                                            <option value={"Last Month"}>Last Month</option>
                                        </select>
                                        <select className="history_fleets_select" defaultValue={this.state.adminFilterValue} onChange={this.handleAdminFilter}>
                                            <option value='all_admin'>All Admin</option>
                                            {this.state.adminFilter.map((x) =>
                                                <option value={x}>{x}</option>)}
                                        </select>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div className='history_table_div'>
                        <Trans><HistoryTable dataTable={dataHistoryTable} showHistoryMap={this.showVehicleLocation}/></Trans>
                    </div>
                </div>
            </div>
        )
    }
}
export default History;