import React from 'react';
import { MDBDataTable } from 'mdbreact';
import './History.css'
import * as ImIcons from 'react-icons/im';
import HistoryMapLeaflet from './History_MapLeaflet';
import { Trans, useTranslation } from 'react-i18next';

class HistoryTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dataMapLat: 0,
            dataMapLng: 0,
            showMap:false
        }
    }

    render() {
        const dataMapHistory = [this.state.dataMapLat, this.state.dataMapLng];
        let historyMap = this.state.showMap ? "show_history_map" : "hide_history_map"
        const rowData = this.props.dataTable;
        const rowHistoryAdminId = rowData[0];
        const rowHistoryAdminName = rowData[1];
        const rowHistoryVehicleId = rowData[2];
        const rowHistoryVehicleName = rowData[3];
        const rowHistoryVehicleType = rowData[4];
        const rowHistoryVehicleQrCode = rowData[5];
        const rowHistoryVehicleEmei = rowData[6];
        const rowHistoryAction = rowData[7];
        const rowHistoryVehicleLastConnected = rowData[8];
        const rowHistoryVehicleLastLockStatusUpdate = rowData[9];
        const rowHistoryVehicleLastRideTime = rowData[10];
        const rowHistoryDate = rowData[11];
        const rowHistoryVehicleLat = rowData[12];
        const rowHistoryVehicleLng = rowData[13];
        const rowHistoryMessage = rowData[14];
        const numRows = rowHistoryAdminId.length;
        const rowsData = [];
        for (var i = 0; i < numRows; i++) {
            const valueindex = {
                admin_id: rowHistoryAdminId[i],
                admin_name: rowHistoryAdminName[i],
                vehicle_id: rowHistoryVehicleId[i],
                vehicle_name: rowHistoryVehicleName[i],
                vehicle_type: rowHistoryVehicleType[i],
                qr_code: rowHistoryVehicleQrCode[i],
                vehicle_emei: rowHistoryVehicleEmei[i],
                action: <Trans>{rowHistoryAction[i]}</Trans>,
                last_connected: rowHistoryVehicleLastConnected[i],
                last_lock_status_update: rowHistoryVehicleLastLockStatusUpdate[i],
                last_ride_time: rowHistoryVehicleLastRideTime[i],
                date: rowHistoryDate[i],
                history_message: rowHistoryMessage[i],
            }
            rowsData.push(valueindex);
        }
        const data = {
            columns: [
                {
                    label: <Trans>Admin ID</Trans>,
                    field: 'admin_id',
                    sort: 'asc',
                    width: 175,
                },
                {
                    label: <Trans>Admin Name</Trans>,
                    field: 'admin_name',
                    sort: 'asc',
                    width: 200,
                },
                {
                    label: <Trans>Vehicle ID</Trans>,
                    field: 'vehicle_id',
                    sort: 'asc',
                    width: 175,
                },
                {
                    label: <Trans>Vehicle Name</Trans>,
                    field: 'vehicle_name',
                    sort: 'asc',
                    width: 175,
                },
                {
                    label: <Trans>Vehicle Type</Trans>,
                    field: 'vehicle_type',
                    sort: 'asc',
                    width: 175,
                },
                {
                    label: <Trans>QR Code</Trans>,
                    field: 'qr_code',
                    sort: 'asc',
                    width: 150,
                },
                {
                    label: <Trans>EMEI</Trans>,
                    field: 'vehicle_emei',
                    sort: 'asc',
                    width: 175,
                },
                {
                    label: <Trans>Action</Trans>,
                    field: 'action',
                    sort: 'asc',
                    width: 175,
                },
                {
                    label: <Trans>Date</Trans>,
                    field: 'date',
                    sort: 'asc',
                    width: 250,
                },
                {
                    label: <Trans>Last Lock Status Update</Trans>,
                    field: 'last_lock_status_update',
                    sort: 'asc',
                    width: 350,
                },
                {
                    label: <Trans>Last Ride time</Trans>,
                    field: 'last_ride_time',
                    sort: 'asc',
                    width: 250,
                },
                {
                    label: <Trans>Last Connected</Trans>,
                    field: 'last_connected',
                    sort: 'asc',
                    width: 250,
                },
                {
                    label: <Trans>Message</Trans>,
                    field: 'history_message',
                    sort: 'asc',
                    width: 150,
                },
            ],
            rows: rowsData,
        };
        return (
            <>
                <div className='history_table'>
                    <MDBDataTable
                        hover
                        scrollX
                        maxHeight='50vh'
                        entriesOptions={[5, 10, 20]}
                        entries={5}
                        data={data}
                        disableRetreatAfterSorting={true}
                    />
                </div>
                <div classname={historyMap}>
                    {/*<HistoryMapLeaflet dataVehicleHistory={dataMapHistory} />*/}
                </div>
            </>
        );
    }


}

export default HistoryTable;