import React from 'react';
import './WalletCredit.css';
import * as FaIcons from 'react-icons/fa';
import { Trans, useTranslation } from 'react-i18next';

class WalletCredit extends React.Component {
    render() {
        return (
            <div>
                <p className='wallet_creadit_title'><Trans>Wallet Credit</Trans></p>
                <table className='walletcredit_table'>
                    <tr>
                        <td className='walletcredit_leftside_menu'><input type="search" placeholder="Type in to search" className="walletcredit_search" /></td>
                        <td className='walletcredit_rightside_menu'>
                            <div>
                                <button className="wallet_creadit_payments_btn_csv" type="button"><FaIcons.FaFileCsv /> CSV</button>
                                <select className="wallet_creadit_payments_filter">
                                    <option>Filter</option>
                                    <option>Today</option>
                                    <option>Yesterday</option>
                                    <option>Last 7 Days</option>
                                    <option>Last 30 Days</option>
                                    <option>This Month</option>
                                    <option>Last Month</option>
                                </select>
                            </div>
                        </td>

                    </tr>
                </table>

                <div>

                </div>
                <table className='walletcredit_table'>
                    <tbody>
                        <tr className='walletcredit_table_th'>
                            <td><b>Invoice ID</b></td>
                            <td><b>Tax ID</b></td>
                            <td><b>User Name</b></td>
                            <td><b>Payment type</b></td>
                            <td><b>Amount</b></td>
                            <td><b>Date</b></td>
                            <td><b>Deposite</b></td>
                            <td><b>Invoice</b></td>
                        </tr>
                        <tr className='walletcredit_table_tr'>
                            <td>-</td>
                            <td>-</td>
                            <td>-</td>
                            <td>-</td>
                            <td>-</td>
                            <td>-</td>
                            <td>-</td>
                            <td>-</td>
                        </tr>
                    </tbody>
                </table>
                <table className='wallet_debit_pagination'>
                    <tr>
                        <td className='total_showing'>Showing 0 to 0 of 0 entries</td>
                        <td className='page_btn_div'>
                            <div>
                                <button className="payments_btn_previous" type="button">Previous</button>
                                <button className="payments_btn_next" type="button">Next</button>
                            </div>
                        </td>
                    </tr>
                </table>
            </div>
        );
    }
}

export default WalletCredit;