import React from 'react';
import './Rides.css'
import { CSVLink } from "react-csv";
import * as FaIcons from 'react-icons/fa';

class RidesTableCSV extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
        this.csvLinkEl = React.createRef();
    }
    render() {
        var currentdate = new Date();
        var fileName = "Complete_Rides_Report_" + currentdate.getDate() + "_" + (currentdate.getMonth() + 1) + "_" + currentdate.getFullYear() + "_" + currentdate.getHours() + "_" + currentdate.getMinutes() + "_" + currentdate.getSeconds() + ".csv";
        const rowData = this.props.tableData;
        const rowDataRideId = rowData[0];
        const rowDataRiderPhoneNumber = rowData[1];
        const rowDataRiderId = rowData[2];
        const rowDataRiderName = rowData[3];
        const rowDataVehicleId = rowData[4];
        const rowDataVehicleQrCode = rowData[5];
        const rowDataVehicleType = rowData[6];
        const rowDataRideAmount = rowData[7];
        const rowDataRideStartPoint = rowData[8];
        const rowDataRideEndPoint = rowData[9];
        const rowDataRideDistance = rowData[10];
        const rowDataDuration = rowData[11];
        const rowDataPauseDuration = rowData[12];
        const rowDataRideDate = rowData[13];
        const rowDataRideRating = rowData[14];
        const rowDataRideForceStop = rowData[15];
        const rowDataRideFleet = rowData[16];

        const numRows = rowDataRideId.length;
        const csvData = [];
        for (var i = 0; i < numRows; i++) {
            const csvDataElement = {
                ride_id: rowDataRideId[i],
                phone_number: rowDataRiderPhoneNumber[i],
                user_id: rowDataRiderId[i],
                user_name: rowDataRiderName[i],
                vehicle_id: rowDataVehicleId[i],
                qr_code: rowDataVehicleQrCode[i],
                vehicle_type: rowDataVehicleType[i],
                ride_fleet: rowDataRideFleet[i],
                amount: rowDataRideAmount[i],
                start_point: rowDataRideStartPoint[i],
                end_point: rowDataRideEndPoint[i],
                distance: rowDataRideDistance[i],
                duration: rowDataDuration[i],
                pause_duration: rowDataPauseDuration[i],
                ride_date: rowDataRideDate[i],
                rating: rowDataRideRating[i],
                force_stop: rowDataRideForceStop[i],
            }
            csvData.push(csvDataElement);
        }
        const headers = [
            { label: "ID", key: "ride_id" },
            { label: "Phone number", key: "phone_number" },
            { label: "user ID", key: "user_id" },
            { label: "User Name", key: "user_name" },
            { label: "Vehicle ID", key: "vehicle_id" },
            { label: "QR Code", key: "qr_code" },
            { label: "Vehicle Type", key: "vehicle_type" },
            { label: "Fleet", key: "ride_fleet" },
            { label: "Amount", key: "amount" },
            { label: "Start Point", key: "start_point" },
            { label: "End Point", key: "end_point" },
            { label: "Distance", key: "distance" },
            { label: "Pause Duration", key: "pause_duration" },
            { label: "Ride Date", key: "ride_date" },
            { label: "Rating", key: "rating" },
            { label: "Force Stop", key: "force_stop" }
        ];
        const data_csv = csvData;

        return (
            <div>
                <CSVLink
                    headers={headers}
                    filename={fileName}
                    data={data_csv}
                    ref={this.csvLinkEl}
                >
                    <div style={{color:"white"}}><FaIcons.FaFileCsv /> CSV</div>
                </CSVLink>
            </div>
        );
    }


}

export default RidesTableCSV;