import React from 'react';
import './Payments.css';
import * as FaIcons from 'react-icons/fa';
import * as BsIcons from 'react-icons/bs';
import PaymentsChart from './PaymentsChart';
import PaymentsTabs from './PaymentsTabs';
import { Trans, useTranslation } from 'react-i18next';

class Payments extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currency: "VND",
        }
        this.setCurrencies = this.setCurrencies.bind(this);
    }
        setCurrencies(e) {
            const valueCurrenciesState = e.target.value;
            this.setState({currency:valueCurrenciesState});
        }
    render() {
        return (
            <div className="div_all">
                <div>
                    <b className="payments_title"><Trans>Payments</Trans></b>
                </div>
                <div>
                    <table className='payments_filter_bar'>
                        <tbody>
                            <tr>
                                <td>
                                    <div className='payments_filter_div'>
                                        <select className="payments_currencies_select" onChange = {this.setCurrencies}>
                                            <option value = "VND">VND</option>
                                            <option value = "USD">USD</option>
                                        </select>
                                        <select className="payments_fleets_select">
                                            <option><Trans>All Zones</Trans></option>
                                            <option>Hoi An</option>
                                            <option>Hanoi 1</option>
                                            <option>Hanoi 2</option>
                                            <option>HoChiMinh City</option>
                                        </select>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <table className="payments_dashboard_table">
                        <tbody>
                            <tr>
                                <td className="payments_analysis_info">
                                    <div className='payments_table_div'>
                                        <table className="payments_table">
                                            <tbody>
                                                <tr>
                                                    <td className='payments_type_icon'>
                                                        <div className='payments_icon'>
                                                            <BsIcons.BsBarChartFill />
                                                        </div>
                                                    </td>
                                                    <td className="payments_analysis_content">
                                                        <div className='payments_analysis'>
                                                            <b>0 {this.state.currency}</b>
                                                            <p><Trans>Average Revenue per day</Trans></p>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </td>

                                <td className="payments_analysis_info">
                                    <div className='payments_table_div'>
                                        <table className="payments_table">
                                            <tbody>
                                                <tr>
                                                    <td className='payments_type_icon'>
                                                        <div className='payments_icon'>
                                                            <BsIcons.BsBarChartFill />
                                                        </div>
                                                    </td>
                                                    <td className="payments_analysis_content">
                                                        <div className='payments_analysis'>
                                                            <b>0 {this.state.currency}</b>
                                                            <p><Trans>Total Revenue</Trans></p>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </td>

                                <td className="payments_analysis_info">
                                    <div className='payments_table_div'>
                                        <table className="payments_table">
                                            <tbody>
                                                <tr>
                                                    <td className='payments_type_icon'>
                                                        <div className='payments_icon'>
                                                            <BsIcons.BsBarChartFill />
                                                        </div>
                                                    </td>
                                                    <td className="payments_analysis_content">
                                                        <div className='payments_analysis'>
                                                            <b>0 {this.state.currency}</b>
                                                            <p><Trans>Average Top Up</Trans></p>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td className="payments_analysis_info">
                                    <div className='payments_table_div'>
                                        <table className="payments_table">
                                            <tbody>
                                                <tr>
                                                    <td className='payments_type_icon'>
                                                        <div className='payments_icon'>
                                                            <BsIcons.BsBarChartFill />
                                                        </div>
                                                    </td>
                                                    <td className="payments_analysis_content">
                                                        <div className='payments_analysis'>
                                                            <b>0 {this.state.currency}</b>
                                                            <p><Trans>Total Wallet Size</Trans></p>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </td>
                                <td className="payments_analysis_info">
                                    <div className='payments_table_div'>
                                        <table className="payments_table">
                                            <tbody>
                                                <tr>
                                                    <td className='payments_type_icon'>
                                                        <div className='payments_icon'>
                                                            <BsIcons.BsBarChartFill />
                                                        </div>
                                                    </td>
                                                    <td className="payments_analysis_content">
                                                        <div className='payments_analysis'>
                                                            <b>0 {this.state.currency}</b>
                                                            <p><Trans>Average Wallet Size</Trans></p>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </td>
                                <td className="payments_analysis_info">
                                    <div className='payments_table_div'>
                                        <table className="payments_table">
                                            <tbody>
                                                <tr>
                                                    <td className='payments_type_icon'>
                                                        <div className='payments_icon'>
                                                            <BsIcons.BsBarChartFill />
                                                        </div>
                                                    </td>
                                                    <td className="payments_analysis_content">
                                                        <div className='payments_analysis'>
                                                            <b>0 VND</b>
                                                            <p><Trans>Average Lifetime Spend</Trans></p>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <table className='payments_chart'>
                        <tbody>
                            <tr>
                                <td className='analysis_chart'>
                                    <div className="payments_revenue_chart">
                                        <PaymentsChart />
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className='payments_tab_panel'>
                    <PaymentsTabs />
                </div>
            </div>
        );
    }
}

export default Payments;