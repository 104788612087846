import React from 'react';
import { MDBDataTable } from 'mdbreact';
import './Tickets.css';
import * as FaIcons from 'react-icons/fa';
import * as AiIcons from 'react-icons/ai';
import { Trans} from 'react-i18next';

class IssuesTablePage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    render() {
        const rowData = this.props.tableData;
        const rowIssueId = rowData[0];
        const rowIssueRider = rowData[1];
        const rowIssuePhone = rowData[2];
        const rowIssueEmail = rowData[3];
        const rowIssueContain = rowData[4];
        const rowIssueDate = rowData[5];
        const rowIssueFleet = rowData[6];
        const numRows = rowIssueId.length;
        const rowsData = [];
        for (var i = 0; i < numRows; i++) {
            const button_value = rowIssueId[i]+"#"+rowIssueRider[i]+"#"+rowIssuePhone[i]+"#"+rowIssueEmail[i]+"#"+rowIssueContain[i]+"#"+rowIssueDate[i]+"#"+rowIssueFleet[i];
            //const buttonValueArray = Array.from(button_value);
            const valueindex = {
                issue_id: rowIssueId[i],
                issue_user: rowIssueRider[i],
                issue_phone: rowIssuePhone[i],
                issue_email: rowIssueEmail[i],
                issue_contain: rowIssueContain[i],
                issue_date: rowIssueDate[i],
                issue_fleet: rowIssueFleet[i],
                issue_assign: <button className="issue_get_ticket_button" value={button_value} onClick={this.props.getTicket}><AiIcons.AiOutlinePlus /> Get Ticket</button>,
            }
            rowsData.push(valueindex);
        }
        const data = {
            columns: [
                {
                    label: 'ID',
                    field: 'issue_id',
                    sort: 'asc',
                    width: 150
                },
                {
                    label: <Trans>User</Trans>,
                    field: 'issue_user',
                    sort: 'asc',
                    width: 200
                },
                {
                    label: <Trans>Phone</Trans>,
                    field: 'issue_phone',
                    sort: 'asc',
                    width: 300
                },
                {
                    label: <Trans>Email</Trans>,
                    field: 'issue_email',
                    sort: 'asc',
                    width: 200
                },
                {
                    label: <Trans>Issue</Trans>,
                    field: 'issue_contain',
                    sort: 'asc',
                    width: 175
                },
                {
                    label: <Trans>Date</Trans>,
                    field: 'issue_date',
                    sort: 'asc',
                    width: 150
                },
                {
                    label: <Trans>Fleet</Trans>,
                    field: 'issue_fleet',
                    sort: 'asc',
                    width: 150
                },
                {
                    label: <Trans>Assign</Trans>,
                    field: 'issue_assign',
                    sort: 'asc',
                    width: 150
                },
            ],
            rows: rowsData,
        };
        return (
            <div className='fleets_table'>
                <MDBDataTable
                    hover
                    scrollX
                    maxHeight='50vh'
                    entriesOptions={[5, 10, 20]} 
                    entries={5}
                    data={data}
                    disableRetreatAfterSorting={true}
                />
            </div>
        );
    }
}

export default IssuesTablePage