import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { IconContext } from 'react-icons/lib';
import logo from './tatu_logo.png';
import Notification from './Notification/Notification';
import './Sidebar.css';
import AvatarCard from './AvatarCard'
import NavSidebar from './NavSidebar';
import * as FaIcons from 'react-icons/fa';
import * as AiIcons from 'react-icons/ai';
import Cookies from 'universal-cookie';


const Nav = styled.div`
  background: #15171c;
  height: 60px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const NavIcon = styled(Link)`
  margin-left: 2rem;
  font-size: 15px;
  height: 60px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const SidebarNav = styled.nav`
  background: #15171c;
  width: 220px;
  height: 100vh;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: ${({ sidebar }) => (sidebar ? '0' : '-100%')};
  transition: 350ms;
  z-index: 10;
`;

const SidebarWrap = styled.div`
  width: 100%;
`;


class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hide: this.props.avatarCardState,
      sidebar: false,
      notificationState: false,
      eventAdmin: [],
      eventNotification: [],
      eventTimestamp: [],
      notification_array:[],
      unreadCount: false,
      unreadCountValue: 0,
    };
     this.wrapperRef = React.createRef();
     this.handleClickOutside = this.handleClickOutside.bind(this);
     this.showSidebar = this.showSidebar.bind(this);
     this.showNotification = this.showNotification.bind(this);
     this.handlePostData = this.handlePostData.bind(this);
  }

    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
            this.setState({
                notificationState: false
            });
        }
    }
  showSidebar = () => {
    this.setState({
      sidebar: !this.state.sidebar
    });
  };

  showNotification = () => {
      if(this.state.notificationState == false){
        this.setState({
            notificationState: true
        });
      }else{
        this.setState({
            notificationState: false
        });  
      }
  };
  async handlePostData() {
    const cookies = new Cookies();
    const formAdminData = new URLSearchParams();
    formAdminData.append('admin_id_param', cookies.get('adminID'));
    //formAdminData.append('admin_id_param', "2");
    await await fetch("https://api.connect-ink.com/API/TATU/API/admin_notification/mark_read_notifications.php",
      {
        method: 'POST',
        headers: {
          'Content-Type': "application/x-www-form-urlencoded;charset=UTF-8"
        },
        body: formAdminData.toString()
      })
      .then(async res => await res.json())
      .then(
        async (data) => {
          await this.setState({
            eventAdmin: data.event_admin,
            eventNotification: data.event_notification,
            eventTimestamp: data.event_timestamp,
            notification_array: data.notification_array,
          });
          if(parseInt(data.unread_notification) > 0){
            this.setState({
                unreadCount: true,
                unreadCountValue: data.unread_notification,
            });
          }else{
            this.setState({
                unreadCount: false,
                unreadCountValue: 0,
            }); 
          }
        }
       );
  }
  componentDidMount() {
      document.addEventListener("mouseup", this.handleClickOutside);
      this.interval = setInterval(() => {
      const cookies = new Cookies();
      const formAdminData = new URLSearchParams();
      formAdminData.append('admin_id_param', cookies.get('adminID'));
      //formAdminData.append('admin_id_param', "2");
      // console.log("SIDEBAR_1");
      // console.log(cookies.get('adminID'));
      fetch("https://api.connect-ink.com/API/WEB_ADMIN/API/admin_notification/notifications.php",
        {
          method: 'POST',
          headers: {
            'Content-Type': "application/x-www-form-urlencoded;charset=UTF-8"
          },
          body: formAdminData.toString()
        })
        .then(res => res.json())
        .then(
          (res) => {
            // console.log("SIDEBAR_1");
            // console.log(res.test_time);
            this.setState({
              eventAdmin: res.event_admin,
              eventNotification: res.event_notification,
              eventTimestamp: res.event_timestamp,
              notification_array: res.notification_array,
            });
            
            if(res.unread_notification > 0){
                this.setState({
                    unreadCount: true,
                    unreadCountValue: res.unread_notification,
                });
            }else{
                this.setState({
                    unreadCount: false
                }); 
            }
          }
        )
    }, 5000);
  }
  componentWillUnmount() {
    document.removeEventListener("mouseup", this.handleClickOutside);
    clearInterval(this.interval);
    
  }
  render() {
    let avatar_card = this.props.avatarCardState ? "avatar_card_hide" : "avatar_card_show";
    let notification_button = this.state.notificationState ?  "show_notification" : "hide_notification";
    let unread_count = this.state.unreadCount ? "show_unread_count" : "hide_unread_count";
    var notificationArray = this.state.notification_array;
    return (
        <>
            <div className="SidebarDiv" onClick={this.props.outsideClick}>
                <IconContext.Provider value={{ color: '#fff' }}>
                <div style={{ width: '100%' }}>
                    <Nav>
                        <NavIcon to='#'>
                            <FaIcons.FaBars onClick={this.showSidebar} />
                        </NavIcon>
                        <div className='elevate-logo'>
                            <img src={logo} />
                        </div>
                        <div className='nav-info-bar' style={{zIndex:999}}>
                            <div style={{ float: "right" }}>
                                <div className={unread_count}>
                                    {this.state.unreadCountValue}
                                </div>
                                <button className='nav-notification-button' ref={this.wrapperRef} onClick={this.showNotification}><FaIcons.FaBell /></button>
                                <button className='nav-user-button' onClick={this.props.avatarClick}><FaIcons.FaUserCircle /></button>
                                <div className={avatar_card}>
                                    <AvatarCard lngValue={this.props.langValue} changLng={this.props.changeLangues}/>
                                </div>
                            </div>
                        </div>
                    </Nav>
                </div>
                <SidebarNav sidebar={this.state.sidebar}>
                    <SidebarWrap style={{color:"white"}}>
                        <NavIcon to='#'>
                            <AiIcons.AiOutlineClose onClick={this.showSidebar} />
                        </NavIcon>
                        <NavSidebar/>
                    </SidebarWrap>
                 </SidebarNav>

                </IconContext.Provider>
            </div>
            <div  className={notification_button} ref={this.wrapperRef}>
              <h3 className="notification-header">Notification</h3>
              <div>
                <button className="mark_as_read_button" onClick={this.handlePostData}><AiIcons.AiOutlineBook/> Mark all as read</button>
                <div className="notification_content_list">
                    <ul className="notification_content_ul">
                        {
                        notificationArray.map((x)=> 
                                <li className="notification_content">
                                    {x.event_person + " " +x.event_notification + "\n"}
                                    <br/>
                                    {"Date: " + x.event_timestamp}
                                </li>
                        )}
                    </ul>
                </div>
              </div>
            </div>
        </>
    );
  }
};

export default Sidebar;
