import React from 'react';
import { MDBDataTable } from 'mdbreact';
import './Parkings';
import { Trans} from 'react-i18next';

class ServiceAreastablePage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    render() {
        const rowData = this.props.tableData;
        const rowDataServiceAreaId = rowData[0];
        const rowDataServiceAreaName = rowData[1];
        const rowDataServiceAreaFleet = rowData[2];
        const rowDataServiceAreaStatus = rowData[3];
        const rowDataServiceAreaCreatedDate = rowData[4];
        const rowDataServiceAreaModifyDate = rowData[5];

        const numRows = rowDataServiceAreaId.length;
        const rowsData = [];
        for (var i = 0; i < numRows; i++) {
            const valueindex = {
                service_area_id: rowDataServiceAreaId[i],
                service_area_name: rowDataServiceAreaName[i],
                fleet: rowDataServiceAreaFleet[i],
                created_date: rowDataServiceAreaCreatedDate[i],
                modify_date: rowDataServiceAreaModifyDate[i],
                status: rowDataServiceAreaStatus[i],
                action: <><button className="edit_button_geofence" value={rowDataServiceAreaId[i]} onClick={this.props.editServiceArea}><Trans>Edit</Trans></button><button className="edit_button_geofence" style={{marginLeft: "10px"}} value={rowDataServiceAreaId[i]} onClick={this.props.deleteZone}><Trans>Delete</Trans></button></>,
            }
            rowsData.push(valueindex);
        }
        const data = {
            columns: [
                {
                    label: 'Area ID',
                    field: 'service_area_id',
                    sort: 'asc',
                    width: 150
                },
                {
                    label: <Trans>Service Area Name</Trans>,
                    field: 'service_area_name',
                    sort: 'asc',
                    width: 250
                },
                {
                    label: <Trans>Fleet</Trans>,
                    field: 'fleet',
                    sort: 'asc',
                    width: 175
                },
                {
                    label: <Trans>Created Date</Trans>,
                    field: 'created_date',
                    sort: 'asc',
                    width: 150
                },
                {
                    label: <Trans>Modify Date</Trans>,
                    field: 'modify_date',
                    sort: 'asc',
                    width: 150
                },
                {
                    label: <Trans>Status</Trans>,
                    field: 'status',
                    sort: 'asc',
                    width: 150
                },
                {
                    label: <Trans>Action</Trans>,
                    field: 'action',
                    sort: 'asc',
                    width: 150
                }

            ],
            rows: rowsData,
        };
        return (
            <div className='parkings_table'>
                <MDBDataTable
                    hover
                    scrollX
                    maxHeight='50vh'
                    entriesOptions={[5, 10, 20]} 
                    entries={5}
                    data={data}
                    disableRetreatAfterSorting={true}
                />
            </div>
        );
    }
}

export default ServiceAreastablePage;