import React from 'react';
import { MDBDataTable } from 'mdbreact';
import './Parkings';
import { Trans} from 'react-i18next';

class SlowSpeedZonetablePage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    render() {
        const rowData = this.props.tableData;
        const rowDataSpeedZoneId = rowData[0];
        const rowDataSpeedZoneName = rowData[1];
        const rowDataSpeedZoneFleet = rowData[2];
        const rowDataSpeedZoneStatus = rowData[3];
        const rowDataSpeedZoneCreatedDate = rowData[4];
        const rowDataSpeedZoneModifyDate = rowData[5];

        const numRows = rowDataSpeedZoneId.length;
        const rowsData = [];
        for (var i = 0; i < numRows; i++) {
            const valueindex = {
                slow_speed_zone_id: rowDataSpeedZoneId[i],
                slow_speed_zone_name: rowDataSpeedZoneName[i],
                fleet: rowDataSpeedZoneFleet[i],
                created_date: rowDataSpeedZoneCreatedDate[i],
                modify_date: rowDataSpeedZoneModifyDate[i],
                status: rowDataSpeedZoneStatus[i],
                action: <><button className="edit_button_geofence" value={rowDataSpeedZoneId[i]} onClick={this.props.editSpeedZone}><Trans>Edit</Trans></button><button className="edit_button_geofence" style={{marginLeft: "10px"}} value={rowDataSpeedZoneId[i]} onClick={this.props.deleteZone}><Trans>Delete</Trans></button></>,
            }

            rowsData.push(valueindex);
        }
        const data = {
            columns: [
                {
                    label: 'ID',
                    field: 'slow_speed_zone_id',
                    sort: 'asc',
                    width: 150
                },
                {
                    label: <Trans>Slow Speed Zone Name</Trans>,
                    field: 'slow_speed_zone_name',
                    sort: 'asc',
                    width: 250
                },
                {
                    label: <Trans>Fleet</Trans>,
                    field: 'fleet',
                    sort: 'asc',
                    width: 175
                },
                {
                    label: <Trans>Created Date</Trans>,
                    field: 'created_date',
                    sort: 'asc',
                    width: 150
                },
                {
                    label: <Trans>Modify Date</Trans>,
                    field: 'modify_date',
                    sort: 'asc',
                    width: 150
                },
                {
                    label: <Trans>Status</Trans>,
                    field: 'status',
                    sort: 'asc',
                    width: 150
                },
                {
                    label: <Trans>Action</Trans>,
                    field: 'action',
                    sort: 'asc',
                    width: 150
                }

            ],
            rows: rowsData,
        };
        return (
            <div className='parkings_table'>
                <MDBDataTable
                    hover
                    scrollX
                    maxHeight='50vh'
                    entriesOptions={[5, 10, 20]} 
                    entries={5}
                    data={data}
                    disableRetreatAfterSorting={true}
                />
            </div>
        );
    }
}

export default SlowSpeedZonetablePage;