import React from 'react';
import './PassRevenue.css';
import * as FaIcons from 'react-icons/fa';
import { Trans, useTranslation } from 'react-i18next';

class PassRevenue extends React.Component {
    render() {
        return (
            <div>
                <p className='pass_revenue_title'><Trans>User Refund</Trans></p>
                <table className='pass_revenue_table'>
                    <tr>
                        <td className='pass_revenue_leftside_menu'><input type="search" placeholder="Type in to search" className="pass_revenue_search" /></td>
                        <td className='pass_revenue_rightside_menu'>
                            <div>
                                <button className="pass_revenue_btn_csv" type="button"><FaIcons.FaFileCsv /> CSV</button>
                            </div>
                        </td>

                    </tr>
                </table>

                <div>

                </div>
                <table className='pass_revenue_table'>
                    <tbody>
                        <tr className='pass_revenue_table_th'>
                            <td><b>User</b></td>
                            <td><b>Refund</b></td>
                            <td><b>Date</b></td>
                            <td><b>Action</b></td>                           
                        </tr>
                        <tr className='pass_revenue_table_tr'>
                            <td>-</td>
                            <td>-</td>
                            <td>-</td>
                            <td>-</td>                           
                        </tr>
                    </tbody>
                </table>
                <table className='pass_revenue_pagination'>
                    <tr>
                        <td className='pass_revenue_total_showing'>Showing 0 to 0 of 0 entries</td>
                        <td className='page_btn_div'>
                            <div>
                                <button className="pass_revenue_btn_previous" type="button">Previous</button>
                                <button className="pass_revenue_btn_next" type="button">Next</button>
                            </div>
                        </td>
                    </tr>
                </table>
            </div>
        );
    }
}

export default PassRevenue;