import React from 'react';
import { MDBDataTable } from 'mdbreact';
import './Promos.css';
import PromoBatchesTableCSV from './Promos_batches_csv';
import { Trans } from 'react-i18next';

class PaymentGatewayTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    render() {
        const rowData = this.props.tableData;
        const numRows = rowData.length;
        const rowsData = [];
        var date_charge = "";
        for (var i = 0; i < numRows; i++) {
            //Convert charge time
            var ts_charge = (rowData[i].created) * 1000;
            var date_ob = new Date(ts_charge);
            // year as 4 digits (YYYY)
            var year = date_ob.getFullYear();

            // month as 2 digits (MM)
            var month = ("0" + (date_ob.getMonth() + 1)).slice(-2);

            // date as 2 digits (DD)
            var date = ("0" + date_ob.getDate()).slice(-2);

            // hours as 2 digits (hh)
            var hours = ("0" + date_ob.getHours()).slice(-2);

            // minutes as 2 digits (mm)
            var minutes = ("0" + date_ob.getMinutes()).slice(-2);

            // seconds as 2 digits (ss)
            var seconds = ("0" + date_ob.getSeconds()).slice(-2);

            const valueindex = {
                payment_id: rowData[i].id,
                amount: rowData[i].amount/100,
                status: rowData[i].status,
                description: rowData[i].description,
                customer: rowData[i].customer,
                date: date + "-" + month + "-" + year + " " + hours + ":" + minutes + ":" + seconds,
            };
            rowsData.push(valueindex);
        }
            
        




        const data = {
            columns: [
                {
                    label: <Trans>PAYMENT ID</Trans>,
                    field: 'payment_id',
                    sort: 'asc',
                    width: 400
                },
                {
                    label: <><Trans>AMOUNT</Trans> (USD)</>,
                    field: 'amount',
                    sort: 'asc',
                    width: 200
                },
                {
                    label: <Trans>STATUS</Trans>,
                    field: 'status',
                    sort: 'asc',
                    width: 200
                },
                {
                    label: <Trans>DESCRIPTION</Trans>,
                    field: 'description',
                    sort: 'asc',
                    width: 200
                },
                  {
                    label: <Trans>CUSTOMER</Trans>,
                    field: 'customer',
                    sort: 'asc',
                    width: 200
                },
                  {
                    label: <Trans>DATE</Trans>,
                    field: 'date',
                    sort: 'asc',
                    width: 200
                },
            ],
            rows: rowsData,
        };
        return (
            <div className='fleets_table' >
                <MDBDataTable
                    hover
                    scrollX
                    maxHeight='50vh'
                    entriesOptions={[5, 10, 20]}
                    entries={5}
                    data={data}
                    disableRetreatAfterSorting={true}
                />
            </div>
        );
    }
}

export default PaymentGatewayTable;