import React from 'react';
import './Reserved.css';
import ReservedtablePage from './Reserved_table';
import { Trans, useTranslation } from 'react-i18next';

class Reserved extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fleetName: "all_fleet",
            fleetFilter: [],
            dataTable: {
                reservationId: [],
                userName: [],
                userId: [],
                vehicleName: [],
                vehicleId: [],
                qrCode: [],
                reservationStart: [],
                reservationEnd: [],
                battery: [],
                status: [],
                reservationRelease: [],
            }
        }
        this.handleFleetChange = this.handleFleetChange.bind(this);
    }
    render() {
        console.log("Cheked Data: " + this.state.dataTable.reservationId)
        const reservationTableData = [this.state.dataTable.reservationId, this.state.dataTable.userName, this.state.dataTable.userId, this.state.dataTable.vehicleName, this.state.dataTable.vehicleId, this.state.dataTable.qrCode, this.state.dataTable.reservationStart, this.state.dataTable.reservationEnd, this.state.dataTable.battery, this.state.dataTable.status, this.state.dataTable.reservationRelease];
        return (
            <div className='reserved_vehicles_contain'>
                <div>
                    <table className="reversed_vehicles_menu_filter">
                        <tbody>
                            <tr>
                                <td>
                                    <div>
                                        <p className="reserved_vehicles_title"><Trans>Reserved Vehicles</Trans></p>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td className='reversed_leftside_menu'>
                                    <div className="select_left">
                                        <select className="fleet_select" onChange={this.handleFleetChange}>
                                            <option value='all_fleet'>All Fleets</option>
                                            {this.state.fleetFilter.map((x) =>
                                                <option value={x}>{x}</option>)}
                                        </select>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div className='reserved_vehicles_table'>
                        <ReservedtablePage tableData={reservationTableData} />
                    </div>
                </div>
            </div>
        )
    }
    async handleFleetChange(e) {
        const fleetName = e.target.value;
        const formData = new URLSearchParams();
        formData.append('fleet_filter_param', fleetName);
        await fetch("http://api.elevate-mobility.com/api/vehicle/vehicle_reserved_table.php",
            {
                method: 'POST',
                headers: {
                    'Content-Type': "application/x-www-form-urlencoded;charset=UTF-8"
                },
                body: formData.toString()
            }
        )
            .then(res => res.json())
            .then(
                (data) => {
                    this.setState({
                        isLoaded: true,
                        fleetFilter: data.fleet_name,
                        fleetName:  e.target.value,
                        dataTable: {
                            reservationId: data.id,
                            userName: data.user_name,
                            userId: data.user_id,
                            vehicleName: data.vehicle_name,
                            vehicleId: data.vehicle_id,
                            qrCode: data.qr_code,
                            reservationStart: data.reservation_start,
                            reservationEnd: data.reservation_end,
                            battery: data.battery,
                            status: data.status,
                            reservationRelease: data.reservation_release
                        }
                    });
                }
            );
    }
    async componentDidMount() {
        const formData = new URLSearchParams();
        formData.append('fleet_filter_param', this.state.fleetName);
        await fetch("http://api.elevate-mobility.com/api/vehicle/vehicle_reserved_table.php",
            {
                method: 'POST',
                headers: {
                    'Content-Type': "application/x-www-form-urlencoded;charset=UTF-8"
                },
                body: formData.toString()
            }
        )
            .then(res => res.json())
            .then(
                (data) => {
                    this.setState({
                        isLoaded: true,
                        fleetFilter: data.fleet_name,
                        dataTable: {
                            reservationId: data.id,
                            userName: data.user_name,
                            userId: data.user_id,
                            vehicleName: data.vehicle_name,
                            vehicleId: data.vehicle_id,
                            qrCode: data.qr_code,
                            reservationStart: data.reservation_start,
                            reservationEnd: data.reservation_end,
                            battery: data.battery,
                            status: data.status,
                            reservationRelease: data.reservation_release
                        }
                    });
                }
            );
    }
}
export default Reserved;