import React from 'react';
import { MDBDataTable } from 'mdbreact';
import './Parkings.css';
import { Trans} from 'react-i18next';

class RestrictedAreastablePage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    render() {
        const rowData = this.props.tableData;
        const rowDataParkingsId = rowData[0];
        const rowDataParkingsName = rowData[1];
        const rowDataParkingsFleet = rowData[2];
        const rowDataParkingsStatus = rowData[3];
        const rowDataParkingsCreatedDate = rowData[4];
        const rowDataParkingsModifyDate = rowData[5];

        const numRows = rowDataParkingsId.length;
        const rowsData = [];
        for (var i = 0; i < numRows; i++) {
            const valueindex = {
                restricted_area_id: rowDataParkingsId[i],
                restricted_area_name: rowDataParkingsName[i],
                fleet: rowDataParkingsFleet[i],
                created_date: rowDataParkingsCreatedDate[i],
                modify_date: rowDataParkingsModifyDate[i],
                status: rowDataParkingsStatus[i],
                action: <><button className="edit_button_geofence" value={rowDataParkingsId[i]} onClick={this.props.editRestrictedZone}><Trans>Edit</Trans></button><button className="edit_button_geofence" style={{marginLeft: "10px"}} value={rowDataParkingsId[i]} onClick={this.props.deleteZone}><Trans>Delete</Trans></button></>,
            }

            rowsData.push(valueindex);
        }
        const data = {
            columns: [
                {
                    label: 'Area ID',
                    field: 'restricted_area_id',
                    sort: 'asc',
                    width: 150
                },
                {
                    label: <Trans>Area Name</Trans>,
                    field: 'restricted_area_name',
                    sort: 'asc',
                    width: 250
                },
                {
                    label: <Trans>Fleet</Trans>,
                    field: 'fleet',
                    sort: 'asc',
                    width: 175
                },
                {
                    label: <Trans>Created Date</Trans>,
                    field: 'created_date',
                    sort: 'asc',
                    width: 150
                },
                {
                    label: <Trans>Modify Date</Trans>,
                    field: 'modify_date',
                    sort: 'asc',
                    width: 150
                },
                {
                    label: <Trans>Status</Trans>,
                    field: 'status',
                    sort: 'asc',
                    width: 150
                },
                {
                    label: <Trans>Action</Trans>,
                    field: 'action',
                    sort: 'asc',
                    width: 150
                }

            ],
            rows: rowsData,
        };
        return (
            <div className='parkings_table'>
                <MDBDataTable
                    hover
                    scrollX
                    maxHeight='50vh'
                    entriesOptions={[5, 10, 20]} 
                    entries={5}
                    data={data}
                    disableRetreatAfterSorting={true}
                />
            </div>
        );
    }
}

export default RestrictedAreastablePage;