import React from 'react';
import './SpeedZoneAreas.css';
import * as FaIcons from 'react-icons/fa';
import { Trans } from 'react-i18next';
import Switch from "react-switch";
import SlowSpeedZonetablePage from './SpeedZoneAreas_table'
import AddSpeedZoneMap from './AddSpeedZoneMap';
import EditSpeedZoneMap from './EditSpeedZoneMap';
import Cookies from 'universal-cookie';

class SpeedZoneAreas extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fleetName: [],
            fleetNameAddParkings: [],
            fleetNameEditParkings: [],
            fleetFilterParam: "all_fleet",
            activeFilterparam: "all",
            activeFilterState: false,
            slowSpeedZoneTable: true,
            addSlowSpeedZone: false,
            editSlowSpeedZone: false,
            addSlowSpeedZoneFormConfirm: false,
            addSlowSpeedZoneFormFooter: true,
            editSlowSpeedZoneFormConfirm: false,
            editSlowSpeedZoneFormFooter: true,
            slowSpeedZoneData: {
                slowSpeedZoneId: [],
                slowSpeedZoneName: [],
                slowSpeedZoneFleet: [],
                slowSpeedZoneStatus: [],
                slowSpeedZoneCreatedDate: [],
                slowSpeedZoneModifyDate: [],
            },
            editSlowSpeedZoneData: {
                editSlowSpeedZoneId: [],
                editSlowSpeedZoneName: [],
                editSlowSpeedZoneFleet: [],
                editSlowSpeedZoneLatArray: [10.77080777782741, 10.770965939767235, 10.770681248215716, 10.770570534761893, 10.770586350972078],
                editSlowSpeedZoneLngArray: [106.6917888056399, 106.69198733018773, 106.69221804790547, 106.69206244758419, 106.69197123360274],
                editSlowSpeedZoneDescription: [],
            },
            onEditState: true,
            temporaryAreaLat: [],
            temporaryAreaLng: [],
            confirmAreaLat: [],
            confirmAreaLng: [],
            add_speed_zone_name: null,
            add_speed_zone_id: null,
            confirmUseArea: false,
            confirmUseEditArea: false,
            temporaryEditAreaLat: [],
            temporaryEditAreaLng: [],
            confirmEditAreaLat: [],
            confirmEditAreaLng: [],
            onEditAreaDrawState: true,
            deleteZoneFormConfirm: false,
            deleteZoneId: null,
            mapAddLat: 16.51632472900294,
            mapAddLng: 107.64006418629653,
        }
        this.selectFleetFilter = this.selectFleetFilter.bind(this);
        this.handleActiveChange = this.handleActiveChange.bind(this);
        //=======================================================================
        this.handleOpenAddSpeedZoneForm = this.handleOpenAddSpeedZoneForm.bind(this);
        this.handleCancelAddSpeedZone = this.handleCancelAddSpeedZone.bind(this);
        this.removePolygon = React.createRef();
        this.removeEditPolygon = React.createRef();
        this._onSpeedZoneCreate = this._onSpeedZoneCreate.bind(this);
        this.removePolygonFunction = this.removePolygonFunction.bind(this);
        this.confirmPolygonFunction = this.confirmPolygonFunction.bind(this);
        this.handlelAddSpeedZoneSubmit = this.handlelAddSpeedZoneSubmit.bind(this);
        this.handleConfirmAddSpeedZone = this.handleConfirmAddSpeedZone.bind(this);
        this.getFleetLocationMap = this.getFleetLocationMap.bind(this);
        this.changeLocation = this.changeLocation.bind(this);
        //========================================================================
        this.editSpeedZoneOpen = this.editSpeedZoneOpen.bind(this);
        this.handleCancelEditSpeedZone = this.handleCancelEditSpeedZone.bind(this);
        this._onCreateEdit = this._onCreateEdit.bind(this);
        this.removeEditPolygonFunction = this.removeEditPolygonFunction.bind(this);
        this.confirmEditPolygonFunction = this.confirmEditPolygonFunction.bind(this);
        this.handlelEditSpeedZoneSubmit = this.handlelEditSpeedZoneSubmit.bind(this);
        this.handleConfirmEditSpeedZone = this.handleConfirmEditSpeedZone.bind(this);
        //=========================================================================
        this.deleteZone = this.deleteZone.bind(this);
        this.deleteZoneconfirm = this.deleteZoneconfirm.bind(this);
        this.deleteZonecancel = this.deleteZonecancel.bind(this);
    }
    async selectFleetFilter(e) {
        const fleetFiltervalue = e.target.value;
        const formZoneFilterData = new URLSearchParams();
        formZoneFilterData.append('fleet_filter_param', fleetFiltervalue);
        formZoneFilterData.append('active_filter_param', this.state.activeFilterparam);
        await fetch("http://api.elevate-mobility.com/api/parking_point/elevate_slow_speed_zone.php",
            {
                method: 'POST',
                headers: {
                    'Content-Type': "application/x-www-form-urlencoded;charset=UTF-8"
                },
                body: formZoneFilterData.toString()
            }
        )
            .then(res => res.json())
            .then(
                (data) => {
                    this.setState({
                        fleetFilterParam: fleetFiltervalue,
                        slowSpeedZoneData: {
                            slowSpeedZoneId: data.slow_speed_area_id,
                            slowSpeedZoneName: data.slow_speed_area_name,
                            slowSpeedZoneFleet: data.slow_speed_area_fleet,
                            slowSpeedZoneStatus: data.slow_speed_area_status,
                            slowSpeedZoneCreatedDate: data.slow_speed_area_created_date,
                            slowSpeedZoneModifyDate: data.slow_speed_area_modify_date,
                        }
                    })
                }
            )
    }
    async handleActiveChange() {
        if (this.state.activeFilterState === false) {
            this.setState({
                activeFilterState: true,
            });
            var activeFilterParam = "Active";
        } else {
            this.setState({
                activeFilterState: false,
            });
            activeFilterParam = "all";
        }
        const formZoneFilterData = new URLSearchParams();
        formZoneFilterData.append('fleet_filter_param', this.state.fleetFilterParam);
        formZoneFilterData.append('active_filter_param', activeFilterParam);
        await fetch("http://api.elevate-mobility.com/api/parking_point/elevate_slow_speed_zone.php",
            {
                method: 'POST',
                headers: {
                    'Content-Type': "application/x-www-form-urlencoded;charset=UTF-8"
                },
                body: formZoneFilterData.toString()
            }
        )
            .then(res => res.json())
            .then(
                (data) => {
                    this.setState({
                        activeFilterparam: activeFilterParam,
                        slowSpeedZoneData: {
                            slowSpeedZoneId: data.slow_speed_area_id,
                            slowSpeedZoneName: data.slow_speed_area_name,
                            slowSpeedZoneFleet: data.slow_speed_area_fleet,
                            slowSpeedZoneStatus: data.slow_speed_area_status,
                            slowSpeedZoneCreatedDate: data.slow_speed_area_created_date,
                            slowSpeedZoneModifyDate: data.slow_speed_area_modify_date,
                        }
                    })
                }
            )
    }
    handleOpenAddSpeedZoneForm() {
        this.setState({
            addSlowSpeedZone: true,
            slowSpeedZoneTable: false,
        })
    }
    handleCancelAddSpeedZone() {
        this.setState({
            slowSpeedZoneTable: true,
            addSlowSpeedZone: false,
            addSlowSpeedZoneFormConfirm: false,
            addSlowSpeedZoneFormFooter: true,
        })
    }
    async _onSpeedZoneCreate(e) {
        const corArray = e.layer._latlngs;
        const areaLatArray = [];
        const areaLngArray = [];
        var arrayLength = corArray[0].length;
        for (var i = 0; i < arrayLength; i++) {
            areaLatArray[i] = corArray[0][i].lat;
            areaLngArray[i] = corArray[0][i].lng;
        }
        await this.setState({
            temporaryAreaLat: areaLatArray,
            temporaryAreaLng: areaLngArray,
            onEditState: false,
            confirmUseArea: true
        });
    }
    removePolygonFunction() {
        this.setState({
            confirmUseArea: false,
            confirmAreaLat: [],
            confirmAreaLng: [],
        });
        var polygon = this.removePolygon.current;
        var layers = polygon._layers;
        var layer_ids = Object.keys(layers);
        var layer;
        layer_ids.forEach(id => {
            layer = layers[id]
            polygon.removeLayer(layer);
        })
        this.setState({
            onEditState: true,
        })
    }
    async confirmPolygonFunction() {
        await this.setState({
            confirmAreaLat: this.state.temporaryAreaLat,
            confirmAreaLng: this.state.temporaryAreaLng,
        })
        alert("This area has been added to slow speed zone.\n Click 'Add Speed Zone' to complete")
    }
    async handlelAddSpeedZoneSubmit(e) {
        const cookies = new Cookies();
        e.preventDefault();
        const data = new FormData(e.target);
        const arrayAreaLat = data.get('area_lat');
        const arrayAreaLng = data.get('area_lng')
        const arrayLat = arrayAreaLat.toString();
        const arrayLng = arrayAreaLng.toString();
        if (arrayLat) {
            const formAddSpeedZone = new URLSearchParams();
            formAddSpeedZone.append('speed_zone_fleet', data.get('speedZoneFleet'));
            formAddSpeedZone.append('speed_zone_name', data.get('speedZoneName'));
            formAddSpeedZone.append('speed_zone_description', data.get('speedZoneDescription'));
            formAddSpeedZone.append('speed_zone_area_lat', arrayLat);
            formAddSpeedZone.append('speed_zone_area_lng', arrayLng);
            formAddSpeedZone.append('admin_name', cookies.get('adminName'));
            await fetch("http://api.elevate-mobility.com/api/parking_point/elevate_add_speed_zone.php",
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': "application/x-www-form-urlencoded;charset=UTF-8"
                    },
                    body: formAddSpeedZone.toString()
                }
            )
                .then(res => res.json())
                .then(
                    (data) => {
                        if (data.success === 1) {
                            this.setState({
                                add_speed_zone_name: data.add_speed_zone_name,
                                add_speed_zone_id: data.add_speed_zone_id,
                                addSlowSpeedZoneFormConfirm: true,
                                addSlowSpeedZoneFormFooter: false,
                            })
                        } else {
                            alert("Fail to add new service area !");
                        }
                    }
                )
        } else {
            alert("Please draw and confirm the area on map !")
        }
    }

    async handleConfirmAddSpeedZone() {
        const formZoneFilterData = new URLSearchParams();
        formZoneFilterData.append('fleet_filter_param', this.state.fleetFilterParam);
        formZoneFilterData.append('active_filter_param', this.state.activeFilterparam);
        await fetch("http://api.elevate-mobility.com/api/parking_point/elevate_slow_speed_zone.php",
            {
                method: 'POST',
                headers: {
                    'Content-Type': "application/x-www-form-urlencoded;charset=UTF-8"
                },
                body: formZoneFilterData.toString()
            }
        )
            .then(res => res.json())
            .then(
                (data) => {
                    this.setState({
                        slowSpeedZoneTable: true,
                        addSlowSpeedZone: false,
                        addSlowSpeedZoneFormConfirm: false,
                        addSlowSpeedZoneFormFooter: true,
                        slowSpeedZoneData: {
                            slowSpeedZoneId: data.slow_speed_area_id,
                            slowSpeedZoneName: data.slow_speed_area_name,
                            slowSpeedZoneFleet: data.slow_speed_area_fleet,
                            slowSpeedZoneStatus: data.slow_speed_area_status,
                            slowSpeedZoneCreatedDate: data.slow_speed_area_created_date,
                            slowSpeedZoneModifyDate: data.slow_speed_area_modify_date,
                        }
                    })
                }
            )
    }
    async getFleetLocationMap(e) {
        const fleetNameRequest = e.target.value;
        const formFleetRequestData = new URLSearchParams();
        formFleetRequestData.append('fleet_request_param', fleetNameRequest);
        console.log("Fleet: " + formFleetRequestData);
        await fetch("http://api.elevate-mobility.com/api/parking_point/elevate_request_fleet_location.php",
            {
                method: 'POST',
                headers: {
                    'Content-Type': "application/x-www-form-urlencoded;charset=UTF-8"
                },
                body: formFleetRequestData.toString()
            })
            .then(res => res.json())
            .then(
                (data) => {
                    this.setState({
                        mapAddLat: data.fleet_name_lat,
                        mapAddLng: data.fleet_name_lng,
                    })
                })
    }
    changeLocation(data) {
        console.log("SHOW CLICK: " + data);
        this.setState({
            mapAddLat: data[0],
            mapAddLng: data[1],
        })
    }
    //===================================================================================================
    async editSpeedZoneOpen(e) {
        //Clear all polygon on map before display edit restricted area
        var polygon = this.removeEditPolygon.current;
        var layers = polygon._layers;
        var layer_ids = Object.keys(layers);
        var layer;
        layer_ids.forEach(id => {
            layer = layers[id]
            polygon.removeLayer(layer);
        })
        const zoneId = e.target.value;
        const formEditSpeedZoneData = new URLSearchParams();
        formEditSpeedZoneData.append('zone_id', zoneId);
        await fetch("http://api.elevate-mobility.com/api/parking_point/elevate_get_speed_zone.php",
            {
                method: 'POST',
                headers: {
                    'Content-Type': "application/x-www-form-urlencoded;charset=UTF-8"
                },
                body: formEditSpeedZoneData.toString()
            }
        )
            .then(res => res.json())
            .then(
                (data) => {
                    this.setState({
                        editSlowSpeedZone: true,
                        slowSpeedZoneTable: false,
                        editSlowSpeedZoneFormFooter: true,
                        editSlowSpeedZoneFormConfirm: false,
                        editSlowSpeedZoneData: {
                            editSlowSpeedZoneId: data.speed_zone_id,
                            editSlowSpeedZoneName: data.speed_zone_name,
                            editSlowSpeedZoneFleet: data.speed_zone_fleet,
                            editSlowSpeedZoneLatArray: data.speed_zone_lat_array,
                            editSlowSpeedZoneLngArray: data.speed_zone_lng_array,
                            editSlowSpeedZoneDescription: data.speed_zone_description,
                        },
                        confirmEditAreaLat: data.speed_zone_lat_array,
                        confirmEditAreaLng: data.speed_zone_lng_array,
                        onEditAreaDrawState: true,
                    })
                }
            )
    }
    handleCancelEditSpeedZone() {
        this.setState({
            slowSpeedZoneTable: true,
            editSlowSpeedZone: false,
            editSlowSpeedZoneFormConfirm: false,
            editSlowSpeedZoneFormFooter: true,
        })
    }
    _onCreateEdit(e) {
        const corArray = e.layer._latlngs;
        const areaLatArray = [];
        const areaLngArray = [];
        var arrayLength = corArray[0].length;
        for (var i = 0; i < arrayLength; i++) {
            areaLatArray[i] = corArray[0][i].lat;
            areaLngArray[i] = corArray[0][i].lng;
        }
        this.setState({
            temporaryEditAreaLat: areaLatArray,
            temporaryEditAreaLng: areaLngArray,
            onEditState: false,
            confirmUseEditArea: true
        })
    }
    removeEditPolygonFunction() {
        this.setState({
            confirmUseEditArea: false,
            confirmEditAreaLat: [],
            confirmEditAreaLng: [],
        });
        //var layerContainer = this.removePolygon.options.edit.featureGroup;
        var polygon = this.removeEditPolygon.current;
        var layers = polygon._layers;
        var layer_ids = Object.keys(layers);
        var layer;
        layer_ids.forEach(id => {
            layer = layers[id]
            polygon.removeLayer(layer);
        })
    }
    async confirmEditPolygonFunction() {
        await this.setState({
            confirmEditAreaLat: this.state.temporaryEditAreaLat,
            confirmEditAreaLng: this.state.temporaryEditAreaLng,
            onEditAreaDrawState: false,
        });
        alert("This area has been added to speed zone information. \nClick 'Save Change' to complete.")
    }
    async handlelEditSpeedZoneSubmit(e) {
        const cookies = new Cookies();
        e.preventDefault();
        const data = new FormData(e.target);
        const arrayAreaLat = data.get('edit_area_lat');
        const arrayAreaLng = data.get('edit_area_lng');
        const arrayLat = arrayAreaLat.toString();
        const arrayLng = arrayAreaLng.toString();
        if (arrayLat) {
            const formEditSpeedZone = new URLSearchParams();
            formEditSpeedZone.append('edit_speed_zone_id', data.get('edit_area_id'));
            formEditSpeedZone.append('edit_speed_zone_fleet', data.get('editSpeedZoneFleet'));
            formEditSpeedZone.append('edit_speed_zone_name', data.get('editSpeedZoneName'));
            formEditSpeedZone.append('edit_speed_zone_description', data.get('editSpeedZoneDescription'));
            formEditSpeedZone.append('edit_speed_zone_area_lat', arrayLat);
            formEditSpeedZone.append('edit_speed_zone_area_lng', arrayLng);
            formEditSpeedZone.append('admin_name', cookies.get('adminName'));
            await fetch("http://api.elevate-mobility.com/api/parking_point/elevate_edit_speed_zone.php",
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': "application/x-www-form-urlencoded;charset=UTF-8"
                    },
                    body: formEditSpeedZone.toString()
                }
            )
                .then(res => res.json())
                .then(
                    async (data) => {
                        if (data.success === 1) {
                            await this.setState({
                                edit_zone_name: data.edit_speed_zone_name,
                                edit_zone_id: data.edit_speed_zone_id,
                                editSlowSpeedZoneFormConfirm: true,
                                editSlowSpeedZoneFormFooter: false,
                                confirmUseEditArea: false
                            })
                        } else {
                            alert("Fail to update speed zone information !")
                        }
                    }
                )
        } else {
            alert("Please draw and confirm parking area on map !")
        }
    }
    async handleConfirmEditSpeedZone(){
        const formServiceAreaFilterData = new URLSearchParams();
        formServiceAreaFilterData.append('fleet_filter_param', this.state.fleetFilterParam);
        formServiceAreaFilterData.append('active_filter_param', this.state.activeFilterparam);
        await fetch("http://api.elevate-mobility.com/api/parking_point/elevate_slow_speed_zone.php",
            {
                method: 'POST',
                headers: {
                    'Content-Type': "application/x-www-form-urlencoded;charset=UTF-8"
                },
                body: formServiceAreaFilterData.toString()
            }
        )
            .then(res => res.json())
            .then(
                (data) => {
                    this.setState({
                        editSlowSpeedZone: false,
                        slowSpeedZoneTable: true,
                        editSlowSpeedZoneFormFooter: true,
                        editSlowSpeedZoneFormConfirm: false,
                        slowSpeedZoneData: {
                            slowSpeedZoneId: data.slow_speed_area_id,
                            slowSpeedZoneName: data.slow_speed_area_name,
                            slowSpeedZoneFleet: data.slow_speed_area_fleet,
                            slowSpeedZoneStatus: data.slow_speed_area_status,
                            slowSpeedZoneCreatedDate: data.slow_speed_area_created_date,
                            slowSpeedZoneModifyDate: data.slow_speed_area_modify_date,
                        }
                    })
                }
            )
    }
    //===================================================================================================
    deleteZone(e) {
        const deleteStationValue = e.target.value;
        this.setState({
            deleteZoneFormConfirm: true,
            deleteZoneId: deleteStationValue,
        })
    }
    async deleteZoneconfirm() {
        const cookies = new Cookies();
        const formZoneFilterData = new URLSearchParams();
        formZoneFilterData.append('fleet_filter_param', this.state.fleetFilterParam);
        formZoneFilterData.append('active_filter_param', this.state.activeFilterparam);
        formZoneFilterData.append('zone_id', this.state.deleteZoneId);
        formZoneFilterData.append('admin_name', cookies.get('adminName'));
        await fetch("http://api.elevate-mobility.com/api/parking_point/elevate_delete_speed_zone.php",
            {
                method: 'POST',
                headers: {
                    'Content-Type': "application/x-www-form-urlencoded;charset=UTF-8"
                },
                body: formZoneFilterData.toString()
            }
        )
            .then(res => res.json())
            .then(
                (data) => {
                    if (data.success === 1) {
                        this.setState({
                            deleteZoneFormConfirm: false,
                            slowSpeedZoneTable: true,
                            slowSpeedZoneData: {
                                slowSpeedZoneId: data.slow_speed_area_id,
                                slowSpeedZoneName: data.slow_speed_area_name,
                                slowSpeedZoneFleet: data.slow_speed_area_fleet,
                                slowSpeedZoneStatus: data.slow_speed_area_status,
                                slowSpeedZoneCreatedDate: data.slow_speed_area_created_date,
                                slowSpeedZoneModifyDate: data.slow_speed_area_modify_date,
                            }
                        })
                    } else {
                        this.setState({
                            deleteZoneFormConfirm: false,
                            slowSpeedZoneTable: true,
                        });
                        alert("The slow speed zone was not deleted completly !")
                    }
                }
            )
    }
    deleteZonecancel(){
        this.setState({
            deleteZoneFormConfirm: false,
            slowSpeedZoneTable: true,
            deleteZoneId: null,
        })
    }
    async componentDidMount() {
        const formZoneFilterData = new URLSearchParams();
        formZoneFilterData.append('fleet_filter_param', this.state.fleetFilterParam);
        formZoneFilterData.append('active_filter_param', this.state.activeFilterparam);
        await fetch("http://api.elevate-mobility.com/api/parking_point/elevate_slow_speed_zone.php",
            {
                method: 'POST',
                headers: {
                    'Content-Type': "application/x-www-form-urlencoded;charset=UTF-8"
                },
                body: formZoneFilterData.toString()
            }
        )
            .then(res => res.json())
            .then(
                (data) => {
                    this.setState({
                        fleetName: data.fleet_name,
                        fleetNameAddParkings: data.fleet_name,
                        slowSpeedZoneData: {
                            slowSpeedZoneId: data.slow_speed_area_id,
                            slowSpeedZoneName: data.slow_speed_area_name,
                            slowSpeedZoneFleet: data.slow_speed_area_fleet,
                            slowSpeedZoneStatus: data.slow_speed_area_status,
                            slowSpeedZoneCreatedDate: data.slow_speed_area_created_date,
                            slowSpeedZoneModifyDate: data.slow_speed_area_modify_date,
                        },
                        mapAddLat: data.first_fleet_name_lat,
                        mapAddLng: data.first_fleet_name_lng,
                    })
                }
            )
    }
    render() {
        console.log(this.state.mapAddLat + " & " + this.state.mapAddLng );
        const tableData = [
            this.state.slowSpeedZoneData.slowSpeedZoneId,
            this.state.slowSpeedZoneData.slowSpeedZoneName,
            this.state.slowSpeedZoneData.slowSpeedZoneFleet,
            this.state.slowSpeedZoneData.slowSpeedZoneStatus,
            this.state.slowSpeedZoneData.slowSpeedZoneCreatedDate,
            this.state.slowSpeedZoneData.slowSpeedZoneModifyDate,
        ];
        let slowSpeedZoneTable = this.state.slowSpeedZoneTable ? "show_parkings_table" : "hide_parkings_table";
        let addSlowSpeedZone = this.state.addSlowSpeedZone ? "add_parkings_show" : "add_parkings_hide";
        let addSlowSpeedZoneFormConfirm = this.state.addSlowSpeedZoneFormConfirm ? "confirm_add_parkings_show" : "confirm_add_parkings_hide";
        let addSlowSpeedZoneFormFooter = this.state.addSlowSpeedZoneFormFooter ? "add_parkings_footer" : "hide_add_parkings_footer";
        let confirmUseArea = this.state.confirmUseArea ? "show_confirm_use_area" : "hide_confirm_use_area";
        let addMapPosition = [this.state.mapAddLat, this.state.mapAddLng];
        //==================================================================================================================================
        let editSlowSpeedZone = this.state.editSlowSpeedZone ? "add_parkings_show" : "add_parkings_hide";
        let editSlowSpeedZoneFormConfirm = this.state.editSlowSpeedZoneFormConfirm ? "confirm_add_parkings_show" : "confirm_add_parkings_hide";
        let editSlowSpeedZoneFormFooter = this.state.editSlowSpeedZoneFormFooter ? "add_parkings_footer" : "hide_add_parkings_footer";
        let confirmUseEditArea = this.state.confirmUseEditArea ? "show_confirm_use_area" : "hide_confirm_use_area";
        //===================================================================================================================================
        let deleteZoneFormConfirm = this.state.deleteZoneFormConfirm ? "confirm_add_parkings_show" : "confirm_add_parkings_hide";
        return (
            <div className='slow_speed_areas_contain'>
                <div className={slowSpeedZoneTable}>
                    <table className="slow_speed_areas_menu_filter">
                        <tbody>
                            <tr>
                                <td className='slow_speed_areas_leftside_menu'>
                                    <div>
                                        <p className="slow_speed_areas_title"><Trans>Speed Zone Areas</Trans></p>
                                    </div>
                                </td>
                                <td className='slow_speed_areas_rightside_menu'>
                                    <button className="btn_add_slow_speed_areas" type="button" onClick={this.handleOpenAddSpeedZoneForm}><FaIcons.FaPlus /> <Trans>Add Slow Speed Zone</Trans></button>
                                    <select className="fleets_select" onChange={this.selectFleetFilter}>
                                        <option value="all_fleet">All Fleets</option>
                                        {
                                            this.state.fleetName.map(
                                                (x) => <option selected={x === this.state.fleetFilterParam} value={x}>{x}</option>
                                            )
                                        }
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <td className='slow_speed_areas_leftside_menu'>
                                </td>
                                <td className='slow_speed_areas_rightside_menu'>
                                    <table className='slow_speed_areas_rightside_button'>
                                        <tr>
                                            <td><label style={{ marginRight: "10px" }}><Trans>Active</Trans></label></td>
                                            <td><Switch onChange={this.handleActiveChange} checked={this.state.activeFilterState} /></td>
                                        </tr>
                                    </table>

                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div className='slow_speed_areas_table'>
                        <SlowSpeedZonetablePage tableData={tableData} editSpeedZone={this.editSpeedZoneOpen} deleteZone={this.deleteZone}/>
                    </div>
                </div>
                {/*===================================================================================================================================================*/}
                <div className={addSlowSpeedZone}>
                    <div className="addParkingsForm">
                        <div className="add_parkings_form_header"><b><Trans>Add Slow Speed Zone</Trans></b></div>
                        <form onSubmit={this.handlelAddSpeedZoneSubmit}>
                            <div className="parkings-form">
                                <div style={{ display: "flex" }}>
                                    <div className="form-group-left-side">
                                        <div className="form-group">
                                            <label><Trans>Fleet</Trans></label>
                                            <select name="speedZoneFleet" onChange={this.getFleetLocationMap}>
                                                {this.state.fleetName.map((x) =>
                                                    <option value={x}>{x}</option>)}
                                            </select>
                                        </div>
                                        <div className="form-group">
                                            <label><Trans>Slow Speed Zone Name</Trans></label>
                                            <input type="text" name="speedZoneName" placeHolder="Enter Name" required />
                                        </div>
                                    </div>
                                    <div className="form-group-right-side">
                                        <div className="form-group">
                                            <label><Trans>Description</Trans></label>
                                            <input type="text" name="speedZoneDescription" />
                                        </div>
                                        <div style={{ display: "none" }} className="form-group">
                                            <input type="text" name="area_lat" value={this.state.confirmAreaLat} />
                                        </div>
                                        <div style={{ display: "none" }} className="form-group">
                                            <input type="text" name="area_lng" value={this.state.confirmAreaLng} />
                                        </div>
                                    </div>
                                </div>
                                <div className={addSlowSpeedZoneFormFooter}>
                                    <button type="button" className="btn_cancel_adding" onClick={this.handleCancelAddSpeedZone}>
                                        <Trans>Cancel</Trans>
                                    </button>
                                    <button type="submit" className="btn_submit_adding" >
                                        <Trans>Add Speed Zone</Trans>
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className="addParkingsMap">
                        <div className="fleetMap">
                            <AddSpeedZoneMap refFeatureGroup={this.removePolygon} getAreaData={this._onSpeedZoneCreate} editState={this.state.onEditState} position={addMapPosition}/>
                            <div className={confirmUseArea}>
                                <i style={{ color: "red" }}> * <Trans>Use this area for this speed zone ?</Trans></i> <button className="add_area_btn" onClick={this.removePolygonFunction}><Trans>No</Trans></button><button onClick={this.confirmPolygonFunction} className="add_area_btn"><Trans>Yes</Trans></button>
                            </div>
                        </div>
                    </div>
                    <div className={addSlowSpeedZoneFormConfirm}>
                        <p className="add_parkings_confirm_title"><Trans>The slow speed zone</Trans> "{this.state.add_speed_zone_name}" (ID: {this.state.add_speed_zone_id}) <Trans>has been added recently </Trans> !</p>
                        <button className="add_fleet_confirm_button" type="button" onClick={this.handleConfirmAddSpeedZone}>
                            OK
                        </button>
                    </div>
                </div>
                {/*===================================================================================================================================*/}
                <div className={editSlowSpeedZone}>
                    <div className="addParkingsForm">
                        <div className="add_parkings_form_header"><b><Trans>Edit Slow Speed Zone</Trans></b></div>
                        <form onSubmit={this.handlelEditSpeedZoneSubmit}>
                            <div className="parkings-form">
                                <div style={{ display: "flex" }}>
                                    <div className="form-group-left-side">
                                        <div className="form-group">
                                            <label><Trans>Fleet</Trans></label>
                                            <select name="editSpeedZoneFleet">
                                                {this.state.fleetName.map((x) =>
                                                    <option selected={x === this.state.editSlowSpeedZoneData.editSlowSpeedZoneFleet} value={x}>{x}</option>)}
                                            </select>
                                        </div>
                                        <div className="form-group">
                                            <label><Trans>Restricted Zone Name</Trans></label>
                                            <input type="text" name="editSpeedZoneName" defaultValue={this.state.editSlowSpeedZoneData.editSlowSpeedZoneName} required />
                                        </div>
                                    </div>
                                    <div className="form-group-right-side">
                                        <div className="form-group">
                                            <label><Trans>Description</Trans></label>
                                            <input type="text" name="editSpeedZoneDescription" defaultValue={this.state.editSlowSpeedZoneData.editSlowSpeedZoneDescription} />
                                        </div>
                                        <div style={{ display: "none" }} className="form-group">
                                            <input type="text" name="edit_area_lat" value={this.state.confirmEditAreaLat} />
                                        </div>
                                        <div style={{ display: "none" }} className="form-group">
                                            <input type="text" name="edit_area_lng" value={this.state.confirmEditAreaLng} />
                                        </div>
                                        <div style={{ display: "none" }} className="form-group">
                                            <input type="text" name="edit_area_id" value={this.state.editSlowSpeedZoneData.editSlowSpeedZoneId} />
                                        </div>
                                    </div>
                                </div>
                                <div className={editSlowSpeedZoneFormFooter}>
                                    <button type="button" className="btn_cancel_adding" onClick={this.handleCancelEditSpeedZone}>
                                        <Trans>Cancel</Trans>
                                    </button>
                                    <button type="submit" className="btn_submit_adding" >
                                        <Trans>Save Changes</Trans>
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className="addParkingsMap">
                        <div className="fleetMap">
                            <EditSpeedZoneMap refFeatureGroup={this.removeEditPolygon} getAreaData={this._onCreateEdit} editState={this.state.onEditAreaDrawState} latArray={this.state.editSlowSpeedZoneData.editSlowSpeedZoneLatArray} lngArray={this.state.editSlowSpeedZoneData.editSlowSpeedZoneLngArray} />
                            <div className={confirmUseEditArea}>
                                <i style={{ color: "red" }}> * Use this area for this speed zone ?</i> <button className="add_area_btn" onClick={this.removeEditPolygonFunction}>No</button><button onClick={this.confirmEditPolygonFunction} className="add_area_btn">Yes</button>
                            </div>
                        </div>
                    </div>
                    <div className={editSlowSpeedZoneFormConfirm}>
                        <p className="add_parkings_confirm_title"><Trans>The speed zone</Trans> "{this.state.edit_zone_name}" ( ID: {this.state.edit_zone_id}) <Trans>has been edited recently </Trans> !</p>
                        <button className="add_fleet_confirm_button" type="button" onClick={this.handleConfirmEditSpeedZone}>
                            OK
                        </button>
                    </div>
                </div>
                {/*===================================================================================================================================*/}
                <div className={deleteZoneFormConfirm}>
                    <p className="add_parkings_confirm_title"><Trans>The speed zone will be deleted !</Trans> !</p>
                    <button style={{backgroundColor:"gray",borderColor: "gray"}} className="add_fleet_confirm_button" type="button" onClick={this.deleteZonecancel}>
                        Cancle
                    </button>
                    <button style={{marginLeft: "10px"}}className="add_fleet_confirm_button" type="button" onClick={this.deleteZoneconfirm}>
                        OK
                    </button>
                </div>
            </div>
        )
    }
}
export default SpeedZoneAreas;