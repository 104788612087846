import React from 'react';
import { MDBDataTable } from 'mdbreact';
import './Fleets.css';
import { Trans, useTranslation } from 'react-i18next';

class FleetstablePage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    render() {
        const rowData = this.props.tableData;
        const rowDataFleetId = rowData[0];
        const rowDataFleetName = rowData[1];
        const rowDataSupportedEmail = rowData[2];
        const rowDataVisibility = rowData[3];
        const rowDataLocation = rowData[4];
        const rowDataBilling = rowData[5];
        const rowDataCreatedDate = rowData[6];
        const numRows = rowDataFleetId.length;
        const rowsData = [];
        for (var i = 0; i < numRows; i++) {
            const valueindex = {
                fleets_id: rowDataFleetId[i],
                fleets_name: rowDataFleetName[i],
                supported_mail: rowDataSupportedEmail[i],
                visibility: rowDataVisibility[i],
                location: rowDataLocation[i],
                billing: rowDataBilling[i],
                created_date: rowDataCreatedDate[i],
            }
            rowsData.push(valueindex);
        }
        const data = {
            columns: [
                {
                    label: 'ID',
                    field: 'fleets_id',
                    sort: 'asc',
                    width: 150
                },
                {
                    label: <Trans>Zone Name</Trans>,
                    field: 'fleets_name',
                    sort: 'asc',
                    width: 150
                },
                {
                    label: <Trans>Supported email</Trans>,
                    field: 'supported_mail',
                    sort: 'asc',
                    width: 150
                },
                {
                    label: <Trans>Visibility</Trans>,
                    field: 'visibility',
                    sort: 'asc',
                    width: 150
                },
                {
                    label: <Trans>Location</Trans>,
                    field: 'location',
                    sort: 'asc',
                    width: 150
                },
                {
                    label: <Trans>Billing Plan</Trans>,
                    field: 'billing',
                    sort: 'asc',
                    width: 150
                },
                {
                    label: <Trans>Created Date</Trans>,
                    field: 'created_date',
                    sort: 'asc',
                    width: 150
                }

            ],
            rows: rowsData,
        };
        return (
            <div className='fleets_table'>
                <MDBDataTable
                    hover
                    scrollX
                    maxHeight='50vh'
                    entriesOptions={[5, 10, 20]} 
                    entries={5}
                    data={data}
                    disableRetreatAfterSorting={true}
                />
            </div>
        );
    }
}

export default FleetstablePage;