import React from 'react';
import './Taxes.css';
import * as AiIcons from 'react-icons/ai';
import { Trans, useTranslation } from 'react-i18next';

class taxes extends React.Component {
    render() {
        return (
            <div className='taxes_contain'>
                <p className='taxes_title'><Trans>Taxes</Trans></p>
                <table className='taxes_table'>
                    <tr>
                        <td className='taxes_leftside_menu'><input type="search" placeholder="Type in to search" className="taxes_search" /></td>   
                        <td className='taxes_rightside_menu'><button className="btn_add_taxes"><AiIcons.AiOutlinePlus/><Trans>Add Taxes</Trans></button></td>                       
                    </tr>
                </table>

                <div>

                </div>
                <table className='taxes_table'>
                    <tbody>
                        <tr className='taxes_table_th'>
                            <td><b><Trans>Tax ID.</Trans></b></td>
                            <td><b><Trans>Tax Name</Trans></b></td>
                            <td><b><Trans>Percentage</Trans></b></td>
                        </tr>
                        <tr className='taxes_table_tr'>
                            <td>-</td>
                            <td>-</td>
                            <td>-</td>
                        </tr>
                    </tbody>
                </table>
                <table className='taxes_pagination'>
                    <tr>
                        <td className='total_showing'>Showing 0 to 0 of 0 entries</td>
                        <td className='page_btn_div'>
                            <div>
                                <button className="payments_btn_previous" type="button">Previous</button>
                                <button className="payments_btn_next" type="button">Next</button>
                            </div>
                        </td>
                    </tr>
                </table>
            </div>
        );
    }
}

export default taxes;