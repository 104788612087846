import React from 'react';
import { MDBDataTable } from 'mdbreact';
import './Vehicles.css'
import * as ImIcons from 'react-icons/im';
import VehicleMapLeaflet from './MapLeaflet'
import { Trans, useTranslation } from 'react-i18next';

class VehiclestablePage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            artistLat: 15.990264303653456,
            artistLng: 108.32260101119351,
            vehicleMapState: false,
            shareLinkDialog: false,
        }
        this.openVehicleMap = this.openVehicleMap.bind(this);
        this.showWebsiteLink = this.showWebsiteLink.bind(this);
        this.closeMap = this.closeMap.bind(this);
        
    }
    closeMap(){
        this.setState({vehicleMapState: false});
    }
    openVehicleMap(e) {
        const vehicleLocation = e.currentTarget.value.split(",");
        console.log(vehicleLocation);
        this.setState({
            vehicleMapState: true,
            artistLat: vehicleLocation[0],
            artistLng: vehicleLocation[1],
            artistId: vehicleLocation[2],
            artistFlag: vehicleLocation[3],
        });
    }
    activePartnerStatus(e){
        const sharelink = e.currentTarget.value;
    }
    showWebsiteLink(e){
        const getLink = e.currentTarget.value;
        if(getLink != ""){
            window.open(getLink);
        }else{
            
        }
    }
    render() {
        let openMapState = this.state.vehicleMapState ? "show_vehicle_map" : "hide_vehicle_map";
        const rowData = this.props.tableData;
        const rowDataArtistId = rowData[0];
        const rowDataArtistName = rowData[1];
        const rowDataArtisDOB = rowData[2];
        const rowDataArtistAddress = rowData[3];
        const rowDataPartnerStatus = rowData[4];
        const rowDataInviteLink = rowData[5];
        const rowDataFacebookLink = rowData[6];
        const rowDataInstagramLink = rowData[7];
        const rowDataWebLink = rowData[8];
        const rowDataRegisterDate = rowData[9];
        const rowDataLastLogin = rowData[10];
        const rowArtistStatus = rowData[11];
        const rowDataArtistLat = rowData[12];
        const rowDataArtistLng = rowData[13];

        const numRows = rowDataArtistId.length;
        const rowsData = [];
        const inviteLinkContent = [];
        const partnerStatusEl = [];
        const rowFacebookLinkButtonStyle = [];
        const rowInstagramLinkButtonStyle = [];
        const rowWebsiteLinkButtonStyle = [];
        
        for (var i = 0; i < numRows; i++) {
            if(rowDataPartnerStatus[i] == "Enable"){
                console.log(rowDataInviteLink[i]);
                inviteLinkContent[i] = rowDataInviteLink[i];
                partnerStatusEl[i] = "partner_enable_button";
            }else if(rowDataPartnerStatus[i] == "Disable"){
                inviteLinkContent[i] = "";
                partnerStatusEl[i] = "partner_disable_button";
            }else if(rowDataPartnerStatus[i] == "Await"){
                inviteLinkContent[i] = "";
                partnerStatusEl[i] = "partner_await_button";
            }else{
                inviteLinkContent[i] = "";
                partnerStatusEl[i] = "vehicle_action_button";
            }
            if(rowDataFacebookLink[i] != ""){
                rowFacebookLinkButtonStyle[i] = "have_facebook_link";
            }else{
                rowFacebookLinkButtonStyle[i] = "no_facbook_link";
            }
            
            if(rowDataInstagramLink[i] != ""){
                rowInstagramLinkButtonStyle[i] =  "have_instagram_link";
            }else{
                rowInstagramLinkButtonStyle[i] =  "no_instagram_link";
            }
            
            if(rowDataWebLink[i] != ""){
                rowWebsiteLinkButtonStyle[i] =  "have_website_link";
            }else{
                rowWebsiteLinkButtonStyle[i] =  "no_website_link";
            }
        }
        const buttonName = [];
        for(var j = 0; j < numRows; j++){
            // if(rowDataPartnerStatus[j] == 'Enable' ){
            //     buttonName[j] = "Enable";
            // }else if(rowDataPartnerStatus[j] == 'Disable' ){
            //     buttonName[j] = "Disable";
            // }else if(rowDataPartnerStatus[j] == 'Await' ){
            //     buttonName[j] = "Await";
            // }else{
            //     buttonName[j] = "Disable";
            //     console.log("Disable last");
            // }
            if(rowDataPartnerStatus[j]){
                buttonName[j] = rowDataPartnerStatus[j];
            }else{
                rowDataPartnerStatus[j]  = "Disable";
                buttonName[j] = "Disable";
                console.log("Disable last");
            }
        }
        console.log("array: " + buttonName );
        console.log("count: " + numRows );
         console.log("status array: " + rowDataPartnerStatus );   
        for (var i = 0; i < numRows; i++) {
            const rowDataArtistLocation = rowDataArtistLat[i]+","+rowDataArtistLng[i]+","+rowDataArtistId[i]+","+rowArtistStatus[i];
            const valueindex = {
                artist_id: rowDataArtistId[i],
                artist_name: rowDataArtistName[i],
                dob: rowDataArtisDOB[i],
                location: <button type="button" value={rowDataArtistLocation}  className="btn-map" onClick={this.openVehicleMap}><ImIcons.ImMap className='map-icons' /></button>,
                action: <><button className="vehicle_action_button" value={rowDataArtistId[i]}><Trans>Disable</Trans></button><button className="role_action_button" value={rowDataArtistId[i]} ><Trans>Delete</Trans></button></>,
                register_date: rowDataRegisterDate[i],
                last_ride_time: rowDataLastLogin[i],
                status: rowArtistStatus[i],
                partner: <button className={partnerStatusEl[i]} value={[rowDataPartnerStatus[i]+","+rowDataArtistId[i]]} onClick={this.props.activePartnerStatus}><Trans>{buttonName[i]}</Trans></button>,
                social_link: <><button className={rowFacebookLinkButtonStyle[i]} value={rowDataFacebookLink[i]} onClick={this.showWebsiteLink}><Trans>Facebook</Trans></button><button className={rowInstagramLinkButtonStyle[i]} value={rowDataInstagramLink[i]} onClick={this.showWebsiteLink}><Trans>Instagram</Trans></button><button className={rowWebsiteLinkButtonStyle[i]} value={rowDataWebLink[i]} onClick={this.showWebsiteLink}><Trans>Website</Trans></button></>,
                invite: rowDataInviteLink[i],
                view: <button className="btn-map" value={rowDataArtistId[i]} onClick={this.props.viewCardImage}>View</button>,
            }

            rowsData.push(valueindex);
        }
        const data = {
            columns: [
                {
                    label: 'Artist ID',
                    field: 'artist_id',
                    sort: 'asc',
                    width: 75,
                },
                {
                    label: <Trans>Artist Name</Trans>,
                    field: 'artist_name',
                    sort: 'asc',
                    width: 200,
                },
                {
                    label: <Trans>DOB</Trans>,
                    field: 'dob',
                    sort: 'asc',
                    width: 175,
                },
                {
                    label: <Trans>Status</Trans>,
                    field: 'status',
                    sort: 'asc',
                    width: 175,
                },
                {
                    label: <Trans>Register Date</Trans>,
                    field: 'register_date',
                    sort: 'asc',
                    width: 175,
                },
                {
                    label: <Trans>Last online time</Trans>,
                    field: 'last_ride_time',
                    sort: 'asc',
                    width: 250
                },
                {
                    label: <Trans>Location</Trans>,
                    field: 'location',
                    sort: 'asc',
                    width: 100,
                },
                {
                    label: <Trans>Partner Status</Trans>,
                    field: 'partner',
                    sort: 'asc',
                    width: 150,
                },
                {
                    label: <Trans>Social Link</Trans>,
                    field: 'social_link',
                    sort: 'asc',
                    width: 350,
                },
                {
                    label: <Trans>Invite Link</Trans>,
                    field: 'invite',
                    sort: 'asc',
                    width: 350,
                },
                {
                    label: <Trans>View</Trans>,
                    field: 'view',
                    sort: 'asc',
                    width: 100,
                },
                

            ],
            rows: rowsData,
        };
        return (
            <>
                <div className='fleets_table'>
                    <MDBDataTable
                        hover
                        scrollX
                        maxHeight='50vh'
                        entriesOptions={[5, 10, 20]}
                        entries={5}
                        data={data}
                        disableRetreatAfterSorting={true}
                    />
                </div>
                <div className={openMapState}>
                    <button  onClick={this.closeMap}>Close Map</button>
                    <VehicleMapLeaflet mapDataInfo={[this.state.artistLat, this.state.artistLng, this.state.artistId, this.state.artistFlag]}/>
                </div>
                <div>
                </div>
            </>
        );
    }
}      
export default VehiclestablePage;