import React from 'react';
import './Notifications.css';
import * as AiIcons from 'react-icons/ai';
import * as FaIcons from 'react-icons/fa';
import { MDBDataTable } from 'mdbreact';
import { Trans } from 'react-i18next';

class Notifications extends React.Component {
    render() {
        
        return (
            <div className='notifications_contain'>
                <p className='notifications_title'>Campaigns</p>
                <table className='notifications_table'>
                    <tr>
                        <td className='notifications_leftside_menu'><input type="search" placeholder="Type in to search" className="notifications_search" /></td>
                        <td className='notifications_rightside_menu'>
                            <button className="btn_create_campaigns"><FaIcons.FaFileCsv /> CSV</button>                            
                            <button className="btn_add_card"><AiIcons.AiOutlinePlus /> Add Notification</button>                          
                        </td>
                    </tr>
                </table>

                <div>
                    {/*}
                    <MDBDataTable
                        hover
                        scrollX
                        maxHeight='50vh'
                        entriesOptions={[5, 10, 20]}
                        entries={5}
                        data={data}
                        disableRetreatAfterSorting={true}
                    />
        */}
                </div>
                <table className='notifications_table'>
                    <tbody>
                        <tr className='notifications_table_th'>
                            <td><b>ID</b></td>
                            <td><b>Title</b></td>
                            <td><b>Message</b></td>
                            <td><b>From Date</b></td>
                            <td><b>Filter</b></td>
                            <td><b>Amount</b></td>
                            <td><b>Created</b></td>
                        </tr>
                        <tr className='notifications_table_tr'>
                            <td>-</td>
                            <td>-</td>
                            <td>-</td>
                            <td>-</td>
                            <td>-</td>
                            <td>-</td>
                            <td>-</td>
                        </tr>
                    </tbody>
                </table>
                <table className='notifications_pagination'>
                    <tr>
                        <td className='total_showing'>Showing 0 to 0 of 0 entries</td>
                        <td className='page_btn_div'>
                            <div>
                                <button className="payments_btn_previous" type="button">Previous</button>
                                <button className="payments_btn_next" type="button">Next</button>
                            </div>
                        </td>
                    </tr>
                </table>
            </div>
        );
    }
}

export default Notifications;