import React from 'react';
import { MDBDataTable } from 'mdbreact';
import './Promos.css';
import { Trans} from 'react-i18next';

class PromostablePage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    render() {
        const rowData = this.props.tableData;
        const rowDataId = rowData[0];
        const rowDataName = rowData[1];
        const rowDataType = rowData[2];
        const rowDataEvent = rowData[3];
        const rowDataPrice = rowData[4];
        const rowDataDate = rowData[5];
        const rowDataDescription = rowData[6];
        const rowDataUsageValidity = rowData[7];
        const rowDataFleet = rowData[8];
        const rowDataStatus = rowData[9];
        const numRows = rowDataId.length;
        const rowsData = [];
        for (var i = 0; i < numRows; i++) {
            const valueindex = {
                promo_id: rowDataId[i],
                name: rowDataName[i],
                type: rowDataType[i],
                promo_event: rowDataEvent[i],
                promo_price: rowDataPrice[i],
                date: rowDataDate[i],
                status: rowDataStatus[i],
            }
            rowsData.push(valueindex);
        }
        const data = {
            columns: [
                {
                    label: <Trans>Promo ID</Trans>,
                    field: 'promo_id',
                    sort: 'asc',
                    width: 150
                },
                {
                    label: <Trans>Name</Trans>,
                    field: 'name',
                    sort: 'asc',
                    width: 175
                },
                {
                    label: <Trans>Type</Trans>,
                    field: 'type',
                    sort: 'asc',
                    width: 175
                },
                {
                    label: <Trans>Promo Event</Trans>,
                    field: 'promo_event',
                    sort: 'asc',
                    width: 300
                },
                {
                    label: <Trans>Promo Price</Trans>,
                    field: 'promo_price',
                    sort: 'asc',
                    width: 175
                },
                {
                    label: <Trans>Date</Trans>,
                    field: 'date',
                    sort: 'asc',
                    width: 275
                },
                {
                    label: <Trans>Status</Trans>,
                    field: 'status',
                    sort: 'asc',
                    width: 150
                }

            ],
            rows: rowsData,
        };
        return (
            <div className='fleets_table'>
                <MDBDataTable
                    hover
                    scrollX
                    maxHeight='50vh'
                    entriesOptions={[5, 10, 20]} 
                    entries={5}
                    data={data}
                    disableRetreatAfterSorting={true}
                />
            </div>
        );
    }
}

export default PromostablePage;