import "./Map.css";
import React, { Component } from "react";
import { MapContainer, TileLayer, useMap, FeatureGroup, Polygon } from "react-leaflet";
import { EditControl } from "react-leaflet-draw";
import "leaflet-draw/dist/leaflet.draw.css";
import 'leaflet-draw';


function SetTimeOut() {
  const map = useMap();
  setTimeout(() => {
    map.invalidateSize();
  }, 250);
  return null;
}
export default class EditServiceAreaMap extends Component {
  constructor(props) {
    super(props);
    this.state = {
      disableState: true
    };
  }
  render() {
    const purpleOptions = { color: '#FF8100' }
    const latArray = this.props.latArray;
    const lngArray = this.props.lngArray;
    var location =[];
    const latArrayString = latArray.toString();
    const lngArrayString = lngArray.toString();
    const latPolygonArray = latArrayString.split(",");
    const lngPolygonArray = lngArrayString.split(",");
    
    for( var i = 0; i < latPolygonArray.length; i++){
      location[i] = [parseFloat(latPolygonArray[i]),parseFloat(lngPolygonArray[i])];
    }
    console.log("MAP LOG: " + location[0]);
    return (
      <>
        <MapContainer style={{ height: "55vh", cursor: "crosshair" }} center={location[0]} zoom={16}>
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          />
          <Polygon pathOptions={purpleOptions} positions={location} />
          <FeatureGroup ref={this.props.refFeatureGroup}>
            <EditControl
              position="topright"
              onCreated={this.props.getAreaData}
              edit={{ 
                edit: false
              }}
              draw={{
                polygon: this.props.editState,
                polyline: false,
                rectangle: false,
                circle: false,
                marker: false,
                circlemarker: false
              }}
            />
          </FeatureGroup>
          <SetTimeOut />
          <MyComponent location={location[0]}/>
        </MapContainer>
      </>
    );
  }
}

function MyComponent({location}) {
  const map = useMap()
  map.setView(location, map.getZoom());
  return null
}