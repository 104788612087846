import React from 'react';
import './Vehicles.css';
import * as FaIcons from 'react-icons/fa';
import VehiclestablePage from './Vehicles_table';
import Switch from "react-switch";
import VehiclesTableCSV from './Vehicles_csv';
import Cookies from 'universal-cookie';
import { Trans, useTranslation } from 'react-i18next';

class Vehicles extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            vehicleTableContain: true,
            addVehicle: false,
            addVehicleSelectFleet: null,
            addVehicleType: null,
            add_vehicle_form_footer: false,
            confirm_add_vehicle: false,
            edit_vehicle_form: false,
            edit_vehicle_form_footer: false,
            edit_vehicle_select_flag: null,
            edit_vehicle_select_fleet: null,
            confirm_edit_vehicle: false,
            view_card_image: false,
            front_card_image: '',
            back_card_image: '',
            front_license_image: '',
            back_license_image: '',
            info: [],
            fleet_filter: [],
            fleet_filter_param: 'all_fleet',
            flag_filter_param: 'all_flag',
            open_param: false,
            artist_available_filter: false,
            artist_id: [],
            artist_name: [],
            artist_dob: [],
            artist_address: [],
            invite_link: [],
            last_connected: [],
            last_ride_time: [],
            artist_lat: [],
            artist_lng: [],
            partner_status: [],
            facebook_link: [],
            instagram_link: [],
            web_link: [],
            register_date: [],
            last_login: [],
            artist_status: [],
        }
        this.handleFlagChange = this.handleFlagChange.bind(this);
        this.handleFleetChange = this.handleFleetChange.bind(this);
        this.handleLockChange = this.handleLockChange.bind(this);
        this.artistAvailableFilter = this.artistAvailableFilter.bind(this);
        this.handleAddVehicle = this.handleAddVehicle.bind(this);
        this.handleCancelAddVehicle = this.handleCancelAddVehicle.bind(this);
        this.handleSelectFleet = this.handleSelectFleet.bind(this);
        this.handleVehicleType = this.handleVehicleType.bind(this);
        this.activePartnerStatus = this.activePartnerStatus.bind(this);
        this.handleCancelEditVehicle = this.handleCancelEditVehicle.bind(this);
        this.handlelEditVehicleSubmit = this.handlelEditVehicleSubmit.bind(this);
        this.handleConfirmEditVehicle = this.handleConfirmEditVehicle.bind(this);
        this.viewCardImage = this.viewCardImage.bind(this);
        this.closeView = this.closeView.bind(this);
    }
    render() {
        //console.log("Button value: " + this.state.confirm_edit_vehicle_id);
        let view_card_image = this.state.view_card_image ? "view_card_image" : "hide_card_image"
        const cookies = new Cookies();
        let adminName = cookies.get('adminName');
        let adminEmail = cookies.get('adminEmail');
        let confirm_edit_vehicle = this.state.confirm_edit_vehicle ? "show_confirm_edit_vehicle" : "hide_confirm_edit_vehicle";
        let edit_vehicle_form_footer = this.state.edit_vehicle_form_footer ? "show_edit_vehicle_form_footer" : "hide_edit_vehicle_form_footer";
        let confirm_add_vehicle = this.state.confirm_add_vehicle ? "show_confirm_add_vehicle" : "hide_confirm_add_vehicle";
        let vehicleTable = this.state.vehicleTableContain ? "vehicles_table_contain" : "hide_vehicle_table_contain";
        let add_vehicle = this.state.addVehicle ? "show_add_vehicle_form" : "hide_add_vehicle_form";
        let add_vehicle_form_footer = this.state.add_vehicle_form_footer ? "show_add_vehicle_form_footer" : "hide_add_vehicle_form_footer";
        let edit_vehicle = this.state.edit_vehicle_form ? "show_edit_vehicle_form" : "hide_edit_vehicle_form";
        /*
                        vehicle_id: data.vehicle_id,
                        vehicle_name: data.vehicle_name,
                        battery: data.battery,
                        cell_voltage: data.cell_voltage,
                        type: data.vehicle_type,
                        emei: data.emei,
                        qr_code: data.qr_code,
                        vehicle_rfid: data.vehicle_rfid,
                        vehicle_sn: data.vehicle_sn,
                        odometer: data.odometer,
                        speed: data.speed,
                        acceleration: data.acceleration,
                        temperature: data.temperature,
                        power_consumption: data.power_consumption,
                        frame_id: data.frame_id,
                        vehicle_sim: data.vcu_id,
                        parking_point: data.parking_point,
                        parking_point_id: data.parking_point_id,
                        vehicle_fleet: data.vehicle_fleet,
                        enable_flag: data.status,
                        register_date: data.register_date,
                        last_connected: data.last_connected,
                        last_lock_status_update: data.last_lock_status_update,
                        last_ride_time: data.last_ride_time,
                        vehicle_lat: data.lat,
                        vehicle_lng: data.lng,
                        vehicle_sim: data.vehicle_sim,
                        vehicle_mac_address: data.vehicle_mac_address,
                        vehicle_device_note: data.vehicle_device_note,
                        vehicle_device_connection: data.vehicle_connection,
                        vehicle_status: data.status,

        */
        const vehicleTableData = [
            this.state.artist_id,
            this.state.artist_name,
            this.state.artist_dob,
            this.state.artist_address,
            this.state.partner_status,
            this.state.invite_link,
            this.state.facebook_link,
            this.state.instagram_link,
            this.state.web_link,
            this.state.register_date,
            this.state.last_login,
            this.state.artist_status,
            this.state.artist_lat,
            this.state.artist_lng
        ];
        return (
            <>
                <div className="vehicles_contain">
                    <div>
                        <p className="vehicles_title"><Trans>Artist</Trans></p>
                        <div className={vehicleTable}>
                            <table className="vehicles_menu_filter">
                                <tbody>
                                    <tr>
                                        <td className='vehicles_leftside_menu'>
                                            <div>

                                            </div>
                                        </td>
                                        {/* <td className='vehicles_rightside_menu'>
                                            <button className="btn_add_vehicle" type="button" onClick={this.handleAddVehicle}><FaIcons.FaPlus /> <Trans>Add Artist</Trans></button>
                                        </td> */}
                                    </tr>
                                    <tr>
                                        <td className='vehicles_leftside_menu'>
                                            <div className="select_left">
                                                {/*<select className="vehicles_flag_select" onChange={this.handleFlagChange}>
                                                    <option value='all_flag'>All Flags</option>
                                                    <option value='Service On'>Service On</option>
                                                    <option value='Service Off'>Service Off</option>
                                                    <option value='Connected'>Connected</option>
                                                    <option value='Not Connected'>Not Connected</option>
                                                    <option value='Lock Speed ON'>Lock Speed ON</option>
                                                    <option value='Missing'>Missing</option>
                                                    <option value='Maintainance'>Maintainance</option>
                                                    <option value='IoT Fault'>IoT Fault</option>
                                                    <option value='Battery Critical'>Battery Critical</option>
                                                    <option value='Unavailable'>Unavailable</option>
                                                    <option value='Stolen'>Stolen</option>
                                                    <option value='Rebalance Pickup'>Rebalance Pickup</option>
                                                    <option value='Agency Pickup'>Agency Pickup</option>
                                                    <option value='Rented'>Rented</option>
                                                </select>*/}
                                                {/* <select className="vehicles_fleets_select" onChange={this.handleFleetChange}>
                                                    <option value='all_fleet'>All Zones</option>
                                                    {this.state.fleet_filter.map((x) =>
                                                        <option value={x}>{x}</option>)}
                                                </select> */}
                                            </div>
                                        </td>
                                        <td className='vehicles_rightside_menu'>
                                            <table className='vehicles_rightside_button'>
                                                {/* <td>
                                                    <select className="payment_select" onChange={this.handlePaymentFilter}>
                                                        <option value="all">All</option>
                                                        <option value="available">Payment Card Available</option>
                                                        <option value="unavailable">Payment Card Not Available</option>
                                                    </select>
                                                </td> */}
                                                <td>
                                                    <select className="payment_select" onChange={this.handleStatusFilter}>
                                                        <option value="all">User status</option>
                                                        <option value="Verify">Verify</option>
                                                        <option value="Unverify">Unverify</option>
                                                    </select>
                                                </td>
                                                <tr>
                                                    {/*<td><label><Trans>Open</Trans> </label></td>*/}
                                                    {/*<td><div className='open_toggle' ><Switch onChange={this.handleLockChange} checked={this.state.open_param} /></div></td>*/}
                                                    <td><label style={{ marginLeft: '10px' }}><Trans>Artist Unavalible</Trans> </label></td>
                                                    <td><div className='open_without_ride_toggle' ><Switch onChange={this.artistAvailableFilter} checked={this.state.artist_available_filter} /></div></td>
                                                    {/*<td><button className="vehicles_btn_csv" type="button"><VehiclesTableCSV tableData={vehicleTableData} /></button></td>*/}
                                                </tr>
                                            </table>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <div className='vehicles_table_div'>
                                <VehiclestablePage tableData={vehicleTableData} activePartnerStatus={this.activePartnerStatus} viewCardImage={this.viewCardImage} />
                            </div>
                        </div>
                    </div>
                    <div className={view_card_image}>
                                <div className='name_tag'>
                                    <div>
                                        <b>Artist name: {this.state.verify_artist_name}</b>
                                    </div>
                                    <div>
                                        <b>Artist phone: {this.state.verify_artist_phone}</b>
                                    </div>
                                    <div>
                                        <b>Artist ID: {this.state.verify_artist_id}</b>
                                    </div>
                                </div>
                                <div className='id_card_div'>
                                    <div className='front_card'>
                                        <b>Front Card Image</b>
                                        <img
                                            className='front_card_img'
                                            src={this.state.front_card_image}
                                            alt=""
                                        />
                                    </div>
                                    <div className='back_card'>
                                        <b>Back Card Image</b>
                                        <img
                                            className='back_card_img'
                                            src={this.state.back_card_image}
                                            alt=""
                                        />
                                    </div>
                                </div>
                                <div className='driver_license_div'>
                                    <div className='front_license'>
                                        <b>Front Cetification Image</b>
                                        <img
                                            className='front_license_img'
                                            src={this.state.front_license_image}
                                            alt=""
                                        />
                                    </div>
                                    <div className='back_license'>
                                        <b>Back Certification Image</b>
                                        <img
                                            className='back_license_img'
                                            src={this.state.back_license_image}
                                            alt=""
                                        />
                                    </div>

                                </div>
                                <div>
                                    <button className="closeViewButton" onClick={this.closeView}>Close</button>
                                </div>
                            </div>
                </div>
                                                    
                {/* <div className={confirm_add_vehicle}>
                    <p className="add_vehicle_confirm_title"><Trans>The vehicle</Trans> {this.state.confirm_add_vehicle_name} <Trans>has been added recently</Trans> !</p>
                    <button className="add_vehicle_confirm_button" type="button" onClick={this.handleConfirmAddVehicle}>
                        OK
                    </button>
                </div> */}
            </>
        )
    }
    async closeView() {
        this.setState({ view_card_image: false });
    }
    async viewCardImage(e) {
        this.setState({ view_card_image: true });
        this.setState({ front_card_image: "https://api.connect-ink.com/API/TATU/API/data/artist/" + e.target.value + "/id_card/front_id_card_" + e.target.value + ".PNG" });
        this.setState({ back_card_image: "https://api.connect-ink.com/API/TATU/API/data/artist/" + e.target.value + "/id_card/back_id_card_" + e.target.value + ".PNG" });
        this.setState({ front_license_image: "https://api.connect-ink.com/API/TATU/API/data/artist/" + e.target.value + "/artist_certification/front_artist_certification_" + e.target.value + ".png" });
        this.setState({ back_license_image: "https://api.connect-ink.com/API/TATU/API/data/artist/" + e.target.value + "/artist_certification/back_artist_certification_" + e.target.value + ".png" });
    }
    async handleFleetChange(event) {
        const valueFleetState = event.target.value;
        const formFleetData = new URLSearchParams();
        formFleetData.append('flag_filter_param', this.state.flag_filter_param);
        formFleetData.append('fleet_filter_param', valueFleetState);
        formFleetData.append('open_param', this.state.open_param);
        formFleetData.append('artist_available_filter', this.state.artist_available_filter);
        await fetch("https://admin.connect-ink.com/API/vehicle/vehicle_fleet_filter.php",
            {
                method: 'POST',
                headers: {
                    'Content-Type': "application/x-www-form-urlencoded;charset=UTF-8"
                },
                body: formFleetData.toString()
            }
        )
            .then(res => res.json())
            .then(
                (data) => {
                    this.setState({
                        isLoaded: true,
                        info: data,
                        fleet_filter_param: valueFleetState,
                        artist_id: data.artist_id,
                        artist_name: data.artist_name,
                        artist_dob: data.artist_dob,
                        artist_address: data.artist_address,
                        partner_status: data.partner_status,
                        invite_link: data.invite_code,
                        facebook_link: data.facebook_link,
                        instagram_link: data.instagram_link,
                        web_link: data.web_link,
                        register_date: data.register_date,
                        last_login: data.last_login,
                        artist_status: data.artist_status,
                        artist_lat: data.artist_lat,
                        artist_lng: data.artist_lng,
                    });
                    console.log("partner_status: " + data.partner_status)
                }
            );
    }
    async handleFlagChange(event) {
        const valueFlagState = event.target.value;
        const formFlagData = new URLSearchParams();
        formFlagData.append('flag_filter_param', valueFlagState);
        formFlagData.append('fleet_filter_param', this.state.fleet_filter_param);
        formFlagData.append('open_param', this.state.open_param);
        formFlagData.append('artist_available_filter', this.state.artist_available_filter);
        await fetch("https://api.connect-ink.com/API/WEB_ADMIN/API/artist/artist_filter.php",
            {
                method: 'POST',
                headers: {
                    'Content-Type': "application/x-www-form-urlencoded;charset=UTF-8"
                },
                body: formFlagData.toString()
            }
        )
            .then(res => res.json())
            .then(
                (data) => {
                    this.setState({
                        isLoaded: true,
                        info: data,
                        flag_filter_param: valueFlagState,
                        artist_id: data.artist_id,
                        artist_name: data.artist_name,
                        artist_dob: data.artist_dob,
                        artist_address: data.artist_address,
                        partner_status: data.partner_status,
                        invite_link: data.invite_code,
                        facebook_link: data.facebook_link,
                        instagram_link: data.instagram_link,
                        web_link: data.web_link,
                        register_date: data.register_date,
                        last_login: data.last_login,
                        artist_status: data.artist_status,
                        artist_lat: data.artist_lat,
                        artist_lng: data.artist_lng,
                    });
                }
            );
    }
    async handleLockChange(e) {
        const formLockData = new URLSearchParams();
        formLockData.append('flag_filter_param', this.state.flag_filter_param);
        formLockData.append('fleet_filter_param', this.state.fleet_filter_param);
        formLockData.append('open_param', !this.state.open_param);
        formLockData.append('artist_available_filter', this.state.artist_available_filter);
        await fetch("https://api.connect-ink.com/API/WEB_ADMIN/API/artist/artist_filter.php",
            {
                method: 'POST',
                headers: {
                    'Content-Type': "application/x-www-form-urlencoded;charset=UTF-8"
                },
                body: formLockData.toString()
            }
        )
            .then(res => res.json())
            .then(
                (data) => {
                    this.setState({
                        isLoaded: true,
                        info: data,
                        open_param: !this.state.open_param,
                        artist_id: data.artist_id,
                        artist_name: data.artist_name,
                        artist_dob: data.artist_dob,
                        artist_address: data.artist_address,
                        partner_status: data.partner_status,
                        invite_link: data.invite_code,
                        facebook_link: data.facebook_link,
                        instagram_link: data.instagram_link,
                        web_link: data.web_link,
                        register_date: data.register_date,
                        last_login: data.last_login,
                        artist_status: data.artist_status,
                        artist_lat: data.artist_lat,
                        artist_lng: data.artist_lng,
                    });
                }
            );
    }
    async artistAvailableFilter(e) {
        const formOpenWithoutRideData = new URLSearchParams();
        var artist_status = this.state.artist_available_filter ? 0 : 1;
        formOpenWithoutRideData.append('artist_available_filter', artist_status);
        await fetch("https://api.connect-ink.com/API/WEB_ADMIN/API/artist/artist_filter.php",
            {
                method: 'POST',
                headers: {
                    'Content-Type': "application/x-www-form-urlencoded;charset=UTF-8"
                },
                body: formOpenWithoutRideData.toString()
            }
        )
            .then(res => res.json())
            .then(
                (data) => {
                    this.setState({
                        isLoaded: true,
                        info: data,
                        artist_available_filter: !this.state.artist_available_filter,
                        artist_id: data.artist_id,
                        artist_name: data.artist_name,
                        artist_dob: data.artist_dob,
                        artist_address: data.artist_address,
                        partner_status: data.partner_status,
                        invite_link: data.invite_code,
                        facebook_link: data.facebook_link,
                        instagram_link: data.instagram_link,
                        web_link: data.web_link,
                        register_date: data.register_date,
                        last_login: data.last_login,
                        artist_status: data.artist_status,
                        artist_lat: data.artist_lat,
                        artist_lng: data.artist_lng,
                    });
                }
            );
    }
    handleAddVehicle() {
        this.setState({
            addVehicle: true,
            add_vehicle_form_footer: true,
            vehicleTableContain: false,
        })
    }
    handleCancelAddVehicle() {
        this.setState({
            addVehicle: false,
            add_vehicle_form_footer: true,
            vehicleTableContain: true,
        })
    }
    handleSelectFleet(e) {
        this.setState({
            addVehicleSelectFleet: e.target.value,
        })
    }
    handleVehicleType(e) {
        this.setState({
            addVehicleType: e.target.value,
        })
    }

    activePartnerStatus(e) {
        console.log("activePartnerStatus =====");
        console.log(e);
        const editArtistId = e.target.value.split(",");
        var artist_status = this.state.artist_available_filter ? 1 : 0;
        console.log(editArtistId[0]);
        console.log(editArtistId[1]);
        console.log(artist_status);
        const formData = new URLSearchParams();
        formData.append('partner_status_param', editArtistId[0]);
        formData.append('artist_status', artist_status);
        formData.append('edit_artist_id', editArtistId[1]);
        fetch("https://api.connect-ink.com/API/WEB_ADMIN/API/artist/edit_artist.php",
            {
                method: 'POST',
                headers: {
                    'Content-Type': "application/x-www-form-urlencoded;charset=UTF-8"
                },
                body: formData.toString()
            }
        )
            .then(res => res.json())
            .then(
                (data) => {
                    this.setState({
                        isLoaded: true,
                        info: data,
                        addVehicle: false,
                        add_vehicle_form_footer: true,
                        vehicleTableContain: true,
                        artist_id: data.artist_id,
                        artist_name: data.artist_name,
                        artist_dob: data.artist_dob,
                        artist_address: data.artist_address,
                        partner_status: data.partner_status,
                        invite_link: data.invite_code,
                        facebook_link: data.facebook_link,
                        instagram_link: data.instagram_link,
                        web_link: data.web_link,
                        register_date: data.register_date,
                        last_login: data.last_login,
                        artist_status: data.artist_status,
                        artist_lat: data.artist_lat,
                        artist_lng: data.artist_lng,
                    });
                    console.log("data.partner_status 1:" + data.partner_status);

                }
            );

    }
    handleCancelEditVehicle() {
        this.setState({
            edit_vehicle_form: false,
            edit_vehicle_form_footer: false,
        })
    }

    async handlelEditVehicleSubmit(e) {
        e.preventDefault();
        const cookies = new Cookies();
        const data = new FormData(e.target);
        const formDataEditVehicle = new URLSearchParams();
        formDataEditVehicle.append('edit_vehicle_type', data.get('edit_vehicle_type'));
        formDataEditVehicle.append('edit_vehicle_emei', data.get('edit_vehicle_emei'));
        formDataEditVehicle.append('edit_vehicle_sim_iccid_number', data.get('edit_vehicle_sim_iccid_number'));
        formDataEditVehicle.append('edit_add_vehicle_sn', data.get('edit_add_vehicle_sn'));
        formDataEditVehicle.append('edit_vehicle_name', data.get('edit_vehicle_name'));
        formDataEditVehicle.append('edit_vehicle_rfid', data.get('edit_vehicle_rfid'));
        formDataEditVehicle.append('edit_vehicle_mac_address', data.get('edit_vehicle_mac_address'));
        formDataEditVehicle.append('edit_vehicle_flag', data.get('edit_vehicle_flag'));
        formDataEditVehicle.append('edit_vehicle_fleet', data.get('edit_vehicle_fleet'));
        formDataEditVehicle.append('edit_vehicle_qr_code', data.get('edit_vehicle_qr_code'));
        formDataEditVehicle.append('edit_vehicle_device_note', data.get('edit_vehicle_device_note'));
        formDataEditVehicle.append('edit_vehicle_device_id', data.get('edit_vehicle_device_id'));
        formDataEditVehicle.append('edit_vehicle_admin_name', data.get('edit_vehicle_admin_name'));
        formDataEditVehicle.append('edit_vehicle_admin_email', data.get('edit_vehicle_admin_email'));
        formDataEditVehicle.append('admin_name', cookies.get('adminName'));
        await fetch("https://admin.connect-ink.com/API/vehicle/edit_vehicle.php",
            {
                method: 'POST',
                headers: {
                    'Content-Type': "application/x-www-form-urlencoded;charset=UTF-8"
                },
                body: formDataEditVehicle.toString()
            }
        )
            .then(res => res.json())
            .then(
                (data) => {
                    this.setState({
                        edit_vehicle_form: true,
                        edit_vehicle_form_footer: false,
                        confirm_edit_vehicle: true,
                        confirm_edit_vehicle_id: data.edit_vehicle_id,
                    });
                    console.log("Edit vehicle state: " + data.updated)
                }
            );
    }
    async handleConfirmEditVehicle() {
        const formData = new URLSearchParams();
        formData.append('location_param', this.state.location_param);
        formData.append('operating_status_param', this.state.operating_status_param);
        await fetch("https://api.connect-ink.com/API/WEB_ADMIN/API/artist/artist_filter.php",
            {
                method: 'POST',
                headers: {
                    'Content-Type': "application/x-www-form-urlencoded;charset=UTF-8"
                },
                body: formData.toString()
            }
        )
            .then(res => res.json())
            .then(
                (data) => {
                    this.setState({
                        isLoaded: true,
                        info: data,
                        addVehicle: false,
                        add_vehicle_form_footer: false,
                        vehicleTableContain: true,
                        edit_vehicle_form: false,
                        edit_vehicle_form_footer: false,
                        confirm_edit_vehicle: false,
                        artist_id: data.artist_id,
                        artist_name: data.artist_name,
                        artist_dob: data.artist_dob,
                        artist_address: data.artist_address,
                        partner_status: data.partner_status,
                        invite_link: data.invite_code,
                        facebook_link: data.facebook_link,
                        instagram_link: data.instagram_link,
                        web_link: data.web_link,
                        register_date: data.register_date,
                        last_login: data.last_login,
                        artist_status: data.artist_status,
                        artist_lat: data.artist_lat,
                        artist_lng: data.artist_lng,
                    });
                }
            );
    }

    async componentDidMount() {
        const formData = new URLSearchParams();
        formData.append('fleet_filter_param', this.state.fleet_filter_param);
        formData.append('operating_status_param', this.state.operating_status_param);
        fetch("https://api.connect-ink.com/API/WEB_ADMIN/API/artist/artist_filter.php",
            {
                method: 'POST',
                headers: {
                    'Content-Type': "application/x-www-form-urlencoded;charset=UTF-8"
                },
                body: formData.toString()
            }
        )
            .then(res => res.json())
            .then(
                (data) => {
                    console.log(data);
                    this.setState({
                        isLoaded: true,
                        info: data,
                        addVehicle: false,
                        add_vehicle_form_footer: true,
                        vehicleTableContain: true,
                        artist_id: data.artist_id,
                        artist_name: data.artist_name,
                        artist_dob: data.artist_dob,
                        artist_address: data.artist_address,
                        partner_status: data.partner_status,
                        invite_link: data.invite_code,
                        facebook_link: data.facebook_link,
                        instagram_link: data.instagram_link,
                        web_link: data.web_link,
                        register_date: data.register_date,
                        last_login: data.last_login,
                        artist_status: data.artist_status,
                        artist_lat: data.artist_lat,
                        artist_lng: data.artist_lng,
                    });
                    console.log("data.partner_status 1:" + data.partner_status)
                }
            );
    }
}
export default Vehicles;