import "./Map.css";
import React, { Component } from "react";
import { MapContainer, Marker, Popup, TileLayer, useMap } from "react-leaflet";
import 'leaflet/dist/leaflet.css';
import { Icon } from 'leaflet';
import onRideMarker from "../../img/marker2.png";
import forcedStopMarker from "../../img/marker4.png";
import unlockFailMarker from "../../img/marker2.png";
import BikeGeotenceAlertMarker from "../../img/marker5.png";
import unlockWithoutRideMarker from "../../img/marker6.png";
import maintenanceMarker from "../../img/marker9.png";
import availableMarker from "../../img/marker.png";
import unavailableMarker from "../../img/marker14.png";
import serviceOnMarker from "../../img/marker10.png";
import serviceOffMarker from "../../img/marker13.png";
import missingMarker from "../../img/marker11.png";
import StolenMarker from "../../img/marker12.png";
import batteryCriticalMarker from "../../img/marker8.png";
import noDataMarker from "../../img/nodata.png";

function SetTimeOut() {
  const map = useMap();
  setTimeout(() => {
    map.invalidateSize();
  }, 250);
  return null;
}
function ChangeMapView({ coords}) {
  const map = useMap();
  setTimeout(() => {
    map.invalidateSize();
  }, 250);
  map.setView(coords, map.getZoom());
  return null;
}

export default class VehicleMapLeaflet extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lat: 38.91254086886275,
      lng: -77.01691019125292,
      zoom: 2,
      currentPos: null
    };
  }
  render() {
    const dataArtist = this.props.mapDataInfo;
    const position = [parseFloat(dataArtist[0]), parseFloat(dataArtist[1])];
    console.log("position client: " + dataArtist);
    //const position = [38.91254086886275, -77.01691019125292];
    const vehicleId = parseFloat(dataArtist[2]);
    const connection = dataArtist[3];
    if (connection === "Onride") {
      var markerIconPng = onRideMarker;
    }
    else if (connection === "Forced Stop") {
      markerIconPng = forcedStopMarker;
    }
    else if (connection === "Unlock Fail") {
      markerIconPng = unlockFailMarker;
    }
    else if (connection === "Bike Geotence Alert") {
      markerIconPng = BikeGeotenceAlertMarker;
    }
    else if (connection === "Unlock without ride") {
      markerIconPng = unlockWithoutRideMarker;
    }
    else if (connection === "Maintenance") {
      markerIconPng = maintenanceMarker;
    }
    else if (connection === "Available") {
      markerIconPng = availableMarker;
    }
    else if (connection === "Unavailable") {
      markerIconPng = unavailableMarker;
    }
    else if (connection === "Service On") {
      markerIconPng = serviceOnMarker;
    }
    else if (connection === "Service Off") {
      markerIconPng = serviceOffMarker;
    }
    else if (connection === "Missing") {
      markerIconPng = missingMarker;
    }
    else if (connection === "Stolen") {
      markerIconPng = StolenMarker;
    }
    else if (connection === "Battery Critical") {
      markerIconPng = batteryCriticalMarker;
    }
    else {
      markerIconPng = noDataMarker;
    }
    return (
        <>
      <MapContainer style={{ height: "70vh" }} center={position} zoom={35} >
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        />
        <Marker position={position} icon={new Icon({ iconUrl: onRideMarker, iconSize: [36, 48], iconAnchor: [12, 41] })}>
        <Popup>
                Vehicle
                <br />
                {vehicleId}
              </Popup>
        </Marker>
        <ChangeMapView coords={position}/>
        <SetTimeOut />
      </MapContainer>
      </>
    );
  }
}


