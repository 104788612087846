import React from 'react';
import { MDBDataTable } from 'mdbreact';
import './Running.css';
import RidesCSV from './Rides_csv';
import { Trans, useTranslation } from 'react-i18next';

class RunningRidestablePage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }
    render() {
        const rowData = this.props.tableData;
        const rowDataRideId = rowData[0];
        const rowDataRiderPhoneNumber = rowData[1];
        const rowDataRiderId = rowData[2];
        const rowDataRiderName = rowData[3];
        const rowDataVehicleId = rowData[4];
        const rowDataVehicleQrCode = rowData[5];
        const rowDataVehicleType = rowData[6];
        const rowDataRideFleet = rowData[7];
        const rowDataRideAmount = rowData[8];
        const rowDataRideStartPoint = rowData[9];
        const rowDataRideEndPoint = rowData[10];
        const rowDataRideDate = rowData[11];
        const rowDataRideRating = rowData[12];
        const rowDataRideStatus = rowData[13];
        const numRows = rowDataRideId.length;
        const rowsData = [];
        for (var i = 0; i < numRows; i++) {
            const buttonValue = rowDataRideId[i];
            const valueindex = {
                ride_id: rowDataRideId[i],
                phone_number: rowDataRiderPhoneNumber[i],
                user_id: rowDataRiderId[i],
                user_name: rowDataRiderName[i],
                vehicle_id: rowDataVehicleId[i],
                qr_code: rowDataVehicleQrCode[i],
                vehicle_type: rowDataVehicleType[i],
                ride_fleet: rowDataRideFleet[i],
                amount: rowDataRideAmount[i],
                start_point: rowDataRideStartPoint[i],
                end_point: rowDataRideEndPoint[i],
                ride_date: rowDataRideDate[i],
                rating: rowDataRideRating[i],
                status: rowDataRideStatus[i],
                action: <><button className="vehicle_action_button" value={[buttonValue]}>
                    {/*<RidesTableCSV tableData={} />*/}
                </button></>,
            }
            rowsData.push(valueindex);
        }
        const data = {
            columns: [
                {
                    label: <Trans>Order ID</Trans>,
                    field: 'ride_id',
                    sort: 'asc',
                    width: 175,
                },
                {
                    label: <Trans>Phone number</Trans>,
                    field: 'phone_number',
                    sort: 'asc',
                    width: 175,
                },
                {
                    label: <Trans>User ID</Trans>,
                    field: 'user_id',
                    sort: 'asc',
                    width: 150,
                },
                {
                    label: <Trans>User Name</Trans>,
                    field: 'user_name',
                    sort: 'asc',
                    width: 200,
                },
                {
                    label: <Trans>Artist ID</Trans>,
                    field: 'vehicle_id',
                    sort: 'asc',
                    width: 175,
                },
                {
                    label: <Trans>Artist Name</Trans>,
                    field: 'vehicle_type',
                    sort: 'asc',
                    width: 150,
                },
                {
                    label: <Trans>Body Type</Trans>,
                    field: 'start_point',
                    sort: 'asc',
                    width: 200,
                },
                {
                    label: <Trans>Tattoo type</Trans>,
                    field: 'end_point',
                    sort: 'asc',
                    width: 200,
                },
                {
                    label: <Trans>Amount</Trans>,
                    field: 'amount',
                    sort: 'asc',
                    width: 150,
                },
                {
                    label: <Trans>Order Date</Trans>,
                    field: 'ride_date',
                    sort: 'asc',
                    width: 125,
                },
                {
                    label: <Trans>Rating</Trans>,
                    field: 'rating',
                    sort: 'asc',
                    width: 50,
                },
                {
                    label: <Trans>Status</Trans>,
                    field: 'status',
                    sort: 'asc',
                    width: 175,
                },
            ],
            rows: rowsData,
        };
        return (
            <>
                <div className='fleets_table'>
                    <MDBDataTable
                        hover
                        scrollX
                        maxHeight='50vh'
                        entriesOptions={[5, 10, 20]}
                        entries={5}
                        data={data}
                        disableRetreatAfterSorting={true}
                    />
                </div>
            </>
        );
    }
}
export default RunningRidestablePage;