import React from 'react';
import './Parkings.css';
import ToggleButton from 'react-toggle-button';
import * as ImIcons from 'react-icons/im';
import * as FaIcons from 'react-icons/fa';
import Toggle from './Toggle';
import ParkingstablePage from './Parkings_table';
import { Trans, useTranslation } from 'react-i18next';
import AddParkingsMap from './AddParkingsMap';
import Switch from "react-switch";
import EditParkingsMap from './EditParkingsMap';
import Cookies from 'universal-cookie';

class Parkings extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fleetName: [],
            fleetNameAddParkings: [],
            fleetNameEditParkings: [],
            locationName: [],
            fleetFilterParam: "all_fleet",
            activeFilterparam: "all",
            activeFilterState: false,
            parkingsTable: true,
            addParkings: false,
            editParkings: false,
            addParkingsFormConfirm: false,
            addParkingsFormFooter: true,
            parkingPointData: {
                parkingPointId: [],
                parkingPointName: [],
                parkingPointOrg: [],
                parkingPointFleet: [],
                parkingPointNumberOfVehicle: [],
                parkingPointStatus: [],
                parkingPointAddress: [],
                parkingPointCreatedDate: [],
                parkingPointModifyDate: [],
                parkingPointLatArray: [],
                parkingPointLngArray: [],
            },
            editParkingPointData: {
                editparkingId: [],
                editparkingName: [],
                editparkingLocation: [],
                editparkingFleet: [],
                editparkingMaxVehicle: [],
                editparkingLatArray: [10.77080777782741, 10.770965939767235, 10.770681248215716, 10.770570534761893, 10.770586350972078],
                editparkingLngArray: [106.6917888056399, 106.69198733018773, 106.69221804790547, 106.69206244758419, 106.69197123360274],
                editparkingDescription: [],
            },
            onEditState: true,
            temporaryAreaLat: [],
            temporaryAreaLng: [],
            confirmAreaLat: [],
            confirmAreaLng: [],
            popupParkingsAreaConfirm: false,
            add_parkings_name: null,
            add_parkings_id: null,
            confirmUseArea: false,
            confirmUseEditArea: false,
            temporaryEditAreaLat: [],
            temporaryEditAreaLng: [],
            confirmEditAreaLat: [],
            confirmEditAreaLng: [],
            onEditAreaDrawState: true,
            onAddAreaDrawState: true,
            deleteZoneFormConfirm: false,
            deleteZoneId: null,
            mapAddLat: 16.51632472900294,
            mapAddLng: 107.64006418629653,
        }
        this.selectFleetFilter = this.selectFleetFilter.bind(this);
        this.handleActiveChange = this.handleActiveChange.bind(this);
        //===========================================================================
        this.handleOpenAddParkingsForm = this.handleOpenAddParkingsForm.bind(this);
        this.removePolygon = React.createRef();
        this.removeEditPolygon = React.createRef();
        this.removePolygonFunction = this.removePolygonFunction.bind(this);
        this.confirmPolygonFunction = this.confirmPolygonFunction.bind(this);
        this._onCreate = this._onCreate.bind(this);
        this.handlelAddParkingsSubmit = this.handlelAddParkingsSubmit.bind(this);
        this.handleConfirmAddParkings = this.handleConfirmAddParkings.bind(this);
        this.handleSelectLocation = this.handleSelectLocation.bind(this);
        this.handleCancelAddParking = this.handleCancelAddParking.bind(this);
        this.getFleetLocationMap = this.getFleetLocationMap.bind(this);
        this.changeLocation = this.changeLocation.bind(this);
        //============================================================================
        this.editParkingPoint = this.editParkingPoint.bind(this);
        this.handleCancelEditParking = this.handleCancelEditParking.bind(this);
        this.handleConfirmEditParkingsArea = this.handleConfirmEditParkingsArea.bind(this);
        this.handleEditSelectLocation = this.handleEditSelectLocation.bind(this);
        this._onCreateEdit = this._onCreateEdit.bind(this);
        this.removeEditPolygonFunction = this.removeEditPolygonFunction.bind(this);
        this.handlelEditParkingsSubmit = this.handlelEditParkingsSubmit.bind(this);
        this.confirmEditPolygonFunction = this.confirmEditPolygonFunction.bind(this);
        //=========================================================================
        this.deleteZone = this.deleteZone.bind(this);
        this.deleteZoneconfirm = this.deleteZoneconfirm.bind(this);
        this.deleteZonecancel = this.deleteZonecancel.bind(this);
    }
    async selectFleetFilter(e) {
        const fleetFiltervalue = e.target.value;
        const formParkingFilterData = new URLSearchParams();
        formParkingFilterData.append('fleet_filter_param', fleetFiltervalue);
        formParkingFilterData.append('active_filter_param', this.state.activeFilterparam);
        await fetch("http://api.elevate-mobility.com/api/parking_point/elevate_parking_point.php",
            {
                method: 'POST',
                headers: {
                    'Content-Type': "application/x-www-form-urlencoded;charset=UTF-8"
                },
                body: formParkingFilterData.toString()
            }
        )
            .then(res => res.json())
            .then(
                (data) => {
                    this.setState({
                        fleetFilterParam: fleetFiltervalue,
                        parkingPointData: {
                            parkingPointId: data.parking_poinbt_id,
                            parkingPointName: data.parking_point_name,
                            parkingPointOrg: data.parking_point_org,
                            parkingPointFleet: data.parking_point_fleet,
                            parkingPointNumberOfVehicle: data.parking_point_number_of_vehicle,
                            parkingPointStatus: data.parking_point_status,
                            parkingPointAddress: data.parking_point_address,
                            parkingPointCreatedDate: data.parking_point_created_date,
                            parkingPointModifyDate: data.parking_point_modify_date,
                            parkingPointLatArray: data.parking_point_lat_array,
                            parkingPointLngArray: data.parking_point_lng_array,
                        }
                    })
                }
            )
    }
    async handleActiveChange() {
        if (this.state.activeFilterState === false) {
            this.setState({
                activeFilterState: true,
            });
            var activeFilterParam = "Active";
        } else {
            this.setState({
                activeFilterState: false,
            });
            activeFilterParam = "all";
        }
        const formParkingFilterData = new URLSearchParams();
        formParkingFilterData.append('fleet_filter_param', this.state.fleetFilterParam);
        formParkingFilterData.append('active_filter_param', activeFilterParam);
        await fetch("http://api.elevate-mobility.com/api/parking_point/elevate_parking_point.php",
            {
                method: 'POST',
                headers: {
                    'Content-Type': "application/x-www-form-urlencoded;charset=UTF-8"
                },
                body: formParkingFilterData.toString()
            }
        )
            .then(res => res.json())
            .then(
                (data) => {
                    this.setState({
                        activeFilterparam: activeFilterParam,
                        parkingPointData: {
                            parkingPointId: data.parking_poinbt_id,
                            parkingPointName: data.parking_point_name,
                            parkingPointOrg: data.parking_point_org,
                            parkingPointFleet: data.parking_point_fleet,
                            parkingPointNumberOfVehicle: data.parking_point_number_of_vehicle,
                            parkingPointStatus: data.parking_point_status,
                            parkingPointAddress: data.parking_point_address,
                            parkingPointCreatedDate: data.parking_point_created_date,
                            parkingPointModifyDate: data.parking_point_modify_date,
                            parkingPointLatArray: data.parking_point_lat_array,
                            parkingPointLngArray: data.parking_point_lng_array,
                        }
                    })
                }
            )
    }
    _onCreate(e) {
        const corArray = e.layer._latlngs;
        const areaLatArray = [];
        const areaLngArray = [];
        var arrayLength = corArray[0].length;
        for (var i = 0; i < arrayLength; i++) {
            areaLatArray[i] = corArray[0][i].lat;
            areaLngArray[i] = corArray[0][i].lng;
        }
        this.setState({
            temporaryAreaLat: areaLatArray,
            temporaryAreaLng: areaLngArray,
            confirmUseArea: true
        })

    }
    async confirmPolygonFunction() {
        await this.setState({
            confirmAreaLat: this.state.temporaryAreaLat,
            confirmAreaLng: this.state.temporaryAreaLng,
        });
        alert("This area has been added to parking.\nClick 'Add Parking' to complete");

    }
    removePolygonFunction() {
        this.setState({
            confirmUseArea: false,
            confirmAreaLat: [],
            confirmAreaLng: [],
        });
        var polygon = this.removePolygon.current;
        var layers = polygon._layers;
        var layer_ids = Object.keys(layers);
        var layer;
        layer_ids.forEach(id => {
            layer = layers[id]
            polygon.removeLayer(layer);
        })
    }
    handleOpenAddParkingsForm() {
        this.setState({
            addParkings: true,
            parkingsTable: false,
        })
    }
    async handleSelectLocation(e) {
        const formLocationData = new URLSearchParams();
        formLocationData.append('location_param', e.target.value);
        await fetch("http://api.elevate-mobility.com/api/parking_point/elevate_select_location.php",
            {
                method: 'POST',
                headers: {
                    'Content-Type': "application/x-www-form-urlencoded;charset=UTF-8"
                },
                body: formLocationData.toString()
            }
        )
            .then(res => res.json())
            .then(
                (data) => {
                    this.setState({
                        fleetNameAddParkings: data.fleet_name,
                        fleetNameEditParkings: data.fleet_name,
                        mapAddLat: data.first_fleet_lat,
                        mapAddLng: data.first_fleet_lng,
                    })
                }
            )
    }
    handleCancelAddParking() {
        this.setState({
            parkingsTable: true,
            addParkings: false,
            addParkingsFormConfirm: false,
            addParkingsFormFooter: true,
        })
    }
    async handlelAddParkingsSubmit(e) {
        e.preventDefault();
        const dataAdd = new FormData(e.target);
        const arrayAreaLat = dataAdd.get('area_lat');
        const arrayAreaLng = dataAdd.get('area_lng')
        const arrayLat = arrayAreaLat.toString();
        const arrayLng = arrayAreaLng.toString();
        const cookies = new Cookies();
        if (arrayLat) {
            const formAddParkingPoint = new URLSearchParams();
            formAddParkingPoint.append('parking_location', dataAdd.get('parkingsLocation'));
            formAddParkingPoint.append('parking_fleet', dataAdd.get('parkingsFleet'));
            formAddParkingPoint.append('parking_name', dataAdd.get('parking_point_name'));
            formAddParkingPoint.append('parking_min_vehicle', dataAdd.get('min_vehicle'));
            formAddParkingPoint.append('parking_max_vehicle', dataAdd.get('max_vehicle'));
            formAddParkingPoint.append('parking_description', dataAdd.get('description'));
            formAddParkingPoint.append('parking_area_lat', arrayLat);
            formAddParkingPoint.append('parking_area_lng', arrayLng);
            formAddParkingPoint.append('admin_name', cookies.get('adminName'));
            await fetch("http://api.elevate-mobility.com/api/parking_point/elevate_add_parking_point.php",
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': "application/x-www-form-urlencoded;charset=UTF-8"
                    },
                    body: formAddParkingPoint.toString()
                }
            )
                .then(res => res.json())
                .then(
                    (data) => {
                        this.setState({
                            add_parkings_name: data.add_parking_name,
                            add_parkings_id: data.add_parking_id,
                            addParkingsFormConfirm: true,
                            addParkingsFormFooter: false,
                        })
                    }
                )
        } else {
            alert("Please draw and confirm parking area on map !")
        }
    }
    async handleConfirmAddParkings() {
        const formParkingFilterData = new URLSearchParams();
        formParkingFilterData.append('fleet_filter_param', this.state.fleetFilterParam);
        formParkingFilterData.append('active_filter_param', this.state.activeFilterparam);
        await fetch("http://api.elevate-mobility.com/api/parking_point/elevate_parking_point.php",
            {
                method: 'POST',
                headers: {
                    'Content-Type': "application/x-www-form-urlencoded;charset=UTF-8"
                },
                body: formParkingFilterData.toString()
            }
        )
            .then(res => res.json())
            .then(
                (data) => {
                    this.setState({
                        addParkingsFormConfirm: false,
                        addParkingsFormFooter: true,
                        addParkings: false,
                        parkingsTable: true,
                        fleetNameAddParkings: data.fleet_name,
                        parkingPointData: {
                            parkingPointId: data.parking_poinbt_id,
                            parkingPointName: data.parking_point_name,
                            parkingPointOrg: data.parking_point_org,
                            parkingPointFleet: data.parking_point_fleet,
                            parkingPointNumberOfVehicle: data.parking_point_number_of_vehicle,
                            parkingPointStatus: data.parking_point_status,
                            parkingPointAddress: data.parking_point_address,
                            parkingPointCreatedDate: data.parking_point_created_date,
                            parkingPointModifyDate: data.parking_point_modify_date,
                            parkingPointLatArray: data.parking_point_lat_array,
                            parkingPointLngArray: data.parking_point_lng_array,
                        }
                    })
                }
            )
    }
    async getFleetLocationMap(e) {
        const fleetNameRequest = e.target.value;
        const formFleetRequestData = new URLSearchParams();
        formFleetRequestData.append('fleet_request_param', fleetNameRequest);
        await fetch("http://api.elevate-mobility.com/api/parking_point/elevate_request_fleet_location.php",
            {
                method: 'POST',
                headers: {
                    'Content-Type': "application/x-www-form-urlencoded;charset=UTF-8"
                },
                body: formFleetRequestData.toString()
            })
            .then(res => res.json())
            .then(
                (data) => {
                    this.setState({
                        mapAddLat: data.fleet_name_lat,
                        mapAddLng: data.fleet_name_lng,
                    })
                })
    }
    changeLocation(data) {
        this.setState({
            mapAddLat: data[0],
            mapAddLng: data[1],
        })
    }
    //=============================================================================================
    async editParkingPoint(e) {
        var polygon = this.removeEditPolygon.current;
        var layers = polygon._layers;
        var layer_ids = Object.keys(layers);
        var layer;
        layer_ids.forEach(id => {
            layer = layers[id]
            polygon.removeLayer(layer);
        })
        const parkingId = e.target.value;
        const formEditParkingsData = new URLSearchParams();
        formEditParkingsData.append('parking_point_id', parkingId);
        await fetch("http://api.elevate-mobility.com/api/parking_point/elevate_get_edit_parking_point.php",
            {
                method: 'POST',
                headers: {
                    'Content-Type': "application/x-www-form-urlencoded;charset=UTF-8"
                },
                body: formEditParkingsData.toString()
            }
        )
            .then(res => res.json())
            .then(
                (data) => {
                    this.setState({
                        editParkings: true,
                        parkingsTable: false,
                        editParkingsFormFooter: true,
                        fleetNameEditParkings: data.locationt_fleet_array,
                        editParkingPointData: {
                            editparkingId: data.parking_point_id,
                            editparkingName: data.parking_point_name,
                            editparkingLocation: data.parking_point_location,
                            editparkingFleet: data.parking_point_fleet,
                            editparkingMaxVehicle: data.parking_point_max_vehicle,
                            editparkingLatArray: data.parking_point_lat_array,
                            editparkingLngArray: data.parking_point_lng_array,
                            editparkingDescription: data.parking_point_description,
                        },
                        confirmEditAreaLat: data.parking_point_lat_array,
                        confirmEditAreaLng: data.parking_point_lng_array,
                        onEditAreaDrawState: true
                    })
                }
            )
    }
    handleCancelEditParking() {
        this.setState({
            parkingsTable: true,
            editParkings: false,
            editParkingsFormConfirm: false,
            editParkingsFormFooter: false,
        })
    }
    async handleEditSelectLocation(e) {
        const formLocationData = new URLSearchParams();
        formLocationData.append('location_param', e.target.value);
        await fetch("http://api.elevate-mobility.com/api/parking_point/elevate_select_location.php",
            {
                method: 'POST',
                headers: {
                    'Content-Type': "application/x-www-form-urlencoded;charset=UTF-8"
                },
                body: formLocationData.toString()
            }
        )
            .then(res => res.json())
            .then(
                (data) => {
                    this.setState({
                        fleetNameEditParkings: data.fleet_name,
                    })
                }
            )
    }
    _onCreateEdit(e) {
        const corArray = e.layer._latlngs;
        const areaLatArray = [];
        const areaLngArray = [];
        var arrayLength = corArray[0].length;
        for (var i = 0; i < arrayLength; i++) {
            areaLatArray[i] = corArray[0][i].lat;
            areaLngArray[i] = corArray[0][i].lng;
        }
        this.setState({
            temporaryEditAreaLat: areaLatArray,
            temporaryEditAreaLng: areaLngArray,
            confirmUseEditArea: true
        })
    }
    removeEditPolygonFunction() {
        this.setState({
            confirmUseEditArea: false,
            confirmEditAreaLat: [],
            confirmEditAreaLng: [],
        });
        //var layerContainer = this.removePolygon.options.edit.featureGroup;
        var polygon = this.removeEditPolygon.current;
        var layers = polygon._layers;
        var layer_ids = Object.keys(layers);
        var layer;
        layer_ids.forEach(id => {
            layer = layers[id]
            polygon.removeLayer(layer);
        })
    }
    async confirmEditPolygonFunction() {
        await this.setState({
            confirmEditAreaLat: this.state.temporaryEditAreaLat,
            confirmEditAreaLng: this.state.temporaryEditAreaLng,
        });
        alert("This area has been assign to parking point information.")
    }
    async handlelEditParkingsSubmit(e) {
        e.preventDefault();
        const cookies = new Cookies();
        const data = new FormData(e.target);
        const arrayAreaLat = data.get('edit_area_lat');
        const arrayAreaLng = data.get('edit_area_lng');
        const arrayLat = arrayAreaLat.toString();
        const arrayLng = arrayAreaLng.toString();
        if (arrayLat) {
            const formAddParkingPoint = new URLSearchParams();
            formAddParkingPoint.append('parking_id', data.get('edit_parkings_id'));
            formAddParkingPoint.append('parking_location', data.get('parkingsLocation'));
            formAddParkingPoint.append('parking_fleet', data.get('parkingsFleet'));
            formAddParkingPoint.append('parking_name', data.get('parking_point_name'));
            formAddParkingPoint.append('parking_min_vehicle', data.get('min_vehicle'));
            formAddParkingPoint.append('parking_max_vehicle', data.get('max_vehicle'));
            formAddParkingPoint.append('parking_description', data.get('description'));
            formAddParkingPoint.append('parking_area_lat', arrayLat);
            formAddParkingPoint.append('parking_area_lng', arrayLng);
            formAddParkingPoint.append('admin_name', cookies.get('adminData'));
            await fetch("http://api.elevate-mobility.com/api/parking_point/elevate_edit_parking_point.php",
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': "application/x-www-form-urlencoded;charset=UTF-8"
                    },
                    body: formAddParkingPoint.toString()
                }
            )
                .then(res => res.json())
                .then(
                    (data) => {
                        this.setState({
                            edit_parkings_name: data.edit_parking_name,
                            edit_parkings_id: data.edit_parking_id,
                            editParkingsFormConfirm: true,
                            editParkingsFormFooter: false,
                        })
                    }
                )
        } else {
            alert("Please draw and confirm parking area on map !")
        }
    }
    async handleConfirmEditParkingsArea() {
        const formParkingFilterData = new URLSearchParams();
        formParkingFilterData.append('fleet_filter_param', this.state.fleetFilterParam);
        formParkingFilterData.append('active_filter_param', this.state.activeFilterparam);
        await fetch("http://api.elevate-mobility.com/api/parking_point/elevate_parking_point.php",
            {
                method: 'POST',
                headers: {
                    'Content-Type': "application/x-www-form-urlencoded;charset=UTF-8"
                },
                body: formParkingFilterData.toString()
            }
        )
            .then(res => res.json())
            .then(
                (data) => {
                    this.setState({
                        editParkingsFormConfirm: false,
                        editParkingsFormFooter: true,
                        editParkings: false,
                        parkingsTable: true,
                        fleetNameAddParkings: data.fleet_name,
                        parkingPointData: {
                            parkingPointId: data.parking_poinbt_id,
                            parkingPointName: data.parking_point_name,
                            parkingPointOrg: data.parking_point_org,
                            parkingPointFleet: data.parking_point_fleet,
                            parkingPointNumberOfVehicle: data.parking_point_number_of_vehicle,
                            parkingPointStatus: data.parking_point_status,
                            parkingPointAddress: data.parking_point_address,
                            parkingPointCreatedDate: data.parking_point_created_date,
                            parkingPointModifyDate: data.parking_point_modify_date,
                            parkingPointLatArray: data.parking_point_lat_array,
                            parkingPointLngArray: data.parking_point_lng_array,
                        }
                    })
                }
            )
    }
    //===================================================================================================
    deleteZone(e) {
        const deleteStationValue = e.target.value;
        this.setState({
            deleteZoneFormConfirm: true,
            deleteZoneId: deleteStationValue,
        })
    }
    async deleteZoneconfirm() {
        const cookies = new Cookies();
        const formZoneFilterData = new URLSearchParams();
        formZoneFilterData.append('fleet_filter_param', this.state.fleetFilterParam);
        formZoneFilterData.append('active_filter_param', this.state.activeFilterparam);
        formZoneFilterData.append('zone_id', this.state.deleteZoneId);
        formZoneFilterData.append('admin_name', cookies.get('adminName'));
        await fetch("http://api.elevate-mobility.com/api/parking_point/elevate_delete_parking_point.php",
            {
                method: 'POST',
                headers: {
                    'Content-Type': "application/x-www-form-urlencoded;charset=UTF-8"
                },
                body: formZoneFilterData.toString()
            }
        )
            .then(res => res.json())
            .then(
                (data) => {
                    if (data.success === 1) {
                        this.setState({
                            deleteZoneFormConfirm: false,
                            parkingsTable: true,
                            parkingPointData: {
                                parkingPointId: data.parking_poinbt_id,
                                parkingPointName: data.parking_point_name,
                                parkingPointOrg: data.parking_point_org,
                                parkingPointFleet: data.parking_point_fleet,
                                parkingPointNumberOfVehicle: data.parking_point_number_of_vehicle,
                                parkingPointStatus: data.parking_point_status,
                                parkingPointAddress: data.parking_point_address,
                                parkingPointCreatedDate: data.parking_point_created_date,
                                parkingPointModifyDate: data.parking_point_modify_date,
                                parkingPointLatArray: data.parking_point_lat_array,
                                parkingPointLngArray: data.parking_point_lng_array,
                            }
                        })
                    } else {
                        this.setState({
                            deleteZoneFormConfirm: false,
                            parkingsTable: true,
                        });
                        alert("The parking point was not deleted completly !")
                    }
                }
            )
    }
    deleteZonecancel() {
        this.setState({
            deleteZoneFormConfirm: false,
            parkingsTable: true,
            deleteZoneId: null,
        })
    }
    async componentDidMount() {
        const formParkingFilterData = new URLSearchParams();
        formParkingFilterData.append('fleet_filter_param', this.state.fleetFilterParam);
        formParkingFilterData.append('active_filter_param', this.state.activeFilterparam);
        await fetch("http://api.elevate-mobility.com/api/parking_point/elevate_parking_point.php",
            {
                method: 'POST',
                headers: {
                    'Content-Type': "application/x-www-form-urlencoded;charset=UTF-8"
                },
                body: formParkingFilterData.toString()
            }
        )
            .then(res => res.json())
            .then(
                (data) => {
                    this.setState({
                        fleetName: data.fleet_name,
                        fleetNameAddParkings: data.fleet_name,
                        locationName: data.location_name,
                        mapAddLat: data.first_fleet_lat,
                        mapAddLng: data.first_fleet_lng,
                        parkingPointData: {
                            parkingPointId: data.parking_poinbt_id,
                            parkingPointName: data.parking_point_name,
                            parkingPointOrg: data.parking_point_org,
                            parkingPointFleet: data.parking_point_fleet,
                            parkingPointNumberOfVehicle: data.parking_point_number_of_vehicle,
                            parkingPointStatus: data.parking_point_status,
                            parkingPointAddress: data.parking_point_address,
                            parkingPointCreatedDate: data.parking_point_created_date,
                            parkingPointModifyDate: data.parking_point_modify_date,
                            parkingPointLatArray: data.parking_point_lat_array,
                            parkingPointLngArray: data.parking_point_lng_array,
                        }
                    })
                }
            )
    }
    render() {
        let confirmUseArea = this.state.confirmUseArea ? "show_confirm_use_area" : "hide_confirm_use_area";
        let parkingsTable = this.state.parkingsTable ? "show_parkings_table" : "hide_parkings_table";
        let addParkings = this.state.addParkings ? "add_parkings_show" : "add_parkings_hide";
        let addParkingsFormConfirm = this.state.addParkingsFormConfirm ? "confirm_add_parkings_show" : "confirm_add_parkings_hide";
        let addParkingsFormFooter = this.state.addParkingsFormFooter ? "add_parkings_footer" : "hide_add_parkings_footer";
        let addMapPosition = [this.state.mapAddLat, this.state.mapAddLng];
        //================================================================================================================================
        let editParkings = this.state.editParkings ? "add_parkings_show" : "add_parkings_hide";
        let editParkingsFormConfirm = this.state.editParkingsFormConfirm ? "confirm_add_parkings_show" : "confirm_add_parkings_hide";
        let editParkingsFormFooter = this.state.editParkingsFormFooter ? "add_parkings_footer" : "hide_add_parkings_footer";
        let confirmUseEditArea = this.state.confirmUseEditArea ? "show_confirm_use_area" : "hide_confirm_use_area";
        //===================================================================================================================================
        let deleteZoneFormConfirm = this.state.deleteZoneFormConfirm ? "confirm_add_parkings_show" : "confirm_add_parkings_hide";
        const tableData = [
            this.state.parkingPointData.parkingPointId,
            this.state.parkingPointData.parkingPointName,
            this.state.parkingPointData.parkingPointOrg,
            this.state.parkingPointData.parkingPointFleet,
            this.state.parkingPointData.parkingPointNumberOfVehicle,
            this.state.parkingPointData.parkingPointStatus,
            this.state.parkingPointData.parkingPointAddress,
            this.state.parkingPointData.parkingPointCreatedDate,
            this.state.parkingPointData.parkingPointModifyDate,
            this.state.parkingPointData.parkingPointLatArray,
            this.state.parkingPointData.parkingPointLngArray
        ];
        return (
            <div className='parkings_contain'>
                <div className={parkingsTable}>
                    <table className="parkings_menu_filter">
                        <tbody>
                            <tr>
                                <td className='parkings_leftside_menu'>
                                    <div>
                                        <p className="parkings_title"><Trans>Parkings</Trans></p>
                                    </div>
                                </td>
                                <td className='parkings_rightside_menu'>
                                    {/*<select className="owner_select">
                                        <option>Rider</option>
                                        <option>Operator</option>
                                    </select>*/}
                                    <button className="btn_add_parkings" type="button" onClick={this.handleOpenAddParkingsForm}><FaIcons.FaPlus /><Trans>Add Parking</Trans></button>
                                    <select className="fleets_select" onChange={this.selectFleetFilter}>
                                        <option value="all_fleet">All Fleets</option>
                                        {
                                            this.state.fleetName.map((x) =>
                                                <option value={x}>{x}</option>)
                                        }
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <td className='parkings_leftside_menu'>
                                    <div>

                                    </div>
                                </td>
                                <td className='parkings_rightside_menu'>
                                    <table className='parkings_rightside_button'>
                                        <tr>
                                            <td><label><Trans>Active</Trans></label></td>
                                            <td><Switch onChange={this.handleActiveChange} checked={this.state.activeFilterState} /></td>
                                        </tr>
                                    </table>

                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div className='parkings_table'>
                        <ParkingstablePage tableData={tableData} editParkings={this.editParkingPoint} deleteZone={this.deleteZone} />
                    </div>
                </div>
                <div className={addParkings}>
                    <div className="addParkingsForm">
                        <div className="add_parkings_form_header"><b><Trans>Add Parking</Trans></b></div>
                        <form onSubmit={this.handlelAddParkingsSubmit}>
                            <div className="parkings-form">
                                <div style={{ display: "flex" }}>
                                    <div className="form-group-left-side">
                                        <div className="form-group">
                                            <label><Trans>Location</Trans></label>
                                            <select name="parkingsLocation" onChange={this.handleSelectLocation}>
                                                <option value="all" selected>Location</option>
                                                {this.state.locationName.map((x) =>
                                                    <option value={x}>{x}</option>)}
                                            </select>
                                        </div>
                                        <div className="form-group">
                                            <label><Trans>Fleet</Trans></label>
                                            <select name="parkingsFleet" onChange={this.getFleetLocationMap} >
                                                {this.state.fleetNameAddParkings.map((x) =>
                                                    <option value={x}>{x}</option>)}
                                            </select>
                                        </div>
                                        <div className="form-group">
                                            <label><Trans>Parking point name</Trans></label>
                                            <input type="text" name="parking_point_name" placeHolder="Enter Parking Name" required />
                                        </div>
                                    </div>
                                    <div className="form-group-right-side">
                                        <div className="form-group">
                                            <label><Trans>Minimum Vehicle</Trans></label>
                                            <input type="number" name="min_vehicle" min="1" required />
                                        </div>
                                        <div className="form-group">
                                            <label><Trans>Maximum Vehicle</Trans></label>
                                            <input type="number" name="max_vehicle" min="1" required />
                                        </div>
                                        <div className="form-group">
                                            <label><Trans>Description</Trans></label>
                                            <input type="text" name="description" placeHolder="Enter Description" />
                                        </div>
                                        <div style={{ display: "none" }} className="form-group">
                                            <input type="text" name="area_lat" value={this.state.confirmAreaLat} />
                                        </div>
                                        <div style={{ display: "none" }} className="form-group">
                                            <input type="text" name="area_lng" value={this.state.confirmAreaLng} />
                                        </div>
                                    </div>
                                </div>
                                <div className={addParkingsFormFooter}>
                                    <button type="button" className="btn_cancel_adding" onClick={this.handleCancelAddParking}>
                                        <Trans>Cancel</Trans>
                                    </button>
                                    <button type="submit" className="btn_submit_adding" >
                                        <Trans>Add Parking</Trans>
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className="addParkingsMap">
                        <div className="fleetMap">
                            <AddParkingsMap refFeatureGroup={this.removePolygon} getAreaData={this._onCreate} editState={this.state.onAddAreaDrawState} position={addMapPosition} clickPosition={this.changeLocation} />
                            <div className={confirmUseArea}>
                                <i style={{ color: "red" }}> * Use this area for this parking point ?</i> <button className="add_area_btn" onClick={this.removePolygonFunction}>No</button><button onClick={this.confirmPolygonFunction} className="add_area_btn">Yes</button>
                            </div>
                        </div>
                    </div>
                    <div className={addParkingsFormConfirm}>
                        <p className="add_parkings_confirm_title"><Trans>The parking point</Trans> {this.state.add_parkings_name} as {this.state.add_parkings_id} <Trans>has been added recently </Trans> !</p>
                        <button className="add_fleet_confirm_button" type="button" onClick={this.handleConfirmAddParkings}>
                            OK
                        </button>
                    </div>
                </div>
                {/*===================================================================================================================================*/}
                <div className={editParkings}>
                    <div className="addParkingsForm">
                        <div className="add_parkings_form_header"><b><Trans>Edit Parking</Trans></b></div>
                        <form onSubmit={this.handlelEditParkingsSubmit}>
                            <div className="parkings-form">
                                <div style={{ display: "flex" }}>
                                    <div className="form-group-left-side">
                                        <div className="form-group">
                                            <label><Trans>Location</Trans></label>
                                            <select name="parkingsLocation" onChange={this.handleEditSelectLocation}>
                                                {this.state.locationName.map((x) =>
                                                    <option selected={x === this.state.editParkingPointData.editparkingLocation} value={x}>{x}</option>)}
                                            </select>
                                        </div>
                                        <div className="form-group">
                                            <label><Trans>Fleet</Trans></label>
                                            <select name="parkingsFleet" >
                                                {this.state.fleetNameEditParkings.map((x) =>
                                                    <option selected={x === this.state.editParkingPointData.editparkingFleet} value={x}>{x}</option>)}
                                            </select>
                                        </div>
                                        <div className="form-group">
                                            <label><Trans>Parking point name</Trans></label>
                                            <input type="text" name="parking_point_name" defaultValue={this.state.editParkingPointData.editparkingName} required />
                                        </div>
                                    </div>
                                    <div className="form-group-right-side">
                                        <div className="form-group">
                                            <label><Trans>Minimum Vehicle</Trans></label>
                                            <input type="number" name="min_vehicle" min="1" defaultValue="1" required />
                                        </div>
                                        <div className="form-group">
                                            <label><Trans>Maximum Vehicle</Trans></label>
                                            <input type="number" name="max_vehicle" min="1" defaultValue={this.state.editParkingPointData.editparkingMaxVehicle} required />
                                        </div>
                                        <div className="form-group">
                                            <label><Trans>Description</Trans></label>
                                            <input type="text" name="description" defaultValue={this.state.editParkingPointData.editparkingDescription} />
                                        </div>
                                        <div style={{ display: "none" }} className="form-group">
                                            <input type="text" name="edit_area_lat" value={this.state.confirmEditAreaLat} />
                                        </div>
                                        <div style={{ display: "none" }} className="form-group">
                                            <input type="text" name="edit_area_lng" value={this.state.confirmEditAreaLng} />
                                        </div>
                                        <div style={{ display: "none" }} className="form-group">
                                            <input type="text" name="edit_parkings_id" value={this.state.editParkingPointData.editparkingId} />
                                        </div>
                                    </div>
                                </div>
                                <div className={editParkingsFormFooter}>
                                    <button type="button" className="btn_cancel_adding" onClick={this.handleCancelEditParking}>
                                        <Trans>Cancel</Trans>
                                    </button>
                                    <button type="submit" className="btn_submit_adding" >
                                        <Trans>Save Changes</Trans>
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className="addParkingsMap">
                        <div className="fleetMap">
                            <EditParkingsMap refFeatureGroup={this.removeEditPolygon} getAreaData={this._onCreateEdit} editState={this.state.onEditAreaDrawState} latArray={this.state.editParkingPointData.editparkingLatArray} lngArray={this.state.editParkingPointData.editparkingLngArray} />
                            <div className={confirmUseEditArea}>
                                <i style={{ color: "red" }}> * Use this area for this parking point ?</i> <button className="add_area_btn" onClick={this.removeEditPolygonFunction}>No</button><button onClick={this.confirmEditPolygonFunction} className="add_area_btn">Yes</button>
                            </div>
                        </div>
                    </div>
                    <div className={editParkingsFormConfirm}>
                        <p className="add_parkings_confirm_title"><Trans>The parking point</Trans> {this.state.edit_parkings_name} as {this.state.edit_parkings_id} <Trans>has been edited recently </Trans> !</p>
                        <button className="add_fleet_confirm_button" type="button" onClick={this.handleConfirmEditParkingsArea}>
                            OK
                        </button>
                    </div>
                </div>
                {/*}===================================================================================================================*/}
                <div className={deleteZoneFormConfirm}>
                    <p className="add_parkings_confirm_title"><Trans>The parking zone will be deleted !</Trans> !</p>
                    <button style={{ backgroundColor: "gray", borderColor: "gray" }} className="add_fleet_confirm_button" type="button" onClick={this.deleteZonecancel}>
                        Cancle
                    </button>
                    <button style={{ marginLeft: "10px" }} className="add_fleet_confirm_button" type="button" onClick={this.deleteZoneconfirm}>
                        OK
                    </button>
                </div>
            </div>
        )
    }
}
export default Parkings;