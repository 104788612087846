import React from 'react';
import './Banner.css';
import * as AiIcons from 'react-icons/ai';
import * as FaIcons from 'react-icons/fa';

class Banner extends React.Component {
    render() {
        return (
            <div className='banner_contain'>
                <p className='banner_title'>Application Banner </p>
                <table className='banner_table'>
                    <tr>
                        <td className='banner_leftside_menu'><input type="search" placeholder="Type in to search" className="banner_search" /></td>
                        <td className='banner_rightside_menu'>                        
                            <button className="btn_add_banner"><AiIcons.AiOutlinePlus /> Add Banner</button>                          
                        </td>
                    </tr>
                </table>

                <div>

                </div>
                <table className='banner_table'>
                    <tbody>
                        <tr className='banner_table_th'>
                            <td><b>ID</b></td>
                            <td><b>Header</b></td>
                            <td><b>Area Type</b></td>
                            <td><b>Info Title</b></td>
                            <td><b>Info Link</b></td>
                            <td><b>Date</b></td>
                            <td><b>Status</b></td>
                        </tr>
                        <tr className='banner_table_tr'>
                            <td>-</td>
                            <td>-</td>
                            <td>-</td>
                            <td>-</td>
                            <td>-</td>
                            <td>-</td>
                            <td>-</td>
                        </tr>
                    </tbody>
                </table>
                <table className='banner_pagination'>
                    <tr>
                        <td className='total_showing'>Showing 0 to 0 of 0 entries</td>
                        <td className='page_btn_div'>
                            <div>
                                <button className="banner_btn_previous" type="button">Previous</button>
                                <button className="banner_btn_next" type="button">Next</button>
                            </div>
                        </td>
                    </tr>
                </table>
            </div>
        );
    }
}

export default Banner;