import React from 'react';
import { MDBDataTable } from 'mdbreact';
import './Running.css';
import RidesCSV from './Rides_csv';
import { Trans, useTranslation } from 'react-i18next';

class UnlockRidestablePage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }
    render() {
        const rowData = this.props.tableData;
        const rowDataRideId = rowData[0];
        const rowDataRiderPhoneNumber = rowData[1];
        const rowDataVehicleId = rowData[2];
        const rowDataRideTime = rowData[3];
        const numRows = rowDataRideId.length;
        const rowsData = [];
        for (var i = 0; i < numRows; i++) {
            const valueindex = {
                ride_id: rowDataRideId[i],
                phone_number: rowDataRiderPhoneNumber[i],
                vehicle_id: rowDataVehicleId[i],
                ride_time: rowDataRideTime[i],
                action: <><button className="verify_unlock_action_button" value={rowDataRideId[i]} onClick={this.props.unlockVerify}><Trans>Unlock Verify</Trans></button></>,
            }

            rowsData.push(valueindex);
        }
        const data = {
            columns: [
                {
                    label: <Trans>Order ID</Trans>,
                    field: 'ride_id',
                    sort: 'asc',
                    width:110,
                },
                {
                    label: <Trans>Phone number</Trans>,
                    field: 'phone_number',
                    sort: 'asc',
                    width: 105,
                },
                {
                    label: <Trans>Artist</Trans>,
                    field: 'vehicle_id',
                    sort: 'asc',
                    width: 75,
                },
                {
                    label: <Trans>Tatoo Time</Trans>,
                    field: 'ride_time',
                    sort: 'asc',
                    width: 100,
                },
                {
                    label: <Trans>Action</Trans>,
                    field: 'action',
                    sort: 'asc',
                    width: 80,
                }
            ],
            rows: rowsData,
        };
        return (
            <>
                <div className='fleets_table'>
                    <MDBDataTable
                        hover
                        scrollX
                        maxHeight='50vh'
                        entriesOptions={[50]}
                        entries={50}
                        data={data}
                        disableRetreatAfterSorting={true}
                    />
                </div>
            </>
        );
    }
}
export default UnlockRidestablePage;