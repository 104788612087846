import './App.css';
import React, { useState } from "react";
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
//import Login from './pages/Login/login';
import Form from './pages/Login/Form'
import Dashboard from './Dashboard'
import Cookies from 'universal-cookie';
const cookies = new Cookies();
const App = () => {
  const [loggedIn, setloggedIn] = useState(cookies.get('loggedIn'));
  console.log('cookies loggedIn App:');
  console.log(cookies.get('loggedIn'));
  function callbackFunction(childData) {
    setloggedIn(childData);
    console.log(childData);
  }

  console.log('loggedIn App:');
  console.log(loggedIn);

  return (
    <>
      <Router>
        <Switch>
          <Route path="/">
            {(loggedIn == true || loggedIn == 'true') ? (
              <Dashboard />
            ) : (
              <Form parentCallback={callbackFunction}/>
            )}
          </Route>
        </Switch>
      </Router>
    </>
  );
}

export default App;
