import React from 'react';
import { Line } from 'react-chartjs-2';
import { translate, Trans } from 'react-i18next';

class RevenueChart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      option: {
        plugins: {
          legend: {
            display: false,
          }
        }
      }
    }
  }

  render() {
    const data = {
      labels: this.props.revenueLabel,
      datasets: [
        {
          label:'Revenue',
          backgroundColor: 'rgba(255,99,132,0.2)',
          borderColor: 'blue',
          borderWidth: 5,
          hoverBackgroundColor: 'rgba(255,99,132,0.4)',
          hoverBorderColor: 'rgba(255,99,132,1)',
          data: this.props.revenueData
        }
      ]
    }
    return (
      <div style={{ width: "100%", color: "black" }}>
        <h4 style={{ textAlign: "left" }}><Trans>Revenue over time</Trans></h4>
        <Line
          data={data}
          options={this.state.option}
        />
      </div>
    );
  }
}

export default RevenueChart