

export default function validate(values) {
  let errors = {};
  if (!values.email) {
    errors.email = "Email address is required";
  } else if (!/\S+@\S+\.\S+/.test(values.email)) {
    errors.email = "Email address is invalid";
  }
  if (!values.password) {
    errors.password = "Password is required";
  } else if (values.password.length < 8) {
    errors.password = "Password must be 8 or more characters";
  }
  if ((values.email) && (values.password)) {
    if ((/\S+@\S+\.\S+/.test(values.email)) && (values.password.length >= 8)) {
      const formLoginData = new URLSearchParams();
      formLoginData.append('admin_email', values.email);
      formLoginData.append('admin_password', values.password);
      fetch("https://admin.connect-ink.com/API/authentication/admin_sign_in_api.php",
        {
          method: 'POST',
          headers: {
            'Content-Type': "application/x-www-form-urlencoded;charset=UTF-8"
          },
          body: formLoginData.toString()
        }
      )
        .then(res => res.json())
        .then(
          (data) => {
            if (data.admin_login == 0) {
              errors.email = "Invalid Email or password";
            }
          }
        );
    }
  }


  {/*else if (!/\d/.test(values.password)) {
      errors.password = "Password must contain atleast 1 number";
    } else if (!/[!@#$%&?]/g.test(values.password)) {
      errors.password = "Password must contain atleast 1 special character";
    } else if (!/[A-Z]/g.test(values.password)) {
      errors.password = "Password must contain atleast 1 capitol letter";
    }*/}
  return errors;
}
