import React from 'react';
import './Org.css';
import * as AiIcons from 'react-icons/ai';
import DatatablePage from './Org_table';
import { Trans } from 'react-i18next';
import Cookies from 'universal-cookie';

class Org extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            add_location_button: false,
            edit_location_button: false,
            add_location_footer: true,
            confirm_add_location: false,
            deleteLocation: false,
            deleteLocationId: null,
            deleteLocationName: null,
            show_org_table: true,
            info: [],
            location_filter: [],
            location_param: 'all_location',
            operating_status_param: 'all_status',
            location_id: [],
            location_name: [],
            location_code: [],
            country: [],
            address: [],
            billing_plan: [],
            operating_status: [],
            disable_param: null,
            enable_param: null,
            show_view_location: false,
            edit_location: {
                edit_location_id: null,
                edit_location_name: null,
                edit_location_code: null,
                edit_location_country: null,
                edit_location_address: null,
                edit_location_billing_plan: null,
            },
            edit_location_id_success: null,
            edit_location_footer: true,
            view_data: {
                location_id: null,
                location_name: null,
                location_country: null,
                location_address: null,
                location_billing_plan: null,
                location_operating_status: null,
            },
            form_add_location: {
                add_location_name: null,
                add_location_country: null,
                add_location_address: null,
                add_location_billing_plan: null,
            }
        }
        this.refCountry = React.createRef();
        this.refEditCountry = React.createRef();
        this.refBillingPlan = React.createRef();
        this.handleCountryChange = this.handleCountryChange.bind(this);
        this.handleStatusChange = this.handleStatusChange.bind(this);
        this.handleAddLocation = this.handleAddLocation.bind(this);
        this.handleCancelAddLocation = this.handleCancelAddLocation.bind(this);
        this.handlelAddLocationSubmit = this.handlelAddLocationSubmit.bind(this);
        this.handleConfirmAddLocation = this.handleConfirmAddLocation.bind(this);
        this.handleDisableLocation = this.handleDisableLocation.bind(this);
        this.handleEnableLocation = this.handleEnableLocation.bind(this);
        this.handlerViewLocation = this.handlerViewLocation.bind(this);
        this.handleCancelViewLocation = this.handleCancelViewLocation.bind(this);
        this.handleEditLocationButton = this.handleEditLocationButton.bind(this);
        this.handlelEditLocationSubmit = this.handlelEditLocationSubmit.bind(this);
        this.handleCancelEditLocation = this.handleCancelEditLocation.bind(this);
        this.handleDeleteLocation = this.handleDeleteLocation.bind(this);
        this.handleCancelDeleteLocation = this.handleCancelDeleteLocation.bind(this);
        this.handleConfirmDeleteLocation = this.handleConfirmDeleteLocation.bind(this);
        this.handleConfirmEditLocation = this.handleConfirmEditLocation.bind(this);
    }
    render() {
        let deleteLocationForm = this.state.deleteLocation ? "show_delete_location_form" : "hide_delete_location_form";
        let add_location = this.state.add_location_button ? "add_location_show" : "add_location_hide";
        let confirm_add_location = this.state.confirm_add_location ? "confirm_add_location_show" : "confirm_add_location_hide";
        let footer = this.state.add_location_footer ? "add_org_footer" : "add_org_footer_hide";
        let edit_footer = this.state.edit_location_footer ? "edit_org_footer" : "edit_org_footer_hide";
        let confirm_edit_location = this.state.edit_location_footer ? "confirm_edit_location_hide" : "confirm_edit_location_show";
        let org_contain = this.state.show_org_table ? "org_contain" : "hide_org_contain";
        let view_location = this.state.show_view_location ? "view_location_show" : "view_location_hide";
        let edit_location = this.state.edit_location_button ? "edit_location_show" : "edit_location_hide";
        const orgTableData = [this.state.location_id, this.state.location_name, this.state.country, this.state.billing_plan, this.state.operating_status, this.state.location_code, this.state.address];
        return (
            <>
                <div className={org_contain}>
                    <div>
                        <p className="org_title"><Trans>Organisations</Trans></p>
                    </div>
                    <div>
                        <table className="org_menu_filter">
                            <tbody>
                                <tr>
                                    <td className='org_leftside_menu'>
                                        <div className="select_left">
                                            <select className="org_country_select" onChange={this.handleCountryChange}>
                                                <option value="all_location"><Trans>Country</Trans></option>
                                                {this.state.location_filter.map((x) =>
                                                    <option value={x}>{x}</option>)}
                                            </select>
                                            <select className="org_status_select" onChange={this.handleStatusChange}>
                                                <option className='org_action_button' value="all_status"><Trans>Operating Status</Trans></option>
                                                <option className='org_action_button' value="Normal">Normal</option>
                                                <option className='org_action_button' value="Disabled">Disabled</option>
                                                <option className='org_action_button' value="Deleted">Deleted</option>
                                            </select>

                                        </div>
                                    </td>
                                    <td className='org_rightside_menu'>
                                        {/*<button className="org_btn_add_location" onClick={this.handleAddLocation} type="button"><AiIcons.AiOutlinePlus /><Trans>Add Location</Trans></button>*/}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div className="table_div">
                            <DatatablePage tableData={orgTableData} handler={this.handleDisableLocation} handlerEnable={this.handleEnableLocation} handlerView={this.handlerViewLocation} handlerEdit={this.handleEditLocationButton} handlerDelete={this.handleDeleteLocation} />
                        </div>
                    </div>
                </div>
                <div className={add_location}>
                    <div className="add_location_form_header"><b><Trans>Add Location</Trans></b></div>
                    <form onSubmit={this.handlelAddLocationSubmit}>
                        <div className="form">
                            <div className="form-group">
                                <label><Trans>Location Name</Trans></label>
                                <input type="text" name="locationName" placeholder="Location Name" required />
                            </div>
                            <div className="form-group">
                                <label><Trans>Location Code</Trans></label>
                                <input type="text" name="locationCode" placeholder="Location Code" required />
                            </div>
                            <div className="form-group">
                                <label><Trans>Country</Trans></label>
                                <select ref={this.refCountry}>
                                <option value="AF$Afghanistan">Afghanistan</option>
                                    <option value="AL$AlbaniaAL">Albania</option>
                                    <option value="DZ$Algeria">Algeria</option>
                                    <option value="AS$American Samoa">American Samoa</option>
                                    <option value="AD$Andorra">Andorra</option>
                                    <option value="AO$Angola">Angola</option>
                                    <option value="AI$Anguilla">Anguilla</option>
                                    <option value="AQ$Antarctica">Antarctica</option>
                                    <option value="AR$Argentina">Argentina</option>
                                    <option value="AM$Armenia">Armenia</option>
                                    <option value="AW$Aruba">Aruba</option>
                                    <option value="AU$Australia">Australia</option>
                                    <option value="AT$Austria">Austria</option>
                                    <option value="AZ$Azerbaijan">Azerbaijan</option>
                                    <option value="BS$Bahamas">Bahamas</option>
                                    <option value="BH$Bahrain">Bahrain</option>
                                    <option value="BD$Bangladesh">Bangladesh</option>
                                    <option value="BB$Barbados">Barbados</option>
                                    <option value="BY$Belarus">Belarus</option>
                                    <option value="BE$Belgium">Belgium</option>
                                    <option value="BZ$Belize">Belize</option>
                                    <option value="BJ$Benin">Benin</option>
                                    <option value="BM$Bermuda">Bermuda</option>
                                    <option value="BT$Bhutan">Bhutan</option>
                                    <option value="BO#Bolivia">Bolivia</option>
                                    <option value="BA$Bosnia and Herzegowina">Bosnia and Herzegowina</option>
                                    <option value="BW$Botswana">Botswana</option>
                                    <option value="BR$Brazil">Brazil</option>
                                    <option value="BN$Brunei">Brunei</option>
                                    <option value="BG$Bulgaria">Bulgaria</option>
                                    <option value="BF$Burkina Faso">Burkina Faso</option>
                                    <option value="BI$Burundi">Burundi</option>
                                    <option value="KH$Cambodia">Cambodia</option>
                                    <option value="CM$Cameroon">Cameroon</option>
                                    <option value="CA$Canada">Canada</option>
                                    <option value="CV$Cape Verde">Cape Verde</option>
                                    <option value="CF$Central African Republic">Central African Republic</option>
                                    <option value="TD$Chad">Chad</option>
                                    <option value="CL$Chile">Chile</option>
                                    <option value="CN$China">China</option>
                                    <option value="CO#Colombia">Colombia</option>
                                    <option value="KM$Comoros">Comoros</option>
                                    <option value="CG$Congo">Congo</option>
                                    <option value="CR$Costa Rica">Costa Rica</option>
                                    <option value="CI$Cote d'Ivoire">Cote d'Ivoire</option>
                                    <option value="HR$Croatia">Croatia</option>
                                    <option value="CU$Cuba">Cuba</option>
                                    <option value="CY$Cyprus">Cyprus</option>
                                    <option value="CZ$Czech Republic">Czech Republic</option>
                                    <option value="DK$Denmark">Denmark</option>
                                    <option value="DJ$Djibouti">Djibouti</option>
                                    <option value="DM$Dominica">Dominica</option>
                                    <option value="DO$Dominican Republic">Dominican Republic</option>
                                    <option value="TL$East Timor">East Timor</option>
                                    <option value="EC$Ecuador">Ecuador</option>
                                    <option value="EG$Egypt">Egypt</option>
                                    <option value="SV$El Salvador">El Salvador</option>
                                    <option value="GQ$Equatorial Guinea">Equatorial Guinea</option>
                                    <option value="ER$Eritrea">Eritrea</option>
                                    <option value="EE$Estonia">Estonia</option>
                                    <option value="ET$Ethiopia">Ethiopia</option>
                                    <option value="FI$Finland">Finland</option>
                                    <option value="FR#France">France</option>
                                    <option value="GA$Gabon">Gabon</option>
                                    <option value="GM$Gambia">Gambia</option>
                                    <option value="GE$Georgia">Georgia</option>
                                    <option value="DE$Germany">Germany</option>
                                    <option value="GH$Ghana">Ghana</option>
                                    <option value="GI$">Gibraltar</option>
                                    <option value="GR$Greece">Greece</option>
                                    <option value="GL$Greenland">Greenland</option>
                                    <option value="GD$Grenada">Grenada</option>
                                    <option value="GE$Guadeloupe">Guadeloupe</option>
                                    <option value="GU$Guam">Guam</option>
                                    <option value="GT$Guatemala">Guatemala</option>
                                    <option value="GN$Guinea">Guinea</option>
                                    <option value="GW$Guinea-Bissau">Guinea-Bissau</option>
                                    <option value="GY$Guyana">Guyana</option>
                                    <option value="HT$Haiti">Haiti</option>
                                    <option value="HN$Honduras">Honduras</option>
                                    <option value="HK$Hong Kong">Hong Kong</option>
                                    <option value="HU$Hungary">Hungary</option>
                                    <option value="IS$Iceland">Iceland</option>
                                    <option value="IN$India">India</option>
                                    <option value="ID$Indonesia">Indonesia</option>
                                    <option value="IR$Iran">Iran</option>
                                    <option value="IQ$Iraq">Iraq</option>
                                    <option value="IE$Ireland">Ireland</option>
                                    <option value="IM$Israel">Israel</option>
                                    <option value="IT$Italy">Italy</option>
                                    <option value="JM$Jamaica">Jamaica</option>
                                    <option value="JP$Japan">Japan</option>
                                    <option value="JO$Jordan">Jordan</option>
                                    <option value="KZ$Kazakhstan">Kazakhstan</option>
                                    <option value="KE$Kenya">Kenya</option>
                                    <option value="KI$Kiribati">Kiribati</option>
                                    <option value="KO$Korea">Korea</option>
                                    <option value="KW$Kuwait">Kuwait</option>
                                    <option value="KG$Kyrgyzstan">Kyrgyzstan</option>
                                    <option value="LA$Lao People's Republic">Lao People's Republic</option>
                                    <option value="LV$Latvia">Latvia</option>
                                    <option value="LB$Lebanon">Lebanon</option>
                                    <option value="LS$Lesotho">Lesotho</option>
                                    <option value="LR$Liberia">Liberia</option>
                                    <option value="LY$Libyan">Libyan</option>
                                    <option value="LI$Liechtenstein">Liechtenstein</option>
                                    <option value="LT$Lithuania">Lithuania</option>
                                    <option value="LU$Luxembourg">Luxembourg</option>
                                    <option value="MO$Macau">Macau</option>
                                    <option value="MK$Madagascar">Madagascar</option>
                                    <option value="MW$Malawi">Malawi</option>
                                    <option value="MY$Malaysia">Malaysia</option>
                                    <option value="MV$Maldives">Maldives</option>
                                    <option value="ML$Mali">Mali</option>
                                    <option value="MT$Malta">Malta</option>
                                    <option value="MH$Marshall Islands">Marshall Islands</option>
                                    <option value="MQ$Martinique">Martinique</option>
                                    <option value="MR$Mauritania">Mauritania</option>
                                    <option value="MU$Mauritius">Mauritius</option>
                                    <option value="YT$Mayotte">Mayotte</option>
                                    <option value="MX$Mexico">Mexico</option>
                                    <option value="MD$Moldova">Moldova</option>
                                    <option value="MC$Monaco">Monaco</option>
                                    <option value="MN$Mongolia">Mongolia</option>
                                    <option value="MS$Montserrat">Montserrat</option>
                                    <option value="MA$Morocco">Morocco</option>
                                    <option value="MZ$Mozambique">Mozambique</option>
                                    <option value="MM$Myanmar">Myanmar</option>
                                    <option value="NA$Namibia">Namibia</option>
                                    <option value="NR$Nauru">Nauru</option>
                                    <option value="NP$Nepal">Nepal</option>
                                    <option value="NL$Netherlands">Netherlands</option>
                                    <option value="AN$Netherlands Antilles">Netherlands Antilles</option>
                                    <option value="NC$New Caledonia">New Caledonia</option>
                                    <option value="NZ$New Zealand">New Zealand</option>
                                    <option value="NI$Nicaragua">Nicaragua</option>
                                    <option value="NE$Niger">Niger</option>
                                    <option value="NG$Nigeria">Nigeria</option>
                                    <option value="NU$Niue">Niue</option>
                                    <option value="NO$Norway">Norway</option>
                                    <option value="OM$Oman">Oman</option>
                                    <option value="PK$Pakistan">Pakistan</option>
                                    <option value="PU$Palau">Palau</option>
                                    <option value="PA$Panama">Panama</option>
                                    <option value="PG$Papua New Guinea">Papua New Guinea</option>
                                    <option value="PY$Paraguay">Paraguay</option>
                                    <option value="PE$Peru">Peru</option>
                                    <option value="PH$Philippines">Philippines</option>
                                    <option value="PN$Pitcairn">Pitcairn</option>
                                    <option value="PL$Poland">Poland</option>
                                    <option value="PT$Portugal">Portugal</option>
                                    <option value="PR$Puerto Rico">Puerto Rico</option>
                                    <option value="QA$Qatar">Qatar</option>
                                    <option value="RE$Reunion">Reunion</option>
                                    <option value="RO$Romania">Romania</option>
                                    <option value="RU$Russian Federation">Russian Federation</option>
                                    <option value="RW$Rwanda">Rwanda</option>
                                    <option value="WS$Samoa">Samoa</option>
                                    <option value="SM$San Marino">San Marino</option>
                                    <option value="ST$Sao Tome and Principe">Sao Tome and Principe</option>
                                    <option value="SA$Saudi Arabia">Saudi Arabia</option>
                                    <option value="SN$Senegal">Senegal</option>
                                    <option value="SC$Seychelles">Seychelles</option>
                                    <option value="SL$Sierra Leone">Sierra Leone</option>
                                    <option value="SG$Singapore">Singapore</option>
                                    <option value="SK$Slovakia">Slovakia</option>
                                    <option value="SI$Slovenia">Slovenia</option>
                                    <option value="SB$Solomon Islands">Solomon Islands</option>
                                    <option value="SO$Somalia">Somalia</option>
                                    <option value="ZA$South Africa">South Africa</option>
                                    <option value="ES$Spain">Spain</option>
                                    <option value="LK$Sri Lanka">Sri Lanka</option>
                                    <option value="SD$Sudan">Sudan</option>
                                    <option value="SR$Suriname">Suriname</option>
                                    <option value="SZ$Swaziland">Swaziland</option>
                                    <option value="SE$Sweden">Sweden</option>
                                    <option value="CH$Switzerland">Switzerland</option>
                                    <option value="SY$Syrian">Syrian</option>
                                    <option value="TW$Taiwan">Taiwan</option>
                                    <option value="TJ$Tajikistan">Tajikistan</option>
                                    <option value="TZ$Tanzania">Tanzania</option>
                                    <option value="TH$Thailand">Thailand</option>
                                    <option value="TG$Togo">Togo</option>
                                    <option value="TK$Tokelau">Tokelau</option>
                                    <option value="TO$Tonga">Tonga</option>
                                    <option value="TT$Trinidad and Tobago">Trinidad and Tobago</option>
                                    <option value="TN$Tunisia">Tunisia</option>
                                    <option value="TR$Turkey">Turkey</option>
                                    <option value="TM$Turkmenistan">Turkmenistan</option>
                                    <option value="TV$Tuvalu">Tuvalu</option>
                                    <option value="UG$Uganda">Uganda</option>
                                    <option value="UA$Ukraine">Ukraine</option>
                                    <option value="AE$United Arab Emirates">United Arab Emirates</option>
                                    <option value="GB$United Kingdom">United Kingdom</option>
                                    <option value="US$United States">United States</option>
                                    <option value="UY$Uruguay">Uruguay</option>
                                    <option value="UZ$Uzbekistan">Uzbekistan</option>
                                    <option value="VU$Vanuatu">Vanuatu</option>
                                    <option value="VE$Venezuela">Venezuela</option>
                                    <option value="VN$Vietnam">Viet Nam</option>
                                    <option value="EH$Western Sahara">Western Sahara</option>
                                    <option value="YE$Yemen">Yemen</option>
                                    <option value="YY$Yugoslavia">Yugoslavia</option>
                                    <option value="ZM$Zambia">Zambia</option>
                                    <option value="ZW$Zimbabwe">Zimbabwe</option>
                                </select>
                            </div>
                            <div className="form-group">
                                <label><Trans>Address</Trans></label>
                                <input type="text" name="address" placeholder="Address" required />
                            </div>
                            <div className="form-group">
                                <label><Trans>Billing Plan</Trans></label>
                                <select name="billingPlan" ref={this.refBillingPlan}>
                                    <option value="Time_Base">Time_Base</option>
                                    <option value="Range_Base">Range_Base</option>
                                </select>
                            </div>
                        </div>
                        <div className={footer}>
                            <button type="button" className="btn_cancel_adding" onClick={this.handleCancelAddLocation}>
                                <Trans>Cancel</Trans>
                            </button>
                            <button type="submit" className="btn_add_location">
                                <Trans>Add Location</Trans>
                            </button>
                        </div>
                    </form>
                </div>
                <div className={confirm_add_location}>
                    <p className="add_location_confirm_title">The location {this.state.form_add_location.add_location_name} has been added recently !</p>
                    <button className="add_location_confirm_button" type="button" onClick={this.handleConfirmAddLocation}>
                        OK
                    </button>
                </div>
                <div className={view_location}>
                    <div className="add_location_form_header"><b><Trans>View Location</Trans></b></div>
                    <div className="form">
                        <div className="form-group">
                            <label><Trans>Location Name</Trans></label>
                            <input type="text" name="locationName" value={this.state.view_data.location_name} />
                        </div>
                        <div className="form-group">
                            <label><Trans>Country</Trans></label>
                            <input type="text" value={this.state.view_data.location_country} />
                        </div>
                        <div className="form-group">
                            <label><Trans>Address</Trans></label>
                            <input type="text" name="address" value={this.state.view_data.location_address} />
                        </div>
                        <div className="form-group">
                            <label><Trans>Billing Plan</Trans></label>
                            <input type="text" name="billing_plane" value={this.state.view_data.location_billing_plan} />
                        </div>
                    </div>
                    <div className="view_location_footer">
                        <button type="button" className="btn_cancel_adding" onClick={this.handleCancelViewLocation}>
                            OK
                        </button>
                    </div>
                </div>
                <div className={edit_location}>
                    <div className="add_location_form_header"><b><Trans>Edit Location</Trans></b></div>
                    <form onSubmit={this.handlelEditLocationSubmit}>
                        <div className="form">
                            <div style={{ display: "none" }}>
                                <input type="text" name="locationId" defaultValue={this.state.edit_location.edit_location_id} />
                            </div>
                            <div className="form-group">
                                <label><Trans>Location Name</Trans></label>
                                <input type="text" name="locationName" defaultValue={this.state.edit_location.edit_location_name} required />
                            </div>
                            <div className="form-group">
                                <label><Trans>Location Code</Trans></label>
                                <input type="text" name="locationCode" defaultValue={this.state.edit_location.edit_location_code} required />
                            </div>
                            <div className="form-group">
                                <label><Trans>Country</Trans></label>
                                <select name="editLocationCountry" ref={this.refEditCountry}>
                                <option value="AL$AlbaniaAL">Albania</option>
                                    <option value="DZ$Algeria">Algeria</option>
                                    <option value="AS$American Samoa">American Samoa</option>
                                    <option value="AD$Andorra">Andorra</option>
                                    <option value="AO$Angola">Angola</option>
                                    <option value="AI$Anguilla">Anguilla</option>
                                    <option value="AQ$Antarctica">Antarctica</option>
                                    <option value="AR$Argentina">Argentina</option>
                                    <option value="AM$Armenia">Armenia</option>
                                    <option value="AW$Aruba">Aruba</option>
                                    <option value="AU$Australia">Australia</option>
                                    <option value="AT$Austria">Austria</option>
                                    <option value="AZ$Azerbaijan">Azerbaijan</option>
                                    <option value="BS$Bahamas">Bahamas</option>
                                    <option value="BH$Bahrain">Bahrain</option>
                                    <option value="BD$Bangladesh">Bangladesh</option>
                                    <option value="BB$Barbados">Barbados</option>
                                    <option value="BY$Belarus">Belarus</option>
                                    <option value="BE$Belgium">Belgium</option>
                                    <option value="BZ$Belize">Belize</option>
                                    <option value="BJ$Benin">Benin</option>
                                    <option value="BM$Bermuda">Bermuda</option>
                                    <option value="BT$Bhutan">Bhutan</option>
                                    <option value="BO#Bolivia">Bolivia</option>
                                    <option value="BA$Bosnia and Herzegowina">Bosnia and Herzegowina</option>
                                    <option value="BW$Botswana">Botswana</option>
                                    <option value="BR$Brazil">Brazil</option>
                                    <option value="BN$Brunei">Brunei</option>
                                    <option value="BG$Bulgaria">Bulgaria</option>
                                    <option value="BF$Burkina Faso">Burkina Faso</option>
                                    <option value="BI$Burundi">Burundi</option>
                                    <option value="KH$Cambodia">Cambodia</option>
                                    <option value="CM$Cameroon">Cameroon</option>
                                    <option value="CA$Canada">Canada</option>
                                    <option value="CV$Cape Verde">Cape Verde</option>
                                    <option value="CF$Central African Republic">Central African Republic</option>
                                    <option value="TD$Chad">Chad</option>
                                    <option value="CL$Chile">Chile</option>
                                    <option value="CN$China">China</option>
                                    <option value="CO#Colombia">Colombia</option>
                                    <option value="KM$Comoros">Comoros</option>
                                    <option value="CG$Congo">Congo</option>
                                    <option value="CR$Costa Rica">Costa Rica</option>
                                    <option value="CI$Cote d'Ivoire">Cote d'Ivoire</option>
                                    <option value="HR$Croatia">Croatia</option>
                                    <option value="CU$Cuba">Cuba</option>
                                    <option value="CY$Cyprus">Cyprus</option>
                                    <option value="CZ$Czech Republic">Czech Republic</option>
                                    <option value="DK$Denmark">Denmark</option>
                                    <option value="DJ$Djibouti">Djibouti</option>
                                    <option value="DM$Dominica">Dominica</option>
                                    <option value="DO$Dominican Republic">Dominican Republic</option>
                                    <option value="TL$East Timor">East Timor</option>
                                    <option value="EC$Ecuador">Ecuador</option>
                                    <option value="EG$Egypt">Egypt</option>
                                    <option value="SV$El Salvador">El Salvador</option>
                                    <option value="GQ$Equatorial Guinea">Equatorial Guinea</option>
                                    <option value="ER$Eritrea">Eritrea</option>
                                    <option value="EE$Estonia">Estonia</option>
                                    <option value="ET$Ethiopia">Ethiopia</option>
                                    <option value="FI$Finland">Finland</option>
                                    <option value="FR#France">France</option>
                                    <option value="GA$Gabon">Gabon</option>
                                    <option value="GM$Gambia">Gambia</option>
                                    <option value="GE$Georgia">Georgia</option>
                                    <option value="DE$Germany">Germany</option>
                                    <option value="GH$Ghana">Ghana</option>
                                    <option value="GI$">Gibraltar</option>
                                    <option value="GR$Greece">Greece</option>
                                    <option value="GL$Greenland">Greenland</option>
                                    <option value="GD$Grenada">Grenada</option>
                                    <option value="GE$Guadeloupe">Guadeloupe</option>
                                    <option value="GU$Guam">Guam</option>
                                    <option value="GT$Guatemala">Guatemala</option>
                                    <option value="GN$Guinea">Guinea</option>
                                    <option value="GW$Guinea-Bissau">Guinea-Bissau</option>
                                    <option value="GY$Guyana">Guyana</option>
                                    <option value="HT$Haiti">Haiti</option>
                                    <option value="HN$Honduras">Honduras</option>
                                    <option value="HK$Hong Kong">Hong Kong</option>
                                    <option value="HU$Hungary">Hungary</option>
                                    <option value="IS$Iceland">Iceland</option>
                                    <option value="IN$India">India</option>
                                    <option value="ID$Indonesia">Indonesia</option>
                                    <option value="IR$Iran">Iran</option>
                                    <option value="IQ$Iraq">Iraq</option>
                                    <option value="IE$Ireland">Ireland</option>
                                    <option value="IM$Israel">Israel</option>
                                    <option value="IT$Italy">Italy</option>
                                    <option value="JM$Jamaica">Jamaica</option>
                                    <option value="JP$Japan">Japan</option>
                                    <option value="JO$Jordan">Jordan</option>
                                    <option value="KZ$Kazakhstan">Kazakhstan</option>
                                    <option value="KE$Kenya">Kenya</option>
                                    <option value="KI$Kiribati">Kiribati</option>
                                    <option value="KO$Korea">Korea</option>
                                    <option value="KW$Kuwait">Kuwait</option>
                                    <option value="KG$Kyrgyzstan">Kyrgyzstan</option>
                                    <option value="LA$Lao People's Republic">Lao People's Republic</option>
                                    <option value="LV$Latvia">Latvia</option>
                                    <option value="LB$Lebanon">Lebanon</option>
                                    <option value="LS$Lesotho">Lesotho</option>
                                    <option value="LR$Liberia">Liberia</option>
                                    <option value="LY$Libyan">Libyan</option>
                                    <option value="LI$Liechtenstein">Liechtenstein</option>
                                    <option value="LT$Lithuania">Lithuania</option>
                                    <option value="LU$Luxembourg">Luxembourg</option>
                                    <option value="MO$Macau">Macau</option>
                                    <option value="MK$Madagascar">Madagascar</option>
                                    <option value="MW$Malawi">Malawi</option>
                                    <option value="MY$Malaysia">Malaysia</option>
                                    <option value="MV$Maldives">Maldives</option>
                                    <option value="ML$Mali">Mali</option>
                                    <option value="MT$Malta">Malta</option>
                                    <option value="MH$Marshall Islands">Marshall Islands</option>
                                    <option value="MQ$Martinique">Martinique</option>
                                    <option value="MR$Mauritania">Mauritania</option>
                                    <option value="MU$Mauritius">Mauritius</option>
                                    <option value="YT$Mayotte">Mayotte</option>
                                    <option value="MX$Mexico">Mexico</option>
                                    <option value="MD$Moldova">Moldova</option>
                                    <option value="MC$Monaco">Monaco</option>
                                    <option value="MN$Mongolia">Mongolia</option>
                                    <option value="MS$Montserrat">Montserrat</option>
                                    <option value="MA$Morocco">Morocco</option>
                                    <option value="MZ$Mozambique">Mozambique</option>
                                    <option value="MM$Myanmar">Myanmar</option>
                                    <option value="NA$Namibia">Namibia</option>
                                    <option value="NR$Nauru">Nauru</option>
                                    <option value="NP$Nepal">Nepal</option>
                                    <option value="NL$Netherlands">Netherlands</option>
                                    <option value="AN$Netherlands Antilles">Netherlands Antilles</option>
                                    <option value="NC$New Caledonia">New Caledonia</option>
                                    <option value="NZ$New Zealand">New Zealand</option>
                                    <option value="NI$Nicaragua">Nicaragua</option>
                                    <option value="NE$Niger">Niger</option>
                                    <option value="NG$Nigeria">Nigeria</option>
                                    <option value="NU$Niue">Niue</option>
                                    <option value="NO$Norway">Norway</option>
                                    <option value="OM$Oman">Oman</option>
                                    <option value="PK$Pakistan">Pakistan</option>
                                    <option value="PU$Palau">Palau</option>
                                    <option value="PA$Panama">Panama</option>
                                    <option value="PG$Papua New Guinea">Papua New Guinea</option>
                                    <option value="PY$Paraguay">Paraguay</option>
                                    <option value="PE$Peru">Peru</option>
                                    <option value="PH$Philippines">Philippines</option>
                                    <option value="PN$Pitcairn">Pitcairn</option>
                                    <option value="PL$Poland">Poland</option>
                                    <option value="PT$Portugal">Portugal</option>
                                    <option value="PR$Puerto Rico">Puerto Rico</option>
                                    <option value="QA$Qatar">Qatar</option>
                                    <option value="RE$Reunion">Reunion</option>
                                    <option value="RO$Romania">Romania</option>
                                    <option value="RU$Russian Federation">Russian Federation</option>
                                    <option value="RW$Rwanda">Rwanda</option>
                                    <option value="WS$Samoa">Samoa</option>
                                    <option value="SM$San Marino">San Marino</option>
                                    <option value="ST$Sao Tome and Principe">Sao Tome and Principe</option>
                                    <option value="SA$Saudi Arabia">Saudi Arabia</option>
                                    <option value="SN$Senegal">Senegal</option>
                                    <option value="SC$Seychelles">Seychelles</option>
                                    <option value="SL$Sierra Leone">Sierra Leone</option>
                                    <option value="SG$Singapore">Singapore</option>
                                    <option value="SK$Slovakia">Slovakia</option>
                                    <option value="SI$Slovenia">Slovenia</option>
                                    <option value="SB$Solomon Islands">Solomon Islands</option>
                                    <option value="SO$Somalia">Somalia</option>
                                    <option value="ZA$South Africa">South Africa</option>
                                    <option value="ES$Spain">Spain</option>
                                    <option value="LK$Sri Lanka">Sri Lanka</option>
                                    <option value="SD$Sudan">Sudan</option>
                                    <option value="SR$Suriname">Suriname</option>
                                    <option value="SZ$Swaziland">Swaziland</option>
                                    <option value="SE$Sweden">Sweden</option>
                                    <option value="CH$Switzerland">Switzerland</option>
                                    <option value="SY$Syrian">Syrian</option>
                                    <option value="TW$Taiwan">Taiwan</option>
                                    <option value="TJ$Tajikistan">Tajikistan</option>
                                    <option value="TZ$Tanzania">Tanzania</option>
                                    <option value="TH$Thailand">Thailand</option>
                                    <option value="TG$Togo">Togo</option>
                                    <option value="TK$Tokelau">Tokelau</option>
                                    <option value="TO$Tonga">Tonga</option>
                                    <option value="TT$Trinidad and Tobago">Trinidad and Tobago</option>
                                    <option value="TN$Tunisia">Tunisia</option>
                                    <option value="TR$Turkey">Turkey</option>
                                    <option value="TM$Turkmenistan">Turkmenistan</option>
                                    <option value="TV$Tuvalu">Tuvalu</option>
                                    <option value="UG$Uganda">Uganda</option>
                                    <option value="UA$Ukraine">Ukraine</option>
                                    <option value="AE$United Arab Emirates">United Arab Emirates</option>
                                    <option value="GB$United Kingdom">United Kingdom</option>
                                    <option value="US$United States">United States</option>
                                    <option value="UY$Uruguay">Uruguay</option>
                                    <option value="UZ$Uzbekistan">Uzbekistan</option>
                                    <option value="VU$Vanuatu">Vanuatu</option>
                                    <option value="VE$Venezuela">Venezuela</option>
                                    <option value="VN$Vietnam">Viet Nam</option>
                                    <option value="EH$Western Sahara">Western Sahara</option>
                                    <option value="YE$Yemen">Yemen</option>
                                    <option value="YY$Yugoslavia">Yugoslavia</option>
                                    <option value="ZM$Zambia">Zambia</option>
                                    <option value="ZW$Zimbabwe">Zimbabwe</option>
                                </select>
                            </div>
                            <div className="form-group">
                                <label><Trans>Address</Trans></label>
                                <input type="text" name="address" defaultValue={this.state.edit_location.edit_location_address} required />
                            </div>
                            <div className="form-group">
                                <label><Trans>Billing Plan</Trans></label>
                                <select name="editLocationBillingPlan" defaultValue={this.state.edit_location.edit_location_billing_plan}>
                                    <option value="Time_Base">Time_Base</option>
                                    <option value="Range_Base">Range_Base</option>
                                </select>
                            </div>
                        </div>
                        <div className={edit_footer}>
                            <button type="button" className="btn_cancel_adding" onClick={this.handleCancelEditLocation}>
                                <Trans>Cancel</Trans>
                            </button>
                            <button type="submit" className="btn_add_location">
                                <Trans>Edit Location</Trans>
                            </button>
                        </div>
                    </form>
                </div>
                <div className={confirm_edit_location}>
                    <p className="add_location_confirm_title">The location ID {this.state.edit_location_id_success} has been edited recently !</p>
                    <button className="add_location_confirm_button" type="button" onClick={this.handleConfirmEditLocation}>
                        OK
                    </button>
                </div>
                <div className={deleteLocationForm}>
                    <form onSubmit={this.handleConfirmDeleteLocation}>
                        <div style={{ display: "none" }}>
                            <div>
                                <input type="text" name="deleteLocationName" defaultValue={this.state.deleteLocationId} />
                            </div>

                        </div>
                        <p className="delete_location_confirm_title"><Trans>Location</Trans> {this.state.deleteLocationName} <Trans>will be deleted</Trans> !</p>
                        <button type="button" className="delete_location_cancel_button" onClick={this.handleCancelDeleteLocation}>
                            <Trans>Cancel</Trans>
                        </button>
                        <button type="submit" className="add_role_confirm_button">
                            <Trans>Delete</Trans>
                        </button>
                    </form>
                </div>
            </>
        )
    }
    handleCancelAddLocation() {
        this.setState({
            add_location_button: false,
            show_org_table: true
        });
    }
    handleAddLocation() {
        this.setState({
            add_location_button: true,
            show_org_table: false,
            add_location_footer: true,
        });
    }
    handlelAddLocationSubmit(e) {
        const cookies = new Cookies();
        const countrySelect = this.refCountry.current.value;
        const countryValue = countrySelect.split("$");
        const countryCode = countryValue[0];
        const countryName = countryValue[1];
        e.preventDefault();
        const data = new FormData(e.target);
        const formAddLocationData = new URLSearchParams();
        formAddLocationData.append('add_location_name', data.get('locationName'));
        formAddLocationData.append('add_location_code', data.get('locationCode'));
        formAddLocationData.append('add_location_country', countryName);
        formAddLocationData.append('add_location_country_code', countryCode);
        formAddLocationData.append('add_location_address', data.get('address'));
        formAddLocationData.append('add_location_billing_plan', this.refBillingPlan.current.value);
        formAddLocationData.append('admin_name', cookies.get('adminName'));
        fetch("https://admin.connect-ink.com/API/organisations/org_add_location.php",
            {
                method: 'POST',
                headers: {
                    'Content-Type': "application/x-www-form-urlencoded;charset=UTF-8"
                },
                body: formAddLocationData.toString()
            }
        )
            .then(res => res.json())
            .then(
                (data) => {
                    this.setState({
                        form_add_location: {
                            add_location_name: data.location_name,
                        },
                        add_location_footer: false,
                        confirm_add_location: true,
                    });
                }
            );
    }
    handleConfirmAddLocation() {
        const formData = new URLSearchParams();
        formData.append('location_param', this.state.location_param);
        formData.append('operating_status_param', this.state.operating_status_param);
        fetch("https://admin.connect-ink.com/API/organisations/org_location.php",
            {
                method: 'POST',
                headers: {
                    'Content-Type': "application/x-www-form-urlencoded;charset=UTF-8"
                },
                body: formData.toString()
            }
        )
            .then(res => res.json())
            .then(
                (data) => {
                    this.setState({
                        isLoaded: true,
                        info: data,
                        location_filter: [...(new Set(data.country))],
                        location_id: data.location_id,
                        location_name: data.location_name,
                        country: data.country,
                        billing_plan: data.billing_plan,
                        operating_status: data.operating_status,
                        confirm_add_location: false,
                        add_location_button: false,
                        show_org_table: true
                    });
                    console.log("Location ID data: " + data.location_id)
                }

            );
    }
    handleCountryChange(event) {
        const valueCountryState = event.target.value;
        const formData = new URLSearchParams();
        formData.append('location_param', valueCountryState);
        formData.append('operating_status_param', this.state.operating_status_param);
        fetch("https://admin.connect-ink.com/API/organisations/org_filter.php",
            {
                method: 'POST',
                headers: {
                    'Content-Type': "application/x-www-form-urlencoded;charset=UTF-8"
                },
                body: formData.toString()
            }
        )
            .then(res => res.json())
            .then(
                (data) => {
                    this.setState({
                        isLoaded: true,
                        info: data,
                        location_param: valueCountryState,
                        location_id: data.location_id,
                        location_name: data.location_name,
                        country: data.country,
                        billing_plan: data.billing_plan,
                        operating_status: data.operating_status
                    });
                }
            );
    }
    handleStatusChange(event) {
        const valueStatusState = event.target.value;
        const formData = new URLSearchParams();
        formData.append('location_param', this.state.location_param);
        formData.append('operating_status_param', valueStatusState);
        fetch("https://admin.connect-ink.com/API/organisations/org_filter.php",
            {
                method: 'POST',
                headers: {
                    'Content-Type': "application/x-www-form-urlencoded;charset=UTF-8"
                },
                body: formData.toString()
            }
        )
            .then(res => res.json())
            .then(
                (data) => {
                    this.setState({
                        isLoaded: true,
                        info: data,
                        operating_status_param: valueStatusState,
                        location_id: data.location_id,
                        location_name: data.location_name,
                        country: data.country,
                        billing_plan: data.billing_plan,
                        operating_status: data.operating_status
                    });
                }
            );
    }
    handleDisableLocation(e) {
        const cookies = new Cookies();
        const formDisableData = new URLSearchParams();
        formDisableData.append('disable_id', e.target.value);
        formDisableData.append('location_param', this.state.location_param);
        formDisableData.append('operating_status_param', this.state.operating_status_param);
        formDisableData.append('admin_name', cookies.get('adminName'));
        fetch("https://admin.connect-ink.com/API/organisations/org_disable_location.php",
            {
                method: 'POST',
                headers: {
                    'Content-Type': "application/x-www-form-urlencoded;charset=UTF-8"
                },
                body: formDisableData.toString()
            }
        )
            .then(res => res.json())
            .then(
                (data) => {
                    this.setState({
                        isLoaded: true,
                        info: data,
                        operating_status_param: data.operating_status,
                        location_id: data.location_id,
                        location_name: data.location_name,
                        country: data.country,
                        billing_plan: data.billing_plan,
                        operating_status: data.operating_status
                    });

                }
            );
    }
    handleEnableLocation(e) {
        const cookies = new Cookies();
        const formEnableData = new URLSearchParams();
        formEnableData.append('enable_id', e.target.value);
        formEnableData.append('location_param', this.state.location_param);
        formEnableData.append('operating_status_param', this.state.operating_status_param);
        formEnableData.append('admin_name', cookies.get('adminName'));
        fetch("https://admin.connect-ink.com/API/organisations/org_enable_location.php",
            {
                method: 'POST',
                headers: {
                    'Content-Type': "application/x-www-form-urlencoded;charset=UTF-8"
                },
                body: formEnableData.toString()
            }
        )
            .then(res => res.json())
            .then(
                (data) => {
                    this.setState({
                        isLoaded: true,
                        info: data,
                        operating_status_param: data.operating_status,
                        location_id: data.location_id,
                        location_name: data.location_name,
                        country: data.country,
                        billing_plan: data.billing_plan,
                        operating_status: data.operating_status
                    });
                }
            );
    }
    handlerViewLocation(e) {
        const formViewData = new URLSearchParams();
        formViewData.append('location_id', e.target.value);
        fetch("https://admin.connect-ink.com/API/organisations/org_view_location.php",
            {
                method: 'POST',
                headers: {
                    'Content-Type': "application/x-www-form-urlencoded;charset=UTF-8"
                },
                body: formViewData.toString()
            }
        )
            .then(res => res.json())
            .then(
                (data) => {
                    this.setState({
                        view_data: {
                            location_id: data.location_id,
                            location_name: data.location_name,
                            location_country: data.country,
                            location_address: data.location_address,
                            location_billing_plan: data.billing_plan,
                            location_operating_status: data.operating_status,
                        }
                    });
                }
            );
        this.setState({
            show_org_table: false,
            show_view_location: true
        });
    }
    handleCancelViewLocation() {
        this.setState({
            show_org_table: true,
            show_view_location: false
        });
    }
    handleEditLocationButton(e) {
        const editDataValue = e.target.value;
        const editData = editDataValue.split(",");
        this.setState({
            edit_location_button: true,
            show_org_table: false,
            edit_location: {
                edit_location_id: editData[5],
                edit_location_name: editData[0],
                edit_location_code: editData[1],
                edit_location_country: editData[2],
                edit_location_address: editData[6],
                edit_location_billing_plan: editData[3],
            }

        });
    }
    handleCancelEditLocation() {
        this.setState({
            edit_location_button: false,
            show_org_table: true,
        });
    }
    handlelEditLocationSubmit(e) {
        e.preventDefault();
        const data = new FormData(e.target);
        const cookies = new Cookies();
        const countrySelect = data.get('editLocationCountry');
        const countryValue = countrySelect.split("$");
        const countryCode = countryValue[0];
        const countryName = countryValue[1];

        const formAddLocationData = new URLSearchParams();
        formAddLocationData.append('edit_location_id', data.get('locationId'));
        formAddLocationData.append('edit_location_name', data.get('locationName'));
        formAddLocationData.append('edit_location_code', data.get('locationCode'));
        formAddLocationData.append('edit_location_country', countryName);
        formAddLocationData.append('edit_location_country_code', countryCode);
        formAddLocationData.append('edit_location_address', data.get('address'));
        formAddLocationData.append('edit_location_billing_plan', data.get('editLocationBillingPlan'));
        formAddLocationData.append('admin_name', cookies.get('adminName'));
        fetch("http://api.elevate-mobility.com/api/organisations/org_edit_location.php",
            {
                method: 'POST',
                headers: {
                    'Content-Type': "application/x-www-form-urlencoded;charset=UTF-8"
                },
                body: formAddLocationData.toString()
            }
        )
            .then(res => res.json())
            .then(
                (data) => {
                    this.setState({
                        edit_location_id_success: data.edit_location_id_success,
                        edit_location_footer: false,
                        edit_location_button: true,
                        show_org_table: false,
                    });
                }
            );
    }
    handleConfirmEditLocation() {
        const formData = new URLSearchParams();
        formData.append('location_param', this.state.location_param);
        formData.append('operating_status_param', this.state.operating_status_param);
        fetch("https://admin.connect-ink.com/API/organisations/org_location.php",
            {
                method: 'POST',
                headers: {
                    'Content-Type': "application/x-www-form-urlencoded;charset=UTF-8"
                },
                body: formData.toString()
            }
        )
            .then(res => res.json())
            .then(
                (data) => {
                    this.setState({
                        isLoaded: true,
                        info: data,
                        location_filter: [...(new Set(data.country))],
                        location_id: data.location_id,
                        location_name: data.location_name,
                        location_code: data.location_code,
                        country: data.country,
                        address: data.location_address,
                        billing_plan: data.billing_plan,
                        operating_status: data.operating_status,
                        edit_location_footer: true,
                        edit_location_button: false,
                        show_org_table: true,
                    });
                }
            );
    }
    handleDeleteLocation(e) {
        const locationInfo = e.currentTarget.value.split(",");
        this.setState({
            deleteLocation: true,
            deleteLocationId: locationInfo[0],
            deleteLocationName: locationInfo[1],
        });
    }
    handleCancelDeleteLocation() {
        this.setState({
            deleteLocation: false,
            show_org_table: true,
        });
    }
    async handleConfirmDeleteLocation(e) {
        e.preventDefault();
        const data = new FormData(e.target);
        const cookies = new Cookies();
        const formData = new URLSearchParams();
        formData.append('delete_location_id', data.get('deleteLocationName'));
        formData.append('admin_name', cookies.get('adminName'));
        console.log("DELETE data: " + formData);
        fetch("https://admin.connect-ink.com/API/organisations/org_delete_location.php",
            {
                method: 'POST',
                headers: {
                    'Content-Type': "application/x-www-form-urlencoded;charset=UTF-8"
                },
                body: formData.toString()
            }
        )
            .then(res => res.json())
            .then(
                (data) => {
                    this.setState({
                        location_id: data.location_id,
                        location_name: data.location_name,
                        location_code: data.location_code,
                        country: data.country,
                        address: data.location_address,
                        billing_plan: data.billing_plan,
                        operating_status: data.operating_status,
                        deleteLocation: false,
                        show_org_table: true,
                    });
                }
            );
    }
    async componentDidMount() {
        const formData = new URLSearchParams();
        formData.append('location_param', this.state.location_param);
        formData.append('operating_status_param', this.state.operating_status_param);
        fetch("https://admin.connect-ink.com/API/organisations/org_location.php",
            {
                method: 'POST',
                headers: {
                    'Content-Type': "application/x-www-form-urlencoded;charset=UTF-8"
                },
                body: formData.toString()
            }
        )
            .then(res => res.json())
            .then(
                (data) => {
                    this.setState({
                        isLoaded: true,
                        info: data,
                        location_filter: [...(new Set(data.country))],
                        location_id: data.location_id,
                        location_name: data.location_name,
                        location_code: data.location_code,
                        country: data.country,
                        address: data.location_address,
                        billing_plan: data.billing_plan,
                        operating_status: data.operating_status

                    });
                }
            );
    }
}

export default Org;