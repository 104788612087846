import "./Map.css";
import React, { Component } from "react";
import { MapContainer, TileLayer, useMap, FeatureGroup, useMapEvents, MapConsumer } from "react-leaflet";
import { EditControl } from "react-leaflet-draw";
import "leaflet-draw/dist/leaflet.draw.css";
import 'leaflet-draw';


/*function ClickToPan(position){
  const map = useMapEvents({
    click: (e) => {
      console.log("on click map: " + e.latlng);
      const onClickLocation = [e.latlng.lat, e.latlng.lng]
      //const centerMap = e.target.getCenter();
      map.setView(onClickLocation, map.getZoom());
    },
  });
  if(!map){
    map.setView(position, map.getZoom());
  }
  return null;
}*/

function SetTimeOut() {
  const map = useMap();
  setTimeout(() => {
    map.invalidateSize();
  }, 250);
  return null;
}

export default class AddStationMap extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lat: 15.748576209260989,
      lng: 102.11316716113033,
      zoom: 2,
      currentPos: [0.0, 0.0],
      disableState: true
    };

  }

  componentDidMount() {
    this.setState({
      lat: this.props.position[0],
      lng: this.props.position[1],
    })
  }

  componentWillReceiveProps(nextProps) {
    if (this.state.lat == nextProps.position[0] && this.state.lng == nextProps.position[1]) {
    }
    else {
      this.setState({
        lat: nextProps.position[0],
        lng: nextProps.position[1],
      })
      const { map } = this.state;
      if (map) {
        map.flyTo(nextProps.position, 13);
      }
    }
  }
  render() {
    const locationArray = [this.state.lat, this.state.lng];
    return (
      <>
        <MapContainer style={{ height: "55vh", cursor: "crosshair" }} center={locationArray} zoom={14} whenCreated={map => this.setState({ map })}>
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          />
          <FeatureGroup ref={this.props.refFeatureGroup} style={{ zIndex: 0 }}>
            <EditControl
              position="topright"
              onCreated={this.props.getAreaData}
              edit={{
                edit: false
              }}
              draw={{
                polygon: this.props.editState,
                polyline: false,
                rectangle: false,
                circle: false,
                marker: false,
                circlemarker: false
              }}
              style={{ zIndex: 0 }}
            />
          </FeatureGroup>
          <SetTimeOut />
          {/*<ClickToPan parentCalback={this.props.clickPosition} />*/}
          {/*<SetCenter position={locationArray} />*/}
        </MapContainer>
      </>
    );
  }
}

