import React from 'react';
import './Station.css';
import * as FaIcons from 'react-icons/fa';
import StationTablePage from './Station_table';
import Switch from "react-switch";
import { Trans } from 'react-i18next';
import AddStationMap from './AddStationMap';
import EditStationMap from './EditStationMap';
import Cookies from 'universal-cookie';

class Station extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fleetName: [],
            fleetNameLat: [],
            fleetNameLng: [],
            fleetNameAddParkings: [],
            fleetNameEditParkings: [],
            fleetFilterParam: "all_fleet",
            activeFilterparam: "all",
            activeFilterState: false,
            stationTable: true,
            addStation: false,
            editStation: false,
            addStationFormConfirm: false,
            addStationFormFooter: true,
            editStationFormConfirm: false,
            editStationFormFooter: true,
            stationData: {
                stationId: [],
                stationName: [],
                stationFleet: [],
                stationStatus: [],
                stationCreatedDate: [],
                stationModifyDate: [],
            },
            editStationData: {
                editStationId: [],
                editStationName: [],
                editStationFleet: [],
                editStationLatArray: [10.77080777782741, 10.770965939767235, 10.770681248215716, 10.770570534761893, 10.770586350972078],
                editStationLngArray: [106.6917888056399, 106.69198733018773, 106.69221804790547, 106.69206244758419, 106.69197123360274],
                editStationDescription: [],
            },
            onEditState: true,
            temporaryAreaLat: [],
            temporaryAreaLng: [],
            confirmAreaLat: [],
            confirmAreaLng: [],
            add_station_name: null,
            add_station_id: null,
            edit_station_name: null,
            edit_station_id: null,
            confirmUseArea: false,
            confirmUseEditArea: false,
            temporaryEditAreaLat: [],
            temporaryEditAreaLng: [],
            confirmEditAreaLat: [],
            confirmEditAreaLng: [],
            onEditAreaDrawState: true,
            onAddAreaDrawState: true,
            deleteStationFormConfirm: false,
            deleteStationId: null,
            mapAddLat: 16.51632472900294,
            mapAddLng: 107.64006418629653,
            mapEditLat: 16.51632472900294,
            mapEditLng: 107.64006418629653,
        }
        this.selectFleetFilter = this.selectFleetFilter.bind(this);
        this.handleActiveChange = this.handleActiveChange.bind(this);
        //=============================================================================
        this.handleOpenAddStationForm = this.handleOpenAddStationForm.bind(this);
        this.handleCancelAddStation = this.handleCancelAddStation.bind(this);
        this.removePolygon = React.createRef();
        this.removeEditPolygon = React.createRef();
        this._onStationCreate = this._onStationCreate.bind(this);
        this.removePolygonFunction = this.removePolygonFunction.bind(this);
        this.confirmPolygonFunction = this.confirmPolygonFunction.bind(this);
        this.handlelAddStationSubmit = this.handlelAddStationSubmit.bind(this);
        this.handleConfirmAddStation = this.handleConfirmAddStation.bind(this);
        this.getFleetLocationMap = this.getFleetLocationMap.bind(this);
        this.changeLocation = this.changeLocation.bind(this);
        //==============================================================================
        this.editStationOpen = this.editStationOpen.bind(this);
        this.handleCancelEditStation = this.handleCancelEditStation.bind(this);
        this._onCreateEdit = this._onCreateEdit.bind(this);
        this.removeEditPolygonFunction = this.removeEditPolygonFunction.bind(this);
        this.confirmEditPolygonFunction = this.confirmEditPolygonFunction.bind(this);
        this.handlelEditStationSubmit = this.handlelEditStationSubmit.bind(this);
        this.handleConfirmEditStation = this.handleConfirmEditStation.bind(this);
        this.getEditFleetMap = this.getEditFleetMap.bind(this);
        //==============================================================================
        this.deleteStation = this.deleteStation.bind(this);
        this.deleteStationconfirm = this.deleteStationconfirm.bind(this);
        this.deleteStationcancel = this.deleteStationcancel.bind(this);
    }
    async selectFleetFilter(e) {
        const fleetFiltervalue = e.target.value;
        const formZoneFilterData = new URLSearchParams();
        formZoneFilterData.append('fleet_filter_param', fleetFiltervalue);
        formZoneFilterData.append('active_filter_param', this.state.activeFilterparam);
        await fetch("http://api.elevate-mobility.com/api/parking_point/elevate_station.php",
            {
                method: 'POST',
                headers: {
                    'Content-Type': "application/x-www-form-urlencoded;charset=UTF-8"
                },
                body: formZoneFilterData.toString()
            }
        )
            .then(res => res.json())
            .then(
                (data) => {
                    this.setState({
                        fleetFilterParam: fleetFiltervalue,
                        stationData: {
                            stationId: data.station_id,
                            stationName: data.station_name,
                            stationFleet: data.station_fleet,
                            stationStatus: data.station_status,
                            stationCreatedDate: data.station_created_date,
                            stationModifyDate: data.station_modify_date,
                        }
                    })
                }
            )
    }
    async handleActiveChange() {
        if (this.state.activeFilterState === false) {
            this.setState({
                activeFilterState: true,
            });
            var activeFilterParam = "Active";
        } else {
            this.setState({
                activeFilterState: false,
            });
            activeFilterParam = "all";
        }
        const formZoneFilterData = new URLSearchParams();
        formZoneFilterData.append('fleet_filter_param', this.state.fleetFilterParam);
        formZoneFilterData.append('active_filter_param', activeFilterParam);
        await fetch("http://api.elevate-mobility.com/api/parking_point/elevate_station.php",
            {
                method: 'POST',
                headers: {
                    'Content-Type': "application/x-www-form-urlencoded;charset=UTF-8"
                },
                body: formZoneFilterData.toString()
            }
        )
            .then(res => res.json())
            .then(
                (data) => {
                    this.setState({
                        activeFilterparam: activeFilterParam,
                        stationData: {
                            stationId: data.station_id,
                            stationName: data.station_name,
                            stationFleet: data.station_fleet,
                            stationStatus: data.station_status,
                            stationCreatedDate: data.station_created_date,
                            stationModifyDate: data.station_modify_date,
                        }
                    })
                }
            )
    }
    //=====================================================================================================
    handleOpenAddStationForm() {
        this.setState({
            confirmUseArea: false,
            confirmAreaLat: [],
            confirmAreaLng: [],
        });
        var polygon = this.removePolygon.current;
        var layers = polygon._layers;
        var layer_ids = Object.keys(layers);
        var layer;
        layer_ids.forEach(id => {
            layer = layers[id]
            polygon.removeLayer(layer);
        })
        this.setState({
            addStation: true,
            stationTable: false,
        })
    }
    handleCancelAddStation() {
        this.setState({
            stationTable: true,
            addStation: false,
            addStationFormConfirm: false,
            addStationFormFooter: true,
        })
    }
    async _onStationCreate(e) {
        const corArray = e.layer._latlngs;
        const areaLatArray = [];
        const areaLngArray = [];
        var arrayLength = corArray[0].length;
        for (var i = 0; i < arrayLength; i++) {
            areaLatArray[i] = corArray[0][i].lat;
            areaLngArray[i] = corArray[0][i].lng;
        }
        await this.setState({
            temporaryAreaLat: areaLatArray,
            temporaryAreaLng: areaLngArray,
            onEditState: false,
            confirmUseArea: true
        });

    }
    removePolygonFunction() {
        this.setState({
            confirmUseArea: false,
            confirmAreaLat: [],
            confirmAreaLng: [],
        });
        var polygon = this.removePolygon.current;
        var layers = polygon._layers;
        var layer_ids = Object.keys(layers);
        var layer;
        layer_ids.forEach(id => {
            layer = layers[id]
            polygon.removeLayer(layer);
        })
    }
    async confirmPolygonFunction() {
        await this.setState({
            confirmAreaLat: this.state.temporaryAreaLat,
            confirmAreaLng: this.state.temporaryAreaLng,
        });
        alert("This area has been added to station.\nClick 'Add Station' to complete")
    }
    async handlelAddStationSubmit(e) {
        e.preventDefault();
        const cookies = new Cookies();
        const data = new FormData(e.target);
        const arrayAreaLat = data.get('area_lat');
        const arrayAreaLng = data.get('area_lng')
        const arrayLat = arrayAreaLat.toString();
        const arrayLng = arrayAreaLng.toString();
        if (arrayLat) {
            const formAddStation = new URLSearchParams();
            formAddStation.append('station_fleet', data.get('stationFleet'));
            formAddStation.append('station_name', data.get('stationName'));
            formAddStation.append('station_description', data.get('stationDescription'));
            formAddStation.append('station_area_lat', arrayLat);
            formAddStation.append('station_area_lng', arrayLng);
            formAddStation.append('admin_name', cookies.get('adminName'));
            await fetch("http://api.elevate-mobility.com/api/parking_point/elevate_add_station.php",
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': "application/x-www-form-urlencoded;charset=UTF-8"
                    },
                    body: formAddStation.toString()
                }
            )
                .then(res => res.json())
                .then(
                    (data) => {
                        if (data.success === 1) {
                            this.setState({
                                add_station_name: data.add_station_name,
                                add_station_id: data.add_station_id,
                                addStationFormConfirm: true,
                                addStationFormFooter: false,
                            })
                        } else {
                            alert("Fail to add new station !");
                        }
                    }
                )
        } else {
            alert("Please draw and confirm the area on map !")
        }
    }
    async handleConfirmAddStation() {
        const formZoneFilterData = new URLSearchParams();
        formZoneFilterData.append('fleet_filter_param', this.state.fleetFilterParam);
        formZoneFilterData.append('active_filter_param', this.state.activeFilterparam);
        await fetch("http://api.elevate-mobility.com/api/parking_point/elevate_station.php",
            {
                method: 'POST',
                headers: {
                    'Content-Type': "application/x-www-form-urlencoded;charset=UTF-8"
                },
                body: formZoneFilterData.toString()
            }
        )
            .then(res => res.json())
            .then(
                (data) => {
                    this.setState({
                        stationTable: true,
                        addStation: false,
                        addStationFormConfirm: false,
                        addStationFormFooter: true,
                        stationData: {
                            stationId: data.station_id,
                            stationName: data.station_name,
                            stationFleet: data.station_fleet,
                            stationStatus: data.station_status,
                            stationCreatedDate: data.station_created_date,
                            stationModifyDate: data.station_modify_date,
                        }
                    })
                }
            )
    }
    async getFleetLocationMap(e) {
        const fleetNameRequest = e.target.value;
        const formFleetRequestData = new URLSearchParams();
        formFleetRequestData.append('fleet_request_param', fleetNameRequest);
        await fetch("http://api.elevate-mobility.com/api/parking_point/elevate_request_fleet_location.php",
            {
                method: 'POST',
                headers: {
                    'Content-Type': "application/x-www-form-urlencoded;charset=UTF-8"
                },
                body: formFleetRequestData.toString()
            })
            .then(res => res.json())
            .then(
                (data) => {
                    this.setState({
                        mapAddLat: data.fleet_name_lat,
                        mapAddLng: data.fleet_name_lng,
                    })
                })
    }
    changeLocation(data) {
        this.setState({
            mapAddLat: data[0],
            mapAddLng: data[1],
        })
    }
    //================================================================================================
    async editStationOpen(e) {
        //Clear all polygon on map before display edit restricted area
        var polygon = this.removeEditPolygon.current;
        var layers = polygon._layers;
        var layer_ids = Object.keys(layers);
        var layer;
        layer_ids.forEach(id => {
            layer = layers[id]
            polygon.removeLayer(layer);
        })
        const zoneId = e.target.value;
        const formEditSpeedZoneData = new URLSearchParams();
        formEditSpeedZoneData.append('zone_id', zoneId);
        await fetch("http://api.elevate-mobility.com/api/parking_point/elevate_get_station.php",
            {
                method: 'POST',
                headers: {
                    'Content-Type': "application/x-www-form-urlencoded;charset=UTF-8"
                },
                body: formEditSpeedZoneData.toString()
            }
        )
            .then(res => res.json())
            .then(
                (data) => {
                    const latArray = data.station_lat_array.split(",");
                    const lngArray = data.station_lng_array.split(",");
                    this.setState({
                        editStation: true,
                        stationTable: false,
                        editStationFormFooter: true,
                        editStationFormConfirm: false,
                        editStationData: {
                            editStationId: data.station_id,
                            editStationName: data.station_name,
                            editStationFleet: data.station_fleet,
                            editStationLatArray: data.station_lat_array,
                            editStationLngArray: data.station_lng_array,
                            editStationDescription: data.station_description,
                        },
                        confirmEditAreaLat: data.station_lat_array,
                        confirmEditAreaLng: data.station_lng_array,
                        mapEditLat: latArray[0],
                        mapEditLng: lngArray[0],
                        onEditAreaDrawState: true,
                        
                    })
                }
            )
    }
    handleCancelEditStation() {
        this.setState({
            stationTable: true,
            editStation: false,
            editStationFormConfirm: false,
            editStationFormFooter: true,
        })
    }
    _onCreateEdit(e) {
        const corArray = e.layer._latlngs;
        const areaLatArray = [];
        const areaLngArray = [];
        var arrayLength = corArray[0].length;
        for (var i = 0; i < arrayLength; i++) {
            areaLatArray[i] = corArray[0][i].lat;
            areaLngArray[i] = corArray[0][i].lng;
        }
        this.setState({
            temporaryEditAreaLat: areaLatArray,
            temporaryEditAreaLng: areaLngArray,
            onEditState: false,
            confirmUseEditArea: true
        })
    }
    removeEditPolygonFunction() {
        this.setState({
            confirmUseEditArea: false,
            confirmEditAreaLat: [],
            confirmEditAreaLng: [],
        });
        //var layerContainer = this.removePolygon.options.edit.featureGroup;
        var polygon = this.removeEditPolygon.current;
        var layers = polygon._layers;
        var layer_ids = Object.keys(layers);
        var layer;
        layer_ids.forEach(id => {
            layer = layers[id]
            polygon.removeLayer(layer);
        })
    }
    async confirmEditPolygonFunction() {
        await this.setState({
            confirmEditAreaLat: this.state.temporaryEditAreaLat,
            confirmEditAreaLng: this.state.temporaryEditAreaLng,
        });
        alert("This area has been added to station information. \nClick 'Save Change' to complete.")
    }
    async handlelEditStationSubmit(e) {
        const cookies = new Cookies();
        e.preventDefault();
        const data = new FormData(e.target);
        const arrayAreaLat = data.get('edit_area_lat');
        const arrayAreaLng = data.get('edit_area_lng');
        const arrayLat = arrayAreaLat.toString();
        const arrayLng = arrayAreaLng.toString();
        if (arrayLat) {
            const formEditStation = new URLSearchParams();
            formEditStation.append('edit_station_id', data.get('edit_area_id'));
            formEditStation.append('edit_station_fleet', data.get('editStationFleet'));
            formEditStation.append('edit_station_name', data.get('editStationName'));
            formEditStation.append('edit_station_description', data.get('editStationDescription'));
            formEditStation.append('edit_station_area_lat', arrayLat);
            formEditStation.append('edit_station_area_lng', arrayLng);
            formEditStation.append('admin_name', cookies.get('adminName'));
            await fetch("http://api.elevate-mobility.com/api/parking_point/elevate_edit_station.php",
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': "application/x-www-form-urlencoded;charset=UTF-8"
                    },
                    body: formEditStation.toString()
                }
            )
                .then(res => res.json())
                .then(
                    async (data) => {
                        if (data.success === 1) {
                            await this.setState({
                                edit_station_name: data.edit_station_name,
                                edit_station_id: data.edit_station_id,
                                editStationFormConfirm: true,
                                editStationFormFooter: false,
                                confirmUseEditArea: false
                            })
                        } else {
                            alert("Fail to update station information !")
                        }
                    }
                )
        } else {
            alert("Please draw and confirm station area on map !")
        }
    }
    async handleConfirmEditStation() {
        const formZoneFilterData = new URLSearchParams();
        formZoneFilterData.append('fleet_filter_param', this.state.fleetFilterParam);
        formZoneFilterData.append('active_filter_param', this.state.activeFilterparam);
        await fetch("http://api.elevate-mobility.com/api/parking_point/elevate_station.php",
            {
                method: 'POST',
                headers: {
                    'Content-Type': "application/x-www-form-urlencoded;charset=UTF-8"
                },
                body: formZoneFilterData.toString()
            }
        )
            .then(res => res.json())
            .then(
                (data) => {
                    this.setState({
                        editStation: false,
                        stationTable: true,
                        editStationFormFooter: true,
                        editStationFormConfirm: false,
                        stationData: {
                            stationId: data.station_id,
                            stationName: data.station_name,
                            stationFleet: data.station_fleet,
                            stationStatus: data.station_status,
                            stationCreatedDate: data.station_created_date,
                            stationModifyDate: data.station_modify_date,
                        }
                    })
                }
            )
    }
    async getEditFleetMap(e) {
        const fleetNameRequest = e.target.value;
        const formFleetRequestData = new URLSearchParams();
        formFleetRequestData.append('fleet_request_param', fleetNameRequest);
        await fetch("http://api.elevate-mobility.com/api/parking_point/elevate_request_fleet_location.php",
            {
                method: 'POST',
                headers: {
                    'Content-Type': "application/x-www-form-urlencoded;charset=UTF-8"
                },
                body: formFleetRequestData.toString()
            })
            .then(res => res.json())
            .then(
                (data) => {
                    this.setState({
                        mapEditLat: data.fleet_name_lat,
                        mapEditLng: data.fleet_name_lng,
                    })
                })
    }
    //==================================================================================================
    deleteStation(e) {
        const deleteStationValue = e.target.value;
        this.setState({
            deleteStationFormConfirm: true,
            deleteStationId: deleteStationValue,
        })
    }
    async deleteStationconfirm() {
        const cookies = new Cookies();
        const formZoneFilterData = new URLSearchParams();
        formZoneFilterData.append('fleet_filter_param', this.state.fleetFilterParam);
        formZoneFilterData.append('active_filter_param', this.state.activeFilterparam);
        formZoneFilterData.append('zone_id', this.state.deleteStationId);
        formZoneFilterData.append('admin_name', cookies.get('adminName'));
        await fetch("http://api.elevate-mobility.com/api/parking_point/elevate_delete_station.php",
            {
                method: 'POST',
                headers: {
                    'Content-Type': "application/x-www-form-urlencoded;charset=UTF-8"
                },
                body: formZoneFilterData.toString()
            }
        )
            .then(res => res.json())
            .then(
                (data) => {
                    if (data.success === 1) {
                        this.setState({
                            deleteStationFormConfirm: false,
                            stationTable: true,
                            stationData: {
                                stationId: data.station_id,
                                stationName: data.station_name,
                                stationFleet: data.station_fleet,
                                stationStatus: data.station_status,
                                stationCreatedDate: data.station_created_date,
                                stationModifyDate: data.station_modify_date,
                            }
                        })
                    } else {
                        this.setState({
                            deleteStationFormConfirm: false,
                            stationTable: true,
                        });
                        alert("The station was not deleted completly !")
                    }
                }
            )
    }
    deleteStationcancel() {
        this.setState({
            deleteStationFormConfirm: false,
            stationTable: true,
            deleteStationId: null,
        })
    }
    async componentDidMount() {
        const formZoneFilterData = new URLSearchParams();
        formZoneFilterData.append('fleet_filter_param', this.state.fleetFilterParam);
        formZoneFilterData.append('active_filter_param', this.state.activeFilterparam);
        await fetch("http://api.elevate-mobility.com/api/parking_point/elevate_station.php",
            {
                method: 'POST',
                headers: {
                    'Content-Type': "application/x-www-form-urlencoded;charset=UTF-8"
                },
                body: formZoneFilterData.toString()
            }
        )
            .then(res => res.json())
            .then(
                (data) => {
                    this.setState({
                        fleetName: data.fleet_name,
                        fleetNameLat: data.fleet_name_lat,
                        fleetNameLng: data.fleet_name_lng,
                        fleetNameAddParkings: data.fleet_name,
                        stationData: {
                            stationId: data.station_id,
                            stationName: data.station_name,
                            stationFleet: data.station_fleet,
                            stationStatus: data.station_status,
                            stationCreatedDate: data.station_created_date,
                            stationModifyDate: data.station_modify_date,
                        },
                        mapAddLat: data.first_fleet_name_lat,
                        mapAddLng: data.first_fleet_name_lng,
                        mapEditLat: data.first_fleet_name_lat,
                        mapEditLng: data.first_fleet_name_lng,
                    })
                }
            )
    }
    render() {
        const fleetLength = this.state.fleetName.length;
        const fleetValueArray = [];
        for (var i = 0; i < fleetLength; i++) {
            fleetValueArray[i] = [this.state.fleetName[i], this.state.fleetNameLat[i], this.state.fleetNameLng[i]];
        }
        const tableData = [
            this.state.stationData.stationId,
            this.state.stationData.stationName,
            this.state.stationData.stationFleet,
            this.state.stationData.stationStatus,
            this.state.stationData.stationCreatedDate,
            this.state.stationData.stationModifyDate,
        ];
        let stationTable = this.state.stationTable ? "show_parkings_table" : "hide_parkings_table";
        let addStation = this.state.addStation ? "add_parkings_show" : "add_parkings_hide";
        let addStationFormConfirm = this.state.addStationFormConfirm ? "confirm_add_parkings_show" : "confirm_add_parkings_hide";
        let addStationFormFooter = this.state.addStationFormFooter ? "add_parkings_footer" : "hide_add_parkings_footer";
        let confirmUseArea = this.state.confirmUseArea ? "show_confirm_use_area" : "hide_confirm_use_area";
        let addMapPosition = [this.state.mapAddLat, this.state.mapAddLng];
        //==================================================================================================================================
        let editStation = this.state.editStation ? "add_parkings_show" : "add_parkings_hide";
        let editStationFormConfirm = this.state.editStationFormConfirm ? "confirm_add_parkings_show" : "confirm_add_parkings_hide";
        let editStationFormFooter = this.state.editStationFormFooter ? "add_parkings_footer" : "hide_add_parkings_footer";
        let confirmUseEditArea = this.state.confirmUseEditArea ? "show_confirm_use_area" : "hide_confirm_use_area";
        let editMapPosition = [this.state.mapEditLat,this.state.mapEditLng]
        //==================================================================================================================================
        let deleteStationFormConfirm = this.state.deleteStationFormConfirm ? "confirm_add_parkings_show" : "confirm_add_parkings_hide";
        return (
            <div className='station_contain'>
                <div className={stationTable}>
                    <table className="station_menu_filter">
                        <tbody>
                            <tr>
                                <td className='station_leftside_menu'>
                                    <div>
                                        <p className="station_title"><Trans>Stations</Trans></p>
                                    </div>
                                </td>
                                <td className='station_rightside_menu'>
                                    <button className="btn_add_station" type="button" onClick={this.handleOpenAddStationForm}><FaIcons.FaPlus /> <Trans>Add Station</Trans></button>
                                    <select className="fleets_select" onChange={this.selectFleetFilter}>
                                        <option value="all_fleet">All Fleets</option>
                                        {
                                            this.state.fleetName.map(
                                                (x) => <option value={x}>{x}</option>
                                            )
                                        }
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <td className='station_leftside_menu'>
                                    <div>

                                    </div>
                                </td>
                                <td className='station_rightside_menu'>
                                    <table className='station_rightside_button'>
                                        <tr>
                                            <td><label style={{ marginRight: "10px" }}><Trans>Active</Trans></label></td>
                                            <td><Switch onChange={this.handleActiveChange} checked={this.state.activeFilterState} /></td>
                                        </tr>
                                    </table>

                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div className='station_table'>
                        <StationTablePage tableData={tableData} editStation={this.editStationOpen} deleteStation={this.deleteStation} />
                    </div>
                </div>
                {/*===================================================================================================================================================*/}
                <div className={addStation}>
                    <div className="addParkingsForm">
                        <div className="add_parkings_form_header"><b><Trans>Add Station</Trans></b></div>
                        <form onSubmit={this.handlelAddStationSubmit}>
                            <div className="parkings-form">
                                <div style={{ display: "flex" }}>
                                    <div className="form-group-left-side">
                                        <div className="form-group">
                                            <label><Trans>Fleet</Trans></label>
                                            <select name="stationFleet" onChange={this.getFleetLocationMap}>
                                                {this.state.fleetName.map((x) =>
                                                    <option value={x}>{x}</option>)}
                                            </select>
                                        </div>
                                        <div className="form-group">
                                            <label><Trans>Station Name</Trans></label>
                                            <input type="text" name="stationName" placeHolder="Enter Station Name" required />
                                        </div>
                                    </div>
                                    <div className="form-group-right-side">
                                        <div className="form-group">
                                            <label><Trans>Description</Trans></label>
                                            <input type="text" name="stationDescription" />
                                        </div>
                                        <div style={{ display: "none" }} className="form-group">
                                            <input type="text" name="area_lat" value={this.state.confirmAreaLat} />
                                        </div>
                                        <div style={{ display: "none" }} className="form-group">
                                            <input type="text" name="area_lng" value={this.state.confirmAreaLng} />
                                        </div>
                                    </div>
                                </div>
                                <div className={addStationFormFooter}>
                                    <button type="button" className="btn_cancel_adding" onClick={this.handleCancelAddStation}>
                                        <Trans>Cancel</Trans>
                                    </button>
                                    <button type="submit" className="btn_submit_adding" >
                                        <Trans>Add Station</Trans>
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className="addParkingsMap">
                        <div className="fleetMap">
                            <AddStationMap refFeatureGroup={this.removePolygon} getAreaData={this._onStationCreate} editState={this.state.onAddAreaDrawState} position={addMapPosition} />
                            <div className={confirmUseArea}>
                                <i style={{ color: "red" }}> * <Trans>Use this area for this station ?</Trans></i> <button className="add_area_btn" onClick={this.removePolygonFunction}><Trans>No</Trans></button><button onClick={this.confirmPolygonFunction} className="add_area_btn"><Trans>Yes</Trans></button>
                            </div>
                        </div>
                    </div>
                    <div className={addStationFormConfirm}>
                        <p className="add_parkings_confirm_title"><Trans>The station</Trans> "{this.state.add_station_name}" (ID: {this.state.add_station_id}) <Trans>has been added recently </Trans> !</p>
                        <button className="add_fleet_confirm_button" type="button" onClick={this.handleConfirmAddStation}>
                            OK
                        </button>
                    </div>
                </div>
                {/*===================================================================================================================================*/}
                <div className={editStation}>
                    <div className="addParkingsForm">
                        <div className="add_parkings_form_header"><b><Trans>Edit Station</Trans></b></div>
                        <form onSubmit={this.handlelEditStationSubmit}>
                            <div className="parkings-form">
                                <div style={{ display: "flex" }}>
                                    <div className="form-group-left-side">
                                        <div className="form-group">
                                            <label><Trans>Fleet</Trans></label>
                                            <select name="editStationFleet" onChange={this.getEditFleetMap}>
                                                {this.state.fleetName.map((x) =>
                                                    <option selected={x === this.state.editStationData.editStationFleet} value={x}>{x}</option>)}
                                            </select>
                                        </div>
                                        <div className="form-group">
                                            <label><Trans>Station Name</Trans></label>
                                            <input type="text" name="editStationName" defaultValue={this.state.editStationData.editStationName} required />
                                        </div>
                                    </div>
                                    <div className="form-group-right-side">
                                        <div className="form-group">
                                            <label><Trans>Description</Trans></label>
                                            <input type="text" name="editStationDescription" defaultValue={this.state.editStationData.editStationDescription} />
                                        </div>
                                        <div style={{ display: "none" }} className="form-group">
                                            <input type="text" name="edit_area_lat" value={this.state.confirmEditAreaLat} />
                                        </div>
                                        <div style={{ display: "none" }} className="form-group">
                                            <input type="text" name="edit_area_lng" value={this.state.confirmEditAreaLng} />
                                        </div>
                                        <div style={{ display: "none" }} className="form-group">
                                            <input type="text" name="edit_area_id" value={this.state.editStationData.editStationId} />
                                        </div>
                                    </div>
                                </div>
                                <div className={editStationFormFooter}>
                                    <button type="button" className="btn_cancel_adding" onClick={this.handleCancelEditStation}>
                                        <Trans>Cancel</Trans>
                                    </button>
                                    <button type="submit" className="btn_submit_adding" >
                                        <Trans>Save Changes</Trans>
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className="addParkingsMap">
                        <div className="fleetMap">
                            <EditStationMap refFeatureGroup={this.removeEditPolygon} getAreaData={this._onCreateEdit} editState={this.state.onEditAreaDrawState} latArray={this.state.editStationData.editStationLatArray} lngArray={this.state.editStationData.editStationLngArray} position={editMapPosition}/>
                            <div className={confirmUseEditArea}>
                                <i style={{ color: "red" }}> * Use this area for station ?</i> <button className="add_area_btn" onClick={this.removeEditPolygonFunction}>No</button><button onClick={this.confirmEditPolygonFunction} className="add_area_btn">Yes</button>
                            </div>
                        </div>
                    </div>
                    <div className={editStationFormConfirm}>
                        <p className="add_parkings_confirm_title"><Trans>The station</Trans> "{this.state.edit_station_name}" ( ID: {this.state.edit_station_id}) <Trans>has been edited recently </Trans> !</p>
                        <button className="add_fleet_confirm_button" type="button" onClick={this.handleConfirmEditStation}>
                            OK
                        </button>
                    </div>
                </div>
                {/*===================================================================================================================================*/}
                <div className={deleteStationFormConfirm}>
                    <p className="add_parkings_confirm_title"><Trans>The station will be deleted !</Trans> !</p>
                    <button style={{ backgroundColor: "gray", borderColor: "gray" }} className="add_fleet_confirm_button" type="button" onClick={this.deleteStationcancel}>
                        Cancle
                    </button>
                    <button style={{ marginLeft: "10px" }} className="add_fleet_confirm_button" type="button" onClick={this.deleteStationconfirm}>
                        OK
                    </button>
                </div>
            </div>
        )
    }
}
export default Station;