import React, { useState } from "react";
import useForm from "./useForm";
import validate from "./Validate";
import { Redirect } from "react-router-dom";
import './login.css';
import logo from './img/tatu_logo.png';
import Cookies from 'universal-cookie';
import Helmet from 'react-helmet'

const Form = props => {
    const { values, errors, handleChange, handleSubmit } = useForm(
        login,
        validate
    );
    const [loggedIn, setLoggedIn] = useState(false);
    const cookies = new Cookies();
    async function login() {
        if ((values.email) && (values.password)) {
            if ((/\S+@\S+\.\S+/.test(values.email)) && (values.password.length >= 8)) {
                const formLoginData = new URLSearchParams();
                formLoginData.append('admin_email', values.email);
                formLoginData.append('admin_password', values.password);
                console.log(values.email);
                console.log(values.password);
                await (await fetch("https://api.connect-ink.com/API/WEB_ADMIN/API/authentication/admin_sign_in_api.php",
                    {
                        method: 'POST',
                        headers: {
                            'Content-Type': "application/x-www-form-urlencoded;charset=UTF-8"
                        },
                        body: formLoginData.toString()
                    }
                )
                    .then(res => res.json())
                    .then(
                        (data) => {
                            if (data.admin_login === 1) {
                                setLoggedIn(true);
                                localStorage.setItem('storageAdminID', data.admin_id);
                                cookies.set('adminID', data.admin_id);
                                cookies.set('adminName', data.admin_name);
                                cookies.set('adminEmail', data.admin_email);
                                cookies.set('loggedIn', true);
                                props.parentCallback(true);
                                return <Redirect to="/" />;
                            }
                            else {
                                errors.email = "Invalid Email or password";
                            }

                        }
                    ));
            }
        }
    }

    return (
        <>
            <Helmet>
                <title>TATU</title>
            </Helmet>
            <div className='elevate_panel'>
                <div className='elevate_logo'>
                    <img src={logo} />
                </div>
            </div>
            <div className="section is-fullheight">
                {loggedIn && <Redirect to="/" />}
                <div className="container">
                    <div className="column is-6 is-offset-3">
                        <div className="box">
                            <form onSubmit={handleSubmit} noValidate>
                                <div className="login_theme">
                                    <table className="login_table">
                                        <tbody>
                                            <tr>
                                                <td colSpan="2" className="title_login"><h2> Login </h2></td>
                                            </tr>
                                            <tr>
                                                <td colSpan="2">
                                                    <input
                                                        type="email"
                                                        placeholder="Email"
                                                        name="email"
                                                        className={`input ${errors.email && "is-danger"}`}
                                                        onChange={handleChange}
                                                        value={values.email || ""}
                                                        required
                                                    />
                                                    {errors.email && (
                                                        <p className="help is-danger">{errors.email}</p>
                                                    )}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colSpan="2">
                                                    <input
                                                        type="password"
                                                        name="password"
                                                        placeholder="Password"
                                                        className={`input ${errors.password && "is-danger"}`}
                                                        onChange={handleChange}
                                                        value={values.password || ""}
                                                        required
                                                    />
                                                    {errors.password && (
                                                        <p className="help is-danger">{errors.password}</p>
                                                    )}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colSpan="2"> <button type="submit" className="button is-block is-info is-fullwidth">Log In</button></td>
                                            </tr>
                                            <tr>
                                                <td colSpan="2" className="style_forgot"> <a href="">Forgot Password </a> </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <table>
                                        <tr>
                                            <td valign="top"> <input type="checkbox" className="style_checkbox"></input> </td>
                                            <td className="style_text"> Remember my login on this computer<br />
                                                Not a member? To request an account, please
                                                contact your <a href="" className="link">Connect-ink Administrator</a>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Form;