import React from 'react';
import { MDBDataTable } from 'mdbreact';
import './Promos.css';
import PromoBatchesTableCSV from './Promos_batches_csv';
import { Trans } from 'react-i18next';

class OffertablePage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    render() {
        
        const rowData = this.props.tableData;
        const rowDataId = rowData[0];
        const rowDataName = rowData[1];
        const rowDataPercentageFee = rowData[2];
        const rowDataFixedFee = rowData[3];
        const rowDataAdditionalPercentageFee = rowData[4];
        const rowDataAdditionalFixedFee = rowData[5];
        const rowDataStatus = rowData[6];
        const numRows = rowDataId.length;
        const name_array = Array.from(new Set(rowDataName));
        const name_array_length = name_array.length;

        console.log(name_array);
        const rowsData = [];

        for (var i = 0; i < name_array_length; i++) {
            const valueindex = {
                fee_id: rowDataId[i],
                fee_name: rowDataName[i],
                percentage_fee: rowDataPercentageFee[i],
                fixed_fee: rowDataFixedFee[i],
                additional_percentage_fee: rowDataAdditionalPercentageFee[i],
                additional_fixed_fee: rowDataAdditionalFixedFee[i],
                status: rowDataStatus[i],
                //action: <><button className="vehicle_action_button" value={rowDataVehicleId[i]}><Trans>Disable</Trans></button><button className="role_action_button" value={rowDataVehicleId[i]} ><Trans>Delete</Trans></button></>,
                
            }
            rowsData.push(valueindex);
        }
        const data = {
            columns: [
                {
                    label: <Trans>Fee ID</Trans>,
                    field: 'fee_id',
                    sort: 'asc',
                    width: 200
                },
                {
                    label: <Trans>Fee Name</Trans>,
                    field: 'fee_name',
                    sort: 'asc',
                    width: 200
                },
                {
                    label: <Trans>Percentage Fee</Trans>,
                    field: 'percentage_fee',
                    sort: 'asc',
                    width: 200
                },
                {
                    label: <Trans>Fixed Fee</Trans>,
                    field: 'fixed_fee',
                    sort: 'asc',
                    width: 200
                },
                {
                    label: <Trans>Additional Percentage Fee</Trans>,
                    field: 'additional_percentage_fee',
                    sort: 'asc',
                    width: 200
                },
                {
                    label: <Trans>Additional Fixed Fee</Trans>,
                    field: 'additional_fixed_fee',
                    sort: 'asc',
                    width: 200
                },
                {
                    label: <Trans>Status</Trans>,
                    field: 'status',
                    sort: 'asc',
                    width: 200
                },
                /*{
                    label: <Trans>Action</Trans>,
                    field: 'action',
                    sort: 'asc',
                    width: 200
                },*/
            ],
            rows: rowsData,
        };
        return (
            <div className='fleets_table' >
                <MDBDataTable
                    hover
                    scrollX
                    maxHeight='50vh'
                    entriesOptions={[5, 10, 20]}
                    entries={5}
                    data={data}
                    disableRetreatAfterSorting={true}
                />
            </div>
        );
    }
}

export default OffertablePage;