import React from 'react';
import { MDBDataTable } from 'mdbreact';
import './Parkings';
import { Trans} from 'react-i18next';

class StationTablePage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    render() {
        const rowData = this.props.tableData;
        const rowDataStationId = rowData[0];
        const rowDataStationName = rowData[1];
        const rowDataStationFleet = rowData[2];
        const rowDataStationStatus = rowData[3];
        const rowDataStationCreatedDate = rowData[4];
        const rowDataStationModifyDate = rowData[5];

        const numRows = rowDataStationId.length;
        const rowsData = [];
        for (var i = 0; i < numRows; i++) {
            const valueindex = {
                station_id: rowDataStationId[i],
                station_name: rowDataStationName[i],
                fleet: rowDataStationFleet[i],
                created_date: rowDataStationCreatedDate[i],
                modify_date: rowDataStationModifyDate[i],
                status: rowDataStationStatus[i],
                action: <><button className="edit_button_geofence" value={rowDataStationId[i]} onClick={this.props.editStation}><Trans>Edit</Trans></button><button className="edit_button_geofence" style={{marginLeft: "10px"}} value={rowDataStationId[i]} onClick={this.props.deleteStation}><Trans>Delete</Trans></button></>,
            }

            rowsData.push(valueindex);
        }
        const data = {
            columns: [
                {
                    label: 'ID',
                    field: 'station_id',
                    sort: 'asc',
                    width: 150
                },
                {
                    label: <Trans>Station Name</Trans>,
                    field: 'station_name',
                    sort: 'asc',
                    width: 250
                },
                {
                    label: <Trans>Fleet</Trans>,
                    field: 'fleet',
                    sort: 'asc',
                    width: 175
                },
                {
                    label: <Trans>Created Date</Trans>,
                    field: 'created_date',
                    sort: 'asc',
                    width: 150
                },
                {
                    label: <Trans>Modify Date</Trans>,
                    field: 'modify_date',
                    sort: 'asc',
                    width: 150
                },
                {
                    label: <Trans>Status</Trans>,
                    field: 'status',
                    sort: 'asc',
                    width: 150
                },
                {
                    label: <Trans>Action</Trans>,
                    field: 'action',
                    sort: 'asc',
                    width: 150
                }

            ],
            rows: rowsData,
        };
        return (
            <div className='parkings_table'>
                <MDBDataTable
                    hover
                    scrollX
                    maxHeight='50vh'
                    entriesOptions={[5, 10, 20]} 
                    entries={5}
                    data={data}
                    disableRetreatAfterSorting={true}
                />
            </div>
        );
    }
}

export default StationTablePage;