import React from 'react';
import { Line } from 'react-chartjs-2';
import { Trans, useTranslation } from 'react-i18next';

const data = {
  labels: ['April', 'May', 'June', 'July', 'Aug', 'Sep', 'Oct'],
  datasets: [
    {
      label: 'Revenue',
      backgroundColor: 'rgba(255,99,132,0.2)',
      borderColor: 'gray',
      borderWidth: 5,
      hoverBackgroundColor: 'rgba(255,99,132,0.4)',
      hoverBorderColor: 'rgba(255,99,132,1)',
      data: [0, 0, 0, 0, 0, 0, 0]

    }
  ],
};
const option = {
  plugins: {
      legend: {
          display: false,         
      }
  }
}

class PaymentsChart extends React.Component {
  render() {
    return (
      <div style={{ width: "100%", color: "black" }}>
        <h4 style={{ textAlign: "left" }}><Trans>Revenue</Trans></h4>
        <Line
           height={100}
          data={data}
          options = {option}
        />
      </div>
    );
  }
}

export default PaymentsChart