import React from 'react';
import { MDBDataTable } from 'mdbreact';
import './Riders.css';
import { Trans, useTranslation } from 'react-i18next';

class RiderstablePage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    render() {
        const rowData = this.props.tableData;
        const rowDatRidesId = rowData[0];
        const rowDataRidesName = rowData[1];
        const rowDataBalance = rowData[2];
        const rowDataEmail = rowData[3];
        const rowDataCountryCode = rowData[4];
        const rowDataStatus = rowData[5];
        const rowDataPhoneNumber = rowData[6];
        const rowDataRegisterDate = rowData[7];
        const rowDataLastLogin = rowData[8];
        const rowDataAppVersion = rowData[9];
        const rowDataPlatform = rowData[10];
        const rowDataOsVersion = rowData[11];
        const rowDataRfid = rowData[12];
        const rowsDataButtonDisplay = [];
        const numRows = rowDatRidesId.length;
        const rowsData = [];

        for (var i = 0; i < numRows; i++) {
            if(rowDataStatus[i] == 'enable'){
                rowsDataButtonDisplay[i] = "Disable";
            }else{
                rowDataStatus[i] = 'disable';
                rowsDataButtonDisplay[i] = "Enable";
            }
        }
        for (var i = 0; i < numRows; i++) {
            const valueindex = {
                user_id: rowDatRidesId[i],
                user_name: rowDataRidesName[i],
                balance: rowDataBalance[i],
                email: rowDataEmail[i],
                country_code: rowDataCountryCode[i],
                status: rowDataStatus[i],
                phone_number: rowDataPhoneNumber[i],
                register_date: rowDataRegisterDate[i],
                last_login: rowDataLastLogin[i],
                app_version: rowDataAppVersion[i],
                platform: rowDataPlatform[i],
                os_version: rowDataOsVersion[i],
                rfid: rowDataRfid[i],
                action: <button className="btn-map" value={[rowDatRidesId[i],rowDataStatus[i]]} onClick={this.props.editClientStatus}>{rowsDataButtonDisplay[i]}</button>,
            }

            rowsData.push(valueindex);
        }
        
        const data = {
            columns: [
                {
                    label: 'ID',
                    field: 'user_id',
                    sort: 'asc',
                    width: 150
                },
                {
                    label: <Trans>User</Trans>,
                    field: 'user_name',
                    sort: 'asc',
                    width: 200
                },
                {
                    label: <Trans>Balance</Trans>,
                    field: 'balance',
                    sort: 'asc',
                    width: 150
                },
                {
                    label: <Trans>Email</Trans>,
                    field: 'email',
                    sort: 'asc',
                    width: 325
                },
                {
                    label: <Trans>Country Code</Trans>,
                    field: 'country_code',
                    sort: 'asc',
                    width: 175
                },
                {
                    label: <Trans>Status</Trans>,
                    field: 'status',
                    sort: 'asc',
                    width: 150
                },
                {
                    label: <Trans>Phone Number</Trans>,
                    field: 'phone_number',
                    sort: 'asc',
                    width: 150
                },
                {
                    label: <Trans>Register Date</Trans>,
                    field: 'register_date',
                    sort: 'asc',
                    width: 150
                },
                {
                    label: <Trans>Last Login</Trans>,
                    field: 'last_login',
                    sort: 'asc',
                    width: 225
                },
                {
                    label: <Trans>Action</Trans>,
                    field: 'action',
                    sort: 'asc',
                    width: 150
                }
            ],
            rows: rowsData,
        };
        // var el = document.querySelector(".sr-only");;
        // //const parent = el.parentElement;
        // //var class_name_parent = parent.className;
        // //parent.style.backgroundColor = ""
        // console.log("pagination_class_name:" + el);
        
        return (
            <div className='riders_table'>
                <MDBDataTable
                    hover
                    scrollX
                    maxHeight='50vh'
                    entriesOptions={[5, 10, 20]} 
                    entries={5}
                    data={data}
                    disableRetreatAfterSorting={true}
                />
            </div>
        );
    }
}

export default RiderstablePage