import { Navigation } from "react-minimal-side-navigation";
import { useHistory} from "react-router-dom";
import React, { useState } from "react";
import * as FaIcons from 'react-icons/fa';
import * as AiIcons from 'react-icons/ai';
import * as IoIcons from 'react-icons/io';
import * as ImIcons from 'react-icons/im';
import * as GiIcons from 'react-icons/gi';
import * as RiIcons from 'react-icons/ri';
import * as MdIcons from "react-icons/md";
import * as VscIcons from "react-icons/vsc";
import './Sidebar.css';
import "react-minimal-side-navigation/lib/ReactMinimalSideNavigation.css";
import {Trans } from 'react-i18next';

export default function NavSidebar() {
    let history = useHistory();
    //const location = useLocation();
    //const history = useHistory();
    return (
        <>
        <Navigation
            //activeItemId={location.pathname}
            onSelect={async ({ itemId }) => { 
                //let history = useHistory();
                history.push(itemId);
            }}
            items={[
                {
                    title: <Trans>Analytics</Trans>,
                    itemId: '/analytics',
                    // use your own custom Icon component as well
                    elemBefore: () => <AiIcons.AiFillHome name="analytics" />,
                },
                // {
                //     title: <Trans>Org</Trans>,
                //     itemId: '/org/org',
                //     elemBefore: () => <RiIcons.RiOrganizationChart name="org" />,
                //     subNav: [
                //         {
                //             title: <Trans>Org</Trans>,
                //             itemId: '/org/org',
                //             elemBefore: () => <GiIcons.GiOrganigram />
                //         },
                //         {
                //             title: <Trans>Role List</Trans>,
                //             itemId: '/org/role_list',
                //             elemBefore: () => <FaIcons.FaUserCircle />
                //         },
                //         {
                //             title: <Trans>Permissions</Trans>,
                //             itemId: '/org/permissions',
                //             elemBefore: () => <IoIcons.IoIosPaper />
                //         },
                //     ],
                // },
                {
                    title: <Trans>Map</Trans>,
                    itemId: '/map',
                    elemBefore: () => <ImIcons.ImLocation />
                },
                {
                    title: <Trans>Artist</Trans>,
                    itemId: '/artist',
                    elemBefore: () => <FaIcons.FaPenAlt name="vehicles" />
                },
                // {
                //     title: <Trans>Artist</Trans>,
                //     itemId: '/vehicles/all',
                //     elemBefore: () => <FaIcons.FaPenAlt name="vehicles" />,
                //     subNav: [
                //         {
                //             title: <Trans>All Artist</Trans>,
                //             itemId: '/vehicles/all',
                //             elemBefore: () => <FaIcons.FaUserAlt />
                //         },
                //     ],
                // },
                /*{
                    title: <Trans>Geofence</Trans>,
                    itemId: '/parkings',
                    elemBefore: () => <FaIcons.FaProductHunt name="geofence" />,
                    subNav: [
                        {
                            title: <Trans>Parking Zones</Trans>,
                            itemId: '/parkings',
                            elemBefore: () => <FaIcons.FaParking />
                        },
                        {
                            title: <Trans>Restricted Zones</Trans>,
                            itemId: '/restrictedAreas',
                            elemBefore: () => <MdIcons.MdDoNotDisturb />
                        },
                        {
                            title: <Trans>Service Area</Trans>,
                            itemId: '/serviceAreas',
                            elemBefore: () => <GiIcons.GiAutoRepair />
                        },
                        {
                            title: <Trans>Slow Speed Zones</Trans>,
                            itemId: '/speedZoneAreas',
                            elemBefore: () => <MdIcons.MdDoNotDisturb />
                        },
                        {
                            title: <Trans>Stations</Trans>,
                            itemId: '/station',
                            elemBefore: () => <FaIcons.FaChargingStation />
                        },
                    ],
                },*/
                {
                    title: <Trans>Client</Trans>,
                    itemId: '/client',
                    elemBefore: () => <IoIcons.IoIosPeople/>
                },
                {
                    title: <Trans>Order</Trans>,
                    itemId: '/order/completed',
                    elemBefore: () => <RiIcons.RiBillFill name="rides" />,
                    subNav: [
                        {
                            title: <Trans>Completed</Trans>,
                            itemId: '/order/completed',
                            elemBefore: () => <GiIcons.GiDutchBike  />
                        },
                        {
                            title: <Trans>On Progess</Trans>,
                            itemId: '/order/running',
                            elemBefore: () => <RiIcons.RiRidingLine />
                        },                      
                    ],
                },
                {
                    title: <Trans>Revenue</Trans>,
                    itemId: '/revenue',
                    elemBefore: () => <RiIcons.RiMoneyDollarCircleFill  />
                },
                {
                    title: <Trans>Pricing</Trans>,
                    itemId: '/pricing/payment_gateway',
                    elemBefore: () => <ImIcons.ImCreditCard name="pricing"/>,
                    subNav: [
                        {
                            title: <Trans>Payment Gateway</Trans>,
                            itemId: '/pricing/payment_gateway',
                            elemBefore: () => <FaIcons.FaHandHoldingUsd />
                        },
                        {
                            title: <Trans>Billing Plan</Trans>,
                            itemId: '/pricing/pricing',
                            elemBefore: () => <RiIcons.RiMoneyDollarCircleLine />
                        },
                        {
                            title: <Trans>Tax</Trans>,
                            itemId: '/pricing/taxes',
                            elemBefore: () => <FaIcons.FaReceipt />
                        },
                        {
                            title: <Trans>Promos</Trans>,
                            itemId: '/pricing/promos',
                            elemBefore: () => <IoIcons.IoIosPricetag />
                        },
                        {
                            title: <Trans>Fees List</Trans>,
                            itemId: '/pricing/offers',
                            elemBefore: () => <FaIcons.FaPercent />
                        },
                    ],
                },
                {
                    title: <Trans>Support</Trans>,
                    itemId: '/support/tickets',
                    elemBefore: () => <FaIcons.FaHeadset name="support" />,
                    subNav: [
                        {
                            title: <Trans>Tickets</Trans>,
                            itemId: '/support/tickets',
                            elemBefore: () => <ImIcons.ImTicket />
                        },
                        {
                            title: <Trans>Messages</Trans>,
                            itemId: '/support/messages',
                            elemBefore: () => <RiIcons.RiFeedbackLine />
                        },
                    ],
                },
                {
                    title: <Trans>Campaign</Trans>,
                    itemId: '/campaign',
                    elemBefore: () => <AiIcons.AiOutlineNotification name="campaign" />,
                    subNav: [
                        {
                            title: <Trans>Notifications</Trans>,
                            itemId: '/campaigns',
                            elemBefore: () => <VscIcons.VscBellDot />
                        },
                    ],
                },
                {
                    title: <Trans>Events</Trans>,
                    itemId: '/events',
                    elemBefore: () => <MdIcons.MdLiveTv />
                },
            ]}
        />
        </>
    );
};

