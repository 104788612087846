import React from 'react';
import { CSVLink } from "react-csv";
import * as FaIcons from 'react-icons/fa';
import './Promos.css';

class PromoBatchesTableCSV extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
        this.csvLinkEl = React.createRef();
    }
    render() {
        const rowPromoCodeData = this.props.tableCSVData;
        const rowPromoNameData = this.props.batchName;
        const rowPromoFleetData = this.props.batchFleet;
        const rowPromoStartDate = this.props.batchStartDate;
        const rowPromoExpiredDate = this.props.batchExpiredDate;
        var currentdate = new Date();
        var fileName = "Promo_Batches_" + currentdate.getDate() + "_" + (currentdate.getMonth() + 1) + "_" + currentdate.getFullYear() + "_" + currentdate.getHours() + "_" + currentdate.getMinutes() + "_" + currentdate.getSeconds() + ".csv";
        const numRows = rowPromoCodeData.length;
        const csvData = [];
        for (var i = 0; i < numRows; i++) {
            const csvDataElement = {
                promo_batch_name: rowPromoNameData,
                promo_batch_fleet: rowPromoFleetData,
                promo_code: rowPromoCodeData[i],
                promo_start_date: rowPromoStartDate,
                promo_expired_date: rowPromoExpiredDate,
            }
            csvData.push(csvDataElement);
        }
        const headers = [
            { label: "Promo Batch Name", key: "promo_batch_name" },
            { label: "Fleet", key: "promo_batch_fleet" },
            { label: "Promo code", key: "promo_code" },
            { label: "Promo Start Date", key: "promo_start_date" },
            { label: "Promo Expired Date", key: "promo_expired_date" },
        ];
        const data_csv = csvData;

        return (
            <div >
                <CSVLink
                    headers={headers}
                    filename={fileName}
                    data={data_csv}
                    ref={this.csvLinkEl}
                >
                    <div style={{ color: "white" }}><FaIcons.FaFileCsv />CSV</div>
                </CSVLink>
            </div>
        );
    }


}

export default PromoBatchesTableCSV;