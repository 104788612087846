import './App.css';
import Helmet from 'react-helmet'
import Sidebar from './components/Sidebar';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import Map from './pages/Map/Map';
import Home from './pages/Home/Home';
import Org from './pages/Org/Org';
import Role_list from './pages/Org/Role_list';
import Permissions from './pages/Org/Permissions';
import Fleets from './pages/Fleets/Fleets';
import Artist from './pages/Artist/Vehicles';
import Reserved from './pages/Artist/Reserved';
import History from './pages/Artist/History';
import VehicleType from './pages/Artist/Vehicle_type';
import Order from './pages/Order/Rides';
import Running from './pages/Order/Running';
import Client from './pages/Client/Riders';
import Payments from './pages/Payments/Payments';
import PaymentGateway from './pages/Pricing/PaymentGateway';
import Pricing from './pages/Pricing/Pricing';
import Taxes from './pages/Pricing/Taxes';
import Promos from './pages/Pricing/Promos';
import Offers from './pages/Pricing/Offers';
import Tickets from './pages/Support/Tickets';
import Messages from './pages/Support/Messages';
import Notifications from './pages/Campaigns/Notifications';
import Banner from './pages/Campaigns/Banner';
import Parkings from './pages/Geofence/Parkings';
import RestrictedAreas from './pages/Geofence/RestrictedAreas';
import ServiceAreas from './pages/Geofence/ServiceAreas';
import SpeedZoneAreas from './pages/Geofence/SpeedZoneAreas';
import Station from './pages/Geofence/Station';
import Events from './pages/Event/Events'
import React from 'react';
import i18n from "./i18n";
import Cookies from 'universal-cookie';
import ReactDOM from "react-dom/client";
const root = ReactDOM.createRoot(document.getElementById("root"));

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hideAvatarCard: true,
      checked: true,
      lng_state: true,
      lng: "en"
    }
    this.handleClick = this.handleClick.bind(this);
    this.avatarClick = this.avatarClick.bind(this);
    this.handleLangueChange = this.handleLangueChange.bind(this);
  }
  handleLangueChange = event => {
    if (this.state.checked === true) {
      var newlang = "en";
    } else {
      newlang = "vi";
    }
    const cookies = new Cookies();
    const formAdminData = new URLSearchParams();
    formAdminData.append('admin_id_param', cookies.get('adminID'));
    formAdminData.append('admin_lng_param', newlang);
    console.log("THIS STATE POST: " + formAdminData);
    fetch("https://api.connect-ink.com/API/WEB_ADMIN/API/admin/update_admin_langues.php",
      {
        method: 'POST',
        headers: {
          'Content-Type': "application/x-www-form-urlencoded;charset=UTF-8"
        },
        body: formAdminData.toString()
      }
    )
      .then(res => res.json())
      .then(
        (data) => {
          i18n.changeLanguage(data.langues_state);
          if (data.langues_state === "vi") {
            this.setState({
              lng: data.langues_state,
              checked: true,
              lng_state: !this.state.lng_state
            })
          }
          else if (data.langues_state === "en") {
            this.setState({
              lng: data.langues_state,
              checked: false,
              lng_state: !this.state.lng_state
            })
          }
          else {
            this.setState({
              lng: data.langues_state,
              checked: false,
              lng_state: !this.state.lng_state
            })
          }
        }
      );
  };
  avatarClick() {
    this.setState({ hideAvatarCard: !this.state.hideAvatarCard });
  }
  handleClick(e) {
    const concernedElement = document.querySelector(".nav-user-button");
    if (concernedElement.contains(e.target)) {
    } else {
      this.setState({ hideAvatarCard: true });
    }
  }
  async componentDidMount() {
    const cookies = new Cookies();
    const formAdminData = new URLSearchParams();
    console.log("THIS POST: " + localStorage.getItem('storageAdminID'));
    formAdminData.append('admin_id_param', localStorage.getItem('storageAdminID'));
    
    await fetch("https://api.connect-ink.com/API/WEB_ADMIN/API/admin/admin_langues.php",
      {
        method: 'POST',
        headers: {
          'Content-Type': "application/x-www-form-urlencoded;charset=UTF-8"
        },
        body: formAdminData.toString()
      }
    )
      .then(res => res.json())
      .then(
        (res) => {
          console.log(res.langues_state);
          i18n.changeLanguage(res.langues_state);

          if (res.langues_state === "vi") {
            this.setState({
              lng: res.langues_state,
              checked: true
            })
          }
          else if (res.langues_state === "en") {
            this.setState({
              lng: res.langues_state,
              checked: false
            })
          }
          else {
            this.setState({
              lng: res.langues_state,
              checked: false
            })
          }
        }
      );

  }
  render() {
    return (
      <div onClick={this.handleClick}>
        <Helmet>
          <title>TATU</title>
        </Helmet>
        <Router outsideClick={this.handleClick}>
          <Sidebar avatarCardState={this.state.hideAvatarCard} avatarClick={this.avatarClick} langValue={this.state.lng} changeLangues={this.handleLangueChange} />
          <Switch>
            <Route exact path='/'><Home /></Route>
            <Route exact path='/analytics'><Home /></Route>
            <Route path='/org/org' component={Org} />
            <Route path='/org/role_list' component={Role_list} />
            <Route path='/org/permissions' component={Permissions} />
            <Route path='/map' component={Map} />
            <Route path='/fleets' component={Fleets} />
            <Route path='/artist' component={Artist} />
            <Route path='/vehicles/reserved' component={Reserved} />
            <Route path='/vehicles/vehicle_history' component={History} />
            <Route path='/parkings' component={Parkings} />
            <Route path='/restrictedAreas' component={RestrictedAreas} />
            <Route path='/serviceAreas' component={ServiceAreas} />
            <Route path='/speedZoneAreas' component={SpeedZoneAreas} />
            <Route path='/station' component={Station} />
            <Route path='/client' component={Client} />
            <Route path='/order/completed' component={Order} />
            <Route path='/order/running' component={Running} />
            <Route path='/revenue' component={Payments} />
            <Route path='/pricing/payment_gateway' component={PaymentGateway} />
            <Route path='/pricing/pricing' component={Pricing} />
            <Route path='/pricing/taxes' component={Taxes} />
            <Route path='/pricing/promos' component={Promos} />
            <Route path='/pricing/offers' component={Offers} />
            <Route path='/support/tickets' component={Tickets} />
            <Route path='/support/messages' component={Messages} />
            <Route path='/campaigns' component={Notifications} />
            <Route path='/banner' component={Banner} />
            <Route path='/events' component={Events} />
          </Switch>
        </Router>
      </div>
    );
  }
}

export default Dashboard;
