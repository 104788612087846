import React from 'react';
import './Vehicles.css'
import { CSVLink } from "react-csv";
import * as FaIcons from 'react-icons/fa';

class HistoryTableCSV extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
        this.csvLinkEl = React.createRef();
    }
    render() {
        var currentdate = new Date();  
        var fileName = "Vehicle_History_" + currentdate.getDate() + "_" + (currentdate.getMonth()+1) + "_" + currentdate.getFullYear() + "_" + currentdate.getHours() + "_" + currentdate.getMinutes() + "_" + currentdate.getSeconds() +".csv";
        const rowData = this.props.tableCSVData;
        const rowHistoryAdminId = rowData[0];
        const rowHistoryAdminName = rowData[1];
        const rowHistoryVehicleId = rowData[2];
        const rowHistoryVehicleName = rowData[3];
        const rowHistoryVehicleType = rowData[4];
        const rowHistoryVehicleQrCode = rowData[5];
        const rowHistoryVehicleEmei = rowData[6];
        const rowHistoryAction = rowData[7];
        const rowHistoryVehicleLastConnected = rowData[8];
        const rowHistoryVehicleLastLockStatusUpdate = rowData[9];
        const rowHistoryVehicleLastRideTime = rowData[10];
        const rowHistoryDate = rowData[11];
        const rowHistoryVehicleLat = rowData[12];
        const rowHistoryVehicleLng = rowData[13];
        const numRows = rowHistoryAdminId.length;
        const csvData = [];
        for (var i = 0; i < numRows; i++) {
            const csvDataElement = {
                admin_id: rowHistoryAdminId[i],
                admin_name: rowHistoryAdminName[i],
                vehicle_id: rowHistoryVehicleId[i],
                vehicle_name: rowHistoryVehicleName[i],
                vehicle_type: rowHistoryVehicleType[i],
                vehicle_qr_code: rowHistoryVehicleQrCode[i],
                vehicle_emei: rowHistoryVehicleEmei[i],
                action: rowHistoryAction[i],
                date: rowHistoryVehicleLastConnected[i],
                last_loct_status_update: rowHistoryVehicleLastLockStatusUpdate[i],
                last_ride_time: rowHistoryVehicleLastRideTime[i],
                last_connected: rowHistoryDate[i],
                vehicle_lat: rowHistoryVehicleLat[i],
                vahicle_lng: rowHistoryVehicleLng[i],
            }
            csvData.push(csvDataElement);
        }
        const headers = [
            { label: "Admin ID", key: "admin_id" },
            { label: "Admin Name", key: "admin_name" },
            { label: "Vehicle ID", key: "vehicle_id" },
            { label: "Vehicle Name", key: "vehicle_name" },
            { label: "Type", key: "vehicle_type" },
            { label: "QR Code", key: "vehicle_qr_code" },
            { label: "EMEI", key: "vehicle_emei" },
            { label: "Action", key: "action" },
            { label: "Date", key: "date" },
            { label: "Last Lock Status Update", key: "last_loct_status_update" },
            { label: "Last Ride time", key: "last_ride_time" },
            { label: "Last Connected", key: "last_connected" },
            { label: "Vehicle latitude", key: "vehicle_lat" },
            { label: "Vehicle longitude", key: "vahicle_lng" },
        ];
        const data_csv = csvData;

        return (
            <div >
                <CSVLink
                    headers={headers}
                    filename={fileName}
                    data={data_csv}
                    ref={this.csvLinkEl}
                >
                    <div style={{color:"white"}}><FaIcons.FaFileCsv />CSV</div>
                </CSVLink>
            </div>
        );
    }


}

export default HistoryTableCSV;