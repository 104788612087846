import React from 'react';
import { MDBDataTable } from 'mdbreact';
import './Vehicles.css'
import * as ImIcons from 'react-icons/im';
import { Trans, useTranslation } from 'react-i18next';

class ReservedtablePage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    render() {
        const rowData = this.props.tableData;
        const rowDataId = rowData[0];
        const rowDataUserName = rowData[1];
        const rowDataUserId = rowData[2];
        const rowDataVehicleName = rowData[3];
        const rowDataVehicleId = rowData[4];
        const rowDataQrCode = rowData[5];
        const rowDataReservationStart = rowData[6];
        const rowDataReservationEnd = rowData[7];
        const rowDataBattery = rowData[8];
        const rowDataStatus = rowData[9];
        const rowDataLastReservationRelease = rowData[10];
        const numRows = rowDataId.length;
        const rowsData = [];
        for (var i = 0; i < numRows; i++) {
            const valueindex = {
                reservation_id: rowDataId[i],
                user_name: rowDataUserName[i],
                vehicle_id: rowDataVehicleId[i],
                vehicle_name: rowDataVehicleName[i],
                qr_code: rowDataQrCode[i],
                reservation_start_date: rowDataReservationStart[i],
                reservation_end_date: rowDataReservationEnd[i],
                battery: rowDataBattery[i],
                status: rowDataStatus[i],
                release: rowDataLastReservationRelease[i],
            }

            rowsData.push(valueindex);
        }
        const data = {
            columns: [
                {
                    label: 'ID',
                    field: 'reservation_id',
                    sort: 'asc',
                    width: 75,
                },
                {
                    label: <Trans>User</Trans>,
                    field: 'user_name',
                    sort: 'asc',
                    width: 250,
                },
                {
                    label: <Trans>Vehicle ID</Trans>,
                    field: 'vehicle_id',
                    sort: 'asc',
                    width: 175,
                },
                {
                    label: <Trans>Vehicle Name</Trans>,
                    field: 'vehicle_name',
                    sort: 'asc',
                    width: 175 ,
                },
                {
                    label: <Trans>QR Code</Trans>,
                    field: 'qr_code',
                    sort: 'asc',
                    width: 175,
                },
                {
                    label: <Trans>Reservation Start Date</Trans>,
                    field: 'reservation_start_date',
                    sort: 'asc',
                    width: 275,
                },
                {
                    label: <Trans>Reservation End Date</Trans>,
                    field: 'reservation_end_date',
                    sort: 'asc',
                    width: 275,
                },
                {
                    label: <Trans>Battery (%)</Trans>,
                    field: 'battery',
                    sort: 'asc',
                    width: 125,
                },
                {
                    label: <Trans>Enable Flags</Trans>,
                    field: 'status',
                    sort: 'asc',
                    width: 175,
                },
                {
                    label: <Trans>Release</Trans>,
                    field: 'release',
                    sort: 'asc',
                    width: 125,
                },                
            ],
            rows: rowsData,
        };
        return (
            <div className='fleets_table'>
                <MDBDataTable
                    hover
                    scrollX
                    maxHeight='50vh'
                    entriesOptions={[5, 10, 20]}
                    entries={5}
                    data={data}
                    disableRetreatAfterSorting={true}
                />
            </div>
        );
    }


}

export default ReservedtablePage;