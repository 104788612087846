import React from 'react';
import './Vehicle_type.css';
import * as FaIcons from 'react-icons/fa';
import VehiclestablePage from './Vehicle_type_table';
import Switch from "react-switch";
import Cookies from 'universal-cookie';
import { Trans, useTranslation } from 'react-i18next';

class VehicleType extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            confirm_edit_vehicle: false,
            vehicleTableContain: true,
        }
        this.handleFlagChange = this.handleFlagChange.bind(this);

    }
    render() {
        const cookies = new Cookies();
        let adminName = cookies.get('adminName');
        let adminEmail = cookies.get('adminEmail');
        let vehicleTable = this.state.vehicleTableContain ? "vehicles_table_contain" : "hide_vehicle_table_contain";
        console.log(vehicleTable);
        /*
        let confirm_edit_vehicle = this.state.confirm_edit_vehicle ? "show_confirm_edit_vehicle" : "hide_confirm_edit_vehicle";
        let edit_vehicle_form_footer = this.state.edit_vehicle_form_footer ? "show_edit_vehicle_form_footer" : "hide_edit_vehicle_form_footer";
        let confirm_add_vehicle = this.state.confirm_add_vehicle ? "show_confirm_add_vehicle" : "hide_confirm_add_vehicle";
        let vehicleTable = this.state.vehicleTableContain ? "vehicles_table_contain" : "hide_vehicle_table_contain";
        let add_vehicle = this.state.addVehicle ? "show_add_vehicle_form" : "hide_add_vehicle_form";
        let add_vehicle_form_footer = this.state.add_vehicle_form_footer ? "show_add_vehicle_form_footer" : "hide_add_vehicle_form_footer";
        let edit_vehicle = this.state.edit_vehicle_form ? "show_edit_vehicle_form" : "hide_edit_vehicle_form";
        const vehicleTypeTableData = [
            this.state.type_id,
            this.state.type_name,
            this.state.register_date,
            this.state.modify_date,
            this.state.status,
        ];
        */
        return (
            <>
                <div className="vehicles_type_contain">
                    <div>
                        <p className="vehicles_type_title"><Trans>Vehicle Type</Trans></p>
                        <div className={vehicleTable}>
                            <table className="vehicles_menu_filter">
                                <tbody>
                                    <tr>
                                        <td className='vehicles_leftside_menu'>
                                            <div>

                                            </div>
                                        </td>
                                        <td className='vehicles_rightside_menu'>
                                            <button className="btn_add_vehicle" type="button" onClick={this.handleAddVehicle}><FaIcons.FaPlus /> <Trans>Add Vehicle Type</Trans></button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            {/*
                            <div className='vehicles_table_div'>
                                <VehiclestablePage tableData={vehicleTypeTableData} openEditVehicle={this.editVehicle} />
                            </div>
        */}
                        </div>
                    </div>
                    {/* 
                    <div className={edit_vehicle}>
                        <div className="edit_vehicle_form_header"><b><Trans>Edit Vehicle</Trans></b></div>
                        <form onSubmit={this.handlelEditVehicleSubmit}>
                            <div style={{ display: "flex" }}>
                                <div className="form-group-left-side">
                                    <div className="form-group">
                                        <label><Trans>Vehicle Type</Trans></label>
                                        <select name="edit_vehicle_type" onChange={this.handleVehicleType} defaultValue={this.state.edit_vehicle_type}>
                                            <option value="Bicycle">Bicycle</option>
                                            <option value="Scooter">Scooter</option>
                                        </select>
                                    </div>
                                    <div className="form-group">
                                        <label><Trans>IMEI</Trans><span style={{ color: "#ff0000" }}>*</span></label>
                                        <input type="text" name="edit_vehicle_emei" defaultValue={this.state.edit_vehicle_emei} required />
                                    </div>
                                    <div className="form-group">
                                        <label><Trans>Sim ICCID Number</Trans></label>
                                        <input type="text" name="edit_vehicle_sim_iccid_number" defaultValue={this.state.edit_vehicle_sim} />
                                    </div>
                                    <div className="form-group">
                                        <label><Trans>Vehicle SN</Trans></label>
                                        <input type="text" name="edit_add_vehicle_sn" defaultValue={this.state.edit_vehicle_sn} />
                                    </div>
                                </div>
                                <div className="form-group-center-side">
                                    <div className="form-group">
                                        <label><Trans>Vehicle Name</Trans><span style={{ color: "#ff0000" }}>*</span></label>
                                        <input type="text" name="edit_vehicle_name" defaultValue={this.state.edit_vehicle_name} />
                                    </div>
                                    <div className="form-group">
                                        <label><Trans>Vehicle RFID</Trans></label>
                                        <input type="text" name="edit_vehicle_rfid" defaultValue={this.state.edit_vehicle_rfid} />
                                    </div>
                                    <div className="form-group">
                                        <label><Trans>Mac Address</Trans></label>
                                        <input type="text" name="edit_vehicle_mac_address" defaultValue={this.state.edit_vehicle_address} />
                                    </div>
                                    <div className="form-group">
                                        <label><Trans>Vehicle Flag</Trans></label>
                                        <select name="edit_vehicle_flag" defaultValue={this.state.edit_vehicle_connection} >
                                            <option value='Service On'>Service On</option>
                                            <option value='Service Off'>Service Off</option>
                                            <option value='Connected'>Connected</option>
                                            <option value='Not Connected'>Not Connected</option>
                                            <option value='Lock Speed ON'>Lock Speed ON</option>
                                            <option value='Missing'>Missing</option>
                                            <option value='Maintainance'>Maintainance</option>
                                            <option value='IoT Fault'>IoT Fault</option>
                                            <option value='Battery Critical'>Battery Critical</option>
                                            <option value='Unavailable'>Unavailable</option>
                                            <option value='Stolen'>Stolen</option>
                                            <option value='Rebalance Pickup'>Rebalance Pickup</option>
                                            <option value='Agency Pickup'>Agency Pickup</option>
                                            <option value='Rented'>Rented</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="form-group-right-side">
                                    <div className="form-group">
                                        <label><Trans>Fleet</Trans></label>
                                        <select name="edit_vehicle_fleet" defaultValue={this.state.edit_vehicle_fleet} >
                                            {this.state.fleet_filter.map((x) =>
                                                <option value={x}>{x}</option>)}
                                        </select>
                                    </div>
                                    <div className="form-group">
                                        <label><Trans>QR Code</Trans> <span style={{ color: "#ff0000" }}>*</span></label>
                                        <input type="text" name="edit_vehicle_qr_code" defaultValue={this.state.edit_vehicle_qrcode} />
                                    </div>
                                    <div className="form-group">
                                        <label><Trans>Device Notes</Trans></label>
                                        <input type="text" name="edit_vehicle_device_note" defaultValue={this.state.edit_vehicle_device_note} />
                                    </div>
                                    <div className="form-group">
                                        <input type="text" name="edit_vehicle_device_id" defaultValue={this.state.edit_vehicle_id} style={{ display: "none" }} />
                                    </div>
                                    <div className="form-group">
                                        <input type="text" name="edit_vehicle_admin_name" value={adminName} style={{ display: "none" }} />
                                    </div>
                                    <div className="form-group">
                                        <input type="text" name="edit_vehicle_admin_email" value={adminEmail} style={{ display: "none" }} />
                                    </div>
                                </div>
                            </div>
                            <div className={edit_vehicle_form_footer}>
                                <button type="button" className="btn_cancel_adding" onClick={this.handleCancelEditVehicle}>
                                    <Trans>Cancel</Trans>
                                </button>
                                <button type="submit" className="btn_add_location">
                                    <Trans>Save Changes</Trans>
                                </button>
                            </div>
                        </form>
                        <div className={confirm_edit_vehicle}>
                            <p className="edit_vehicle_confirm_title"><Trans>The vehicle</Trans> {this.state.confirm_edit_vehicle_id} <Trans>has been updated recently</Trans> !</p>
                            <button className="edit_vehicle_confirm_button" type="button" onClick={this.handleConfirmEditVehicle}>
                                OK
                            </button>
                        </div>
                    </div>
                    */}
                </div>
                {/*
                <div className={add_vehicle}>
                    <div className="add_vehicle_form_header"><b><Trans>Add Vehicle</Trans></b></div>
                    <form onSubmit={this.handlelAddVehicleSubmit}>
                        <div style={{ display: "flex" }}>
                            <div className="form-group-left-side">
                                <div className="form-group">
                                    <label><Trans>Vehicle Type</Trans></label>
                                    <select name="add_vehicle_type" onChange={this.handleVehicleType}>
                                        <option selected value="Bicycle">Bicycle</option>
                                        <option value="Scooter">Scooter</option>
                                    </select>
                                </div>
                                <div className="form-group">
                                    <label><Trans>IMEI</Trans> <span style={{ color: "#ff0000" }}>*</span></label>
                                    <input type="text" name="add_vehicle_emei" placeholder="Enter IMEI" required />
                                </div>
                                <div className="form-group">
                                    <label><Trans>Sim ICCID Number</Trans></label>
                                    <input type="text" name="add_vehicle_sim_iccid_number" placeholder="Enter sim iccid number" />
                                </div>
                                <div className="form-group">
                                    <label><Trans>Vehicle SN</Trans></label>
                                    <input type="text" name="add_vehicle_sn" placeholder="Enter Vehicle SN" />
                                </div>
                            </div>
                            <div className="form-group-center-side">
                                <div className="form-group">
                                    <label><Trans>Vehicle Name</Trans> <span style={{ color: "#ff0000" }}>*</span></label>
                                    <input type="text" name="vehicle_name" placeholder="Enter Vehicle Name" />
                                </div>
                                <div className="form-group">
                                    <label><Trans>Vehicle RFID</Trans></label>
                                    <input type="text" name="vehicle_rfid" placeholder="Enter Vehicle RFID" />
                                </div>
                                <div className="form-group">
                                    <label><Trans>Mac Address</Trans></label>
                                    <input type="text" name="vehicle_mac_address" placeholder="Enter Mac Address" />
                                </div>
                            </div>
                            <div className="form-group-right-side">
                                <div className="form-group">
                                    <label><Trans>Fleet</Trans></label>
                                    <select name="vehicle_fleet" ref={this.refCountry} onChange={this.handleSelectFleet}>
                                        {this.state.fleet_filter.map((x) =>
                                            <option value={x}>{x}</option>)}
                                    </select>
                                </div>
                                <div className="form-group">
                                    <label><Trans>QR Code</Trans> <span style={{ color: "#ff0000" }}>*</span></label>
                                    <input type="text" name="vehicle_qr_code" placeholder="Enter QR Code" />
                                </div>
                                <div className="form-group">
                                    <label><Trans>Device Notes</Trans></label>
                                    <input type="text" name="vehicle_device_note" placeholder="Enter Device Notes" />
                                </div>
                            </div>
                        </div>
                        <div className={add_vehicle_form_footer}>
                            <button type="button" className="btn_cancel_adding" onClick={this.handleCancelAddVehicle}>
                            <Trans>Cancel</Trans>
                            </button>
                            <button type="submit" className="btn_add_location">
                            <Trans>Add Vehicle</Trans>
                            </button>
                        </div>
                    </form>
                </div>
                                        */}
                                        {/*
                <div className={confirm_add_vehicle}>
                    <p className="add_vehicle_confirm_title"><Trans>The vehicle</Trans> {this.state.confirm_add_vehicle_name} <Trans>has been added recently</Trans> !</p>
                    <button className="add_vehicle_confirm_button" type="button" onClick={this.handleConfirmAddVehicle}>
                        OK
                    </button>
                </div>
                                    */}
            </>
        )
    }
    handleAddVehicle() {
        this.setState({
            addVehicle: true,
            add_vehicle_form_footer: true,
            vehicleTableContain: false,
        })
    }
    handleCancelAddVehicle() {
        this.setState({
            addVehicle: false,
            add_vehicle_form_footer: true,
            vehicleTableContain: true,
        })
    }
    handleSelectFleet(e) {
        this.setState({
            addVehicleSelectFleet: e.target.value,
        })
    }
    handleVehicleType(e) {
        this.setState({
            addVehicleType: e.target.value,
        })
    }
    handlelAddVehicleSubmit(e) {
        const cookies = new Cookies();
        e.preventDefault();
        const data = new FormData(e.target);
        const formDataAddVehicle = new URLSearchParams();
        formDataAddVehicle.append('add_vehicle_type', data.get('add_vehicle_type'));
        formDataAddVehicle.append('add_vehicle_emei', data.get('add_vehicle_emei'));
        formDataAddVehicle.append('add_vehicle_sim_iccid_number', data.get('add_vehicle_sim_iccid_number'));
        formDataAddVehicle.append('add_vehicle_sn', data.get('add_vehicle_sn'));
        formDataAddVehicle.append('vehicle_name', data.get('vehicle_name'));
        formDataAddVehicle.append('vehicle_rfid', data.get('vehicle_rfid'));
        formDataAddVehicle.append('vehicle_mac_address', data.get('vehicle_mac_address'));
        formDataAddVehicle.append('vehicle_fleet', this.state.addVehicleSelectFleet);
        formDataAddVehicle.append('vehicle_qr_code', data.get('vehicle_qr_code'));
        formDataAddVehicle.append('vehicle_device_note', data.get('vehicle_device_note'));
        formDataAddVehicle.append('admin_name', cookies.get('adminName'));
        fetch("http://api.elevate-mobility.com/api/vehicle/add_vehicle.php",
            {
                method: 'POST',
                headers: {
                    'Content-Type': "application/x-www-form-urlencoded;charset=UTF-8"
                },
                body: formDataAddVehicle.toString()
            }
        )
            .then(res => res.json())
            .then(
                (data) => {
                    this.setState({
                        add_vehicle_form_footer: false,
                        confirm_add_vehicle: true,
                        confirm_add_vehicle_name: data.vehicle_name,
                    });
                }
            );
    }
    async handleConfirmAddVehicle() {
        const formData = new URLSearchParams();
        formData.append('location_param', this.state.location_param);
        formData.append('operating_status_param', this.state.operating_status_param);
        await fetch("http://api.elevate-mobility.com/api/vehicle/vehicle_fleet_filter.php")
            .then(res => res.json())
            .then(
                (data) => {
                    this.setState({
                        add_vehicle_form_footer: true,
                        confirm_add_vehicle: false,
                        vehicleTableContain: true,
                        addVehicle: false,
                        vehicle_id: data.vehicle_id,
                        vehicle_name: data.vehicle_name,
                        battery: data.battery,
                        type: data.vehicle_type,
                        emei: data.emei,
                        qr_code: data.qr_code,
                        vehicle_sn: data.vehicle_sn,
                        odometer: data.odometer,
                        enable_flag: data.status,
                        register_date: data.register_date,
                        last_connected: data.last_connected,
                        last_lock_status_update: data.last_lock_status_update,
                        last_ride_time: data.last_ride_time,
                        vehicle_lat: data.lat,
                        vehicle_lng: data.lng,
                        vehicle_fleet: data.vehicle_fleet,
                        vehicle_rfid: data.vehicle_rfid,
                        vehicle_sim: data.vehicle_sim,
                        vehicle_mac_address: data.vehicle_mac_address,
                        vehicle_device_note: data.vehicle_device_note,
                        vehicle_device_connection: data.vehicle_connection,
                    });
                }
            );
    }
    editVehicle(e) {
        const editVehicle = e.target.value;
        const editVehicleInfo = editVehicle.split(",");
        const editVehicleId = editVehicleInfo[0];
        const editVehicleType = editVehicleInfo[1];
        const editVehicleEmei = editVehicleInfo[2];
        const editVehicleSim = editVehicleInfo[3];
        const editVehicleSn = editVehicleInfo[4];
        const editVehicleName = editVehicleInfo[5];
        const editVehicleRfid = editVehicleInfo[6];
        const editVehiclemacAddress = editVehicleInfo[7];
        const editVehicleConnection = editVehicleInfo[12];
        const editVehicleFleet = editVehicleInfo[9];
        const editVehicleQrCode = editVehicleInfo[10];
        const editVehicleDeviceNote = editVehicleInfo[11];
        this.setState({
            edit_vehicle_form: true,
            edit_vehicle_form_footer: true,
            edit_vehicle_id: editVehicleId,
            edit_vehicle_type: editVehicleType,
            edit_vehicle_emei: editVehicleEmei,
            edit_vehicle_sim: editVehicleSim,
            edit_vehicle_sn: editVehicleSn,
            edit_vehicle_name: editVehicleName,
            edit_vehicle_rfid: editVehicleRfid,
            edit_vehicle_address: editVehiclemacAddress,
            edit_vehicle_connection: editVehicleConnection,
            edit_vehicle_fleet: editVehicleFleet,
            edit_vehicle_qrcode: editVehicleQrCode,
            edit_vehicle_device_note: editVehicleDeviceNote,
        })
    }
    handleCancelEditVehicle() {
        this.setState({
            edit_vehicle_form: false,
            edit_vehicle_form_footer: false,
        })
    }

    async handlelEditVehicleSubmit(e) {
        e.preventDefault();
        const cookies = new Cookies();
        const data = new FormData(e.target);
        const formDataEditVehicle = new URLSearchParams();
        formDataEditVehicle.append('edit_vehicle_type', data.get('edit_vehicle_type'));
        formDataEditVehicle.append('edit_vehicle_emei', data.get('edit_vehicle_emei'));
        formDataEditVehicle.append('edit_vehicle_sim_iccid_number', data.get('edit_vehicle_sim_iccid_number'));
        formDataEditVehicle.append('edit_add_vehicle_sn', data.get('edit_add_vehicle_sn'));
        formDataEditVehicle.append('edit_vehicle_name', data.get('edit_vehicle_name'));
        formDataEditVehicle.append('edit_vehicle_rfid', data.get('edit_vehicle_rfid'));
        formDataEditVehicle.append('edit_vehicle_mac_address', data.get('edit_vehicle_mac_address'));
        formDataEditVehicle.append('edit_vehicle_flag', data.get('edit_vehicle_flag'));
        formDataEditVehicle.append('edit_vehicle_fleet', data.get('edit_vehicle_fleet'));
        formDataEditVehicle.append('edit_vehicle_qr_code', data.get('edit_vehicle_qr_code'));
        formDataEditVehicle.append('edit_vehicle_device_note', data.get('edit_vehicle_device_note'));
        formDataEditVehicle.append('edit_vehicle_device_id', data.get('edit_vehicle_device_id'));
        formDataEditVehicle.append('edit_vehicle_admin_name', data.get('edit_vehicle_admin_name'));
        formDataEditVehicle.append('edit_vehicle_admin_email', data.get('edit_vehicle_admin_email'));
        formDataEditVehicle.append('admin_name', cookies.get('adminName'));
        await fetch("http://api.elevate-mobility.com/api/vehicle/edit_vehicle.php",
            {
                method: 'POST',
                headers: {
                    'Content-Type': "application/x-www-form-urlencoded;charset=UTF-8"
                },
                body: formDataEditVehicle.toString()
            }
        )
            .then(res => res.json())
            .then(
                (data) => {
                    this.setState({
                        edit_vehicle_form: true,
                        edit_vehicle_form_footer: false,
                        confirm_edit_vehicle: true,
                        confirm_edit_vehicle_id: data.edit_vehicle_id,
                    });
                    console.log("Edit vehicle state: " + data.updated)
                }
            );
    }
    async handleConfirmEditVehicle() {
        const formData = new URLSearchParams();
        formData.append('location_param', this.state.location_param);
        formData.append('operating_status_param', this.state.operating_status_param);
        await fetch("http://api.elevate-mobility.com/api/vehicle/vehicle_fleet_filter.php",
            {
                method: 'POST',
                headers: {
                    'Content-Type': "application/x-www-form-urlencoded;charset=UTF-8"
                },
                body: formData.toString()
            }
        )
            .then(res => res.json())
            .then(
                (data) => {
                    this.setState({
                        isLoaded: true,
                        info: data,
                        addVehicle: false,
                        add_vehicle_form_footer: false,
                        vehicleTableContain: true,
                        edit_vehicle_form: false,
                        edit_vehicle_form_footer: false,
                        confirm_edit_vehicle: false,
                        fleet_filter: data.fleet_name,
                        vehicle_id: data.vehicle_id,
                        vehicle_name: data.vehicle_name,
                        battery: data.battery,
                        type: data.vehicle_type,
                        emei: data.emei,
                        qr_code: data.qr_code,
                        vehicle_sn: data.vehicle_sn,
                        odometer: data.odometer,
                        enable_flag: data.status,
                        register_date: data.register_date,
                        last_connected: data.last_connected,
                        last_lock_status_update: data.last_lock_status_update,
                        last_ride_time: data.last_ride_time,
                        vehicle_lat: data.lat,
                        vehicle_lng: data.lng,
                        vehicle_fleet: data.vehicle_fleet,
                        vehicle_rfid: data.vehicle_rfid,
                        vehicle_sim: data.vehicle_sim,
                        vehicle_mac_address: data.vehicle_mac_address,
                        vehicle_device_note: data.vehicle_device_note,
                        vehicle_device_connection: data.vehicle_connection,
                    });
                }
            );
    }

    async componentDidMount() {
        const formData = new URLSearchParams();
        formData.append('location_param', this.state.location_param);
        formData.append('operating_status_param', this.state.operating_status_param);
        fetch("http://api.elevate-mobility.com/api/vehicle/vehicle_fleet_filter.php")
            .then(res => res.json())
            .then(
                (data) => {
                    this.setState({
                        isLoaded: true,
                        info: data,
                        addVehicle: false,
                        add_vehicle_form_footer: true,
                        vehicleTableContain: true,
                        fleet_filter: data.fleet_name,
                        vehicle_id: data.vehicle_id,
                        vehicle_name: data.vehicle_name,
                        battery: data.battery,
                        type: data.vehicle_type,
                        emei: data.emei,
                        qr_code: data.qr_code,
                        vehicle_sn: data.vehicle_sn,
                        odometer: data.odometer,
                        enable_flag: data.status,
                        register_date: data.register_date,
                        last_connected: data.last_connected,
                        last_lock_status_update: data.last_lock_status_update,
                        last_ride_time: data.last_ride_time,
                        vehicle_lat: data.lat,
                        vehicle_lng: data.lng,
                        vehicle_fleet: data.vehicle_fleet,
                        vehicle_rfid: data.vehicle_rfid,
                        vehicle_sim: data.vehicle_sim,
                        vehicle_mac_address: data.vehicle_mac_address,
                        vehicle_device_note: data.vehicle_device_note,
                        vehicle_device_connection: data.vehicle_connection,
                    });
                }
            );
    }
}
export default VehicleType;