import React from 'react';
import { MDBDataTable } from 'mdbreact';
import './Tickets.css';
import * as FaIcons from 'react-icons/fa';
import { Trans} from 'react-i18next';

class TicketsTablePage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    render() {
        const rowData = this.props.tableData;
        const rowTicketId = rowData[0];
        const rowTicketRiderPhone = rowData[1];
        const rowTicketRiderEmail = rowData[2];
        const rowTicketRiderName = rowData[3];
        const rowTicketVehicleId = rowData[4];
        const rowTicketVehicleQr = rowData[5];
        const rowTicketVehicleFleet = rowData[6];
        const rowTicketDate = rowData[7];
        const rowTicketAdminAssign = rowData[8];
        const rowTicketSubject = rowData[9];
        const rowTicketStatus = rowData[10];
        const numRows = rowTicketId.length;
        const rowsData = [];
        for (var i = 0; i < numRows; i++) {
            let var_button = rowTicketStatus[i] == "On Sending" ? 
                                <button className="ticket_report_button" value={rowTicketId[i]} onClick={this.props.getTicketIssue}><FaIcons.FaRegStickyNote /> Get report</button> :  
                            rowTicketStatus[i] == "Pending" ? 
                                <button className="ticket_report_button" value={rowTicketId[i]} onClick={this.props.assignTicketIssue}><FaIcons.FaRegStickyNote /> Assign</button> : 
                            rowTicketStatus[i] == "In Progress" ? 
                                <button className="ticket_report_button" value={rowTicketId[i]} onClick={this.props.completeTicketIssue}><FaIcons.FaRegStickyNote /> Done</button> : 
                            rowTicketStatus[i] == "Done" ?
                                <button className="ticket_report_button" value={rowTicketId[i]} ><FaIcons.FaRegStickyNote /> Complete</button>:
                                <p>Unknow state</p>
            const valueindex = {
                ticket_id: rowTicketId[i],
                rider_phone: rowTicketRiderPhone[i],
                rider_email: rowTicketRiderEmail[i],
                rider_name: rowTicketRiderName[i],
                vehicle_id: rowTicketVehicleId[i],
                vehicle_qr: rowTicketVehicleQr[i],
                vehicle_fleet: rowTicketVehicleFleet[i],
                ticket_date: rowTicketDate[i],
                admin_assign: rowTicketAdminAssign[i],
                ticket_subject: rowTicketSubject[i],
                ticket_status: rowTicketStatus[i],
                ticket_action: var_button,
            }
            rowsData.push(valueindex);
        }
        const data = {
            columns: [
                {
                    label: 'ID',
                    field: 'ticket_id',
                    sort: 'asc',
                    width: 150
                },
                {
                    label: <Trans>Phone</Trans>,
                    field: 'rider_phone',
                    sort: 'asc',
                    width: 200
                },
                {
                    label: <Trans>Email</Trans>,
                    field: 'rider_email',
                    sort: 'asc',
                    width: 300
                },
                {
                    label: <Trans>Client</Trans>,
                    field: 'rider_name',
                    sort: 'asc',
                    width: 200
                },
                {
                    label: <Trans>Artist ID</Trans>,
                    field: 'vehicle_id',
                    sort: 'asc',
                    width: 175
                },
                {
                    label: <Trans>Artist QR</Trans>,
                    field: 'vehicle_qr',
                    sort: 'asc',
                    width: 150
                },
                {
                    label: <Trans>Zone</Trans>,
                    field: 'vehicle_fleet',
                    sort: 'asc',
                    width: 150
                },
                {
                    label: <Trans>Date</Trans>,
                    field: 'ticket_date',
                    sort: 'asc',
                    width: 250
                },
                {
                    label: <Trans>Assign to</Trans>,
                    field: 'admin_assign',
                    sort: 'asc',
                    width: 200
                },
                {
                    label: <Trans>Subject</Trans>,
                    field: 'ticket_subject',
                    sort: 'asc',
                    width: 350
                },
                {
                    label: <Trans>Status</Trans>,
                    field: 'ticket_status',
                    sort: 'asc',
                    width: 150
                },
                {
                    label: <Trans>Action</Trans>,
                    field: 'ticket_action',
                    sort: 'asc',
                    width: 150
                }
            ],
            rows: rowsData,
        };
        return (
            <div className='fleets_table'>
                <MDBDataTable
                    hover
                    scrollX
                    maxHeight='50vh'
                    entriesOptions={[5, 10, 20]} 
                    entries={5}
                    data={data}
                    disableRetreatAfterSorting={true}
                />
            </div>
        );
    }
}

export default TicketsTablePage