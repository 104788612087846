import React from 'react';
import './Fleets.css';
import FleetstablePage from './Fleets_table'
import * as AiIcons from 'react-icons/ai';
import MapLeaflet from './MapLeaflet';
import Country_data from './Country_data.json';
import { Trans, useTranslation } from 'react-i18next';
import Cookies from 'universal-cookie';

class Fleets extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoaded: false,
            addFleet: false,
            fleetTable: true,
            companyDetail: false,
            info: [],
            fleet_id: [],
            fleet_name: [],
            supported_email: [],
            visibility: [],
            location: [],
            billing: [],
            created_date: [],
            showFleetCountryLat: 15.748576209260989,
            showFleetCountryLng: 30.11316716113033,
            showFleetCountryZoom: 1,
            add_fleet_lat: 0,
            add_fleet_lng: 0,
            addFleetFormFooter: true,
            addFleetFormConfirm: false,
        }
        this.refCountry = React.createRef();
        this.refReservation = React.createRef();
        this.fleetVisibility = React.createRef();
        this.billingPlan = React.createRef();
        this.parkingOption = React.createRef();
        this.addFleet = this.addFleet.bind(this);
        this.handleCancelAddFleet = this.handleCancelAddFleet.bind(this);
        this.handleShowFleetCountry = this.handleShowFleetCountry.bind(this);
        this.handleCheckedCompanyDetail = this.handleCheckedCompanyDetail.bind(this);
        this.handlelAddFleetSubmit = this.handlelAddFleetSubmit.bind(this);
        this.handleConfirmAddFleet = this.handleConfirmAddFleet.bind(this);
        this.handleGetClickLocation = this.handleGetClickLocation.bind(this);
    }
    handleCheckedCompanyDetail() {
        this.setState({
            addFleet: true,
            companyDetail: !this.state.companyDetail,
            fleetTable: false,
        })
    }
    handleShowFleetCountry(e) {
        const dataAddCountry = e.target.value.split(",");
        this.setState({
            showFleetCountryLat: dataAddCountry[0],
            showFleetCountryLng: dataAddCountry[1],
            showFleetCountryZoom: dataAddCountry[2],
        })
    }
    addFleet() {
        this.setState({
            addFleet: true,
            fleetTable: false,
        })
    }
    handleCancelAddFleet() {
        this.setState({
            addFleet: false,
            fleetTable: true,
        })
    }
    handleGetClickLocation(data) {
        this.setState({
            add_fleet_lat: data[0],
            add_fleet_lng: data[1],
            showFleetCountryLat: data[0],
            showFleetCountryLng: data[1],
        })
    }
    async handlelAddFleetSubmit(e) {
        e.preventDefault();
        const data = new FormData(e.target);
        const countryData = this.refCountry.current.value;
        const countryName = countryData.split(",")[3];
        const formAddFleetData = new URLSearchParams();
        formAddFleetData.append('add_fleet_name', data.get('fleetName'));
        formAddFleetData.append('add_fleet_support_email', data.get('alertEmail'));
        formAddFleetData.append('add_fleet_lat', data.get('latitude'));
        formAddFleetData.append('add_fleet_location', data.get('location'));
        formAddFleetData.append('add_location_name', this.refReservation.current.value);
        formAddFleetData.append('add_fleet_organization_address', data.get('address'));
        formAddFleetData.append('add_fleet_organization_postal_zip_code', data.get('zipCode'));
        formAddFleetData.append('add_fleet_organization_registration_no', data.get('registrationNo'));
        formAddFleetData.append('add_fleet_country', countryName); //chinh lai country
        formAddFleetData.append('add_fleet_visibility', this.fleetVisibility.current.value);
        formAddFleetData.append('add_fleet_lng', data.get('longitude'));
        formAddFleetData.append('add_fleet_billing', this.billingPlan.current.value);
        formAddFleetData.append('add_fleet_parking_option', this.parkingOption.current.value);
        formAddFleetData.append('add_fleet_organization_city', data.get('city'));
        formAddFleetData.append('add_fleet_organization_taxe_id', data.get('taxId'));
        await fetch("https://admin.connect-ink.com/API/fleets/add_fleets.php",
            {
                method: 'POST',
                headers: {
                    'Content-Type': "application/x-www-form-urlencoded;charset=UTF-8"
                },
                body: formAddFleetData.toString()
            }
        )
            .then(res => res.json())
            .then(
                (data) => {
                    this.setState({
                        isLoaded: true,
                        info: data,
                        add_fleet_name: data.add_fleet_name,
                        fleet_id: data.fleet_id,
                        fleet_name: data.fleet_name,
                        supported_email: data.supported_email,
                        visibility: data.visibility,
                        location: data.location,
                        billing: data.billing,
                        created_date: data.created_date,
                        addFleetFormFooter: false,
                        addFleetFormConfirm: true,
                    });
                    console.log(this.state.visibility)
                }
            );
    }
    handleConfirmAddFleet() {
        this.setState({
            addFleet: false,
            fleetTable: true,
            addFleetFormFooter: true,
            addFleetFormConfirm: false,
        })
    }
    async componentDidMount() {
        fetch("https://admin.connect-ink.com/API/fleets/fleets_table.php")
            .then(res => res.json())
            .then(
                (data) => {
                    this.setState({
                        isLoaded: true,
                        info: data,
                        fleet_id: data.fleet_id,
                        fleet_name: data.fleet_name,
                        supported_email: data.supported_email,
                        visibility: data.visibility,
                        location: data.location,
                        billing: data.billing,
                        created_date: data.created_date
                    });
                }
            );
    }
    render() {
        var json_object = Country_data;
        const countryArrayLength = json_object.Name;
        var numRowsCountry = Object.keys(countryArrayLength).length;
        const countrySelectData = [];
        const countrySelectName = [];
        for (var i = 0; i < numRowsCountry; i++) {
            countrySelectData[i] = [json_object.Lat[i], json_object.Lon[i], json_object.Zoom[i], json_object.Name[i]];
            countrySelectName[i] = json_object.Name[i];
        }
        const countrySelectOptionData = [...(new Set(countrySelectData))]
        const countrySelectOptionName = [...(new Set(countrySelectName))]
        let fleetContain = this.state.fleetTable ? "fleets_contain" : "fleets_contain_hide";
        let addFleet = this.state.addFleet ? "add_fleet_show" : "add_fleet_hide";
        let addFleetFormFooter = this.state.addFleetFormFooter ? "add_fleet_footer" : "hide_add_fleet_footer";
        let addFleetFormConfirm = this.state.addFleetFormConfirm ? "confirm_add_fleet_show" : "confirm_add_location_hide";
        let CompanyDetail = this.state.companyDetail ? "show_company_detail" : "hide_company_detail";
        const fleetTableData = [this.state.fleet_id, this.state.fleet_name, this.state.supported_email, this.state.visibility, this.state.location, this.state.billing, this.state.created_date];
        const countryInfo = [this.state.showFleetCountryLat, this.state.showFleetCountryLng, this.state.showFleetCountryZoom];
        return (
            <>
                <div className={fleetContain}>
                    <div>
                        <b className="fleets_title"><Trans>Zone</Trans></b>
                    </div>
                    <div>
                        <table className="fleets_menu_filter">
                            <tbody>
                                <tr>
                                    <td className='fleets_rightside_menu'>
                                        <button className="btn_add_fleets" type="button" onClick={this.addFleet}><AiIcons.AiOutlinePlus /> <Trans>Add Zone</Trans></button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <FleetstablePage tableData={fleetTableData} />
                    </div>
                </div>
                <div className={addFleet}>
                    <div className="addFleetForm">
                        <div className="add_location_form_header"><b><Trans>Add Zone</Trans></b></div>
                        <form onSubmit={this.handlelAddFleetSubmit}>
                            <div className="fleet-form">
                                <div style={{ display: "flex" }}>
                                    <div className="form-group-left-side">
                                        <div className="form-group">
                                            <label><Trans>Zone Name</Trans></label>
                                            <input type="text" name="fleetName" placeholder="Zone Name" required />
                                        </div>
                                        <div className="form-group">
                                            <label><Trans>Supported email</Trans></label>
                                            <input type="text" name="alertEmail" placeholder="Enter email" required />
                                        </div>
                                        <div className="form-group">
                                            <label><Trans>Latitude</Trans></label>
                                            <input type="text" name="latitude" value={this.state.add_fleet_lat} readonly />
                                        </div>
                                        <label><Trans>Use organization's details</Trans></label>
                                        <div className={CompanyDetail}>
                                            <div className="form-group">
                                                <label><Trans>Address</Trans></label>
                                                <input type="text" name="address" placeholder="Enter Address" />
                                            </div>
                                            <div className="form-group">
                                                <label><Trans>Postal/Zip Code</Trans></label>
                                                <input type="text" name="zipCode" placeholder="Enter Postal/Zip Code" />
                                            </div>
                                            <div className="form-group">
                                                <label><Trans>Registration No.</Trans></label>
                                                <input type="text" name="registrationNo" placeholder="Enter Registration No" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group-right-side">
                                        <div className="form-group">
                                            <label><Trans>Country</Trans></label>
                                            <select name="changCountry" ref={this.refCountry} onChange={this.handleShowFleetCountry}>
                                                <option value={[0, 0, 2, "Unknown"]} selected>Country</option>
                                                {countrySelectOptionData.map((x) =>
                                                    <option value={x}>{x[3]}</option>)}
                                                {/*Put country option here*/}
                                            </select>
                                        </div>
                                        <div className="form-group">
                                            <label><Trans>Visibility</Trans></label>
                                            <select ref={this.fleetVisibility}>
                                                <option value="Public">Public</option>
                                                <option value="Private">Private</option>
                                            </select>
                                        </div>
                                        <div className="form-group">
                                            <label><Trans>Longitude</Trans></label>
                                            <input type="text" name="longitude" value={this.state.add_fleet_lng} readonly />
                                        </div>
                                        <input className="companyDetail" type="checkbox" id="companyDetail" name="companyDetail" onChange={this.handleCheckedCompanyDetail}></input>

                                        <div className={CompanyDetail}>
                                            <div className="form-group">
                                                <label><Trans>City</Trans></label>
                                                <input type="text" name="city" placeholder="Enter City" />
                                            </div>
                                            <div className="form-group">
                                                <label><Trans>Tax ID</Trans></label>
                                                <input type="text" name="taxId" placeholder="Enter Tax ID" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={addFleetFormFooter}>
                                    <button type="button" className="btn_cancel_adding" onClick={this.handleCancelAddFleet}>
                                        <Trans>Cancel</Trans>
                                    </button>
                                    <button type="submit" className="btn_submit_adding" >
                                        <Trans>Add Zone</Trans>
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className="addFleetMap">
                        <div className="fleetMap">
                            <MapLeaflet mapDataInfo={countryInfo} getClickLocation={this.handleGetClickLocation} />
                        </div>
                    </div>
                    <div className={addFleetFormConfirm}>
                        <p className="add_fleet_confirm_title"><Trans>The Zone</Trans> {this.state.add_fleet_name} <Trans>has been added recently</Trans> !</p>
                        <button className="add_fleet_confirm_button" type="button" onClick={this.handleConfirmAddFleet}>
                            OK
                        </button>
                    </div>
                </div>
            </>
        )
    }
}
export default Fleets;