import React from 'react';
import './Running.css';
import io from 'socket.io-client';
import RunningRidestablePage from './Running_table';
import UnlockRidestablePage from './Unlock_table';
import LockRidestablePage from './Lock_table';
import { Trans, useTranslation } from 'react-i18next';

class Running extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fleet_name_filter: [],
            fleet_filter_param: 'all_fleet',
            table_data: {
                ride_id: [],
                rider_phone_number: [],
                rider_id: [],
                rider_name: [],
                vehicle_id: [],
                vehicle_qr_code: [],
                vehicle_type: [],
                ride_fleet: [],
                ride_amount: [],
                ride_start_point: [],
                ride_end_point: [],
                ride_distance: [],
                ride_duration: [],
                pause_duration: [],
                ride_date: [],
                ride_rating: [],
                ride_status: [],
                ride_force_stop: [],
            },
            unlock_table_data: {
                ride_id: [],
                rider_phone_number: [],
                vehicle_id: [],
                ride_time: [],

            },
            lock_table_data: {
                ride_id: [],
                rider_phone_number: [],
                vehicle_id: [],
                ride_time: [],

            },
            view_card_image: false,
            front_id_card_url: '',
            back_id_card_url: '',
            front_license_url: '',
            back_license_url: '',
        }
        // this.socket = io('http://admin.elevate-mobility.com:27123');
        this.handleChangeFleet = this.handleChangeFleet.bind(this);
        this.handleForceStop = this.handleForceStop.bind(this);
        this.handleUnlockVerify = this.handleUnlockVerify.bind(this);
        this.handleLockVerify = this.handleLockVerify.bind(this);
    }
    async handleUnlockVerify(e) {
        console.log(e.target.value);
        const unlock_vehicle_ride = e.target.value;
        const formUnlockData = new URLSearchParams();
        formUnlockData.append('ride_id', unlock_vehicle_ride);
        formUnlockData.append('fleet_filter_param', this.state.fleet_filter_param);
        await fetch("https://admin.connect-ink.com/API/rides/unlock_rides_verify.php",
            {
                method: 'POST',
                headers: {
                    'Content-Type': "application/x-www-form-urlencoded;charset=UTF-8"
                },
                body: formUnlockData.toString()
            })
            .then(res => res.json())
            .then(
                (data) => {
                    this.setState({
                        //fleet_name_filter: data.fleet_name,
                        unlock_table_data: {
                            ride_id: data.ride_id,
                            rider_phone_number: data.rider_phone_number,
                            vehicle_id: data.vehicle_id,
                            ride_time: data.ride_time,
                        },

                    })
                }
            );
    }
    async handleLockVerify(e) {
        console.log(e.target.value);
        const lock_vehicle_ride = e.target.value;
        const formLockData = new URLSearchParams();
        formLockData.append('ride_id', lock_vehicle_ride);
        formLockData.append('fleet_filter_param', this.state.fleet_filter_param);
        await fetch("https://admin.connect-ink.com/API/rides/lock_rides_verify.php",
            {
                method: 'POST',
                headers: {
                    'Content-Type': "application/x-www-form-urlencoded;charset=UTF-8"
                },
                body: formLockData.toString()
            })
            .then(res => res.json())
            .then(
                async (data) => {
                    console.log(data.ride_id);
                    await this.setState({
                        //fleet_name_filter: data.fleet_name,
                        lock_table_data: {
                            ride_id: data.ride_id,
                            rider_phone_number: data.rider_phone_number,
                            vehicle_id: data.vehicle_id,
                            ride_time: data.ride_time,
                        },

                    })
                }
            );
    }
    async handleForceStop(e) {
        const forceStopRide = e.target.value;
        const formData = new URLSearchParams();
        formData.append('rides_id_param', forceStopRide);
        await fetch("https://admin.connect-ink.com/API/rides/force_stop_running_ride.php",
            {
                method: 'POST',
                headers: {
                    'Content-Type': "application/x-www-form-urlencoded;charset=UTF-8"
                },
                body: formData.toString()
            }
        )
            .then(res => res.json())
            .then(
                (data) => {
                    this.setState({
                        table_data: {
                            ride_id: data.ride_id,
                            rider_phone_number: data.rider_phone_number,
                            rider_id: data.rider_id,
                            rider_name: data.rider_name,
                            vehicle_id: data.vehicle_id,
                            vehicle_qr_code: data.vehicle_qr_code,
                            vehicle_type: data.vehicle_type,
                            ride_fleet: data.ride_fleet,
                            ride_amount: data.ride_amount,
                            ride_start_point: data.ride_start_point,
                            ride_end_point: data.ride_end_point,
                            ride_distance: data.ride_distance,
                            ride_duration: data.ride_duration,
                            pause_duration: data.pause_duration,
                            ride_date: data.ride_date,
                            ride_rating: data.ride_rating,
                            ride_status: data.ride_status,
                            ride_force_stop: data.ride_force_stop,
                        },
                        totalRide: data.total_ride,
                        totalDistance: data.total_trip,
                        totalRider: data.total_rider,
                    });
                }
            );
    }
    async handleChangeFleet(e) {
        const fleetFilterParam = e.target.value;
        const formData = new URLSearchParams();
        formData.append('fleet_filter_param', fleetFilterParam);
        await fetch("https://admin.connect-ink.com/API/rides/running_rides_table.php",
            {
                method: 'POST',
                headers: {
                    'Content-Type': "application/x-www-form-urlencoded;charset=UTF-8"
                },
                body: formData.toString()
            }
        )
            .then(res => res.json())
            .then(
                (data) => {
                    this.setState({
                        fleet_filter_param: fleetFilterParam,
                        table_data: {
                            ride_id: data.order_id,
                            rider_phone_number: data.user_phone_number,
                            rider_id: data.user_id,
                            rider_name: data.user_name,
                            vehicle_id: data.artist_id,
                            vehicle_qr_code: data.artist_phone_number,
                            vehicle_type: data.artist_name,
                            ride_fleet: data.ride_fleet,
                            ride_amount: data.tatoo_price,
                            ride_start_point: data.tattoo_body_part,
                            ride_end_point: data.tattoo_type,
                            ride_date: data.date,
                            ride_rating: data.rating,
                            ride_status: data.status,
                        },
                        totalRide: data.total_ride,
                        totalDistance: data.total_trip,
                        totalRider: data.total_rider,
                    });
                }
            );
    }
    async componentDidMount() {
        const formData = new URLSearchParams();
        await fetch("https://api.connect-ink.com/API/WEB_ADMIN/API/order/running_rides_table.php",
            {
                method: 'POST',
                headers: {
                    'Content-Type': "application/x-www-form-urlencoded;charset=UTF-8"
                },
                body: formData.toString()
            }
        )
            .then(res => res.json())
            .then(
                (data) => {
                    this.setState({
                        //fleet_name_filter: data.fleet_name,
                        table_data: {
                            ride_id: data.order_id,
                            rider_phone_number: data.user_phone_number,
                            rider_id: data.user_id,
                            rider_name: data.user_name,
                            vehicle_id: data.artist_id,
                            vehicle_qr_code: data.artist_phone_number,
                            vehicle_type: data.artist_name,
                            ride_fleet: data.ride_fleet,
                            ride_amount: data.tatoo_price,
                            ride_start_point: data.tattoo_body_part,
                            ride_end_point: data.tattoo_type,
                            ride_date: data.date,
                            ride_rating: data.rating,
                            ride_status: data.status,
                        },
                    });
                }
            );
        const formUnlockData = new URLSearchParams();
        formUnlockData.append('fleet_filter_param', this.state.fleet_filter_param);
        await fetch("http://api.elevate-mobility.com/api/rides/unlock_rides_table.php",
            {
                method: 'POST',
                headers: {
                    'Content-Type': "application/x-www-form-urlencoded;charset=UTF-8"
                },
                body: formUnlockData.toString()
            })
            .then(res => res.json())
            .then(
                (data) => {
                    this.setState({
                        fleet_name_filter: data.fleet_name,
                        unlock_table_data: {
                            ride_id: data.ride_id,
                            rider_phone_number: data.rider_phone_number,
                            vehicle_id: data.vehicle_id,
                            ride_time: data.ride_time,
                        },

                    })
                }
            );

        const formLockData = new URLSearchParams();
        formLockData.append('fleet_filter_param', this.state.fleet_filter_param);
        await fetch("http://api.elevate-mobility.com/api/rides/lock_rides_table.php",
            {
                method: 'POST',
                headers: {
                    'Content-Type': "application/x-www-form-urlencoded;charset=UTF-8"
                },
                body: formLockData.toString()
            })
            .then(res => res.json())
            .then(
                (data) => {
                    this.setState({
                        //fleet_name_filter: data.fleet_name,
                        lock_table_data: {
                            ride_id: data.ride_id,
                            rider_phone_number: data.rider_phone_number,
                            vehicle_id: data.vehicle_id,
                            ride_time: data.ride_time,
                        },

                    })
                }
            );
        // this.socket.on("confirmDataVehicle", async () => {
        //     console.log("New vehicle event");
        //     const formUnlockDataSocket = new URLSearchParams();
        //     formUnlockDataSocket.append('fleet_filter_param', this.state.fleet_filter_param);
        //     await fetch("http://api.elevate-mobility.com/api/rides/unlock_rides_table.php",
        //         {
        //             method: 'POST',
        //             headers: {
        //                 'Content-Type': "application/x-www-form-urlencoded;charset=UTF-8"
        //             },
        //             body: formUnlockDataSocket.toString()
        //         })
        //         .then(res => res.json())
        //         .then(
        //             (data) => {
        //                 this.setState({
        //                     fleet_name_filter: data.fleet_name,
        //                     unlock_table_data: {
        //                         ride_id: data.ride_id,
        //                         rider_phone_number: data.rider_phone_number,
        //                         vehicle_id: data.vehicle_id,
        //                         ride_time: data.ride_time,
        //                     },

        //                 })
        //             }
        //         );

        //     const formLockDataSocket = new URLSearchParams();
        //     formLockDataSocket.append('fleet_filter_param', this.state.fleet_filter_param);
        //     await fetch("http://api.elevate-mobility.com/api/rides/lock_rides_table.php",
        //         {
        //             method: 'POST',
        //             headers: {
        //                 'Content-Type': "application/x-www-form-urlencoded;charset=UTF-8"
        //             },
        //             body: formLockDataSocket.toString()
        //         })
        //         .then(res => res.json())
        //         .then(
        //             (data) => {
        //                 this.setState({
        //                     //fleet_name_filter: data.fleet_name,
        //                     lock_table_data: {
        //                         ride_id: data.ride_id,
        //                         rider_phone_number: data.rider_phone_number,
        //                         vehicle_id: data.vehicle_id,
        //                         ride_time: data.ride_time,
        //                     },

        //                 })
        //             }
        //         );
        // });

    }
    render() {
        let ridetableData = [
            this.state.table_data.ride_id,
            this.state.table_data.rider_phone_number,
            this.state.table_data.rider_id,
            this.state.table_data.rider_name,
            this.state.table_data.vehicle_id,
            this.state.table_data.vehicle_qr_code,
            this.state.table_data.vehicle_type,
            this.state.table_data.ride_fleet,
            this.state.table_data.ride_amount,
            this.state.table_data.ride_start_point,
            this.state.table_data.ride_end_point,
            this.state.table_data.ride_date,
            this.state.table_data.ride_rating,
            this.state.table_data.ride_status,
            
        ];
        let unlockTableData = [
            this.state.unlock_table_data.ride_id,
            this.state.unlock_table_data.rider_phone_number,
            this.state.unlock_table_data.vehicle_id,
            this.state.unlock_table_data.ride_time
        ];
        let lockTableData = [
            this.state.lock_table_data.ride_id,
            this.state.lock_table_data.rider_phone_number,
            this.state.lock_table_data.vehicle_id,
            this.state.lock_table_data.ride_time
        ];
        
        return (
            <div className='running_contain'>
                <div>
                    <table className="running_menu_filter">
                        <tbody>
                            <tr>
                                <td>
                                    <div>
                                        <p className="running_title"><Trans>On Progress</Trans></p>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td className='running_leftside_menu'>

                                </td>
                                <td className='running_rightside_menu'>
                                    {/*<select className="running_fleets_select" onChange={this.handleChangeFleet}>
                                        <option value="all_fleet">All Fleets</option>
                                        {this.state.fleet_name_filter.map((x) =>
                                            <option value={x}>{x}</option>
                                        )}
                                    </select>*/}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div className='running_table'>
                        <RunningRidestablePage tableData={ridetableData}/>
                    </div>
                    {/*<div className='request_table'>
                        <div className='unlock_request_table'>
                            {<UnlockRidestablePage tableData={unlockTableData} unlockVerify={this.handleUnlockVerify} />}
                        </div>
                        <div className='unlock_request_table'>
                            {<LockRidestablePage tableData={lockTableData} unlockVerify={this.handleLockVerify} />}
                        </div>
                    </div>*/}
                    
                    {/*<div>
                        <div className='running_table'>
                            <div>Ride ID</div>
                            <div>Phone Number</div>
                            <div>Vehicle</div>
                            <div>Time</div>
                            <div><button value="VS00001">Unlock</button></div>
                        </div>
                                </div>*/}
                </div>
            </div>
        )
    }
}
export default Running;