import React from 'react';
import './Rides.css';
import Switch from "react-switch";
import * as FaIcons from 'react-icons/fa';
import * as GiIcons from 'react-icons/gi';
import * as IoIcons from 'react-icons/io';
import * as MdIcons from "react-icons/md";
import * as RiIcons from "react-icons/ri";
import RidestablePage from './Rides_table';
//import RidesTableCSV from './Rides_table_csv';
import { DateRangePickerComponent } from '@syncfusion/ej2-react-calendars';
import { Trans, useTranslation } from 'react-i18next';

class Rides extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fleet_name_filter: [],
            fleet_filter_param: 'all_fleet',
            date_filter_param: 'all',
            force_stop_param: 'all',
            forceStopState: false,
            table_data: {
                ride_id: [],
                rider_phone_number: [],
                rider_id: [],
                rider_name: [],
                vehicle_id: [],
                vehicle_qr_code: [],
                vehicle_type: [],
                ride_fleet: [],
                ride_amount: [],
                ride_start_point: [],
                ride_end_point: [],
                ride_distance: [],
                ride_duration: [],
                pause_duration: [],
                ride_date: [],
                ride_rating: [],
                ride_status: [],
                ride_force_stop: [],
                ride_deposit:[],
                ride_remain:[],
                artist_complain_status:[],
                artist_complain_date:[],
                artist_complain_reason:[],
                client_complain_status:[],
                client_complain_date:[],
                client_complain_reason:[],
            },
            totalRide: 0,
            totalDistance: 0,
            totalRider: 0,
            totalOrderDay: 1,
        }
        this.handleChangeFleet = this.handleChangeFleet.bind(this);
        this.handleForceStopChange = this.handleForceStopChange.bind(this);
        this.handleChangeDate = this.handleChangeDate.bind(this);
        this.dismiss_client_complaint = this.dismiss_client_complaint.bind(this);
        this.dismiss_artist_complaint = this.dismiss_artist_complaint.bind(this);
    }
    async dismiss_artist_complaint(e){
        const order_id = e.target.value;
        console.log("dismiss_artist_complaint");
       const formData = new URLSearchParams();
       formData.append('fleet_filter_param', this.state.fleet_filter_param);
        formData.append('force_stop_param', this.state.force_stop_param);
        formData.append('date_filter_param', this.state.date_filter_param);
        formData.append('order_id', order_id);
        fetch("https://api.connect-ink.com/API/TATU/API/order_management/dismiss_artist_complaint.php",
         {
                method: 'POST',
                headers: {
                    'Content-Type': "application/x-www-form-urlencoded;charset=UTF-8"
                },
                body: formData.toString()
            })
            .then(res => res.json())
            .then(
                (data) => {
                    this.setState({
                        table_data: {
                            ride_id: data.order_id,
                            rider_phone_number: data.user_phone_number,
                            rider_id: data.user_id,
                            rider_name: data.user_name,
                            vehicle_id: data.artist_id,
                            vehicle_qr_code: data.artist_phone_number,
                            vehicle_type: data.artist_name,
                            ride_fleet: data.ride_fleet,
                            ride_amount: data.tatoo_price,
                            ride_start_point: data.tattoo_body_part,
                            ride_end_point: data.tattoo_type,
                            ride_date: data.date,
                            ride_rating: data.rating,
                            ride_status: data.status,
                            ride_deposit:data.deposi_amount,
                            ride_remain:data.remain_amount,
                            artist_complain_status:data.artist_complain_status,
                            artist_complain_date:data.artist_complain_date,
                            artist_complain_reason:data.artist_complain_reason,
                            client_complain_status:data.client_complain_status,
                            client_complain_date:data.client_complain_date,
                            client_complain_reason:data.client_complain_reason,
                            
                        },
                        totalRide: data.total_ride,
                        totalDistance: data.total_trip,
                        totalRider: data.total_rider,
                        totalOrderDay: data.total_order_date,
                    });
                }
            );
    }
    async dismiss_client_complaint(e){
        const order_id = e.target.value;
        console.log("dismiss_client_complaint " +order_id);
        const formData = new URLSearchParams();
        formData.append('fleet_filter_param', this.state.fleet_filter_param);
        formData.append('force_stop_param', this.state.force_stop_param);
        formData.append('date_filter_param', this.state.date_filter_param);
        formData.append('order_id', order_id);
        fetch("https://api.connect-ink.com/API/TATU/API/order_management/dismiss_client_complaint.php",
         {
                method: 'POST',
                headers: {
                    'Content-Type': "application/x-www-form-urlencoded;charset=UTF-8"
                },
                body: formData.toString()
            })
            .then(res => res.json())
            .then(
                (data) => {
                    console.log("DATA complient");
                    console.log(data);
                    this.setState({
                        table_data: {
                            ride_id: data.order_id,
                            rider_phone_number: data.user_phone_number,
                            rider_id: data.user_id,
                            rider_name: data.user_name,
                            vehicle_id: data.artist_id,
                            vehicle_qr_code: data.artist_phone_number,
                            vehicle_type: data.artist_name,
                            ride_fleet: data.ride_fleet,
                            ride_amount: data.tatoo_price,
                            ride_start_point: data.tattoo_body_part,
                            ride_end_point: data.tattoo_type,
                            ride_date: data.date,
                            ride_rating: data.rating,
                            ride_status: data.status,
                            ride_deposit:data.deposi_amount,
                            ride_remain:data.remain_amount,
                            artist_complain_status:data.artist_complain_status,
                            artist_complain_date:data.artist_complain_date,
                            artist_complain_reason:data.artist_complain_reason,
                            client_complain_status:data.client_complain_status,
                            client_complain_date:data.client_complain_date,
                            client_complain_reason:data.client_complain_reason,
                            
                        },
                        totalRide: data.total_ride,
                        totalDistance: data.total_trip,
                        totalRider: data.total_rider,
                        totalOrderDay: data.total_order_date,
                    });
                }
            );
    }
    
    async handleChangeDate(e) {
        const dateValue = e.target.value;
        if (dateValue) {
            var dateValuePOST = dateValue;
        }
        else {
            dateValuePOST = "all";
        }
        const formData = new URLSearchParams();
        formData.append('fleet_filter_param', this.state.fleet_filter_param);
        formData.append('force_stop_param', this.state.force_stop_param);
        formData.append('date_filter_param', dateValuePOST);
        await fetch("https://api.connect-ink.com/API/WEB_ADMIN/API/order/rides_table.php",
            {
                method: 'POST',
                headers: {
                    'Content-Type': "application/x-www-form-urlencoded;charset=UTF-8"
                },
                body: formData.toString()
            }
        )
            .then(res => res.json())
            .then(
                (data) => {
                    this.setState({
                        date_filter_param: dateValuePOST,
                        table_data: {
                            ride_id: data.order_id,
                            rider_phone_number: data.user_phone_number,
                            rider_id: data.user_id,
                            rider_name: data.user_name,
                            vehicle_id: data.artist_id,
                            vehicle_qr_code: data.artist_phone_number,
                            vehicle_type: data.artist_name,
                            ride_fleet: data.ride_fleet,
                            ride_amount: data.tatoo_price,
                            ride_start_point: data.tattoo_body_part,
                            ride_end_point: data.tattoo_type,
                            ride_date: data.date,
                            ride_rating: data.rating,
                            ride_status: data.status,
                            ride_deposit:data.deposi_amount,
                            ride_remain:data.remain_amount,
                            artist_complain_status:data.artist_complain_status,
                            artist_complain_date:data.artist_complain_date,
                            artist_complain_reason:data.artist_complain_reason,
                            client_complain_status:data.client_complain_status,
                            client_complain_date:data.client_complain_date,
                            client_complain_reason:data.client_complain_reason,
                        },
                        totalRide: data.total_ride,
                        totalDistance: data.total_trip,
                        totalRider: data.total_rider,
                        totalOrderDay: data.total_order_date,
                    });
                }
            );
    }
    async handleForceStopChange() {
        this.setState({
            forceStopState: !this.state.forceStopState,
        });
        if (this.state.forceStopState === true) {
            var forceStopParam = 'Yes';
        } else {
            forceStopParam = 'all';
        }
        const formData = new URLSearchParams();
        formData.append('fleet_filter_param', this.state.fleet_filter_param);
        formData.append('force_stop_param', forceStopParam);
        formData.append('date_filter_param', this.state.date_filter_param);
        await fetch("https://api.connect-ink.com/API/WEB_ADMIN/API/order/rides_table.php",
            {
                method: 'POST',
                headers: {
                    'Content-Type': "application/x-www-form-urlencoded;charset=UTF-8"
                },
                body: formData.toString()
            }
        )
            .then(res => res.json())
            .then(
                (data) => {
                    this.setState({
                        force_stop_param: forceStopParam,
                        table_data: {
                            ride_id: data.order_id,
                            rider_phone_number: data.user_phone_number,
                            rider_id: data.user_id,
                            rider_name: data.user_name,
                            vehicle_id: data.artist_id,
                            vehicle_qr_code: data.artist_phone_number,
                            vehicle_type: data.artist_name,
                            ride_fleet: data.ride_fleet,
                            ride_amount: data.tatoo_price,
                            ride_start_point: data.tattoo_body_part,
                            ride_end_point: data.tattoo_type,
                            ride_date: data.date,
                            ride_rating: data.rating,
                            ride_status: data.status,
                            ride_deposit:data.deposi_amount,
                            ride_remain:data.remain_amount,
                            artist_complain_status:data.artist_complain_status,
                            artist_complain_date:data.artist_complain_date,
                            artist_complain_reason:data.artist_complain_reason,
                            client_complain_status:data.client_complain_status,
                            client_complain_date:data.client_complain_date,
                            client_complain_reason:data.client_complain_reason,
                        },
                        totalRide: data.total_ride,
                        totalDistance: data.total_trip,
                        totalRider: data.total_rider,
                        totalOrderDay: data.total_order_date,
                    });
                }
            );
    }
    async handleChangeFleet(e) {
        const fleetFilterParam = e.target.value;
        const formData = new URLSearchParams();
        formData.append('fleet_filter_param', fleetFilterParam);
        formData.append('force_stop_param', this.state.force_stop_param);
        formData.append('date_filter_param', this.state.date_filter_param);
        await fetch("https://api.connect-ink.com/API/WEB_ADMIN/API/order/rides_table.php",
            {
                method: 'POST',
                headers: {
                    'Content-Type': "application/x-www-form-urlencoded;charset=UTF-8"
                },
                body: formData.toString()
            }
        )
            .then(res => res.json())
            .then(
                (data) => {
                    this.setState({
                        fleet_filter_param: fleetFilterParam,
                        table_data: {
                            ride_id: data.order_id,
                            rider_phone_number: data.user_phone_number,
                            rider_id: data.user_id,
                            rider_name: data.user_name,
                            vehicle_id: data.artist_id,
                            vehicle_qr_code: data.artist_phone_number,
                            vehicle_type: data.artist_name,
                            ride_fleet: data.ride_fleet,
                            ride_amount: data.tatoo_price,
                            ride_start_point: data.tattoo_body_part,
                            ride_end_point: data.tattoo_type,
                            ride_date: data.date,
                            ride_rating: data.rating,
                            ride_status: data.status,
                            ride_deposit:data.deposi_amount,
                            ride_remain:data.remain_amount,
                            artist_complain_status:data.artist_complain_status,
                            artist_complain_date:data.artist_complain_date,
                            artist_complain_reason:data.artist_complain_reason,
                            client_complain_status:data.client_complain_status,
                            client_complain_date:data.client_complain_date,
                            client_complain_reason:data.client_complain_reason,
                        },
                        totalRide: data.total_ride,
                        totalDistance: data.total_trip,
                        totalRider: data.total_rider,
                        totalOrderDay: data.total_order_date,
                    });
                }
            );
    }
    async componentDidMount() {
        console.log("Start");
        const formData = new URLSearchParams();
        formData.append('fleet_filter_param', this.state.fleet_filter_param);
        formData.append('force_stop_param', this.state.force_stop_param);
        formData.append('date_filter_param', this.state.date_filter_param);
        await fetch("https://api.connect-ink.com/API/WEB_ADMIN/API/order/rides_table.php",
            {
                method: 'POST',
                headers: {
                    'Content-Type': "application/x-www-form-urlencoded;charset=UTF-8"
                },
                body: formData.toString()
            }
        )
            .then(res => res.json())
            .then(
                (data) => {
                    this.setState({
                        fleet_name_filter: data.fleet_name,
                        table_data: {
                            ride_id: data.order_id,
                            rider_phone_number: data.user_phone_number,
                            rider_id: data.user_id,
                            rider_name: data.user_name,
                            vehicle_id: data.artist_id,
                            vehicle_qr_code: data.artist_phone_number,
                            vehicle_type: data.artist_name,
                            ride_fleet: data.ride_fleet,
                            ride_amount: data.tatoo_price,
                            ride_start_point: data.tattoo_body_part,
                            ride_end_point: data.tattoo_type,
                            ride_date: data.date,
                            ride_rating: data.rating,
                            ride_status: data.status,
                            ride_deposit:data.deposi_amount,
                            ride_remain:data.remain_amount,
                            artist_complain_status:data.artist_complain_status,
                            artist_complain_date:data.artist_complain_date,
                            artist_complain_reason:data.artist_complain_reason,
                            client_complain_status:data.client_complain_status,
                            client_complain_date:data.client_complain_date,
                            client_complain_reason:data.client_complain_reason,
                            
                        },
                        totalRide: data.total_ride,
                        totalDistance: data.total_trip,
                        totalRider: data.total_rider,
                        totalOrderDay: data.total_order_date,
                    });
                    console.log("Start");
                    console.log(data.order_id);
                }
            );
    }
    render() {
        const maxDate = new Date();
        const avgLengthperRide = (parseFloat(this.state.totalRide) / parseFloat(this.state.totalOrderDay)).toFixed(2);
        const avgRidePerRider = (parseFloat(this.state.totalRide) / parseFloat(this.state.totalRider)).toFixed(2);
        let ridetableData = [
            this.state.table_data.ride_id,
            this.state.table_data.rider_phone_number,
            this.state.table_data.rider_id,
            this.state.table_data.rider_name,
            this.state.table_data.vehicle_id,
            this.state.table_data.vehicle_qr_code,
            this.state.table_data.vehicle_type,
            this.state.table_data.ride_fleet,
            this.state.table_data.ride_amount,
            this.state.table_data.ride_start_point,
            this.state.table_data.ride_end_point,
            this.state.table_data.ride_date,
            this.state.table_data.ride_rating,
            this.state.table_data.ride_status,
            this.state.table_data.ride_deposit,
            this.state.table_data.ride_remain,
            this.state.table_data.artist_complain_status,
            this.state.table_data.artist_complain_date,
            this.state.table_data.artist_complain_reason,
            this.state.table_data.client_complain_status,
            this.state.table_data.client_complain_date,
            this.state.table_data.client_complain_reason,
            
        ];
        return (
            <div className='rides_contain'>
                <div>
                    <table className="menu_filter">
                        <tbody>
                            <tr>
                                <td>
                                    <div>
                                        <p className="rides_title"><Trans>Order</Trans></p>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td className='leftside_menu'>
                                    <div className="select_left">
                                    </div>
                                </td>
                                <td className='rightside_menu'>
                                    <div className='rides_button_div'>
                                        <div style={{ width: "25%", display: "inline-block", border: "solid 1px gray", borderRadius: "5px", height: "40px" }}>
                                            <DateRangePickerComponent
                                                onChange={this.handleChangeDate}
                                            >
                                                Filter</DateRangePickerComponent>
                                        </div>
                                        {/*<button className="btn_get_rides_csv" type="button"><RidesTableCSV tableData={ridetableData} /}</button>*/}
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <table className='rides_anaylysis'>
                        <tbody>
                            <tr>
                                <td>
                                    <div className='rides_in_use'>
                                        <table className='rides_in_use_table'>
                                            <tbody>
                                                <tr>
                                                    <td className='rides_icon_analysis'><RiIcons.RiBillFill /></td>
                                                    <td>
                                                        <table className='rides_average_length_table'>
                                                            <tbody>
                                                                <tr>
                                                                    <td><b>{this.state.totalRide}</b></td>
                                                                </tr>
                                                                <tr>
                                                                    <td><p><Trans>Complete Order</Trans></p></td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </td>
                                <td>
                                    <div className='rides_in_use'>
                                        <table>
                                            <tr>
                                                <td className='rides_icon_analysis'><RiIcons.RiFileCopy2Fill /></td>
                                                <td>
                                                    <table className='rides_average_length_table'>
                                                        <tbody>
                                                            <tr>
                                                                <td><b>{avgLengthperRide}</b></td>
                                                            </tr>
                                                            <tr>
                                                                <td><p><Trans>Average Order Per Day</Trans></p></td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                </td>
                                <td>
                                    <div className='rides_in_use'>
                                        <table>
                                            <tr>
                                                <td className='rides_icon_analysis'><IoIcons.IoIosPeople /></td>
                                                <td>
                                                    <table className='rides_average_length_table'>
                                                        <tbody>
                                                            <tr>
                                                                <td><b>{this.state.totalRider}</b></td>
                                                            </tr>
                                                            <tr>
                                                                <td><p><Trans>Total User</Trans>s</p></td>
                                                            </tr>
                                                        </tbody>
                                                    </table>

                                                </td>
                                            </tr>
                                        </table>

                                    </div>
                                </td>
                                <td>
                                    <div className='rides_in_use'>
                                        <table>
                                            <tr>
                                                <td className='rides_icon_analysis'><RiIcons.RiBillFill  /></td>
                                                <td>
                                                    <table className='rides_average_length_table'>
                                                        <tbody>
                                                            <tr>
                                                                <td><b>{avgRidePerRider}</b></td>
                                                            </tr>
                                                            <tr>
                                                                <td><p><Trans>Average Order Per User</Trans></p></td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div className='rides_table_panel'>
                        <table className="rides_table_menu_filter">
                            <tbody>
                                <tr>
                                    <td></td>
                                    <td>

                                    </td>
                                </tr>
                                <tr>
                                    <td className='leftside_menu'>
                                    </td>
                                    <td className='rides_rightside_menu'>

                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div className='rides_table_div'>
                            <RidestablePage tableData={ridetableData} dismissArtistComplaint={this.dismiss_artist_complaint} dismissClientComplaint={this.dismiss_client_complaint} />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default Rides;