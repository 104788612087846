import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";

i18n.use(LanguageDetector).init({
  // we init with resources
  resources: {
    en: {
      translations: {
        Logout: "Logout",
        Language: "Language",
        "All days" : "All days",
        "Yes": "Yes",
        "No": "No",
        "Artist":"Artist",
        "Client":"Client",
        "Analytics": "Analytics",
        "All Zone": "All Zone",
        "Total Revenue": "Total Revenue",
        "Artist on order": "Artist on order",
        "Unavailable Artists": "Unavailable Artits",
        "Available Artists": "Available Artists",
        "Total Artist": "Total Artist",
        "Total Order": "Total Order",
        "Total Duration Today": "Total Duration Today",
        "Average Order Per Day" : "Average Order Per Day",
        "Avg Order Per day Per Client" : "Avg Order Per day Per Client",
        "Avg Order Per day Per Artist" : "Avg Order Per day Per Artist",
        "Total Active Users Today": "Total Active Users Today",
        "Pending Order":"Pending Order",
        "Completed Order": "Completed Order",
        "Outstanding Issues": "Outstanding Issues",
        "Total Client": "Total Client",
        "Total New Client Today": "Total New Client Today",
        "Total Active Client" : "Total Active Client",
        "Total Inactive Client" : "Total Inactive Client",
        "Revenue": "Revenue",
        "Revenue over time": "Revenue over time",
        "Rides over time": "Rides over time",
        "User Signup": "User Signup",
        "Order over time":"Order over time",
        "User Signup":"Client Signup",
        "Org": "Org",
        "Organisations": "Organisations",
        "Zone" : "Zone",
        "Zone Name" : "Zone Name",
        "Add Zone" : "Add Zone",
        "Add Location": "Add Location",
        "Location ID": "Location ID",
        "Location Name": "Location Name",
        "Country": "Country",
        "Operating Status": "Operating Status",
        Action: "Action",
        Disable: "Disable",
        Enable: "Enable",
        View: "View",
        Edit: "Edit",
        Delete: "Delete",
        "Location Code": "Location Code",
        Address: "Address",
        Cancel: "Cancel",
        "The location": "The location",
        "has been added recently": "has been added recently",
        "View Location": "View Location",
        "Edit Location": "Edit Location",
        "The location ID": "The location ID",
        "has been edited recently": "has been edited recently",
        Location: "Location",
        "will be deleted": "will be deleted",
        "Map": "Map",
        "Search by User ID, User Name" : "Search by User ID, User Name",
        "Filter":"Filter",
        Find: "Find",
        "Vehicle Type": "Vehicle Type",
        "User Flag Information": "User Flag Information",
        "Artist Enable" : "Artist Enable",
        "Artist Invisible" : "Artist Invisible",
        "Client Enable" : "Client Enable",
        "Client Invisible" : "Client Invisible",
        "Onride Bike": "Onride Bike",
        "Forced Stop Bike": "Forced Stop Bike",
        "Unlock Fail": "Unlock Fail",
        "IoT Fault": "IoT Fault",
        "Unlock without ride": "Unlock without ride",
        Maintenance: "Maintenance",
        Available: "Available",
        Unavailable: "Unavailable",
        "Service On": "Service On",
        "Service Off": "Service Off",
        Missing: "Missing",
        Stolen: "Stolen",
        "Battery Critical": "Battery Critical",
        "Role List": "Role List",
        Roles: "Roles",
        "Add Role": "Add Role",
        "Role name": "Role name",
        Level: "Level",
        "Edit Role": "Edit Role",
        "Role ID": "Role ID",
        "Role Level": "Role Level",
        "The Role": "The Role",
        "Add new role": "Add new role",
        "Role Name": "Role Name",
        Permissions: "Permissions",
        "Roles Permissons": "Roles Permissons",
        "Application banner": "Application banner",
        Banner: "Banner",
        Campaigns: "Campaigns",
        Customzone: "Customzone",
        FeedbackOption: "FeedbackOption",
        Marker: "Marker",
        Onride: "Onride",
        Operatorhistory: "Operatorhistory",
        Parkings: "Parkings",
        Restrictedareas: "Restrictedareas",
        Serviceareas: "Serviceareas",
        Speedzoneareas: "Speedzoneareas",
        Station: "Station",
        Taxes: "Taxes",
        Timeslider: "Timeslider",
        Vehiclereserved: "Vehiclereserved",
        "Loading permission table...": "Loading permission table...",
        Fleets: "Fleets",
        "Add Fleet": "Add Fleet",
        "Fleet Name": "Fleet Name",
        "Supported email": "Supported email",
        Visibility: "Visibility",
        "Created Date": "Created Date",
        Latitude: "Latitude",
        Longitude: "Longitude",
        "Vehicle Reservation Minutes": "Vehicle Reservation Minutes",
        "Use organization's details": "Use organization's details",
        "Postal/Zip Code": "Postal/Zip Code",
        "Registration No.": "Registration No.",
        "Is Parking Optional": "Is Parking Optional",
        City: "City",
        "Tax ID": "Tax ID",
        "The fleet": "The fleet",
        Vehicles: "Vehicles",
        "All Vehicles": "All Vehicles",
        Open: "Open",
        "Open without ride": "Open without ride",
        "Edit Vehicle": "Edit Vehicle",
        "Save Changes": "Save Changes",
        "Add Vehicle": "Add Vehicle",
        "Vehicle Name": "Vehicle Name",
        "Battery (%)": "Battery (%)",
        IMEI: "IMEI",
        "Sim ICCID Number": "Sim ICCID Number",
        "Vehicle SN": "Vehicle SN",
        "Vehicle RFID": "Vehicle RFID",
        "Mac Address": "Mac Address",
        "Vehicle Flag": "Vehicle Flag",
        Fleet: "Fleet",
        "QR Code": "QR Code",
        "Device Notes": "Device Notes",
        "The vehicle": "The vehicle",
        "Odometer Distance (km)": "Odometer Distance (km)",
        "Enabled Flags": "Enabled Flags",
        "Register Date": "Register Date",
        "Last Connected": "Last Connected",
        "Last Lock Status Update": "Last Lock Status Update",
        "Last ride time": "Last ride time",
        Reserved: "Reserved",
        "Reserved Vehicles": "Reserved Vehicles",
        User: "User",
        "Vehicle ID": "Vehicle ID",
        "Reservation Start Date": "Reservation Start Date",
        "Reservation End Date": "Reservation End Date",
        "Enable Flags": "Enable Flags",
        Release: "Release",
        "Vehicle History": "Vehicle History",
        "Admin ID": "Admin ID",
        "Admin Name": "Admin Name",
        Date: "Date",
        Message: "Message",
        Next: "Next",
        "Last Ride time": "Last Ride time",
        Geofence: "Geofence",
        "Parking Zones": "Parking Zones",
        "Add Parking": "Add Parking",
        "Parking point name": "Parking point name",
        "Parking Point name": "Parking point name",
        "Minimum Vehicle": " Minimum Vehicle",
        "Maximum Vehicle": "Maximum Vehicle",
        Description: "Description",
        Active: "Active",
        "Modify Date": "Modify Date",
        "Restricted Zones": "Restricted Zones",
        "Restricted Areas": "Restricted Areas",
        "Add Restricted Area": "Add Restricted Area",
        "Add Restricted Zone": "Add Restricted Zone",
        "Restricted Zone Name": "Restricted Zone Name",
        "Use this area for this restricted zone ?":
          "Use this area for this restricted zone ?",
        "Please draw and confirm restricted area on map !":
          "Please draw and confirm restricted area on map !",
        "Service Area": "Service Area",
        "Service Areas": "Service Areas",
        "Service Area Name": "Service Area Name",
        "Add Service Area": "Add Service Area",
        "Slow Speed Zones": "Slow Speed Zones",
        "Speed Zone Areas": "Speed Zone Areas",
        "Add Slow Speed Zone": "Add Slow Speed Zone",
        "Slow Speed Zone Name": "Slow Speed Zone Name",
        "Add Speed Zone": "Add Speed Zone",
        "Use this area for this speed zone ?":
          "Use this area for this speed zone ?",
        Stations: "Stations",
        "Add Station": "Add Station",
        "Station Name": "Station Name",
        "Use this area for this station ?": "Use this area for this station ?",
        Riders: "Riders",
        Balance: "Balance",
        "Country Code": "Country Code",
        Status: "Status",
        "Phone Number": "Phone Number",
        "Last Login": "Last Login",
        "App Version": "App Version",
        Platform: "Platform",
        "Os Version": "Os Version",
        Rides: "Rides",
        Completed: "Completed",
        "Ride ID": "Ride ID",
        "Phone number": "Phone number",
        "User ID": "User ID",
        "User Name": "User Name",
        "Complete Rides": "Complete Rides",
        "Average Ride Length": "Average Ride Length",
        "Total User": "Total User",
        "Average Rides Per User": "Average Rides Per User",
        Amount: "Amount",
        "Start Point": "Start Point",
        "End Point": "End Point",
        "Distance (km)": "Distance (km)",
        Duration: "Duration",
        "Pause Duration": "Pause Duration",
        "Ride Date": "Ride Date",
        Rating: "Rating",
        "Force stop": "Force stop",
        Running: "Running",
        "In Use": "In Use",
        "Force Stop": "Force Stop",
        Payments: "Payments",
        "Average Revenue per day": "Average Revenue per day",
        "Average Trip Revenue": "Average Trip Revenue",
        "Average Top Up": "Average Top Up",
        "Total Wallet Size": "Total Wallet Size",
        "Average Wallet Size": "Average Wallet Size",
        "Average Lifetime Spend": "Average Lifetime Spend",
        "Wallet Debit": "Wallet Debit",
        "Wallet Credit": "Wallet Credit",
        "User Deposite": "User Deposite",
        "Pass Revenue": "Pass Revenue",
        Pricing: "Pricing",
        "Payment Gateway": "Payment Gateway",
        "Payment Gateway Name": "Payment Gateway Name",
        "Payment Gateway Type": "Payment Gateway Type",
        ID: "ID",
        "Billing Plan": "Billing Plan",
        "Add Billing Plan": "Add Billing Plan",
        "Billing Plan Name": "Billing Plan Name",
        "Tax Inclusive": "Tax Inclusive",
        Tax: "Tax",
        "Add Taxes": "Add Taxes",
        "Tax ID.": "Tax ID",
        "Tax Name": "Tax Name",
        Percentage: "Percentage",
        Promos: "Promos",
        "Promo ID": "Promo ID",
        "Promo Name": "Promo Name",
        "Promo Type": "Promo Type",
        "Promo Price": "Promo Price",
        "Promos Batches": "Promos Batches",
        "Promos Batch Name": "Promos Batch Name",
        Total: "Total",
        "Add Promos": "Add Promos",
        Offers: "Offers",
        "Add Offers": "Add Offers",
        Title: "Title",
        Category: "Category",
        Support: "Support",
        Tickets: "Tickets",
        "Feedback Option": "Feedback Option",
        Campaign: "Campaign",
        Notifications: "Notifications",
        Banners: "Banners",
        Events: "Events",
        "Order":"Order",
      },
    },
    vi: {
      translations: {
        Logout: "Đăng xuất",
        Language: "Ngôn ngữ",
        "All days" : "Tất cả ngày",
        "Yes": "Có",
        "No": "Không",
        "Artist":"Thợ xăm",
        "Client":"Khách hàng",
        Analytics: "Thống kê",
        "All Zones": "Tất cả khu vực",
        "Total Revenue": "Tổng doanh thu",
        "Artist on order": "Thợ xăm trong đơn hàng",
        "Unavailable Artists": "Thợ xăm bị vô hiệu",
        "Available Artists": "Thợ xăm đang hoạt động",
        "Total Artist": "Tổng số thợ xăm",
        "Total Order": "Tổng số đơn hàng",
        "Total Duration Today": "Tổng thời gian hôm nay",
        
        "Average Order Per Day" : "Đơn hàng mỗi ngày",
        "Avg Order Per day Per Client" : "Trung bình đơn hàng mỗi ngày của khách hàng",
        "Avg Order Per day Per Artist" : "Trung bình đơn hàng mỗi ngày của thợ xăm",
        "Pending Order":"Đơn hàng đã đặt cọc",
        "Completed Order": "Đơn hàng hoàn thành",
        "Outstanding Issues": "Outstanding Issues",
        "Total New Client": "Total New Client",
        "Total Client To-date": "Total Client To-date",
        
        "Total Active Client Today": "Người dùng hôm nay",
        "Outstanding Issues": "Vấn đề chưa giải quyết",
        "Total New Client": "Tổng số người dùng mới",
        "Total Client To-date": "Tổng số người dùng",
        "Revenue": "Doanh thu",
        "Total Client": "Tổng số khách hàng",
        "Total New Client Today": "Khách hàng đăng ký hôm nay",
        "Total Active Client" : "Khách hàng hoạt động",
        "Total Inactive Client" : "Khách hàng bị vô hiệu",
        "Revenue over time": "Biểu đồ doanh thu",
        "Rides over time": "Biểu đồ chuyến đi",
        "User Signup": "Biểu đồ người dùng",
        "Order over time":"Biểu đồ đơn hàng",
        "Org": "Tổ chức",
        "Country" : "Quốc gia",
        "Zone" : "Khu vực",
        "Zone Name" : "Tên khu vực",
        "Add Zone" : "Thêm khu vực",
        "Organisations": "Tổ chức",
        "Add Location": "Thêm địa điểm",
        "Location ID": "ID",
        "Location Name": "Tên địa điểm",
        "Country": "Quốc gia",
        "Operating Status": "Trạng thái hoạt động",
        "Action": "Hoạt động",
        "Disable": "Vô hiệu",
        "Enable": "Kích hoạt",
        "View": "Xem",
        "Edit": "Sửa",
        "Delete": "Xóa",
        "Location Code": "Mã địa điểm",
        "Address": "Địa chỉ",
        "Cancel": "Hủy",
        "The location": "Địa điểm",
        "has been added recently": "vừa được thêm thành công",
        "View Location": "Xem địa điểm",
        "Edit Location": "Sửa địa điểm",
        "The location ID": "Địa điểm có ID",
        "has been edited recently": "vừa được thay đổi",
        "Location": "Địa điểm",
        "will be deleted": "sẽ bị xóa",
        "Map": "Bản đồ",
        "Search by User ID, User Name" : "Tìm người dùng (ID, Name)",
        "Filter":"Lọc",
        "Find": "Tìm",
        "Vehicle Type": "Loại phương tiện",
        "User Flag Information": "Thông tin trạng thái người dùng",
        "Artist Enable" : "Thợ xăm hoạt động",
        "Artist Invisible" : "Thợ xăm không hoạt động",
        "Client Enable" : "Khách hàng hoạt động",
        "Client Invisible" : "Khách hàng không hoạt động",
        "Onride Bike": "Xe đang chạy",
        "Forced Stop Bike": "Xe bị buộc dừng",
        "Unlock Fail": "Mở khóa thất bại",
        "IoT Fault": "Lỗi IoT",
        "Unlock without ride": "Mở khóa nhưng không thực hiện chuyến xe",
        Maintenance: "Bảo trì",
        Available: "Có sẵn",
        Unavailable: "Không có sẵn",
        "Service On": "Dịch vụ đang bật",
        "Service Off": "Dịch vụ tắt",
        Missing: "Còn thiếu",
        Stolen: "Trộm cắp",
        "Battery Critical": "Sắp hết pin",
        "Role List": "Vai trò",
        Roles: "Vai trò",
        "Add Role": "Thêm vai trò",
        "Role name": "Tên vai trò",
        Level: "Cấp độ",
        "Edit Role": "Sửa vai trò",
        "Role ID": "ID",
        "Role Level": "Cấp độ",
        "The Role": "Vai trò",
        "Add new role": "Thêm vai trò mới",
        "Role Name": "Tên vai trò",
        Permissions: "Quyền hạn",
        "Roles Permissons": "Quyền của vai trò",
        "Application banner": "Biểu ngữ ứng dụng",
        Banner: "Biểu ngữ",
        Campaigns: "Chiến dịch",
        Customzone: "Tùy chỉnh khu vực",
        FeedbackOption: "Phản hồi tùy chọn",
        Marker: "Đánh dấu",
        Onride: "Đi xe",
        Operatorhistory: "Lịch sử điều hành",
        Parkings: "Bãi đậu xe",
        Restrictedareas: "Khu vực cấm",
        Serviceareas: "Khu vực dịch vụ",
        Speedzoneareas: "Speedzoneareas",
        Station: "Trạm xe",
        Taxes: "Thuế",
        Timeslider: "Timeslider",
        Vehiclereserved: "Xe đặt trước",
        "Loading permission table...": "Đang tải bảng cấp quyền...",
        Fleets: "Đoàn xe",
        "Add Fleet": "Thêm đoàn xe",
        "Fleet Name": "Tên đoàn xe",
        "Supported email": "Email hỗ trợ",
        Visibility: "Hiển thị",
        "Created Date": "Ngày tạo",
        Latitude: "Vĩ độ",
        Longitude: "Kinh độ",
        "Vehicle Reservation Minutes": "Thời gian đặt xe",
        "Use organization's details": "Sử dụng thông tin tổ chức",
        "Postal/Zip Code": "Mã bưu chính",
        "Registration No.": "Số đăng ký",
        "Is Parking Optional": "Bãi đậu xe tùy chọn ?",
        City: "Thành phố",
        "Tax ID": "Mã số thuế",
        "The fleet": "Đoàn xe",
        Vehicles: "Phương tiện",
        "All Vehicles": "Tất cả phương tiện",
        Open: "Mở khóa",
        "Open without ride": "Mở khóa mà không đi xe",
        "Edit Vehicle": "Sửa thông tin phương tiện",
        "Add Vehicle": "Thêm phương tiện",
        "Save Changes": "Lưu thay đổi",
        "Vehicle Name": "Tên phương tiện",
        "Battery (%)": "Pin (%)",
        IMEI: "IMEI",
        "Sim ICCID Number": "Số SIM ICCID",
        "Vehicle SN": "Số SN xe",
        "Vehicle RFID": "Mã RFID",
        "Mac Address": "Địa chỉ MAC",
        "Vehicle Flag": "Trạng thái kết nối",
        Fleet: "Đoàn xe",
        "QR Code": "Mã QR",
        "Device Notes": "Ghi chú thiết bị",
        "The vehicle": "Phương tiện",
        "Odometer Distance (km)": "Khoảng cách đồng hồ đo (km)",
        "Enabled Flags": "Trạng thái kết nối",
        "Register Date": "Ngày đăng ký",
        "Last Connected": "Kết nối lần cuối",
        "Last Lock Status Update": "Cập nhật trạng thái khóa lần cuối",
        "Last ride time": "Thời gian đi xe cuối cùng",
        Reserved: "Xe đặt trước",
        "Reserved Vehicles": "Xe đã đặt trước",
        User: "Người dùng",
        "Vehicle ID": "ID phương tiện",
        "Reservation Start Date": "Ngày bắt đầu đặt",
        "Reservation End Date": "Ngày kết thúc đặt",
        "Enable Flags": "Enable Flags",
        Release: "Release",
        "Vehicle History": "Lịch sử phương tiện",
        "Admin ID": "ID quản trị viên",
        "Admin Name": "Tên quản trị viên",
        Date: "Ngày",
        Message: "Thông điệp",
        Next: "Tiếp",
        "Last Ride time": "Thời gian đi xe cuối cùng",
        Geofence: "Khu vực",
        "Parking Zones": "Khu đậu xe",
        "Add Parking": "Thêm khu đậu xe",
        "Parking point name": "Tên khu đậu xe",
        "Parking Point Name": "Tên khu đậu xe",
        "Minimum Vehicle": "Số xe tối thiểu",
        "Maximum Vehicle": "Số xe tối đa",
        Description: "Mô tả",
        Active: "Hoạt động",
        "Modify Date": "Ngày sửa đổi",
        "Restricted Zones": "Khu vực bị hạn chế",
        "Restricted Areas": "Khu vực bị bạn chế",
        "Add Restricted Area": "Thêm khu vực",
        "Add Restricted Zone": "Thêm khu vực bị hạn chế",
        "Restricted Zone Name": "Tên khu vực",
        "Use this area for this restricted zone ?":
          "Sử dụng làm khu vực bị hạn chế ?",
        "Please draw and confirm restricted area on map !":
          "Vui lòng vẽ và xác nhận khu vực bị hạn chết trên bản đồ",
        "Service Area": "Khu vực dịch vụ",
        "Service Areas": "Khu vực dịch vụ",
        "Service Area Name": "Tên khu vực",
        "Add Service Area": "Thêm khu vực",
        "Slow Speed Zones": "Khu vực giảm tốc",
        "Speed Zone Areas": "Khu vực giảm tốc",
        "Add Slow Speed Zone": "Thêm khu vực",
        "Slow Speed Zone Name": "Tên khu vực",
        "Add Speed Zone": "Thêm khu vực",
        "Use this area for this speed zone ?": "Sử dụng làm khu vực giảm tốc ?",
        Stations: "Trạm xe",
        "Add Station": "Thêm trạm xe",
        "Station Name": "Tên trạm xe",
        "Use this area for this station ?": "Sử dụng làm trạm xe ?",
        Riders: "Người đạp xe",
        Balance: "Số dư",
        "Country Code": "Mã quốc gia",
        Status: "Trạng thái",
        "Phone Number": "Số điện thoại",
        "Last Login": "Phiên đăng nhập cuối",
        "App Version": "Phiên bản App",
        Platform: "Nền tảng",
        "Os Version": "Phiên bản hệ điều hành",
        Rides: "Chuyến xe",
        Completed: "Đã hoàn thành",
        "Ride ID": "ID chuyến xe",
        "Phone number": "Số điện thoại",
        "User ID": "ID người dùng",
        "User Name": "Tên người dùng",
        "Complete Rides": "Chuyến xe hoàn thành",
        "Average Ride Length": "Độ dài trung bình chuyến xe",
        "Total User": "Tổng số người dùng",
        "Average Rides Per User": "Trung bình chuyến xe mỗi người dùng",
        Amount: "Tổng tiền",
        "Start Point": "Điểm đi",
        "End Point": "Điểm đến",
        "Distance (km)": "Khoảng cách (km)",
        Duration: "Thời gian",
        "Pause Duration": "Pause Duration",
        "Ride Date": "Ngày sử dụng",
        Rating: "Đánh giá",
        "Force stop": "Bắt buộc dừng",
        Running: "Đang chạy",
        "In Use": "Đang đi",
        "Force Stop": "Buộc dừng",
        Payments: "Thanh toán",
        "Average Revenue per day": "Doanh thu trung bình mỗi ngày",
        "Average Trip Revenue": "Doanh thu trung bình mỗi chuyến đi",
        "Average Top Up": "Số tiền trung bình mỗi lần nạp",
        "Total Wallet Size": "Tổng kích thước ví",
        "Average Wallet Size": "Kích thước ví trung bình",
        "Average Lifetime Spend": "Chi tiêu trung bình",
        "Wallet Debit": "Ghi nợ",
        "Wallet Credit": "Tín dụng",
        "User Deposite": "Tiền gửi của người dùng",
        "Pass Revenue": "Pass Revenue",
        Pricing: "Định giá",
        "Payment Gateway": "Cổng thanh toán",
        "Payment Gateway Name": "Tên cổng thanh toán",
        "Payment Type": "Hình thức thanh toán",
        ID: "ID",
        "Billing Plan": "Gói thanh toán",
        "Add Billing Plan": "Thêm gói thanh toán",
        "Billing Plan Name": "Tên gói thanh toán",
        "Tax Inclusive": "Bao gồm thuế",
        Tax: "Thuế",
        "Add Taxes": "Thêm thuế",
        "Tax ID.": "ID",
        "Tax Name": "Tên thuế",
        Percentage: "Phần trăm",
        Promos: "Khuyến mại",
        "Promo ID": "ID khuyến mại",
        "Promo Name": "Tên khuyến mại",
        "Promo Type": "Loại khuyến mại",
        "Promo Price": "Giá khuyến mại",
        "Promos Batches": "Đợt khuyến mại",
        "Promos Batch Name": "Tên đợt khuyến mại",
        Total: "Tổng số lượng",
        "Add Promos": "Thêm khuyến mại",
        Offers: "Ưu đãi",
        "Add Offers": "Thêm ưu đãi",
        Title: "Tiêu đề",
        Category: "Phân loại",
        Support: "Hỗ trợ",
        Tickets: "Vé",
        "Feedback Option": "Tùy chọn phản hồi",
        Campaign: "Chiến dịch",
        Notifications: "Thông báo",
        Banners: "Biểu ngữ",
        Events: "Sự kiện",
        "Order":"Đơn hàng",
      },
    },
  },
  fallbackLng: "vi",
  /*lng: "vi",*/
  debug: false,

  // have a common namespace used around the full app
  ns: ["translations"],
  defaultNS: "translations",

  keySeparator: false, // we use content as keys

  interpolation: {
    escapeValue: false, // not needed for react!!
    formatSeparator: ",",
  },

  react: {
    wait: true,
  },
});

export default i18n;
