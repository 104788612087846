import React from 'react';
import './Pricing.css';
import * as AiIcons from 'react-icons/ai';
import { Trans, useTranslation } from 'react-i18next';

class Pricing extends React.Component {
    render() {
        return (
            <div className='pricing_contain'>
                <p className='pricing_title'><Trans>Billing Plan</Trans></p>
                <table className='pricing_table'>
                    <tr>
                        <td className='pricing_leftside_menu'><input type="search" placeholder="Type in to search" className="pricing_search" /></td>   
                        <td className='pricing_rightside_menu'><button className="btn_add_pricing"><AiIcons.AiOutlinePlus/> <Trans>Add Billing Plan</Trans></button></td>                       
                    </tr>
                </table>

                <div>

                </div>
                <table className='pricing_table'>
                    <tbody>
                        <tr className='pricing_table_th'>
                            <td><b><Trans>ID</Trans></b></td>
                            <td><b><Trans>Billing Plan Name</Trans></b></td>
                            <td><b><Trans>Tax Inclusive</Trans></b></td>
                            <td><b><Trans>Status</Trans></b></td>
                        </tr>
                        <tr className='pricing_table_tr'>
                            <td>1</td>
                            <td>Plan 1</td>
                            <td>No</td>
                            <td>active</td>
                        </tr>
                        <tr className='pricing_table_tr'>
                            <td>2</td>
                            <td>Plan 2</td>
                            <td>Yes</td>
                            <td>active</td>
                        </tr>
                        <tr className='pricing_table_tr'>
                            <td>3</td>
                            <td>Plan 3</td>
                            <td>No</td>
                            <td>active</td>
                        </tr>
                    </tbody>
                </table>
                <table className='pricing_pagination'>
                    <tr>
                        <td className='total_showing'>Showing 0 to 0 of 0 entries</td>
                        <td className='page_btn_div'>
                            <div>
                                <button className="payments_btn_previous" type="button">Previous</button>
                                <button className="payments_btn_next" type="button">Next</button>
                            </div>
                        </td>
                    </tr>
                </table>
            </div>
        );
    }
}

export default Pricing;