import React from 'react';
import Switch from "react-switch";

class Toggle extends React.Component {
  constructor() {
    super();
    this.state = { checked: false };
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(checked) {
    this.setState({ checked });
  }
  render() {
    return (
      <>
        <Switch onChange={this.handleChange} checked={this.state.checked} />
      </>
    );
  }
}
export default Toggle;