import React from 'react';
import { MDBDataTable } from 'mdbreact';
import './Org_table.css';
import {Trans} from 'react-i18next';

class DatatablePage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            disable_location: false,
            view_location: false,
            edit_location: false,
            delete_location: false
        }
    }
    render() {
        const rowData = this.props.tableData;
        const rowDataLocationId = rowData[0];
        const rowDataLocationName = rowData[1];
        const rowDataCountry = rowData[2];
        const rowDataBillingPlan = rowData[3];
        const rowDataOperatingStatus = rowData[4];
        const rowDataLocationCode = rowData[5];
        const rowDataLocationAddress = rowData[6];
        const numRows = rowDataLocationId.length;
        const rowsData = [];
        for (var i = 0; i < numRows; i++) {
            if (rowDataOperatingStatus[i] === "Normal") {
                const valueindex = {
                    location_id: rowDataLocationId[i],
                    location_name: rowDataLocationName[i],
                    country: rowDataCountry[i],
                    billing_plan: rowDataBillingPlan[i],
                    operating_status: rowDataOperatingStatus[i],
                    action: <>
                        <button className='org_action_button' value={rowDataLocationId[i]} onClick={this.props.handler}><Trans>Disable</Trans></button>
                        <button className='org_action_button' value={rowDataLocationId[i]} onClick={this.props.handlerView}><Trans>View</Trans></button>
                        <button className='org_action_button' value={[rowDataLocationName[i],rowDataLocationCode[i],rowDataCountry[i],rowDataBillingPlan[i],rowDataOperatingStatus[i],rowDataLocationId[i],rowDataLocationAddress[i]]} onClick={this.props.handlerEdit}><Trans>Edit</Trans></button>
                        <button className='org_action_button' value={[rowDataLocationId[i],rowDataLocationName[i]]} onClick={this.props.handlerDelete}><Trans>Delete</Trans></button>
                    </>
                }
                rowsData.push(valueindex);
            }
            else {
                const valueindex = {
                    location_id: rowDataLocationId[i],
                    location_name: rowDataLocationName[i],
                    country: rowDataCountry[i],
                    billing_plan: rowDataBillingPlan[i],
                    operating_status: rowDataOperatingStatus[i],
                    action: <>
                        <button className='org_action_button' value={rowDataLocationId[i]} onClick={this.props.handlerEnable}><Trans>Enable</Trans></button>
                        <button className='org_action_button' value={rowDataLocationId[i]} onClick={this.props.handlerView}><Trans>View</Trans></button>
                        <button className='org_action_button' value={[rowDataLocationName[i],rowDataLocationCode[i],rowDataCountry[i],rowDataBillingPlan[i],rowDataOperatingStatus[i],rowDataLocationId[i],rowDataLocationAddress[i]]} onClick={this.props.handlerEdit}><Trans>Edit</Trans></button>
                        <button className='org_action_button' value={[rowDataLocationId[i],rowDataLocationName[i]]} onClick={this.props.handlerDelete}><Trans>Delete</Trans></button>
                    </>
                }
                rowsData.push(valueindex);
            }
        }
        const data = {
            columns: [
                {
                    label: <Trans>Location ID</Trans>,
                    field: 'location_id',
                    sort: 'asc',
                    width: 75
                },
                {
                    label: <Trans>Location Name</Trans>,
                    field: 'location_name',
                    sort: 'asc',
                    width: 125
                },
                {
                    label: <Trans>Country</Trans>,
                    field: 'country',
                    sort: 'asc',
                    width: 100
                },
                {
                    label: <Trans>Operating Status</Trans>,
                    field: 'operating_status',
                    sort: 'asc',
                    width: 175
                },
                {
                    label: <Trans>Action</Trans>,
                    field: 'action',
                    sort: 'asc',
                    width: 300
                }
            ],

            rows: rowsData,
        };

        return (
            <div className='org_table'>
                <MDBDataTable
                    hover
                    scrollX
                    maxHeight='50vh'
                    entriesOptions={[5, 10, 20]}
                    entries={5}
                    data={data}
                    disableRetreatAfterSorting={true}
                />
            </div>
        );
    }
}

export default DatatablePage;