import React from 'react';
import './ServiceAreas.css';
import './Parkings.css';
import * as FaIcons from 'react-icons/fa';
import { Trans } from 'react-i18next';
import Switch from "react-switch";
import ServiceAreastablePage from './ServiceAreas_table';
import AddServiceAreasMap from './AddServiceAreasMap';
import EditServiceAreaMap from './EditServiceAreaMap';
import Cookies from 'universal-cookie';

class ServiceAreas extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fleetName: [],
            fleetNameAddParkings: [],
            fleetNameEditParkings: [],
            fleetFilterParam: "all_fleet",
            activeFilterparam: "all",
            activeFilterState: false,
            serviceAreaTable: true,
            addServiceArea: false,
            editServiceArea: false,
            addServiceAreaFormConfirm: false,
            addServiceAreaFormFooter: true,
            serviceAreaData: {
                serviceAreaId: [],
                serviceAreaName: [],
                serviceAreaFleet: [],
                serviceAreaStatus: [],
                serviceAreaCreatedDate: [],
                serviceAreaModifyDate: [],
            },
            editServiceAreaData: {
                editServiceAreaId: [],
                editServiceAreaName: [],
                editServiceAreaFleet: [],
                editServiceAreaLatArray: [10.77080777782741, 10.770965939767235, 10.770681248215716, 10.770570534761893, 10.770586350972078],
                editServiceAreaLngArray: [106.6917888056399, 106.69198733018773, 106.69221804790547, 106.69206244758419, 106.69197123360274],
                editServiceAreaDescription: [],
            },
            onEditState: true,
            temporaryAreaLat: [],
            temporaryAreaLng: [],
            confirmAreaLat: [],
            confirmAreaLng: [],
            popupParkingsAreaConfirm: false,
            add_service_area_name: null,
            add_service_area_id: null,
            confirmUseArea: false,
            confirmUseEditArea: false,
            temporaryEditAreaLat: [],
            temporaryEditAreaLng: [],
            confirmEditAreaLat: [],
            confirmEditAreaLng: [],
            onEditAreaDrawState: true,
            editServiceAreaFormConfirm: false,
            editServiceAreaFormFooter: true,
            deleteZoneFormConfirm: false,
            deleteZoneId: null,
            mapAddLat: 16.51632472900294,
            mapAddLng: 107.64006418629653,
        }
        this.selectFleetFilter = this.selectFleetFilter.bind(this);
        this.handleActiveChange = this.handleActiveChange.bind(this);
        this.handleOpenAddServiceAreaForm = this.handleOpenAddServiceAreaForm.bind(this);
        this.handleCancelAddServiceArea = this.handleCancelAddServiceArea.bind(this);
        this.removePolygon = React.createRef();
        this.removeEditPolygon = React.createRef();
        this._onServiceAreaCreate = this._onServiceAreaCreate.bind(this);
        this.removePolygonFunction = this.removePolygonFunction.bind(this);
        this.confirmPolygonFunction = this.confirmPolygonFunction.bind(this);
        this.handlelAddServiceAreaSubmit = this.handlelAddServiceAreaSubmit.bind(this);
        this.handleConfirmAddServiceArea = this.handleConfirmAddServiceArea.bind(this);
        this.getFleetLocationMap = this.getFleetLocationMap.bind(this);
        this.changeLocation = this.changeLocation.bind(this);
        //=====================================================================================
        this.editServiceAreaOpen = this.editServiceAreaOpen.bind(this);
        this.handleCancelEditServiceArea = this.handleCancelEditServiceArea.bind(this);
        this._onCreateEdit = this._onCreateEdit.bind(this);
        this.handlelEditServiceAreaSubmit = this.handlelEditServiceAreaSubmit.bind(this);
        this.removeEditPolygonFunction = this.removeEditPolygonFunction.bind(this);
        this.confirmEditPolygonFunction = this.confirmEditPolygonFunction.bind(this);
        this.handleConfirmEditServiceArea = this.handleConfirmEditServiceArea.bind(this);
        //=========================================================================
        this.deleteZone = this.deleteZone.bind(this);
        this.deleteZoneconfirm = this.deleteZoneconfirm.bind(this);
        this.deleteZonecancel = this.deleteZonecancel.bind(this);
    }
    async selectFleetFilter(e) {
        const fleetFiltervalue = e.target.value;
        const formParkingFilterData = new URLSearchParams();
        formParkingFilterData.append('fleet_filter_param', fleetFiltervalue);
        formParkingFilterData.append('active_filter_param', this.state.activeFilterparam);
        await fetch("http://api.elevate-mobility.com/api/parking_point/elevate_service_area.php",
            {
                method: 'POST',
                headers: {
                    'Content-Type': "application/x-www-form-urlencoded;charset=UTF-8"
                },
                body: formParkingFilterData.toString()
            }
        )
            .then(res => res.json())
            .then(
                (data) => {
                    this.setState({
                        fleetFilterParam: fleetFiltervalue,
                        serviceAreaData: {
                            serviceAreaId: data.service_area_id,
                            serviceAreaName: data.service_area_name,
                            serviceAreaFleet: data.service_area_fleet,
                            serviceAreaStatus: data.service_area_status,
                            serviceAreaCreatedDate: data.service_area_created_date,
                            serviceAreaModifyDate: data.service_area_modify_date,
                        }
                    })
                }
            )
    }
    async handleActiveChange() {
        if (this.state.activeFilterState === false) {
            this.setState({
                activeFilterState: true,
            });
            var activeFilterParam = "Active";
        } else {
            this.setState({
                activeFilterState: false,
            });
            activeFilterParam = "all";
        }
        const formParkingFilterData = new URLSearchParams();
        formParkingFilterData.append('fleet_filter_param', this.state.fleetFilterParam);
        formParkingFilterData.append('active_filter_param', activeFilterParam);
        await fetch("http://api.elevate-mobility.com/api/parking_point/elevate_service_area.php",
            {
                method: 'POST',
                headers: {
                    'Content-Type': "application/x-www-form-urlencoded;charset=UTF-8"
                },
                body: formParkingFilterData.toString()
            }
        )
            .then(res => res.json())
            .then(
                (data) => {
                    this.setState({
                        activeFilterparam: activeFilterParam,
                        serviceAreaData: {
                            serviceAreaId: data.service_area_id,
                            serviceAreaName: data.service_area_name,
                            serviceAreaFleet: data.service_area_fleet,
                            serviceAreaStatus: data.service_area_status,
                            serviceAreaCreatedDate: data.service_area_created_date,
                            serviceAreaModifyDate: data.service_area_modify_date,
                        }
                    })
                }
            )
    }
    handleOpenAddServiceAreaForm() {
        this.setState({
            addServiceArea: true,
            serviceAreaTable: false,
        })
    }
    handleCancelAddServiceArea() {
        this.setState({
            serviceAreaTable: true,
            addServiceArea: false,
            addServiceAreaFormConfirm: false,
            addServiceAreaFormFooter: true,
        })
    }
    async _onServiceAreaCreate(e) {
        const corArray = e.layer._latlngs;
        const areaLatArray = [];
        const areaLngArray = [];
        var arrayLength = corArray[0].length;
        for (var i = 0; i < arrayLength; i++) {
            areaLatArray[i] = corArray[0][i].lat;
            areaLngArray[i] = corArray[0][i].lng;
        }
        await this.setState({
            temporaryAreaLat: areaLatArray,
            temporaryAreaLng: areaLngArray,
            onEditState: false,
            confirmUseArea: true
        });
    }
    removePolygonFunction() {
        this.setState({
            confirmUseArea: false,
            confirmAreaLat: [],
            confirmAreaLng: [],
        });
        var polygon = this.removePolygon.current;
        var layers = polygon._layers;
        var layer_ids = Object.keys(layers);
        var layer;
        layer_ids.forEach(id => {
            layer = layers[id]
            polygon.removeLayer(layer);
        })
        this.setState({
            onEditState: true,
        })
    }
    async confirmPolygonFunction() {
        await this.setState({
            confirmAreaLat: this.state.temporaryAreaLat,
            confirmAreaLng: this.state.temporaryAreaLng,
        })
        alert("This area has been added to service area.\nClick 'Add Service Area' to complete.")
    }
    async handlelAddServiceAreaSubmit(e) {
        const cookies = new Cookies();
        e.preventDefault();
        const data = new FormData(e.target);
        const arrayAreaLat = data.get('area_lat');
        const arrayAreaLng = data.get('area_lng')
        const arrayLat = arrayAreaLat.toString();
        const arrayLng = arrayAreaLng.toString();
        if (arrayLat) {
            const formAddServiceArea = new URLSearchParams();
            formAddServiceArea.append('service_area_fleet', data.get('serviceAreaFleet'));
            formAddServiceArea.append('service_area_name', data.get('serviceAreaName'));
            formAddServiceArea.append('service_area_description', data.get('serviceAreaDescription'));
            formAddServiceArea.append('service_area_area_lat', arrayLat);
            formAddServiceArea.append('service_area_area_lng', arrayLng);
            formAddServiceArea.append('admin_name', cookies.get('adminName'));
            await fetch("http://api.elevate-mobility.com/api/parking_point/elevate_add_service_area.php",
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': "application/x-www-form-urlencoded;charset=UTF-8"
                    },
                    body: formAddServiceArea.toString()
                }
            )
                .then(res => res.json())
                .then(
                    (data) => {
                        if (data.success === 1) {
                            this.setState({
                                add_service_area_name: data.add_service_area_name,
                                add_service_area_id: data.add_service_area_id,
                                addServiceAreaFormConfirm: true,
                                addServiceAreaFormFooter: false,
                            })
                        } else {
                            alert("Fail to add new service area !");
                        }
                    }
                )
        } else {
            alert("Please draw and confirm parking area on map !")
        }
    }
    async handleConfirmAddServiceArea() {
        const formServiceAreaFilterData = new URLSearchParams();
        formServiceAreaFilterData.append('fleet_filter_param', this.state.fleetFilterParam);
        formServiceAreaFilterData.append('active_filter_param', this.state.activeFilterparam);
        await fetch("http://api.elevate-mobility.com/api/parking_point/elevate_service_area.php",
            {
                method: 'POST',
                headers: {
                    'Content-Type': "application/x-www-form-urlencoded;charset=UTF-8"
                },
                body: formServiceAreaFilterData.toString()
            }
        )
            .then(res => res.json())
            .then(
                (data) => {
                    this.setState({
                        serviceAreaTable: true,
                        addServiceArea: false,
                        addServiceAreaFormConfirm: false,
                        addServiceAreaFormFooter: true,
                        serviceAreaData: {
                            serviceAreaId: data.service_area_id,
                            serviceAreaName: data.service_area_name,
                            serviceAreaFleet: data.service_area_fleet,
                            serviceAreaStatus: data.service_area_status,
                            serviceAreaCreatedDate: data.service_area_created_date,
                            serviceAreaModifyDate: data.service_area_modify_date,

                        }
                    })
                }
            )
    }
    async getFleetLocationMap(e) {
        const fleetNameRequest = e.target.value;
        const formFleetRequestData = new URLSearchParams();
        formFleetRequestData.append('fleet_request_param', fleetNameRequest);
        console.log("Fleet: " + formFleetRequestData);
        await fetch("http://api.elevate-mobility.com/api/parking_point/elevate_request_fleet_location.php",
            {
                method: 'POST',
                headers: {
                    'Content-Type': "application/x-www-form-urlencoded;charset=UTF-8"
                },
                body: formFleetRequestData.toString()
            })
            .then(res => res.json())
            .then(
                (data) => {
                    this.setState({
                        mapAddLat: data.fleet_name_lat,
                        mapAddLng: data.fleet_name_lng,
                    })
                })
    }
    changeLocation(data) {
        console.log("SHOW CLICK: " + data);
        this.setState({
            mapAddLat: data[0],
            mapAddLng: data[1],
        })
    }
    //================================================================================================
    async editServiceAreaOpen(e) {
        //Clear all polygon on map before display edit restricted area
        var polygon = this.removeEditPolygon.current;
        var layers = polygon._layers;
        var layer_ids = Object.keys(layers);
        var layer;
        layer_ids.forEach(id => {
            layer = layers[id]
            polygon.removeLayer(layer);
        })
        const zoneId = e.target.value;
        const formEditServiceAreaData = new URLSearchParams();
        formEditServiceAreaData.append('zone_id', zoneId);
        await fetch("http://api.elevate-mobility.com/api/parking_point/elevate_get_service_area.php",
            {
                method: 'POST',
                headers: {
                    'Content-Type': "application/x-www-form-urlencoded;charset=UTF-8"
                },
                body: formEditServiceAreaData.toString()
            }
        )
            .then(res => res.json())
            .then(
                (data) => {
                    this.setState({
                        editServiceArea: true,
                        serviceAreaTable: false,
                        editServiceAreaFormFooter: true,
                        editServiceAreaFormConfirm: false,
                        editServiceAreaData: {
                            editServiceAreaId: data.service_area_id,
                            editServiceAreaName: data.service_area_name,
                            editServiceAreaFleet: data.service_area_fleet,
                            editServiceAreaLatArray: data.service_area_lat_array,
                            editServiceAreaLngArray: data.service_area_lng_array,
                            editServiceAreaDescription: data.service_area_description,
                        },
                        confirmEditAreaLat: data.service_area_lat_array,
                        confirmEditAreaLng: data.service_area_lng_array,
                        onEditAreaDrawState: true,
                    })
                    console.log("TEST: " + this.state.editServiceAreaData.editServiceAreaLatArray)
                }
            )
    }
    handleCancelEditServiceArea() {
        this.setState({
            serviceAreaTable: true,
            editServiceArea: false,
            editServiceAreaFormConfirm: false,
            editServiceAreaFormFooter: true,
        })
    }
    _onCreateEdit(e) {
        const corArray = e.layer._latlngs;
        const areaLatArray = [];
        const areaLngArray = [];
        var arrayLength = corArray[0].length;
        for (var i = 0; i < arrayLength; i++) {
            areaLatArray[i] = corArray[0][i].lat;
            areaLngArray[i] = corArray[0][i].lng;
        }
        this.setState({
            temporaryEditAreaLat: areaLatArray,
            temporaryEditAreaLng: areaLngArray,
            onEditState: false,
            confirmUseEditArea: true
        })
    }
    async handlelEditServiceAreaSubmit(e) {
        e.preventDefault();
        const cookies = new Cookies();
        const data = new FormData(e.target);
        const arrayAreaLat = data.get('edit_area_lat');
        const arrayAreaLng = data.get('edit_area_lng');
        const arrayLat = arrayAreaLat.toString();
        const arrayLng = arrayAreaLng.toString();
        if (arrayLat) {
            const formAddParkingPoint = new URLSearchParams();
            formAddParkingPoint.append('edit_service_area_id', data.get('edit_area_id'));
            formAddParkingPoint.append('edit_service_area_fleet', data.get('editServiceAreaFleet'));
            formAddParkingPoint.append('edit_service_area_name', data.get('editServiceAreaName'));
            formAddParkingPoint.append('edit_service_area_description', data.get('editServiceAreaDescription'));
            formAddParkingPoint.append('edit_service_area_area_lat', arrayLat);
            formAddParkingPoint.append('edit_service_area_area_lng', arrayLng);
            formAddParkingPoint.append('admin_name', cookies.get('adminName'));
            await fetch("http://api.elevate-mobility.com/api/parking_point/elevate_edit_service_area.php",
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': "application/x-www-form-urlencoded;charset=UTF-8"
                    },
                    body: formAddParkingPoint.toString()
                }
            )
                .then(res => res.json())
                .then(
                    async (data) => {
                        if (data.success === 1) {
                            await this.setState({
                                edit_zone_name: data.edit_service_area_name,
                                edit_zone_id: data.edit_service_area_id,
                                editServiceAreaFormConfirm: true,
                                editServiceAreaFormFooter: false,
                                confirmUseEditArea: false
                            })
                        } else {
                            alert("Fail to update service area information !")
                        }
                    }
                )
        } else {
            alert("Please draw and confirm parking area on map !")
        }
    }
    removeEditPolygonFunction() {
        this.setState({
            confirmUseEditArea: false,
            confirmEditAreaLat: [],
            confirmEditAreaLng: [],
        });
        //var layerContainer = this.removePolygon.options.edit.featureGroup;
        var polygon = this.removeEditPolygon.current;
        var layers = polygon._layers;
        var layer_ids = Object.keys(layers);
        var layer;
        layer_ids.forEach(id => {
            layer = layers[id]
            polygon.removeLayer(layer);
        })
    }
    async confirmEditPolygonFunction() {
        await this.setState({
            confirmEditAreaLat: this.state.temporaryEditAreaLat,
            confirmEditAreaLng: this.state.temporaryEditAreaLng,
            onEditAreaDrawState: false,
        });
        alert("This area has been added to service area information.\nClick 'Save Changes' to complete.")
    }
    async handleConfirmEditServiceArea() {
        const formServiceAreaFilterData = new URLSearchParams();
        formServiceAreaFilterData.append('fleet_filter_param', this.state.fleetFilterParam);
        formServiceAreaFilterData.append('active_filter_param', this.state.activeFilterparam);
        await fetch("http://api.elevate-mobility.com/api/parking_point/elevate_service_area.php",
            {
                method: 'POST',
                headers: {
                    'Content-Type': "application/x-www-form-urlencoded;charset=UTF-8"
                },
                body: formServiceAreaFilterData.toString()
            }
        )
            .then(res => res.json())
            .then(
                (data) => {
                    this.setState({
                        editServiceArea: false,
                        serviceAreaTable: true,
                        editServiceAreaFormFooter: true,
                        editServiceAreaFormConfirm: false,
                        serviceAreaData: {
                            serviceAreaId: data.service_area_id,
                            serviceAreaName: data.service_area_name,
                            serviceAreaFleet: data.service_area_fleet,
                            serviceAreaStatus: data.service_area_status,
                            serviceAreaCreatedDate: data.service_area_created_date,
                            serviceAreaModifyDate: data.service_area_modify_date,

                        }
                    })
                }
            )
    }
    //===================================================================================================
    deleteZone(e) {
        const deleteStationValue = e.target.value;
        this.setState({
            deleteZoneFormConfirm: true,
            deleteZoneId: deleteStationValue,
        })
    }
    async deleteZoneconfirm() {
        const cookies = new Cookies();
        const formZoneFilterData = new URLSearchParams();
        formZoneFilterData.append('fleet_filter_param', this.state.fleetFilterParam);
        formZoneFilterData.append('active_filter_param', this.state.activeFilterparam);
        formZoneFilterData.append('zone_id', this.state.deleteZoneId);
        formZoneFilterData.append('admin_name', cookies.get('adminName'));
        await fetch("http://api.elevate-mobility.com/api/parking_point/elevate_delete_service_area.php",
            {
                method: 'POST',
                headers: {
                    'Content-Type': "application/x-www-form-urlencoded;charset=UTF-8"
                },
                body: formZoneFilterData.toString()
            }
        )
            .then(res => res.json())
            .then(
                (data) => {
                    if (data.success === 1) {
                        this.setState({
                            deleteZoneFormConfirm: false,
                            serviceAreaTable: true,
                            serviceAreaData: {
                                serviceAreaId: data.service_area_id,
                                serviceAreaName: data.service_area_name,
                                serviceAreaFleet: data.service_area_fleet,
                                serviceAreaStatus: data.service_area_status,
                                serviceAreaCreatedDate: data.service_area_created_date,
                                serviceAreaModifyDate: data.service_area_modify_date,
                            }
                        })
                    } else {
                        this.setState({
                            deleteZoneFormConfirm: false,
                            serviceAreaTable: true,
                        });
                        alert("The service area was not deleted completly !")
                    }
                }
            )
    }
    deleteZonecancel(){
        this.setState({
            deleteZoneFormConfirm: false,
            serviceAreaTable: true,
            deleteZoneId: null,
        })
    }
    async componentDidMount() {
        const formServiceAreaFilterData = new URLSearchParams();
        formServiceAreaFilterData.append('fleet_filter_param', this.state.fleetFilterParam);
        formServiceAreaFilterData.append('active_filter_param', this.state.activeFilterparam);
        await fetch("http://api.elevate-mobility.com/api/parking_point/elevate_service_area.php",
            {
                method: 'POST',
                headers: {
                    'Content-Type': "application/x-www-form-urlencoded;charset=UTF-8"
                },
                body: formServiceAreaFilterData.toString()
            }
        )
            .then(res => res.json())
            .then(
                (data) => {
                    this.setState({
                        fleetName: data.fleet_name,
                        fleetNameAddParkings: data.fleet_name,
                        serviceAreaData: {
                            serviceAreaId: data.service_area_id,
                            serviceAreaName: data.service_area_name,
                            serviceAreaFleet: data.service_area_fleet,
                            serviceAreaStatus: data.service_area_status,
                            serviceAreaCreatedDate: data.service_area_created_date,
                            serviceAreaModifyDate: data.service_area_modify_date,
                        },
                        mapAddLat: data.first_fleet_name_lat,
                        mapAddLng: data.first_fleet_name_lng,
                        mapEditLat: data.first_fleet_name_lat,
                        mapEditLng: data.first_fleet_name_lng,
                    })
                }
            )
    }
    render() {
        const tableData = [
            this.state.serviceAreaData.serviceAreaId,
            this.state.serviceAreaData.serviceAreaName,
            this.state.serviceAreaData.serviceAreaFleet,
            this.state.serviceAreaData.serviceAreaStatus,
            this.state.serviceAreaData.serviceAreaCreatedDate,
            this.state.serviceAreaData.serviceAreaModifyDate,
        ];
        let serviceAreaTable = this.state.serviceAreaTable ? "show_parkings_table" : "hide_parkings_table";
        let addaServiceArea = this.state.addServiceArea ? "add_parkings_show" : "add_parkings_hide";
        let addServiceAreaFormConfirm = this.state.addServiceAreaFormConfirm ? "confirm_add_parkings_show" : "confirm_add_parkings_hide";
        let addServiceAreaFormFooter = this.state.addServiceAreaFormFooter ? "add_parkings_footer" : "hide_add_parkings_footer";
        let confirmUseArea = this.state.confirmUseArea ? "show_confirm_use_area" : "hide_confirm_use_area";
        let addMapPosition = [this.state.mapAddLat, this.state.mapAddLng];
        //================================================================================
        let editServiceArea = this.state.editServiceArea ? "add_parkings_show" : "add_parkings_hide";
        let editServiceAreaFormConfirm = this.state.editServiceAreaFormConfirm ? "confirm_add_parkings_show" : "confirm_add_parkings_hide";
        let editServiceAreaFormFooter = this.state.editServiceAreaFormFooter ? "add_parkings_footer" : "hide_add_parkings_footer";
        let confirmUseEditArea = this.state.confirmUseEditArea ? "show_confirm_use_area" : "hide_confirm_use_area";
        //=================================================================================
        let deleteZoneFormConfirm = this.state.deleteZoneFormConfirm ? "confirm_add_parkings_show" : "confirm_add_parkings_hide";

        console.log(":LOCATION R: " + this.state.editServiceAreaData.editServiceAreaLatArray)
        return (
            <div className='service_areas_contain'>
                <div className={serviceAreaTable}>
                    <table className="service_areas_menu_filter">
                        <tbody>
                            <tr>
                                <td className='service_areas_leftside_menu'>
                                    <div>
                                        <p className="service_areas_title"><Trans>Service Areas</Trans></p>
                                    </div>
                                </td>
                                <td className='service_areas_rightside_menu'>
                                    <button className="btn_add_service_areas" type="button" onClick={this.handleOpenAddServiceAreaForm}><FaIcons.FaPlus /> <Trans>Add Service Area</Trans></button>
                                    <select className="fleets_select" onChange={this.selectFleetFilter}>
                                        <option value="all_fleet">All Fleets</option>
                                        {
                                            this.state.fleetName.map(
                                                (x) => <option selected={x === this.state.fleetFilterParam} value={x}>{x}</option>
                                            )
                                        }
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <td className='service_areas_leftside_menu'>
                                    <div>

                                    </div>
                                </td>
                                <td className='service_areas_rightside_menu'>
                                    <table className='service_areas_rightside_button'>
                                        <tr>
                                            <td><label style={{ marginRight: "10px" }}><Trans>Active</Trans> </label></td>
                                            <td><Switch onChange={this.handleActiveChange} checked={this.state.activeFilterState} /></td>
                                        </tr>
                                    </table>

                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div className='service_areas_table'>
                        <ServiceAreastablePage tableData={tableData} editServiceArea={this.editServiceAreaOpen} deleteZone={this.deleteZone}/>
                    </div>
                </div>
                {/*===================================================================================================================================================*/}
                <div className={addaServiceArea}>
                    <div className="addParkingsForm">
                        <div className="add_parkings_form_header"><b><Trans>Add Service Area</Trans></b></div>
                        <form onSubmit={this.handlelAddServiceAreaSubmit}>
                            <div className="parkings-form">
                                <div style={{ display: "flex" }}>
                                    <div className="form-group-left-side">
                                        <div className="form-group">
                                            <label><Trans>Fleet</Trans></label>
                                            <select name="serviceAreaFleet" onChange={this.getFleetLocationMap}>
                                                {this.state.fleetName.map((x) =>
                                                    <option value={x}>{x}</option>)}
                                            </select>
                                        </div>
                                        <div className="form-group">
                                            <label><Trans>Service Area Name</Trans></label>
                                            <input type="text" name="serviceAreaName" placeHolder="Enter Service Area Name" required />
                                        </div>
                                    </div>
                                    <div className="form-group-right-side">
                                        <div className="form-group">
                                            <label><Trans>Description</Trans></label>
                                            <input type="text" name="serviceAreaDescription" />
                                        </div>
                                        <div style={{ display: "none" }} className="form-group">
                                            <input type="text" name="area_lat" value={this.state.confirmAreaLat} />
                                        </div>
                                        <div style={{ display: "none" }} className="form-group">
                                            <input type="text" name="area_lng" value={this.state.confirmAreaLng} />
                                        </div>
                                    </div>
                                </div>
                                <div className={addServiceAreaFormFooter}>
                                    <button type="button" className="btn_cancel_adding" onClick={this.handleCancelAddServiceArea}>
                                        <Trans>Cancel</Trans>
                                    </button>
                                    <button type="submit" className="btn_submit_adding" >
                                        <Trans>Add Service Area</Trans>
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className="addParkingsMap">
                        <div className="fleetMap">
                            <AddServiceAreasMap refFeatureGroup={this.removePolygon} getAreaData={this._onServiceAreaCreate} editState={this.state.onEditState} position={addMapPosition} clickPosition={this.changeLocation} />
                            <div className={confirmUseArea}>
                                <i style={{ color: "red" }}> * Use this area for this service area ?</i> <button className="add_area_btn" onClick={this.removePolygonFunction}>No</button><button onClick={this.confirmPolygonFunction} className="add_area_btn">Yes</button>
                            </div>
                        </div>
                    </div>
                    <div className={addServiceAreaFormConfirm}>
                        <p className="add_parkings_confirm_title"><Trans>The service area</Trans> "{this.state.add_service_area_name}" (ID: {this.state.add_service_area_id}) <Trans>has been added recently </Trans> !</p>
                        <button className="add_fleet_confirm_button" type="button" onClick={this.handleConfirmAddServiceArea}>
                            OK
                        </button>
                    </div>
                </div>
                {/*===================================================================================================================================*/}
                <div className={editServiceArea}>
                    <div className="addParkingsForm">
                        <div className="add_parkings_form_header"><b><Trans>Edit Service Area</Trans></b></div>
                        <form onSubmit={this.handlelEditServiceAreaSubmit}>
                            <div className="parkings-form">
                                <div style={{ display: "flex" }}>
                                    <div className="form-group-left-side">
                                        <div className="form-group">
                                            <label><Trans>Fleet</Trans></label>
                                            <select name="editServiceAreaFleet">
                                                {this.state.fleetName.map((x) =>
                                                    <option selected={x === this.state.editServiceAreaData.editServiceAreaFleet} value={x}>{x}</option>)}
                                            </select>
                                        </div>
                                        <div className="form-group">
                                            <label><Trans>Restricted Zone Name</Trans></label>
                                            <input type="text" name="editServiceAreaName" defaultValue={this.state.editServiceAreaData.editServiceAreaName} required />
                                        </div>
                                    </div>
                                    <div className="form-group-right-side">
                                        <div className="form-group">
                                            <label><Trans>Description</Trans></label>
                                            <input type="text" name="editServiceAreaDescription" defaultValue={this.state.editServiceAreaData.editServiceAreaDescription} />
                                        </div>
                                        <div style={{ display: "none" }} className="form-group">
                                            <input type="text" name="edit_area_lat" value={this.state.confirmEditAreaLat} />
                                        </div>
                                        <div style={{ display: "none" }} className="form-group">
                                            <input type="text" name="edit_area_lng" value={this.state.confirmEditAreaLng} />
                                        </div>
                                        <div style={{ display: "none" }} className="form-group">
                                            <input type="text" name="edit_area_id" value={this.state.editServiceAreaData.editServiceAreaId} />
                                        </div>
                                    </div>
                                </div>
                                <div className={editServiceAreaFormFooter}>
                                    <button type="button" className="btn_cancel_adding" onClick={this.handleCancelEditServiceArea}>
                                        <Trans>Cancel</Trans>
                                    </button>
                                    <button type="submit" className="btn_submit_adding" >
                                        <Trans>Save Changes</Trans>
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className="addParkingsMap">
                        <div className="fleetMap">
                            <EditServiceAreaMap refFeatureGroup={this.removeEditPolygon} getAreaData={this._onCreateEdit} editState={this.state.onEditAreaDrawState} latArray={this.state.editServiceAreaData.editServiceAreaLatArray} lngArray={this.state.editServiceAreaData.editServiceAreaLngArray} />
                            <div className={confirmUseEditArea}>
                                <i style={{ color: "red" }}> * Use this area for this service area ?</i> <button className="add_area_btn" onClick={this.removeEditPolygonFunction}>No</button><button onClick={this.confirmEditPolygonFunction} className="add_area_btn">Yes</button>
                            </div>
                        </div>
                    </div>
                    <div className={editServiceAreaFormConfirm}>
                        <p className="add_parkings_confirm_title"><Trans>The service area</Trans> "{this.state.edit_zone_name}" ( ID: {this.state.edit_zone_id}) <Trans>has been edited recently </Trans> !</p>
                        <button className="add_fleet_confirm_button" type="button" onClick={this.handleConfirmEditServiceArea}>
                            OK
                        </button>
                    </div>
                </div>
                {/*===================================================================================================================================*/}
                <div className={deleteZoneFormConfirm}>
                    <p className="add_parkings_confirm_title"><Trans>The speed zone will be deleted !</Trans> !</p>
                    <button style={{backgroundColor:"gray",borderColor: "gray"}} className="add_fleet_confirm_button" type="button" onClick={this.deleteZonecancel}>
                        Cancle
                    </button>
                    <button style={{marginLeft: "10px"}}className="add_fleet_confirm_button" type="button" onClick={this.deleteZoneconfirm}>
                        OK
                    </button>
                </div>
            </div>
        )
    }
}
export default ServiceAreas;