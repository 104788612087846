import React from 'react';
import './Promos.css';
import * as AiIcons from 'react-icons/ai';
import * as FaIcons from 'react-icons/fa';
import { Trans } from 'react-i18next';
import PromostablePage from './Promos_table';
import PromosBatchestablePage from './Promos_batches_table';
class promos extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fleet_filter_param: "all_fleet",
            promo_type_param: "all_type",
            promo_batch_type_param: "all_type",
            fleet_filter: [],
            promotion_table: {
                id: [],
                name: [],
                type: [],
                event: [],
                price: [],
                date: [],
                description: [],
                usage_validity: [],
                fleet: [],
                status: [],
            },
            promotion_batch_table: {
                id: [],
                name: [],
                type: [],
                event: [],
                code: [],
                fleet: [],
                start_date: [],
                expired_date: [],
            },
        }
        this.changeFleetFilterPromo = this.changeFleetFilterPromo.bind(this);
        this.changeTypeFilterPromo = this.changeTypeFilterPromo.bind(this);
        this.selectPromosBatchType = this.selectPromosBatchType.bind(this);
    }
    async selectPromosBatchType(e) {
        const typeFilterParam = e.target.value;
        const formPromotiontData = new URLSearchParams();
        formPromotiontData.append('fleet_filter_param', this.state.fleet_filter_param);
        formPromotiontData.append('promo_batch_type_param', typeFilterParam);
        await fetch("https://admin.connect-ink.com/API/promotion/promotion_batches_table.php",
            {
                method: 'POST',
                headers: {
                    'Content-Type': "application/x-www-form-urlencoded;charset=UTF-8"
                },
                body: formPromotiontData.toString()
            }
        )
            .then(res => res.json())
            .then(
                (data) => {
                    this.setState({
                        promotion_batch_table: {
                            id: data.promo_batch_id,
                            name: data.promo_batch_name,
                            type: data.promo_batch_type,
                            event: data.promo_batch_event,
                            code: data.promo_batch_code,
                            fleet: data.promo_batch_fleet,
                            start_date: data.promo_batch_start_date,
                            expired_date: data.promo_batch_expired_date,
                        },
                        promo_batch_type_param: typeFilterParam,
                    })
                    console.log(data.promo_batch_start_date)
                })
    }
    async changeTypeFilterPromo(e) {
        const typeFilterParam = e.target.value;
        const formPromotiontData = new URLSearchParams();
        formPromotiontData.append('fleet_filter_param', this.state.fleet_filter_param);
        formPromotiontData.append('promo_type', typeFilterParam);
        await fetch("https://admin.connect-ink.com/API/promotion/promotion_table.php",
            {
                method: 'POST',
                headers: {
                    'Content-Type': "application/x-www-form-urlencoded;charset=UTF-8"
                },
                body: formPromotiontData.toString()
            }
        )
            .then(res => res.json())
            .then(
                (data) => {
                    this.setState({
                        promotion_table: {
                            id: data.promo_id,
                            name: data.promo_name,
                            type: data.promo_type,
                            event: data.promo_event,
                            price: data.promo_price,
                            date: data.promo_date,
                            description: data.promo_description,
                            usage_validity: data.usage_validity,
                            fleet: data.fleet,
                            status: data.status,
                        },
                        promo_type_param: typeFilterParam,
                    })
                })
    }
    async changeFleetFilterPromo(e) {
        const fleetFilterParam = e.target.value;
        const formPromotiontData = new URLSearchParams();
        formPromotiontData.append('fleet_filter_param', fleetFilterParam);
        formPromotiontData.append('promo_type', this.state.promo_type_param);
        await fetch("https://admin.connect-ink.com/API/promotion/promotion_table.php",
            {
                method: 'POST',
                headers: {
                    'Content-Type': "application/x-www-form-urlencoded;charset=UTF-8"
                },
                body: formPromotiontData.toString()
            }
        )
            .then(res => res.json())
            .then(
                (data) => {
                    this.setState({
                        promotion_table: {
                            id: data.promo_id,
                            name: data.promo_name,
                            type: data.promo_type,
                            event: data.promo_event,
                            price: data.promo_price,
                            date: data.promo_date,
                            description: data.promo_description,
                            usage_validity: data.usage_validity,
                            fleet: data.fleet,
                            status: data.status,
                        },
                        fleet_filter_param: fleetFilterParam,
                    })
                })
    }
    async componentDidMount() {
        const formPromotiontData = new URLSearchParams();
        formPromotiontData.append('fleet_filter_param', this.state.fleet_filter_param);
        formPromotiontData.append('promo_type', this.state.promo_type_param);
        formPromotiontData.append('promo_batch_type_param', this.state.promo_batch_type_param);
        await fetch("https://admin.connect-ink.com/API/promotion/promotion_table.php",
            {
                method: 'POST',
                headers: {
                    'Content-Type': "application/x-www-form-urlencoded;charset=UTF-8"
                },
                body: formPromotiontData.toString()
            }
        )
            .then(res => res.json())
            .then(
                (data) => {
                    this.setState({
                        promotion_table: {
                            id: data.promo_id,
                            name: data.promo_name,
                            type: data.promo_type,
                            event: data.promo_event,
                            price: data.promo_price,
                            date: data.promo_date,
                            description: data.promo_description,
                            usage_validity: data.usage_validity,
                            fleet: data.fleet,
                            status: data.status,
                        },
                        fleet_filter: data.fleet_name,
                    })
                })
        await fetch("https://admin.connect-ink.com/API/promotion/promotion_batches_table.php",
            {
                method: 'POST',
                headers: {
                    'Content-Type': "application/x-www-form-urlencoded;charset=UTF-8"
                },
                body: formPromotiontData.toString()
            }
        )
            .then(res => res.json())
            .then(
                (data) => {
                    this.setState({
                        promotion_batch_table: {
                            id: data.promo_batch_id,
                            name: data.promo_batch_name,
                            type: data.promo_batch_type,
                            event: data.promo_batch_event,
                            code: data.promo_batch_code,
                            fleet: data.promo_batch_fleet,
                            start_date: data.promo_batch_start_date,
                            expired_date: data.promo_batch_expired_date,
                        }
                    })
                })
    }
    render() {
        const promoTableData = [
            this.state.promotion_table.id,
            this.state.promotion_table.name,
            this.state.promotion_table.type,
            this.state.promotion_table.event,
            this.state.promotion_table.price,
            this.state.promotion_table.date,
            this.state.promotion_table.description,
            this.state.promotion_table.usage_validity,
            this.state.promotion_table.fleet,
            this.state.promotion_table.status,
        ];
        const promoBatchTableData = [
            this.state.promotion_batch_table.id,
            this.state.promotion_batch_table.name,
            this.state.promotion_batch_table.type,
            this.state.promotion_batch_table.event,
            this.state.promotion_batch_table.code,
            this.state.promotion_batch_table.fleet,
            this.state.promotion_batch_table.start_date,
            this.state.promotion_batch_table.expired_date,
        ];
        return (
            <div className='promos_contain'>
                <p className='promos_title'><Trans>Promos</Trans></p>
                <table className='promos_menu_bar_table'>
                    <tr>
                        <td className='promos_rightside_menu'>
                            <button className="btn_add_promos"><AiIcons.AiOutlinePlus /><Trans> Add Promos</Trans></button>
                            <select className="promos_fleets_select" onChange={this.changeFleetFilterPromo}>
                                <option value="all_fleet">All Zones</option>
                                {this.state.fleet_filter.map((m) => <option value={m}>{m}</option>)}
                            </select>
                            <select className="promos_select" onChange={this.changeTypeFilterPromo}>
                                <option value="all_type">All Promos</option>
                                <option value="free_tattoo">Free Tattoo</option>
                                <option value="wallet_credit">Wallet Credit</option>
                                <option value="percent_discount">Percent Discount</option>
                                <option value="flat_discount">Flat Discount</option>
                                <option value="refer_a_friend">Refer a friend</option>
                            </select>
                        </td>
                    </tr>
                </table>

                <div>

                </div>
                <div className='promos_table'>
                    <PromostablePage tableData={promoTableData} />
                </div>

                <p className='promos_batches_title'><Trans>Promos Batches</Trans></p>
                <table className='promos_menu_bar_table'>
                    <tr>
                        <td className='promos_rightside_menu'>
                            <button className="btn_add_promos"><AiIcons.AiOutlinePlus /><Trans> Add Promo Batch</Trans></button>
                            <select className="promos_select" onChange={this.selectPromosBatchType}>
                                <option value="all_type">All Promos</option>
                                <option value="running_promos">Running Promos</option>
                                <option value="expired_promos">Expired Promos</option>
                                <option value="upcoming_promos">Upcoming Promos</option>
                            </select>
                        </td>
                    </tr>
                </table>
                <div className='promos_batches_table'>
                    <PromosBatchestablePage tableData={promoBatchTableData} />
                </div>
            </div>
        );
    }
}

export default promos;