import React from 'react';
import { Line } from 'react-chartjs-2';
import { translate, Trans } from 'react-i18next';


class RidesChart extends React.Component {
  render() {
    const data = {
      labels: this.props.ridesLabel,
      datasets: [
        {
          label: 'Rides',
          backgroundColor: 'rgba(255,99,132,0.2)',
          borderColor: 'green',
          borderWidth: 5,
          hoverBackgroundColor: 'rgba(255,99,132,0.4)',
          hoverBorderColor: 'rgba(255,99,132,1)',
          data: this.props.ridesData
        }
      ]
    };
    const option = {
      plugins: {
        legend: {
          display: false,
        }
      }
    }
    return (
      <div style={{ width: "100%", color: "black" }}>
        <h4 style={{ textAlign: "left" }}><Trans>Order over time</Trans></h4>
        <Line
          data={data}
          options={option}
        />
      </div>
    );
  }
}

export default RidesChart;