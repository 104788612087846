import React from 'react';
import './PaymentGateway.css';
import * as FaIcons from 'react-icons/fa';
import { Trans, useTranslation } from 'react-i18next';
import PaymentGatewayTable from './PaymentGatewayTable';
class PaymentGateway extends React.Component {
       constructor(props) {
            super(props);
            this.state = {
                All: true,
                Succeeded:false,
                Refunded: false,
                Uncaptured: false,
                Failed: false,
                charges_info: [],
                charges_count: 0,
            }
        this.clickAll = this.clickAll.bind(this);
        this.clickSucceeded = this.clickSucceeded.bind(this);
        this.clickRefunded = this.clickRefunded.bind(this);
        this.clickUncaptured = this.clickUncaptured.bind(this);
        this.clickFailed = this.clickFailed.bind(this);
       }
       
       async clickAll(){
            this.setState({
                All: true,
                Succeeded:false,
                Refunded: false,
                Uncaptured: false,
                Failed: false
            });
            const formData = new URLSearchParams();
            formData.append('zone', 'all_fleets');
            formData.append('payment_status', 'all');
            await fetch("https://api.connect-ink.com/API/TATU/API/PAYMENT_GATEWAY/Credit_Card/VISA/stripe_list_all_payments.php",
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': "application/x-www-form-urlencoded;charset=UTF-8"
                    },
                    body: formData.toString()
                }
            )
            .then(res => res.json())
            .then(
                (data) => {
                    this.setState({
                        charges_info: data.result,
                        charges_count: data.count,
                    })
                })
            
        }
        async clickSucceeded(){
            this.setState({
                All: false,
                Succeeded:true,
                Refunded: false,
                Uncaptured: false,
                Failed: false
            });
            
            const formData = new URLSearchParams();
            formData.append('zone', 'all_fleets');
            formData.append('payment_status', 'succeeded');
            await fetch("https://api.connect-ink.com/API/TATU/API/PAYMENT_GATEWAY/Credit_Card/VISA/stripe_list_all_payments.php",
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': "application/x-www-form-urlencoded;charset=UTF-8"
                    },
                    body: formData.toString()
                }
            )
            .then(res => res.json())
            .then(
                (data) => {
                    this.setState({
                        charges_info: data.result,
                        charges_count: data.count,
                    })
                })
            
        }
        async clickRefunded(){
            this.setState({
                All: false,
                Succeeded:false,
                Refunded: true,
                Uncaptured: false,
                Failed: false
            });
            
             const formData = new URLSearchParams();
            formData.append('zone', 'all_fleets');
            formData.append('payment_status', 'refunded');
            await fetch("https://api.connect-ink.com/API/TATU/API/PAYMENT_GATEWAY/Credit_Card/VISA/stripe_list_all_payments.php",
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': "application/x-www-form-urlencoded;charset=UTF-8"
                    },
                    body: formData.toString()
                }
            )
            .then(res => res.json())
            .then(
                (data) => {
                    this.setState({
                        charges_info: data.result,
                        charges_count: data.count,
                    })
                })
        }
        async clickUncaptured(){
            this.setState({
                All: false,
                Succeeded:false,
                Refunded: false,
                Uncaptured: true,
                Failed: false
            });
            
             const formData = new URLSearchParams();
            formData.append('zone', 'all_fleets');
            formData.append('payment_status', 'uncaptured');
            await fetch("https://api.connect-ink.com/API/TATU/API/PAYMENT_GATEWAY/Credit_Card/VISA/stripe_list_all_payments.php",
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': "application/x-www-form-urlencoded;charset=UTF-8"
                    },
                    body: formData.toString()
                }
            )
            .then(res => res.json())
            .then(
                (data) => {
                    this.setState({
                        charges_info: data.result,
                        charges_count: data.count,
                    })
                })
        }
        async clickFailed(){
            this.setState({
                All: false,
                Succeeded:false,
                Refunded: false,
                Uncaptured: false,
                Failed: true
            });
            
             const formData = new URLSearchParams();
            formData.append('zone', 'all_fleets');
            formData.append('payment_status', 'failed');
            await fetch("https://api.connect-ink.com/API/TATU/API/PAYMENT_GATEWAY/Credit_Card/VISA/stripe_list_all_payments.php",
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': "application/x-www-form-urlencoded;charset=UTF-8"
                    },
                    body: formData.toString()
                }
            )
            .then(res => res.json())
            .then(
                (data) => {
                    this.setState({
                        charges_info: data.result,
                        charges_count: data.count,
                    })
                })
            
            
        }
        async componentDidMount() {
            const formData = new URLSearchParams();
            formData.append('zone', 'all_fleets');
            formData.append('payment_status', 'all');
            await fetch("https://api.connect-ink.com/API/TATU/API/PAYMENT_GATEWAY/Credit_Card/VISA/stripe_list_all_payments.php",
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': "application/x-www-form-urlencoded;charset=UTF-8"
                    },
                    body: formData.toString()
                }
            )
            .then(res => res.json())
            .then(
                (data) => {
                    this.setState({
                        charges_info: data.result,
                        charges_count: data.count,
                    })
                })
        }
    render() {
        let payment_gateway_all_filter_button_el = this.state.All ? "payment_gateway_all_filter_button_el_click" : "payment_gateway_all_filter_button_el";
        let payment_gateway_succeeded_filter_button_el = this.state.Succeeded ? "payment_gateway_succeeded_filter_button_el_click" : "payment_gateway_succeeded_filter_button_el";
        let payment_gateway_refund_filter_button_el = this.state.Refunded ? "payment_gateway_refund_filter_button_el_click" : "payment_gateway_refund_filter_button_el";
        let payment_gateway_uncaptured_filter_button_el = this.state.Uncaptured ? "payment_gateway_uncaptured_filter_button_el_click" : "payment_gateway_uncaptured_filter_button_el";
        let payment_gateway_failed_filter_button_el = this.state.Failed ? "payment_gateway_failed_filter_button_el_click" : "payment_gateway_failed_filter_button_el";
        const chargeInfoData = this.state.charges_info;
        const countInfoData = this.state.charges_count;
        
        
        return (
            <div className='payment_gateway_contain'>
                <p className='payment_gateway_title'><Trans>Payment Gateway</Trans></p>
                <table className='payment_gateway_table'>
                    <tr className= "payment_gateway_filter_button">
                        <td>
                        <button onClick={this.clickAll} className= {payment_gateway_all_filter_button_el}>All</button>
                        <button onClick={this.clickSucceeded} className= {payment_gateway_succeeded_filter_button_el}>Succeeded</button>
                        <button onClick={this.clickRefunded} className= {payment_gateway_refund_filter_button_el}>Refunded</button>
                        <button onClick={this.clickUncaptured} className= {payment_gateway_uncaptured_filter_button_el}>Uncaptured</button>
                        <button onClick={this.clickFailed} className= {payment_gateway_failed_filter_button_el}>Failed</button>
                        </td>
                    </tr>
                </table>    
                <div>
                    <PaymentGatewayTable tableData ={chargeInfoData} countInfo ={countInfoData} />
                    {/*<table className='payment_gateway_table'>
                    <tbody>
                        <tr className='payment_gateway_table_th'>
                            <td><b><Trans>ID</Trans></b></td>
                            <td><b><Trans>PAYMENT GATEWAY NAME</Trans></b></td>
                            <td><b><Trans>AMOUNT</Trans></b></td>
                            <td><b><Trans>DESCRIPTION</Trans></b></td>
                            <td><b><Trans>CUSTOMER</Trans></b></td>
                            <td><b><Trans>DATE</Trans></b></td>
                        </tr>
                        <tr className='payment_gateway_table_tr'>
                            <td>1</td>
                            <td>stripe</td>
                            <td>US$9.99</td>
                            <td>Example charge</td>
                            <td>info@connect-ink.com</td>
                            <td>30-05-2023 13:14:27</td>
                        </tr>
                    </tbody>
                </table>
                <table className='payment_gateway_pagination'>
                    <tr>
                        <td className='total_showing'>Showing 0 to 0 of 0 entries</td>
                        <td className='page_btn_div'>
                            <div>
                                <button className="payments_btn_previous" type="button">Previous</button>
                                <button className="payments_btn_next" type="button">Next</button>
                            </div>
                        </td>
                    </tr>
                </table>*/}
                </div>
            </div>
        );
    }
}

export default PaymentGateway;