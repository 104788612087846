import React from 'react';
import './Riders.css'
import { CSVLink } from "react-csv";
import * as FaIcons from 'react-icons/fa';

class RidersTableCSV extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
        this.csvLinkEl = React.createRef();
    }
    render() {
        var currentdate = new Date();  
        var fileName = "Riders_Table_" + currentdate.getDate() + "_" + (currentdate.getMonth()+1) + "_" + currentdate.getFullYear() + "_" + currentdate.getHours() + "_" + currentdate.getMinutes() + "_" + currentdate.getSeconds() +".csv";
        const rowData = this.props.tableData;
        const rowDatRidesId = rowData[0];
        const rowDataRidesName = rowData[1];
        const rowDataBalance = rowData[2];
        const rowDataEmail = rowData[3];
        const rowDataCountryCode = rowData[4];
        const rowDataStatus = rowData[5];
        const rowDataPhoneNumber = rowData[6];
        const rowDataRegisterDate = rowData[7];
        const rowDataLastLogin = rowData[8];
        const rowDataAppVersion = rowData[9];
        const rowDataPlatform = rowData[10];
        const rowDataOsVersion = rowData[11];
        const rowDataRfid = rowData[12];
        
        const numRows = rowDatRidesId.length;
        const csvData = [];
        for (var i = 0; i < numRows; i++) {
            const csvDataElement = {
                user_id: rowDatRidesId[i],
                user_name: rowDataRidesName[i],
                balance: rowDataBalance[i],
                email: rowDataEmail[i],
                country_code: rowDataCountryCode[i],
                status: rowDataStatus[i],
                phone_number: rowDataPhoneNumber[i],
                register_date: rowDataRegisterDate[i],
                last_login: rowDataLastLogin[i],
                app_version: rowDataAppVersion[i],
                platform: rowDataPlatform[i],
                os_version: rowDataOsVersion[i],
                rfid: rowDataRfid[i],
            }
            csvData.push(csvDataElement);
        }
        const headers = [
            { label: "ID", key: "user_id" },
            { label: "User Name", key: "user_name" },
            { label: "Balance", key: "balance" },
            { label: "Email", key: "email" },
            { label: "Country Code", key: "country_code" },
            { label: "Status", key: "status" },
            { label: "Phone Number", key: "phone_number" },
            { label: "Register Date", key: "register_date" },
            { label: "Last Login", key: "last_login" },
            { label: "App Version", key: "app_version" },
            { label: "Platform", key: "platform" },
            { label: "Os Version", key: "os_version" },
            { label: "RFID", key: "rfid" },
        ];
        const data_csv = csvData;

        return (
            <div>
                <CSVLink
                    headers={headers}
                    filename={fileName}
                    data={data_csv}
                    ref={this.csvLinkEl}
                >
                    <div style={{color:"white"}}><FaIcons.FaFileCsv />CSV</div>
                </CSVLink>
            </div>
        );
    }


}

export default RidersTableCSV;