import "./Map.css";
import React, { Component } from "react";
import { MapContainer, Marker, Popup, TileLayer, useMap, useMapEvents } from "react-leaflet";
import L from "leaflet";
import { useState } from 'react';
import { Icon } from 'leaflet';
import onRideMarker from "../../img/marker2.png";
import forcedStopMarker from "../../img/marker4.png";
import unlockFailMarker from "../../img/marker2.png";
import BikeGeotenceAlertMarker from "../../img/marker5.png";
import unlockWithoutRideMarker from "../../img/marker6.png";
import maintenanceMarker from "../../img/marker9.png";
import availableMarker from "../../img/marker.png";
import unavailableMarker from "../../img/marker14.png";
import serviceOnMarker from "../../img/marker10.png";
import serviceOffMarker from "../../img/marker13.png";
import missingMarker from "../../img/marker11.png";
import StolenMarker from "../../img/marker12.png";
import batteryCriticalMarker from "../../img/marker8.png";
import noDataMarker from "../../img/nodata.png";

function SetTimeOut() {
  const map = useMap();
  setTimeout(() => {
    map.invalidateSize();
  }, 250);
  return null;
}

export default class SearchMapLeaflet extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lat:0,
      lng:0,
    };
  }
  componentDidMount() {
    this.setState({
      lat: this.props.position[0],
      lng: this.props.position[1],
    })
  }

  componentWillReceiveProps(nextProps) {
    if (this.state.lat == nextProps.position[0] && this.state.lng == nextProps.position[1]) {
    }
    else {
      this.setState({
        lat: nextProps.position[0],
        lng: nextProps.position[1],
      })
      const { map } = this.state;
      if (map) {
        map.flyTo(nextProps.position, 13);
      }
    }
  }
  render() {

    const dataInfo = this.props.searchVehicleInfo;
    const location = [dataInfo[3], dataInfo[4]];
    const connection = dataInfo[2];
    const vehicleId = dataInfo[0];
    const vehicleType = dataInfo[5];
    console.log("MAP LEAFLET: " + vehicleId);
    let userTypeName = "User";
    const vehicleLengthArray = vehicleId.length;
    var markerIconPng = [];
    for (var j = 0; j < vehicleLengthArray; j++) {
      if(vehicleType[j] == "artist"){
            userTypeName = "Artist";
            if(connection[j] == "enable"){
                markerIconPng[j] = unlockFailMarker;
            }else {
                markerIconPng[j] = unavailableMarker;
            }
        } else if(vehicleType[j] == "client"){
            userTypeName = "Client";
            if(connection[j] == "Verify"){
                markerIconPng[j] = serviceOnMarker;
            }else{
                markerIconPng[j] = serviceOffMarker;
            }
        }
    }
    const rowsData = [];
        for (var i = 0; i < vehicleLengthArray; i++) {
        rowsData[i] = [location[i], vehicleId[i], markerIconPng[i], userTypeName[i]];
    }
    return (
      <MapContainer style={{ height: "70vh" }} center={[14.482843489402825,108.29555639455745]} zoom={13} whenCreated={map => this.setState({ map })}>
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        />
        {rowsData.map((m,index) => {
          return (
            <Marker position={m[0]} icon={new Icon({ iconUrl: m[2], iconSize: [24, 36], iconAnchor: [12, 41] })}>
              <Popup>
                {m[3]}
                <br />
                {m[2]}
              </Popup>
            </Marker>
          )
        }
        )}
        <SetTimeOut />
      </MapContainer>

    );
  }
}
