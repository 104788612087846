import React from 'react';
import './Rides.css'
import { CSVLink } from "react-csv";
import * as FaIcons from 'react-icons/fa';

class RidesCSV extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
        this.csvLinkEl = React.createRef();
    }
    render() {
        const rowData = this.props.tableData;
        const rowDataRideId = rowData[0];
        const rowDataRiderPhoneNumber = rowData[1];
        const rowDataRiderId = rowData[2];
        const rowDataRiderName = rowData[3];
        const rowDataVehicleId = rowData[4];
        const rowDataVehicleQrCode = rowData[5];
        const rowDataVehicleType = rowData[6];
        const rowDataRideFleet = rowData[7];
        const rowDataRideAmount = rowData[8];
        const rowDataRideStartPoint = rowData[9];
        const rowDataRideEndPoint = rowData[10];
        const rowDataRideDistance = rowData[11];
        const rowDataDuration = rowData[12];
        const rowDataPauseDuration = rowData[13];
        const rowDataRideDate = rowData[14];
        const rowDataRideRating = rowData[15];
        const rowDataRideForceStop = rowData[16];

        var currentdate = new Date();
        var fileName = "Complete_Rides_" + rowDataRideId + "_Report_" + currentdate.getDate() + "_" + (currentdate.getMonth() + 1) + "_" + currentdate.getFullYear() + "_" + currentdate.getHours() + "_" + currentdate.getMinutes() + "_" + currentdate.getSeconds() + ".csv";

        const csvData = [{
            ride_id: rowDataRideId,
            phone_number: rowDataRiderPhoneNumber,
            user_id: rowDataRiderId,
            user_name: rowDataRiderName,
            vehicle_id: rowDataVehicleId,
            qr_code: rowDataVehicleQrCode,
            vehicle_type: rowDataVehicleType,
            ride_fleet: rowDataRideFleet,
            amount: rowDataRideAmount,
            start_point: rowDataRideStartPoint,
            end_point: rowDataRideEndPoint,
            distance: rowDataRideDistance,
            duration: rowDataDuration,
            pause_duration: rowDataPauseDuration,
            ride_date: rowDataRideDate,
            rating: rowDataRideRating,
            force_stop: rowDataRideForceStop,
        }];
        console.log("AAABB: "+ csvData);
        const headers = [
            { label: "ID", key: "ride_id" },
            { label: "Phone number", key: "phone_number" },
            { label: "user ID", key: "user_id" },
            { label: "User Name", key: "user_name" },
            { label: "Vehicle ID", key: "vehicle_id" },
            { label: "QR Code", key: "qr_code" },
            { label: "Vehicle Type", key: "vehicle_type" },
            { label: "Fleet", key: "ride_fleet" },
            { label: "Amount", key: "amount" },
            { label: "Start Point", key: "start_point" },
            { label: "End Point", key: "end_point" },
            { label: "Distance", key: "distance" },
            { label: "Duration", key: "duration" },
            { label: "Pause Duration", key: "pause_duration" },
            { label: "Ride Date", key: "ride_date" },
            { label: "Rating", key: "rating" },
            { label: "Force Stop", key: "force_stop" }
        ];
        const data_csv = csvData;

        return (
            <div>
                <CSVLink
                    headers={headers}
                    filename={fileName}
                    data={data_csv}
                    ref={this.csvLinkEl}
                >
                   <div style={{fontSize: '0.75vw', color: "white"}}><FaIcons.FaFileCsv /> CSV</div>
                </CSVLink>
            </div>
        );
    }


}

export default RidesCSV;