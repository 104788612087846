import "./Map.css";
import React, { Component } from "react";
import { MapContainer, Marker, Popup, TileLayer, useMap } from "react-leaflet";
import 'leaflet/dist/leaflet.css';
import markerIconPng from "../../img/marker.png";
import {Icon} from 'leaflet';

export default class HistoryMapLeaflet extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lat: 15.748576209260989,
      lng: 30.11316716113033,
      zoom: 2,
      currentPos: null
    };
  }
  render() {
    const dataVehicle = this.props.dataVehicleHistory;
    const position = [parseFloat(dataVehicle[0]), parseFloat(dataVehicle[1])];
    console.log("History Map: " + position );
    return (
      <MapContainer style={{ height: "60vh" }} zoom={14}>
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        />
        <Marker draggable={false} position={position} icon={new Icon({ iconUrl: markerIconPng, iconSize: [25, 41], iconAnchor: [12, 41] })}/>
        {/*<Marker position={position}></Marker>*/}
        {/*<Marker position={position}>
          <Popup>
            A pretty CSS3 popup.
            <br />
            Easily customizable.
          </Popup>
          </Marker>*/}
        <ChangeMapView coords={position}/>
      </MapContainer>
    );
  }
}

function ChangeMapView({ coords }) {
  const map = useMap();
  setTimeout(() => {
    map.invalidateSize();
  }, 250);
  map.setView(coords, 14);
  return null;
}

