import React from "react";
import { Tab, Tabs as TabsComponent, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import WalletDebit from './WalletDebit';
import WalletCredit from './WalletCredit';
import UserDeposite from './UserDeposite';
import PassRevenue from './PassRevenue';
import { Trans, useTranslation } from 'react-i18next';

const PaymentsTabs = () => (
  <TabsComponent>
    <TabList>

        <Tab key={1}>{<Trans>User Deposite</Trans>}</Tab>
        <Tab key={2}>{<Trans>User Refund</Trans>}</Tab>
    </TabList>
    
    <TabPanel key={1}><UserDeposite/></TabPanel>
    <TabPanel key={2}><PassRevenue/></TabPanel>
    
  </TabsComponent>
);

export default PaymentsTabs;
