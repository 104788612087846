import React from 'react';
import './Tickets.css';
import * as AiIcons from 'react-icons/ai';
import * as FaIcons from 'react-icons/fa';
import TicketsTablePage from './Tickets_table';
import IssuesTablePage from './Issues_table';

class Tickets extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fleet_filter_param: 'all_fleet',
            date_filter_param: 'all_date',
            status_filter_param: 'all_tickets',
            fleet_filter: [],
            ticketTable: true,
            ticket_table: {
                id: [],
                phone: [],
                email: [],
                rider: [],
                vehicle_id: [],
                vehicle_qr: [],
                fleet: [],
                date: [],
                assign_to: [],
                subject: [],
                status: [],
            },
            issue_table: {
                id: [],
                rider: [],
                phone: [],
                email: [],
                contain: [],
                date: [],
                fleet: [],
            },
            add_ticket_from_issue_from: false,
            add_ticket_from_issue: {
                id: "",
                rider: "",
                phone: "",
                email: "",
                contain: "",
                date: "",
                fleet: "",
            }

        }
        this.ticketFilterSelect = this.ticketFilterSelect.bind(this);
        this.addTicketFromIssueButton = this.addTicketFromIssueButton.bind(this);
        this.cancelAddTicketFromIssue = this.cancelAddTicketFromIssue.bind(this);
        this.getTicketIssue = this.getTicketIssue.bind(this);
        this.assignTicketIssue = this.assignTicketIssue.bind(this);
        this.completeTicketIssue = this.completeTicketIssue.bind(this);
    }
    async completeTicketIssue(e){
        console.log(e.target.value);
        const formTicketData = new URLSearchParams();
        formTicketData.append('fleet_filter_param', this.state.fleet_filter_param);
        formTicketData.append('date_filter_param', this.state.date_filter_param);
        formTicketData.append('status_filter_param', this.state.status_filter_param);
        formTicketData.append('ticket_id', e.target.value);
        await fetch("https://admin.connect-ink.com/API/ticket/ticket_complete_api.php",
            {
                method: 'POST',
                headers: {
                    'Content-Type': "application/x-www-form-urlencoded;charset=UTF-8"
                },
                body: formTicketData.toString()
            }
        )
            .then(res => res.json())
            .then(
                (data) => {
                    this.setState({
                        ticket_table: {
                            id: data.id,
                            phone: data.phone,
                            email: data.email,
                            rider: data.rider,
                            vehicle_id: data.vehicle_id,
                            vehicle_qr: data.vehicle_qr,
                            fleet: data.fleet,
                            date: data.date,
                            assign_to: data.assign_to,
                            subject: data.subject,
                            status: data.status,
                        },
                    })
                })
    }
    async assignTicketIssue(e) {
        console.log(e.target.value);
        const formTicketData = new URLSearchParams();
        formTicketData.append('fleet_filter_param', this.state.fleet_filter_param);
        formTicketData.append('date_filter_param', this.state.date_filter_param);
        formTicketData.append('status_filter_param', this.state.status_filter_param);
        formTicketData.append('ticket_id', e.target.value);
        await fetch("https://admin.connect-ink.com/API/ticket/ticket_assign_api.php",
            {
                method: 'POST',
                headers: {
                    'Content-Type': "application/x-www-form-urlencoded;charset=UTF-8"
                },
                body: formTicketData.toString()
            }
        )
            .then(res => res.json())
            .then(
                (data) => {
                    this.setState({
                        ticket_table: {
                            id: data.id,
                            phone: data.phone,
                            email: data.email,
                            rider: data.rider,
                            vehicle_id: data.vehicle_id,
                            vehicle_qr: data.vehicle_qr,
                            fleet: data.fleet,
                            date: data.date,
                            assign_to: data.assign_to,
                            subject: data.subject,
                            status: data.status,
                        },
                    })
                })
    }
    async getTicketIssue(e) {
        console.log(e.target.value);
        const formTicketData = new URLSearchParams();
        formTicketData.append('fleet_filter_param', this.state.fleet_filter_param);
        formTicketData.append('date_filter_param', this.state.date_filter_param);
        formTicketData.append('status_filter_param', this.state.status_filter_param);
        formTicketData.append('ticket_id', e.target.value);
        await fetch("https://admin.connect-ink.com/API/ticket/ticket_get_api.php",
            {
                method: 'POST',
                headers: {
                    'Content-Type': "application/x-www-form-urlencoded;charset=UTF-8"
                },
                body: formTicketData.toString()
            }
        )
            .then(res => res.json())
            .then(
                (data) => {
                    this.setState({
                        ticket_table: {
                            id: data.id,
                            phone: data.phone,
                            email: data.email,
                            rider: data.rider,
                            vehicle_id: data.vehicle_id,
                            vehicle_qr: data.vehicle_qr,
                            fleet: data.fleet,
                            date: data.date,
                            assign_to: data.assign_to,
                            subject: data.subject,
                            status: data.status,
                        },
                    })
                })

    }
    cancelAddTicketFromIssue() {
        this.setState({
            ticketTable: true,
            add_ticket_from_issue_from: false,
        })
    }
    addTicketFromIssueButton(e) {
        const issueInfo = e.currentTarget.value;
        const issueArray = issueInfo.split("#");
        const issueId = issueArray[0];
        const issueRider = issueArray[1];
        const issuePhone = issueArray[2];
        const issueEmail = issueArray[3];
        const issueContain = issueArray[4];
        const issueDate = issueArray[5];
        const issueFleet = issueArray[6];
        this.setState({
            add_ticket_from_issue_from: true,
            ticketTable: false,
            add_ticket_from_issue: {
                id: issueId,
                rider: issueRider,
                phone: issuePhone,
                email: issueEmail,
                contain: issueContain,
                date: issueDate,
                fleet: issueFleet,
            }
        })
    }
    async ticketFilterSelect(e) {
        const ticketFilterParam = e.target.value;
        console.log(ticketFilterParam);
        const formTicketData = new URLSearchParams();
        formTicketData.append('fleet_filter_param', this.state.fleet_filter_param);
        formTicketData.append('date_filter_param', this.state.date_filter_param);
        formTicketData.append('status_filter_param', ticketFilterParam);
        await fetch("https://admin.connect-ink.com/API/ticket/ticket_table.php",
            {
                method: 'POST',
                headers: {
                    'Content-Type': "application/x-www-form-urlencoded;charset=UTF-8"
                },
                body: formTicketData.toString()
            }
        )
            .then(res => res.json())
            .then(
                (data) => {
                    this.setState({
                        ticket_table: {
                            id: data.id,
                            phone: data.phone,
                            email: data.email,
                            rider: data.rider,
                            vehicle_id: data.vehicle_id,
                            vehicle_qr: data.vehicle_qr,
                            fleet: data.fleet,
                            date: data.date,
                            assign_to: data.assign_to,
                            subject: data.subject,
                            status: data.status,
                        },
                        status_filter_param: ticketFilterParam,
                    })
                })
    }
    async fleetFilterSelect(e) {
        const fleetFilterParam = e.target.value;
        console.log(fleetFilterParam);
        const formTicketData = new URLSearchParams();
        formTicketData.append('fleet_filter_param', fleetFilterParam);
        formTicketData.append('date_filter_param', this.state.date_filter_param);
        formTicketData.append('status_filter_param', this.state.status_filter_param);
        await fetch("https://admin.connect-ink.com/API/ticket/ticket_table.php",
            {
                method: 'POST',
                headers: {
                    'Content-Type': "application/x-www-form-urlencoded;charset=UTF-8"
                },
                body: formTicketData.toString()
            }
        )
            .then(res => res.json())
            .then(
                (data) => {
                    this.setState({
                        ticket_table: {
                            id: data.id,
                            phone: data.phone,
                            email: data.email,
                            rider: data.rider,
                            vehicle_id: data.vehicle_id,
                            vehicle_qr: data.vehicle_qr,
                            fleet: data.fleet,
                            date: data.date,
                            assign_to: data.assign_to,
                            subject: data.subject,
                            status: data.status,
                        },
                        fleet_filter_param: fleetFilterParam,
                    })
                })
    }
    async componentDidMount() {
        const formTicketData = new URLSearchParams();
        formTicketData.append('fleet_filter_param', this.state.fleet_filter_param);
        formTicketData.append('date_filter_param', this.state.date_filter_param);
        formTicketData.append('status_filter_param', this.state.status_filter_param);
        await fetch("https://admin.connect-ink.com/API/ticket/ticket_table.php",
            {
                method: 'POST',
                headers: {
                    'Content-Type': "application/x-www-form-urlencoded;charset=UTF-8"
                },
                body: formTicketData.toString()
            }
        )
            .then(res => res.json())
            .then(
                (data) => {
                    this.setState({
                        ticket_table: {
                            id: data.id,
                            phone: data.phone,
                            email: data.email,
                            rider: data.rider,
                            vehicle_id: data.vehicle_id,
                            vehicle_qr: data.vehicle_qr,
                            fleet: data.fleet,
                            date: data.date,
                            assign_to: data.assign_to,
                            subject: data.subject,
                            status: data.status,
                        },
                        issue_table: {
                            id: data.issue_id,
                            rider: data.issue_user,
                            phone: data.issue_phone,
                            email: data.issue_email,
                            contain: data.issue_contain,
                            date: data.issue_date,
                            fleet: data.issue_fleet,
                        },
                        fleet_filter: data.fleet_name,
                    })
                })
    }
    render() {
        let add_ticket_from_issue_from = this.state.add_ticket_from_issue_from ? "show_add_ticket_form" : "hide_add_ticket_form";
        let ticketTable = this.state.ticketTable ? "show_ticket_table" : "hide_ticket_table";
        //Setup to display ticket status filter button
        if (this.state.status_filter_param == "all_tickets") {
            var all_ticket_button = "all_ticket_select";
            var pending_ticket_button = "pending_ticket_unselect";
            var in_progress_ticket_button = "in_progress_ticket_unselect";
        } else if (this.state.status_filter_param == "Pending") {
            all_ticket_button = "all_ticket_unselect";
            pending_ticket_button = "pending_ticket_select";
            in_progress_ticket_button = "in_progress_ticket_unselect";
        } else if (this.state.status_filter_param == "In Progress") {
            all_ticket_button = "all_ticket_unselect";
            pending_ticket_button = "pending_ticket_unselect";
            in_progress_ticket_button = "in_progress_ticket_select";
        }
        else if (this.state.status_filter_param == "Done") {
            all_ticket_button = "all_ticket_unselect";
            pending_ticket_button = "pending_ticket_unselect";
            in_progress_ticket_button = "done_ticket_select ";
        }
        const table_data = [
            this.state.ticket_table.id,
            this.state.ticket_table.phone,
            this.state.ticket_table.email,
            this.state.ticket_table.rider,
            this.state.ticket_table.vehicle_id,
            this.state.ticket_table.vehicle_qr,
            this.state.ticket_table.fleet,
            this.state.ticket_table.date,
            this.state.ticket_table.assign_to,
            this.state.ticket_table.subject,
            this.state.ticket_table.status,
        ];
        const issue_table_data = [
            this.state.issue_table.id,
            this.state.issue_table.rider,
            this.state.issue_table.phone,
            this.state.issue_table.email,
            this.state.issue_table.contain,
            this.state.issue_table.date,
            this.state.issue_table.fleet,
        ]
        return (
            <div className='tickets_contain' >
                <p className='tickets_title'>Tickets</p>
                <div className={ticketTable}>
                    <table className='tickets_tool_table'>
                        <tr>
                            <td className='tickets_rightside_menu'>
                                <button className="btn_csv_tickets"><FaIcons.FaFileCsv /> CSV</button>
                                <select className="tickets_filter">
                                    <option valua="all_date">Filter</option>
                                    <option value="today">Today</option>
                                    <option value="yesterday">Yesterday</option>
                                    <option value="last_7_day">Last 7 Days</option>
                                    <option value="last_30_day">Last 30 Days</option>
                                    <option value="this_month">This Month</option>
                                    <option value="last_month">Last Month</option>
                                </select>
                                <button className="btn_add_tickets"><AiIcons.AiOutlinePlus /> Add tickets</button>
                                <select className="tickets_fleets_select" onChange={this.fleetFilterSelect.bind(this)}>
                                    <option value='all_fleet'>All Zones</option>
                                    {this.state.fleet_filter.map((m) => <option value={m}>{m}</option>)}
                                </select>
                            </td>
                        </tr>
                    </table>
                    <div className="ticket_status_filter">
                        <button className="all_ticket_filter" value="all_tickets" onClick={this.ticketFilterSelect}>All Tickets</button>
                        <div className={all_ticket_button} />
                        <button className="pending_ticket_filter" value="Pending" onClick={this.ticketFilterSelect}>Pending</button>
                        <div className={pending_ticket_button} />
                        <button className="in_progress_ticket_filter" value="In Progress" onClick={this.ticketFilterSelect}>In Progress</button>
                        <div className={in_progress_ticket_button} />
                        <button className="done_ticket_filter" value="Done" onClick={this.ticketFilterSelect}>Done</button>

                    </div>
                    <div className='tickets_table'>
                        <TicketsTablePage tableData={table_data} getTicketIssue={this.getTicketIssue} assignTicketIssue={this.assignTicketIssue} completeTicketIssue={this.completeTicketIssue} />
                    </div>

                </div>
                {/*<div className={add_ticket_from_issue_from}>
                    <form onSubmit={this.addTicketFromIssue}>
                        <div className='add_ticker_from_issue_left'>
                            <label>Rider</label>
                            <input value={this.state.add_ticket_from_issue.rider}></input>
                            <label>Phone</label>
                            <input value={this.state.add_ticket_from_issue.phone}></input>
                            <label>Email</label>
                            <input value={this.state.add_ticket_from_issue.email}></input>
                            <label>Date</label>
                            <input value={this.state.add_ticket_from_issue.date}></input>

                        </div>
                        <div className='add_ticker_from_issue_right'>

                        </div>
                        <button type="button" onClick={this.cancelAddTicketFromIssue}>Cancel</button> 
                        <button type="submit">Add Ticket</button>
                    </form>

                        </div>*/}
            </div>
        );
    }
}
export default Tickets;