import React from 'react';
import { MDBDataTable } from 'mdbreact';
import './Role_list.css';
import { Trans } from 'react-i18next';

class RoletablePage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            editFormState: false,
            editFormConfirmState: false,
            editRoleId: null,
            editRoleName: null,
            editRoleLevel: null,
            edit_role_form_footer: true
        }
    }
    render() {

        let editForm = this.state.editFormState ? "showEditRoleForm" : "hideEditRoleForm";
        let editRoleFormFooter = this.state.edit_role_form_footer ? "editFormFooter" : "editFormFooter_hide";
        let editRoleConfirm = this.state.editFormConfirmState ? "edit_role_show" : "edit_role_hide";
        const rowsData = [];
        const rowData = this.props.dataTable;
        if (rowData[0] === undefined) {
            const valueindex = {
                role_name: "-",
                role_level: "-",
                action: "-",
            }
        } else{              
            const rowDataRolePermission = rowData[0];
            const rowDataRoleName = rowData[1];
            const rowDataRoleId = rowData[2];
            const numRows = rowDataRolePermission.length;
            for (var i = 0; i < numRows; i++) {
                const valueindex = {
                    role_name: rowDataRoleName[i],
                    role_level: rowDataRolePermission[i],
                    action: <><button className="role_action_button" value={[rowDataRoleName[i], rowDataRolePermission[i], rowDataRoleId[i]]} onClick={this.props.openEditRoleForm}><Trans>Edit</Trans></button><button className="role_action_button" value={[rowDataRoleName[i], rowDataRoleId[i]]} onClick={this.props.openDeleteRoleForm}><Trans>Delete</Trans></button></>,
                }
                rowsData.push(valueindex);
            }
        }

        const data = {
            columns: [
                {
                    label: <Trans>Role name</Trans>,
                    field: 'role_name',
                    sort: 'asc',
                    width: 150
                },
                {
                    label: <Trans>Level</Trans>,
                    field: 'role_level',
                    sort: 'asc',
                    width: 150
                },
                {
                    label: <Trans>Action</Trans>,
                    field: 'action',
                    sort: 'asc',
                    width: 150
                }
            ],
            rows: rowsData
        };
        return (
            <div>
                <div className='role_table'>
                    <MDBDataTable
                        hover
                        scrollX
                        maxHeight='50vh'
                        entriesOptions={[5, 10, 20]}
                        entries={5}
                        data={data}
                        disableRetreatAfterSorting={true}
                    />
                </div>

            </div>
        );
    }


}

export default RoletablePage;