import React from 'react';
import { MDBDataTable } from 'mdbreact';
import './Vehicles.css'
import * as ImIcons from 'react-icons/im';
import VehicleMapLeaflet from './MapLeaflet'
import { Trans, useTranslation } from 'react-i18next';

class VehiclestablePage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            vehicleLat: 15.990264303653456,
            vehicleLng: 108.32260101119351,
            vehicleMapState: false,
        }
        this.openVehicleMap = this.openVehicleMap.bind(this);
    }
    openVehicleMap(e) {
        const vehicleLocation = e.currentTarget.value.split(",");
        this.setState({
            vehicleMapState: true,
            vehicleLat: vehicleLocation[0],
            vehicleLng: vehicleLocation[1],
            vehicleId: vehicleLocation[2],
            vehicleFlag: vehicleLocation[3],
        });
    }
    render() {
        let openMapState = this.state.vehicleMapState ? "show_vehicle_map" : "hide_vehicle_map";
        const rowData = this.props.tableData;
        const rowDataVehicleId = rowData[0];
        const rowDataVehiclenName = rowData[1];
        const rowDataBattery = rowData[2];
        const rowDataType = rowData[3];
        const rowDataEmei = rowData[4];
        const rowDataQrCode = rowData[5];
        const rowDataVehicleSn = rowData[6];
        const rowDataOdometer = rowData[7];
        const rowDataEnableFlag = rowData[8];
        const rowDataRegisterDate = rowData[9];
        const rowDataLastConnected = rowData[10];
        const rowDataLastLockStatusUpdate = rowData[11];
        const rowDataLastRideTime = rowData[12];
        const rowDataVehicleLat = rowData[13];
        const rowDataVehicleLng = rowData[14];
        const rowDataVehicleFleet = rowData[15];
        const rowDataVehicleRfid = rowData[16];
        const rowDataVehicleSim = rowData[17];
        const rowDataVehicleMacAdress = rowData[18];
        const rowDataVehicleDeviceNote = rowData[19];
        const rowDataVehicleConnection = rowData[20];
        const numRows = rowDataVehicleId.length;
        const rowsData = [];
        for (var i = 0; i < numRows; i++) {
            const rowDataVehicleLocation = rowDataVehicleLat[i]+","+rowDataVehicleLng[i]+","+rowDataVehicleId[i]+","+rowDataEnableFlag[i];
            const valueindex = {
                vehicle_id: rowDataVehicleId[i],
                vehicle_name: rowDataVehiclenName[i],
                battery: rowDataBattery[i],
                type: rowDataType[i],
                imei: rowDataEmei[i],
                qr_code: rowDataQrCode[i],
                vehicle_sn: rowDataVehicleSn[i],
                odo: rowDataOdometer[i],
                ebabled_flags: rowDataEnableFlag[i],
                register_date: rowDataRegisterDate[i],
                last_connected: rowDataLastConnected[i],
                last_loct_status_update: rowDataLastLockStatusUpdate[i],
                last_ride_time: rowDataLastRideTime[i],
                location: <button type="button" value={rowDataVehicleLocation}  className="btn-map" onClick={this.openVehicleMap}><ImIcons.ImMap className='map-icons' /></button>,
                action: <><button className="vehicle_action_button" value={[rowDataVehicleId[i],rowDataType[i],rowDataEmei[i],rowDataVehicleSim[i],rowDataVehicleSn[i],rowDataVehiclenName[i],rowDataVehicleRfid[i],rowDataVehicleMacAdress[i],rowDataLastConnected[i],rowDataVehicleFleet[i],rowDataQrCode[i],rowDataVehicleDeviceNote[i],rowDataVehicleConnection[i]]} onClick={this.props.openEditVehicle}><Trans>Edit</Trans></button>{/*<button className="role_action_button" value={rowDataVehicleId} onClick={this.props.openDeleteVehicle}>Delete</button>*/}</>,
            }

            rowsData.push(valueindex);
        }
        const data = {
            columns: [
                {
                    label: 'ID',
                    field: 'vehicle_id',
                    sort: 'asc',
                    width: 75,
                },
                {
                    label: <Trans>Vehicle Name</Trans>,
                    field: 'vehicle_name',
                    sort: 'asc',
                    width: 200,
                },
                {
                    label: <Trans>Battery (%)</Trans>,
                    field: 'battery',
                    sort: 'asc',
                    width: 125,
                },
                {
                    label: <Trans>Vehicle Type</Trans>,
                    field: 'type',
                    sort: 'asc',
                    width: 175,
                },
                {
                    label: <Trans>IMEI</Trans>,
                    field: 'imei',
                    sort: 'asc',
                    width: 250,
                },
                {
                    label: <Trans>QR Code</Trans>,
                    field: 'qr_code',
                    sort: 'asc',
                    width: 175,
                },
                {
                    label: <Trans>Vehicle SN</Trans>,
                    field: 'vehicle_sn',
                    sort: 'asc',
                    width: 150,
                },
                {
                    label: <Trans>Odometer Distance (km)</Trans>,
                    field: 'odo',
                    sort: 'asc',
                    width: 300,
                },
                {
                    label: <Trans>Enabled Flags</Trans>,
                    field: 'ebabled_flags',
                    sort: 'asc',
                    width: 175,
                },
                {
                    label: <Trans>Register Date</Trans>,
                    field: 'register_date',
                    sort: 'asc',
                    width: 175,
                },
                {
                    label: <Trans>Last Connected</Trans>,
                    field: 'last_connected',
                    sort: 'asc',
                    width: 200,
                },
                {
                    label: <Trans>Last Lock Status Update</Trans>,
                    field: 'last_loct_status_update',
                    sort: 'asc',
                    width: 325,
                },
                {
                    label: <Trans>Last ride time</Trans>,
                    field: 'last_ride_time',
                    sort: 'asc',
                    width: 250
                },
                {
                    label: <Trans>Location</Trans>,
                    field: 'location',
                    sort: 'asc',
                    width: 100,
                },
                {
                    label: <Trans>Action</Trans>,
                    field: 'action',
                    sort: 'asc',
                    width: 200,
                }

            ],
            rows: rowsData,
        };
        return (
            <>
                <div className='fleets_table'>
                    <MDBDataTable
                        hover
                        scrollX
                        maxHeight='50vh'
                        entriesOptions={[5, 10, 20]}
                        entries={5}
                        data={data}
                        disableRetreatAfterSorting={true}
                    />
                </div>
                <div className={openMapState}>
                    <VehicleMapLeaflet mapDataInfo={[this.state.vehicleLat, this.state.vehicleLng, this.state.vehicleId, this.state.vehicleFlag]}/>
                </div>
            </>
        );
    }
}
export default VehiclestablePage;