import React, { Component } from 'react';
import { MDBDataTable } from 'mdbreact';
import './Role_list.css';
import Switch from "react-switch";
import { Trans } from 'react-i18next';
import Cookies from 'universal-cookie';

class PermissiontablePage extends React.Component {
    constructor() {
        super();
        this.state = {
            checked: false,
            targetToggle: null,
            loadingPermission: true,

        };
        this.handleChange = this.handleChange.bind(this);
    }

    async handleChange() {
        const toggleValue = await [this.state.targetToggle];
        const toggleValueString = await toggleValue.toString();
        const toggleNameString = await toggleValueString.split("$")[0];
        const toggleNamePOST = await toggleNameString.split('_').join(' ');
        const togglePermissionPOST = await toggleValueString.split("$")[1];
        const togglePermission_Value = await this.state[this.state.targetToggle] ? "false" : "true";
        const cookies = new Cookies();
        const formEditToggleState = new URLSearchParams();
        //console.log("TEST DISPLAY: " + toggleValueString);
        formEditToggleState.append('toggle_edit_name', toggleNamePOST);
        formEditToggleState.append('toggle_edit_permission', togglePermissionPOST);
        formEditToggleState.append('toggle_edit_permission_value', togglePermission_Value);
        formEditToggleState.append('admin_name', cookies.get('adminName'));
        //console.log(toggleNamePOST+"$"+togglePermissionPOST);
        await fetch("http://api.elevate-mobility.com/api/organisations/org_permission_edit_toggle.php",
            {
                method: 'POST',
                headers: {
                    'Content-Type': "application/x-www-form-urlencoded;charset=UTF-8"
                },
                body: formEditToggleState.toString()
            }
        )
            .then(res => res.json())
            .then(
                (data) => {
                    if (data.role_permission_value_update === "false") {
                        this.setState({
                            [this.state.targetToggle]: false
                        });
                    }
                    else if (data.role_permission_value_update === "true") {
                        this.setState({
                            [this.state.targetToggle]: true
                        });
                    }
                    //console.log("Value toggle:" + [this.state.targetToggle] + "AND" + togglePermissionPOST + "/" + data.role_permission_update + "AND" + toggleNamePOST + "/" + data.role_name_update + "&" + data.role_permission_value_update+ data.admin)
                }
            );
    }
    async componentDidMount() {
        await fetch("https://admin.connect-ink.com/API/organisations/org_permission_list.php")
            .then(res => res.json())
            .then(
                (data) => {
                    const roleName = data.role_name;
                    const roleAnalytics = data.analytics;
                    const roleApplicationBanner = data.application_banner;
                    const roleBanner = data.banner;
                    const roleCampaigns = data.campaign;
                    const roleCustomzone = data.customzone;
                    const roleFeedbackOption = data.feedback_option;
                    const roleFleets = data.fleets;
                    const roleMap = data.map;
                    const roleMarker = data.marker;
                    const roleOffers = data.offers;
                    const roleOnride = data.onride;
                    const roleOperatorhistory = data.operator_history;
                    const roleOrg = data.org;
                    const roleParkings = data.parking;
                    const rolePayments = data.payment;
                    const rolePricing = data.pricing;
                    const rolePromos = data.promos;
                    const roleRestrictedareas = data.restricted_areas;
                    const roleRiders = data.riders;
                    const roleRides = data.rides;
                    const roleServiceareas = data.service_areas;
                    const roleSpeedzoneareas = data.speed_zone_areas;
                    const roleStation = data.station;
                    const roleTaxes = data.taxes;
                    const roleTickets = data.ticket;
                    const roleTimeslider = data.time_slider;
                    const roleVehiclereserved = data.vehicle_reserved;
                    const roleVehicle = data.vehicle;
                    const lengthRoleArray = roleName.length;
                    for (var i = 0; i < lengthRoleArray; i++) {
                        //==========================================================================
                        if (roleAnalytics[i] === "true") {
                            this.setState({
                                [roleName[i].split(' ').join('_') + "$analytics"]: true,
                            });
                        }
                        else {
                            this.setState({
                                [roleName[i].split(' ').join('_') + "$analytics"]: false,
                            });
                        }
                        //==========================================================================
                        if (roleApplicationBanner[i] === "true") {
                            this.setState({
                                [roleName[i].split(' ').join('_') + "$application_banner"]: true,
                            });
                        }
                        else {
                            this.setState({
                                [roleName[i].split(' ').join('_') + "$application_banner"]: false,
                            });
                        }
                        //==========================================================================   
                        if (roleBanner[i] === "true") {
                            this.setState({
                                [roleName[i].split(' ').join('_') + "$banner"]: true,
                            });
                        }
                        else {
                            this.setState({
                                [roleName[i].split(' ').join('_') + "$banner"]: false,
                            });
                        }
                        //==========================================================================
                        if (roleCampaigns[i] === "true") {
                            this.setState({
                                [roleName[i].split(' ').join('_') + "$campaigns"]: true,
                            });
                        }
                        else {
                            this.setState({
                                [roleName[i].split(' ').join('_') + "$campaigns"]: false,
                            });
                        }
                        //==========================================================================
                        if (roleCustomzone[i] === "true") {
                            this.setState({
                                [roleName[i].split(' ').join('_') + "$customzone"]: true,
                            });
                        }
                        else {
                            this.setState({
                                [roleName[i].split(' ').join('_') + "$customzone"]: false,
                            });
                        }
                        //==========================================================================
                        if (roleFeedbackOption[i] === "true") {
                            this.setState({
                                [roleName[i].split(' ').join('_') + "$FeedbackOption"]: true,
                            });
                        }
                        else {
                            this.setState({
                                [roleName[i].split(' ').join('_') + "$FeedbackOption"]: false,
                            });
                        }
                        //==========================================================================
                        if (roleFleets[i] === "true") {
                            this.setState({
                                [roleName[i].split(' ').join('_') + "$Fleets"]: true,
                            });
                        }
                        else {
                            this.setState({
                                [roleName[i].split(' ').join('_') + "$Fleets"]: false,
                            });
                        }
                        //==========================================================================
                        if (roleMap[i] === "true") {
                            this.setState({
                                [roleName[i].split(' ').join('_') + "$Map"]: true,
                            });
                        }
                        else {
                            this.setState({
                                [roleName[i].split(' ').join('_') + "$Map"]: false,
                            });
                        }
                        //==========================================================================
                        if (roleMarker[i] === "true") {
                            this.setState({
                                [roleName[i].split(' ').join('_') + "$Marker"]: true,
                            });
                        }
                        else {
                            this.setState({
                                [roleName[i].split(' ').join('_') + "$Marker"]: false,
                            });
                        }
                        //==========================================================================
                        if (roleOffers[i] === "true") {
                            this.setState({
                                [roleName[i].split(' ').join('_') + "$Offers"]: true,
                            });
                        }
                        else {
                            this.setState({
                                [roleName[i].split(' ').join('_') + "$Offers"]: false,
                            });
                        }
                        //==========================================================================
                        if (roleOnride[i] === "true") {
                            this.setState({
                                [roleName[i].split(' ').join('_') + "$Onride"]: true,
                            });
                        }
                        else {
                            this.setState({
                                [roleName[i].split(' ').join('_') + "$Onride"]: false,
                            });
                        }
                        //==========================================================================
                        if (roleOperatorhistory[i] === "true") {
                            this.setState({
                                [roleName[i].split(' ').join('_') + "$Operatorhistory"]: true,
                            });
                        }
                        else {
                            this.setState({
                                [roleName[i].split(' ').join('_') + "$Operatorhistory"]: false,
                            });
                        }
                        //==========================================================================
                        if (roleOrg[i] === "true") {
                            this.setState({
                                [roleName[i].split(' ').join('_') + "$Org"]: true,
                            });
                        }
                        else {
                            this.setState({
                                [roleName[i].split(' ').join('_') + "$Org"]: false,
                            });
                        }
                        //==========================================================================
                        if (roleParkings[i] === "true") {
                            this.setState({
                                [roleName[i].split(' ').join('_') + "$Parkings"]: true,
                            });
                        }
                        else {
                            this.setState({
                                [roleName[i].split(' ').join('_') + "$Parkings"]: false,
                            });
                        }
                        //==========================================================================
                        if (rolePayments[i] === "true") {
                            this.setState({
                                [roleName[i].split(' ').join('_') + "$Payments"]: true,
                            });
                        }
                        else {
                            this.setState({
                                [roleName[i].split(' ').join('_') + "$Payments"]: false,
                            });
                        }
                        //==========================================================================
                        if (rolePricing[i] === "true") {
                            this.setState({
                                [roleName[i].split(' ').join('_') + "$Pricing"]: true,
                            });
                        }
                        else {
                            this.setState({
                                [roleName[i].split(' ').join('_') + "$Pricing"]: false,
                            });
                        }
                        //==========================================================================
                        if (rolePromos[i] === "true") {
                            this.setState({
                                [roleName[i].split(' ').join('_') + "$Promos"]: true,
                            });
                        }
                        else {
                            this.setState({
                                [roleName[i].split(' ').join('_') + "$Promos"]: false,
                            });
                        }
                        //==========================================================================
                        if (roleRestrictedareas[i] === "true") {
                            this.setState({
                                [roleName[i].split(' ').join('_') + "$Restrictedareas"]: true,
                            });
                        }
                        else {
                            this.setState({
                                [roleName[i].split(' ').join('_') + "$Restrictedareas"]: false,
                            });
                        }
                        //==========================================================================
                        if (roleRiders[i] === "true") {
                            this.setState({
                                [roleName[i].split(' ').join('_') + "$Riders"]: true,
                            });
                        }
                        else {
                            this.setState({
                                [roleName[i].split(' ').join('_') + "$Riders"]: false,
                            });
                        }
                        //==========================================================================
                        if (roleRides[i] === "true") {
                            this.setState({
                                [roleName[i].split(' ').join('_') + "$Rides"]: true,
                            });
                        }
                        else {
                            this.setState({
                                [roleName[i].split(' ').join('_') + "$Rides"]: false,
                            });
                        }
                        //==========================================================================
                        if (roleServiceareas[i] === "true") {
                            this.setState({
                                [roleName[i].split(' ').join('_') + "$Serviceareas"]: true,
                            });
                        }
                        else {
                            this.setState({
                                [roleName[i].split(' ').join('_') + "$Serviceareas"]: false,
                            });
                        }
                        //==========================================================================
                        if (roleSpeedzoneareas[i] === "true") {
                            this.setState({
                                [roleName[i].split(' ').join('_') + "$Speedzoneareas"]: true,
                            });
                        }
                        else {
                            this.setState({
                                [roleName[i].split(' ').join('_') + "$Speedzoneareas"]: false,
                            });
                        }
                        //==========================================================================
                        if (roleStation[i] === "true") {
                            this.setState({
                                [roleName[i].split(' ').join('_') + "$Station"]: true,
                            });
                        }
                        else {
                            this.setState({
                                [roleName[i].split(' ').join('_') + "$Station"]: false,
                            });
                        }
                        //==========================================================================
                        if (roleTaxes[i] === "true") {
                            this.setState({
                                [roleName[i].split(' ').join('_') + "$Taxes"]: true,
                            });
                        }
                        else {
                            this.setState({
                                [roleName[i].split(' ').join('_') + "$Taxes"]: false,
                            });
                        }
                        //==========================================================================
                        if (roleTickets[i] === "true") {
                            this.setState({
                                [roleName[i].split(' ').join('_') + "$Tickets"]: true,
                            });
                        }
                        else {
                            this.setState({
                                [roleName[i].split(' ').join('_') + "$Tickets"]: false,
                            });
                        }
                        //==========================================================================
                        if (roleTimeslider[i] === "true") {
                            this.setState({
                                [roleName[i].split(' ').join('_') + "$Timeslider"]: true,
                            });
                        }
                        else {
                            this.setState({
                                [roleName[i].split(' ').join('_') + "$Timeslider"]: false,
                            });
                        }
                        //==========================================================================
                        if (roleVehiclereserved[i] === "true") {
                            this.setState({
                                [roleName[i].split(' ').join('_') + "$Vehiclereserved"]: true,
                            });
                        }
                        else {
                            this.setState({
                                [roleName[i].split(' ').join('_') + "$Vehiclereserved"]: false,
                            });
                        }
                        //==========================================================================
                        if (roleVehicle[i] === "true") {
                            this.setState({
                                [roleName[i].split(' ').join('_') + "$Vehicles"]: true,
                            });
                        }
                        else {
                            this.setState({
                                [roleName[i].split(' ').join('_') + "$Vehicles"]: false,
                            });
                        }

                    };

                }

            );
            this.setState({
                loadingPermission: false
            })
    }
    render() {
        let loadingPermission = this.state.loadingPermission ? "showLoadingPermission" : "hideLoadingPermission";
        let roleNameArray = this.props.roleName;
        const numRows = roleNameArray.length;
        const rowsData = [];
        const columnsData = [{ label: "", field: "permission_id", sort: 'asc', width: 150 }];

        for (var i = 0; i < numRows; i++) {
            const valuecolumnindex = {
                label: roleNameArray[i],
                field: roleNameArray[i],
                sort: 'asc',
                width: 150,
            }
            columnsData.push(valuecolumnindex);
        };
        const analytics = {};
        analytics.permission_id = <Trans>Analytics</Trans>;
        for (var i = 0; i < numRows; i++) {
            const targetToggle = [roleNameArray[i].split(' ').join('_') + "$analytics"];
            analytics[roleNameArray[i]] = <><Switch onChange={async (e) => { await this.setState({ targetToggle: targetToggle }); this.handleChange(e) }} name={[roleNameArray[i].split(' ').join('_') + "$analytics"]} checked={this.state[roleNameArray[i].split(' ').join('_') + "$analytics"]} className='permissions_toggle' /></>
        };
        rowsData.push(analytics);

        const application_banner = {};
        application_banner.permission_id = <Trans>Application banner</Trans>;
        for (var i = 0; i < numRows; i++) {
            const targetToggleApplicationBanner = [roleNameArray[i].split(' ').join('_') + "$application_banner"];
            application_banner[roleNameArray[i]] = <><Switch onChange={(e) => { this.setState({ targetToggle: targetToggleApplicationBanner }); this.handleChange(e) }} name={[roleNameArray[i].split(' ').join('_') + "$application_banner"]} checked={this.state[roleNameArray[i].split(' ').join('_') + "$application_banner"]} className='permissions_toggle' /></>
        };
        rowsData.push(application_banner);

        const banner = {};
        banner.permission_id = <Trans>Banner</Trans>;
        for (var i = 0; i < numRows; i++) {
            const targetToggleBanner = [roleNameArray[i].split(' ').join('_') + "$banner"];
            banner[roleNameArray[i]] = <><Switch onChange={(e) => { this.setState({ targetToggle: targetToggleBanner }); this.handleChange(e) }} name={[roleNameArray[i].split(' ').join('_') + "$banner"]} checked={this.state[roleNameArray[i].split(' ').join('_') + "$banner"]} className='permissions_toggle' /></>
        };
        rowsData.push(banner);

        const campaigns = {};
        campaigns.permission_id = <Trans>Campaigns</Trans>;
        for (var i = 0; i < numRows; i++) {
            const targetToggleCampaigns = [roleNameArray[i].split(' ').join('_') + "$campaigns"];
            campaigns[roleNameArray[i]] = <><Switch onChange={(e) => { this.setState({ targetToggle: targetToggleCampaigns }); this.handleChange(e) }} name={[roleNameArray[i].split(' ').join('_') + "$campaigns"]} checked={this.state[roleNameArray[i].split(' ').join('_') + "$campaigns"]} className='permissions_toggle' /></>
        };
        rowsData.push(campaigns);

        const customzone = {};
        customzone.permission_id = <Trans>Customzone</Trans>;
        for (var i = 0; i < numRows; i++) {
            const targetToggleCustomZone = [roleNameArray[i].split(' ').join('_') + "$customzone"];
            customzone[roleNameArray[i]] = <><Switch onChange={(e) => { this.setState({ targetToggle: targetToggleCustomZone }); this.handleChange(e) }} name={[roleNameArray[i].split(' ').join('_') + "$customzone"]} checked={this.state[roleNameArray[i].split(' ').join('_') + "$customzone"]} className='permissions_toggle' /></>
        };
        rowsData.push(customzone);

        const FeedbackOption = {};
        FeedbackOption.permission_id = <Trans>FeedbackOption</Trans>;
        for (var i = 0; i < numRows; i++) {
            const targetToggleFeedbackOption = [roleNameArray[i].split(' ').join('_') + "$FeedbackOption"];
            FeedbackOption[roleNameArray[i]] = <><Switch onChange={(e) => { this.setState({ targetToggle: targetToggleFeedbackOption }); this.handleChange(e) }} name={[roleNameArray[i].split(' ').join('_') + "$FeedbackOption"]} checked={this.state[roleNameArray[i].split(' ').join('_') + "$FeedbackOption"]} className='permissions_toggle' /></>
        };
        rowsData.push(FeedbackOption);

        const Fleets = {};
        Fleets.permission_id = <Trans>Fleets</Trans>;
        for (var i = 0; i < numRows; i++) {
            const targetToggle = [roleNameArray[i].split(' ').join('_') + "$Fleets"];
            Fleets[roleNameArray[i]] = <><Switch onChange={(e) => { this.setState({ targetToggle: targetToggle }); this.handleChange(e) }} name={[roleNameArray[i].split(' ').join('_') + "$Fleets"]} checked={this.state[roleNameArray[i].split(' ').join('_') + "$Fleets"]} className='permissions_toggle' /></>
        };
        rowsData.push(Fleets);

        const Map = {};
        Map.permission_id = <Trans>Map</Trans>;
        for (var i = 0; i < numRows; i++) {
            const targetToggleMap = [roleNameArray[i].split(' ').join('_') + "$Map"];
            Map[roleNameArray[i]] = <><Switch onChange={(e) => { this.setState({ targetToggle: targetToggleMap }); this.handleChange(e) }} name={[roleNameArray[i].split(' ').join('_') + "$Map"]} checked={this.state[roleNameArray[i].split(' ').join('_') + "$Map"]} className='permissions_toggle' /></>
        };
        rowsData.push(Map);

        const Marker = {};
        Marker.permission_id = <Trans>Marker</Trans>;
        for (var i = 0; i < numRows; i++) {
            const targetToggle = [roleNameArray[i].split(' ').join('_') + "$Marker"];
            Marker[roleNameArray[i]] = <><Switch onChange={(e) => { this.setState({ targetToggle: targetToggle }); this.handleChange(e) }} name={[roleNameArray[i].split(' ').join('_') + "$Marker"]} checked={this.state[roleNameArray[i].split(' ').join('_') + "$Marker"]} className='permissions_toggle' /></>
        };
        rowsData.push(Marker);

        const Offers = {};
        Offers.permission_id = <Trans>Offers</Trans>;
        for (var i = 0; i < numRows; i++) {
            const targetToggle = [roleNameArray[i].split(' ').join('_') + "$Offers"];
            Offers[roleNameArray[i]] = <><Switch onChange={(e) => { this.setState({ targetToggle: targetToggle }); this.handleChange(e) }} name={[roleNameArray[i].split(' ').join('_') + "$Offers"]} checked={this.state[roleNameArray[i].split(' ').join('_') + "$Offers"]} className='permissions_toggle' /></>
        };
        rowsData.push(Offers);

        const Onride = {};
        Onride.permission_id = <Trans>Onride</Trans>;
        for (var i = 0; i < numRows; i++) {
            const targetToggle = [roleNameArray[i].split(' ').join('_') + "$Onride"];
            Onride[roleNameArray[i]] = <><Switch onChange={(e) => { this.setState({ targetToggle: targetToggle }); this.handleChange(e) }} name={[roleNameArray[i].split(' ').join('_') + "$Onride"]} checked={this.state[roleNameArray[i].split(' ').join('_') + "$Onride"]} className='permissions_toggle' /></>
        };
        rowsData.push(Onride);

        const Operatorhistory = {};
        Operatorhistory.permission_id = <Trans>Operatorhistory</Trans>;
        for (var i = 0; i < numRows; i++) {
            const targetToggle = [roleNameArray[i].split(' ').join('_') + "$Operatorhistory"];
            Operatorhistory[roleNameArray[i]] = <><Switch onChange={(e) => { this.setState({ targetToggle: targetToggle }); this.handleChange(e) }} name={[roleNameArray[i].split(' ').join('_') + "$Operatorhistory"]} checked={this.state[roleNameArray[i].split(' ').join('_') + "$Operatorhistory"]} className='permissions_toggle' /></>
        };
        rowsData.push(Operatorhistory);

        const Org = {};
        Org.permission_id = <Trans>Org</Trans>;
        for (var i = 0; i < numRows; i++) {
            const targetToggle = [roleNameArray[i].split(' ').join('_') + "$Org"];
            Org[roleNameArray[i]] = <><Switch onChange={(e) => { this.setState({ targetToggle: targetToggle }); this.handleChange(e) }} name={[roleNameArray[i].split(' ').join('_') + "$Org"]} checked={this.state[roleNameArray[i].split(' ').join('_') + "$Org"]} className='permissions_toggle' /></>
        };
        rowsData.push(Org);

        const Parkings = {};
        Parkings.permission_id = <Trans>Parkings</Trans>;
        for (var i = 0; i < numRows; i++) {
            const targetToggle = [roleNameArray[i].split(' ').join('_') + "$Parkings"];
            Parkings[roleNameArray[i]] = <><Switch onChange={(e) => { this.setState({ targetToggle: targetToggle }); this.handleChange(e) }} name={[roleNameArray[i].split(' ').join('_') + "$Parkings"]} checked={this.state[roleNameArray[i].split(' ').join('_') + "$Parkings"]} className='permissions_toggle' /></>
        };
        rowsData.push(Parkings);

        const Payments = {};
        Payments.permission_id = <Trans>Payments</Trans>;
        for (var i = 0; i < numRows; i++) {
            const targetToggle = [roleNameArray[i].split(' ').join('_') + "$Payments"];
            Payments[roleNameArray[i]] = <><Switch onChange={(e) => { this.setState({ targetToggle: targetToggle }); this.handleChange(e) }} name={[roleNameArray[i].split(' ').join('_') + "$Payments"]} checked={this.state[roleNameArray[i].split(' ').join('_') + "$Payments"]} className='permissions_toggle' /></>
        };
        rowsData.push(Payments);

        const Pricing = {};
        Pricing.permission_id = <Trans>Pricing</Trans>;
        for (var i = 0; i < numRows; i++) {
            const targetToggle = [roleNameArray[i].split(' ').join('_') + "$Pricing"];
            Pricing[roleNameArray[i]] = <><Switch onChange={(e) => { this.setState({ targetToggle: targetToggle }); this.handleChange(e) }} name={[roleNameArray[i].split(' ').join('_') + "$Pricing"]} checked={this.state[roleNameArray[i].split(' ').join('_') + "$Pricing"]} className='permissions_toggle' /></>
        };
        rowsData.push(Pricing);

        const Promos = {};
        Promos.permission_id = <Trans>Promos</Trans>;
        for (var i = 0; i < numRows; i++) {
            const targetToggle = [roleNameArray[i].split(' ').join('_') + "$Promos"];
            Promos[roleNameArray[i]] = <><Switch onChange={(e) => { this.setState({ targetToggle: targetToggle }); this.handleChange(e) }} name={[roleNameArray[i].split(' ').join('_') + "$Promos"]} checked={this.state[roleNameArray[i].split(' ').join('_') + "$Promos"]} className='permissions_toggle' /></>
        };
        rowsData.push(Promos);

        const Restrictedareas = {};
        Restrictedareas.permission_id = <Trans>Restrictedareas</Trans>;
        for (var i = 0; i < numRows; i++) {
            const targetToggle = [roleNameArray[i].split(' ').join('_') + "$Restrictedareas"];
            Restrictedareas[roleNameArray[i]] = <><Switch onChange={(e) => { this.setState({ targetToggle: targetToggle }); this.handleChange(e) }} name={[roleNameArray[i].split(' ').join('_') + "$Restrictedareas"]} checked={this.state[roleNameArray[i].split(' ').join('_') + "$Restrictedareas"]} className='permissions_toggle' /></>
        };
        rowsData.push(Restrictedareas);

        const Riders = {};
        Riders.permission_id = <Trans>Client</Trans>;
        for (var i = 0; i < numRows; i++) {
            const targetToggle = [roleNameArray[i].split(' ').join('_') + "$Riders"];
            Riders[roleNameArray[i]] = <><Switch onChange={(e) => { this.setState({ targetToggle: targetToggle }); this.handleChange(e) }} name={[roleNameArray[i].split(' ').join('_') + "$Riders"]} checked={this.state[roleNameArray[i].split(' ').join('_') + "$Riders"]} className='permissions_toggle' /></>
        };
        rowsData.push(Riders);

        const Rides = {};
        Rides.permission_id = <Trans>Order</Trans>;
        for (var i = 0; i < numRows; i++) {
            const targetToggle = [roleNameArray[i].split(' ').join('_') + "$Rides"];
            Rides[roleNameArray[i]] = <><Switch onChange={(e) => { this.setState({ targetToggle: targetToggle }); this.handleChange(e) }} name={[roleNameArray[i].split(' ').join('_') + "$Rides"]} checked={this.state[roleNameArray[i].split(' ').join('_') + "$Rides"]} className='permissions_toggle' /></>
        };
        rowsData.push(Rides);

        const Serviceareas = {};
        Serviceareas.permission_id = <Trans>Serviceareas</Trans>;
        for (var i = 0; i < numRows; i++) {
            const targetToggle = [roleNameArray[i].split(' ').join('_') + "$Serviceareas"];
            Serviceareas[roleNameArray[i]] = <><Switch onChange={(e) => { this.setState({ targetToggle: targetToggle }); this.handleChange(e) }} name={[roleNameArray[i].split(' ').join('_') + "$Serviceareas"]} checked={this.state[roleNameArray[i].split(' ').join('_') + "$Serviceareas"]} className='permissions_toggle' /></>
        };
        rowsData.push(Serviceareas);

        const Speedzoneareas = {};
        Speedzoneareas.permission_id = <Trans>Speedzoneareas</Trans>;
        for (var i = 0; i < numRows; i++) {
            const targetToggle = [roleNameArray[i].split(' ').join('_') + "$Speedzoneareas"];
            Speedzoneareas[roleNameArray[i]] = <><Switch onChange={(e) => { this.setState({ targetToggle: targetToggle }); this.handleChange(e) }} name={[roleNameArray[i].split(' ').join('_') + "$Speedzoneareas"]} checked={this.state[roleNameArray[i].split(' ').join('_') + "$Speedzoneareas"]} className='permissions_toggle' /></>
        };
        rowsData.push(Speedzoneareas);

        const Station = {};
        Station.permission_id = <Trans>Station</Trans>;
        for (var i = 0; i < numRows; i++) {
            const targetToggle = [roleNameArray[i].split(' ').join('_') + "$Station"];
            Station[roleNameArray[i]] = <><Switch onChange={(e) => { this.setState({ targetToggle: targetToggle }); this.handleChange(e) }} name={[roleNameArray[i].split(' ').join('_') + "$Station"]} checked={this.state[roleNameArray[i].split(' ').join('_') + "$Station"]} className='permissions_toggle' /></>
        };
        rowsData.push(Station);

        const Taxes = {};
        Taxes.permission_id = <Trans>Taxes</Trans>;
        for (var i = 0; i < numRows; i++) {
            const targetToggle = [roleNameArray[i].split(' ').join('_') + "$Taxes"];
            Taxes[roleNameArray[i]] = <><Switch onChange={(e) => { this.setState({ targetToggle: targetToggle }); this.handleChange(e) }} name={[roleNameArray[i].split(' ').join('_') + "$Taxes"]} checked={this.state[roleNameArray[i].split(' ').join('_') + "$Taxes"]} className='permissions_toggle' /></>
        };
        rowsData.push(Taxes);

        const Tickets = {};
        Tickets.permission_id = <Trans>Tickets</Trans>;
        for (var i = 0; i < numRows; i++) {
            const targetToggle = [roleNameArray[i].split(' ').join('_') + "$Tickets"];
            Tickets[roleNameArray[i]] = <><Switch onChange={(e) => { this.setState({ targetToggle: targetToggle }); this.handleChange(e) }} name={[roleNameArray[i].split(' ').join('_') + "$Tickets"]} checked={this.state[roleNameArray[i].split(' ').join('_') + "$Tickets"]} className='permissions_toggle' /></>
        };
        rowsData.push(Tickets);

        const Timeslider = {};
        Timeslider.permission_id = <Trans>Timeslider</Trans>;
        for (var i = 0; i < numRows; i++) {
            const targetToggle = [roleNameArray[i].split(' ').join('_') + "$Timeslider"];
            Timeslider[roleNameArray[i]] = <><Switch onChange={(e) => { this.setState({ targetToggle: targetToggle }); this.handleChange(e) }} name={[roleNameArray[i].split(' ').join('_') + "$Timeslider"]} checked={this.state[roleNameArray[i].split(' ').join('_') + "$Timeslider"]} className='permissions_toggle' /></>
        };
        rowsData.push(Timeslider);

        const Vehiclereserved = {};
        Vehiclereserved.permission_id = <Trans>Vehiclereserved</Trans>;
        for (var i = 0; i < numRows; i++) {
            const targetToggle = [roleNameArray[i].split(' ').join('_') + "$Vehiclereserved"];
            Vehiclereserved[roleNameArray[i]] = <><Switch onChange={(e) => { this.setState({ targetToggle: targetToggle }); this.handleChange(e) }} name={[roleNameArray[i].split(' ').join('_') + "$Vehiclereserved"]} checked={this.state[roleNameArray[i].split(' ').join('_') + "$Vehiclereserved"]} className='permissions_toggle' /></>
        };
        rowsData.push(Vehiclereserved);

        const Vehicles = {};
        Vehicles.permission_id = <Trans>Vehicles</Trans>;
        for (var i = 0; i < numRows; i++) {
            const targetToggle = [roleNameArray[i].split(' ').join('_') + "$Vehicles"];
            Vehicles[roleNameArray[i]] = <><Switch onChange={(e) => { this.setState({ targetToggle: targetToggle }); this.handleChange(e) }} name={[roleNameArray[i].split(' ').join('_') + "$Vehicles"]} checked={this.state[roleNameArray[i].split(' ').join('_') + "$Vehicles"]} className='permissions_toggle' /></>
        };
        rowsData.push(Vehicles);

        const data = {
            columns: columnsData,
            rows: rowsData,
        };

        return (
            <>
                <div className={loadingPermission}>
                    <p><Trans>Loading permission table...</Trans></p>
                </div>
                <div className='role_table' >
                    <MDBDataTable
                        hover
                        scrollX
                        maxHeight='60vh'
                        entriesOptions={[5, 10, 30]}
                        entries={5}
                        data={data}
                        disableRetreatAfterSorting={true}
                    />
                </div>
            </>
        );
    }
}

export default PermissiontablePage;