import React, { Component } from 'react';
import Avatar from 'react-avatar';
import './AvatarCard.css';
//import { BrowserRouter as Router, Switch, Route, Redirect, withRouter, useHistory } from 'react-router-dom';
import Cookies from 'universal-cookie';
import { Redirect } from "react-router-dom";
import Switch from "react-switch";
import i18n from "../i18n";
import {Trans } from 'react-i18next';


class AvatarCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loggedIn: false,
      lng: "vi",
      checked: true,
      lng_state: true,
    };
    this.LogOut = this.LogOut.bind(this);
  }
  LogOut() {
    const cookies = new Cookies();
    cookies.set('loggedIn', false, { path: '/' });
    /*console.log('cookies loggedIn Dashboard');
    console.log(cookies.get('loggedIn'));*/
    window.location.href = '/';
    /*window.location.reload();*/
    return <Redirect to="/" />;
  }
  /*handleLangueChange = event => {
    if (this.state.checked === true) {
      var newlang = "en";
    } else {
      newlang = "vi";
    }
    const cookies = new Cookies();
    const formAdminData = new URLSearchParams();
    formAdminData.append('admin_id_param', cookies.get('adminID'));
    formAdminData.append('admin_lng_param', newlang);
    console.log("THIS STATE POST: " + formAdminData);
    fetch("http://api.elevate-mobility.com/api/admin/update_admin_langues.php",
      {
        method: 'POST',
        headers: {
          'Content-Type': "application/x-www-form-urlencoded;charset=UTF-8"
        },
        body: formAdminData.toString()
      }
    )
    .then(res => res.json())
    .then(
      (data) => {
        i18n.changeLanguage(data.langues_state);
        if (data.langues_state === "vi") {
          this.setState({
            lng: data.langues_state,
            checked: true,
            lng_state : !this.state.lng_state
          })
        }
        else if (data.langues_state === "en") {
          this.setState({
            lng: data.langues_state,
            checked: false,
            lng_state : !this.state.lng_state
          })
        }
        else {
          this.setState({
            lng: data.langues_state,
            checked: false,
            lng_state : !this.state.lng_state
          })
        }
      }
    );
  };
  async componentDidMount() {
    const cookies = new Cookies();
    const formAdminData = new URLSearchParams();
    formAdminData.append('admin_id_param', cookies.get('adminID'));
    console.log("THIS POST: " + formAdminData)
    await fetch("http://api.elevate-mobility.com/api/admin/admin_langues.php",
      {
        method: 'POST',
        headers: {
          'Content-Type': "application/x-www-form-urlencoded;charset=UTF-8"
        },
        body: formAdminData.toString()
      }
    )
      .then(res => res.json())
      .then(
        (data) => {
          if (data.langues_state === "vi") {
            this.setState({
              lng: data.langues_state,
              checked: true
            })
          }
          else if (data.langues_state === "en") {
            this.setState({
              lng: data.langues_state,
              checked: false
            })
          }
          else {
            this.setState({
              lng: data.langues_state,
              checked: false
            })
          }
        }
      );
    i18n.changeLanguage(this.state.lng);
  }*/
  render() {
    if(this.props.lngValue==="vi"){
      var lngValue = true;
    }else{
      lngValue = false;
    }
    const cookies = new Cookies();
    let adminEmail = cookies.get('adminEmail');
    let adminName = cookies.get('adminName');

    return (
      <>
        <Avatar name={adminName} size="60" round="30px" />
        <br />
        <a style={{zIndex:999}}>{adminName}</a>
        <br />
        <a style={{zIndex:999}}>{adminEmail}</a>
        <br />
        <div>
          <Trans>Language</Trans>
        </div>
        <br />
        <div style={{zIndex:999}}>
          <label style={{ marginRight: "5px" }}>En</label>
          <Switch onChange={this.props.changLng} checked={lngValue} name="Toggle_name" />
          <label style={{ marginLeft: "5px" }}>Vi</label>
        </div>
        <button className='logout_button' onClick={this.LogOut}><Trans>Logout</Trans></button>
      </>
    );
  }
}

export default AvatarCard;
