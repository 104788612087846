import React from 'react';
import './Events.css';
import * as FaIcons from 'react-icons/fa';
import * as BsIcons from 'react-icons/bs';

class events extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fleetName: [],
            fleetFilterParam: "all_fleet",
            eventFilterparam: "all",
            eventData: {
                eventId: [],
                eventNotification: [],
                eventAdmin: [],
                eventType: [],
                eventDate: [],
            }
        }
        this.selectFleetFilter = this.selectFleetFilter.bind(this);
        this.selectTypeFilter = this.selectTypeFilter.bind(this);
    }
    async selectFleetFilter(e) {
        const fleetFiltervalue = e.target.value;
        const formEventFilterData = new URLSearchParams();
        formEventFilterData.append('fleet_filter_param', fleetFiltervalue);
        formEventFilterData.append('event_filter_param', this.state.eventFilterparam);
        await fetch("https://admin.connect-ink.com/API/event/elevate_event.php",
            {
                method: 'POST',
                headers: {
                    'Content-Type': "application/x-www-form-urlencoded;charset=UTF-8"
                },
                body: formEventFilterData.toString()
            }
        )
            .then(res => res.json())
            .then(
                (data) => {
                    this.setState({
                        fleetFilterParam: fleetFiltervalue,
                        eventData: {
                            eventId: data.event_id,
                            eventNotification: data.event_notification,
                            eventAdmin: data.event_admin,
                            eventType: data.event_type,
                            eventDate: data.event_date,
                        }
                    })
                }
            )
    }
    async selectTypeFilter(e) {
        const eventTypeFiltervalue = e.target.value;
        const formEventFilterData = new URLSearchParams();
        formEventFilterData.append('fleet_filter_param', this.state.fleetFilterParam);
        formEventFilterData.append('event_filter_param', eventTypeFiltervalue);
        await fetch("https://admin.connect-ink.com/API/event/elevate_event.php",
            {
                method: 'POST',
                headers: {
                    'Content-Type': "application/x-www-form-urlencoded;charset=UTF-8"
                },
                body: formEventFilterData.toString()
            }
        )
            .then(res => res.json())
            .then(
                (data) => {
                    this.setState({
                        eventFilterparam: eventTypeFiltervalue,
                        eventData: {
                            eventId: data.event_id,
                            eventNotification: data.event_notification,
                            eventAdmin: data.event_admin,
                            eventType: data.event_type,
                            eventDate: data.event_date,
                        }
                    })
                }
            )
    }
    async componentDidMount() {
        const formEventFilterData = new URLSearchParams();
        formEventFilterData.append('fleet_filter_param', this.state.fleetFilterParam);
        formEventFilterData.append('event_filter_param', this.state.eventFilterparam);
        await fetch("https://admin.connect-ink.com/API/event/elevate_event.php",
            {
                method: 'POST',
                headers: {
                    'Content-Type': "application/x-www-form-urlencoded;charset=UTF-8"
                },
                body: formEventFilterData.toString()
            }
        )
            .then(res => res.json())
            .then(
                (data) => {
                    this.setState({
                        fleetName: data.fleet_name,
                        eventData: {
                            eventId: data.event_id,
                            eventNotification: data.event_notification,
                            eventAdmin: data.event_admin,
                            eventType: data.event_type,
                            eventDate: data.event_date,
                        }
                    })
                }
            )
    }
    render() {
        let eventColor;
        const eventId = this.state.eventData.eventId;
        const eventArray = [];
        const eventLength = eventId.length;
        if (eventLength === 0) {
            eventArray[0] = ['No matching records found'];
        } else {
            for (var i = 0; i < eventLength; i++) {
                if (this.state.eventData.eventType[i] == 'Organisation Events') {
                    eventColor = 'organisation_events_color';
                } else if (this.state.eventData.eventType[i] == 'Zone Events') {
                    eventColor = 'zone_events_color';
                } else if (this.state.eventData.eventType[i] == 'Artist Events') {
                    eventColor = 'artist_event_color';
                } else if (this.state.eventData.eventType[i] == 'Client Events') {
                    eventColor = 'client_events_color';
                } else if (this.state.eventData.eventType[i] == 'Offer Events') {
                    eventColor = 'offer_events_color';
                } else if (this.state.eventData.eventType[i] == 'Billing Plan Events') {
                    eventColor = 'billing_plan_events_color';
                } else if (this.state.eventData.eventType[i] == 'Payment Events') {
                    eventColor = 'payment_events_color';
                } else if (this.state.eventData.eventType[i] == 'Tax Events') {
                    eventColor = 'tax_events_color';
                } else if (this.state.eventData.eventType[i] == 'Campaign Events') {
                    eventColor = 'campaign_events_color';
                } else if (this.state.eventData.eventType[i] == 'Ticket Events') {
                    eventColor = 'ticket_events_color';
                } else if (this.state.eventData.eventType[i] == 'Offer Events') {
                    eventColor = 'offer_events_color';
                } else if (this.state.eventData.eventType[i] == 'Billing Events') {
                    eventColor = 'billing_events_color';
                } else if (this.state.eventData.eventType[i] == 'Banner Events') {
                    eventColor = 'banner_events_color';
                } else if (this.state.eventData.eventType[i] == 'Marker Events') {
                    eventColor = 'marker_events_color';
                } else if (this.state.eventData.eventType[i] == 'Feedback Events') {
                    eventColor = 'feedback_events_color';
                } else if (this.state.eventData.eventType[i] == 'Promo Events') {
                    eventColor = 'promo_events_color';
                } else if (this.state.eventData.eventType[i] == 'Order Events') {
                    eventColor = 'order_events_color';
                }else{ 
                    eventColor = 'default_events_color';
                }
                eventArray[i] = [
                    this.state.eventData.eventDate[i] + " : ",
                    this.state.eventData.eventType[i] + " - ",
                    this.state.eventData.eventAdmin[i],
                    this.state.eventData.eventNotification[i],
                    eventColor
                ]
                console.log(this.state.eventData.eventDate[i] + " : ",
                    this.state.eventData.eventType[i] + " - ",
                    this.state.eventData.eventAdmin[i],
                    this.state.eventData.eventNotification[i],
                    eventColor);
            }
        };
        
        return (
            <div className="div_all">
                <div>
                    <p className="events_title">Event</p>
                </div>
                <div className="events_details_filter">
                    <table className='events_filter_bar'>
                        <tbody>
                            <tr>
                                <td>
                                    <select className="events_fleets_select" onChange={this.selectFleetFilter}>
                                        <option value="all_fleet">All Zones</option>
                                        {
                                            this.state.fleetName.map((x) => <option value={x}>{x}</option>)
                                        }
                                    </select>
                                    <select className="events_filter_select" onChange={this.selectTypeFilter}>
                                        <option value="all">Select Events</option>
                                        <option value="Organisation Events">Organisation Events</option>
                                        <option value="Zone Events" >Zone Events</option>
                                        <option value="Vehicle Events">Artist Events</option>
                                        <option value="Marker Events">Client Events</option>
                                        <option value="Offer Events">Offer Events</option>
                                        <option value="Billing Plan Events">Billing Plan Events</option>
                                        <option value="Payment Events">Payment Events</option>
                                        <option value="Tax Events">Tax Events</option>
                                        <option value="Campaign Events">Campaign Events</option>
                                        <option value="Ticket Events">Ticket Events</option>
                                        <option value="Billing Events">Billing Events</option>
                                        <option value="Banner Events">Banner Events</option>
                                        <option value="Slow Speed Events">Marker Events</option>
                                        <option value="Feedback Events">Feedback Events</option>
                                        <option value="Promo Events">Promo Events</option>
                                        <option value="Station Events">Order Events</option>
                    
                                    </select>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className='events_details'>
                    <div className='events_details_table'>
                        {
                            eventArray.map((x) => <div className='events_details_table_tr'><b>{x[0]}</b><b></b> <a className={x[4]}>{x[1]}</a><a>{x[2]} {x[3]}</a></div>)
                        }
                    </div>

                </div>
                <div className='events_legend'>
                    <div className='events_legend_title'><a>Event Legend</a></div>
                    <table className='events_flag'>
                        <tbody>
                            <tr>
                                <td><div className="event_legend_flag" style={{ background: '#FF5050' }}></div></td><td className="event_legend_flag_name"><a class="flag_label">Organisation Events</a></td>
                                <td><div className="event_legend_flag" style={{ background: '#99E472' }}></div></td><td className="event_legend_flag_name"><a class="flag_label">Zone Events</a></td>
                                <td><div className="event_legend_flag" style={{ background: '#118DFF' }}></div></td><td className="event_legend_flag_name"><a class="flag_label">Artist Events</a></td>
                                <td><div className="event_legend_flag" style={{ background: '#660033' }}></div></td><td className="event_legend_flag_name"><a class="flag_label">Client Events</a></td>
                                {/*<td><div className="event_legend_flag" style={{ background: '#b875a3' }}></div></td><td className="event_legend_flag_name"><a class="flag_label">Restricted Area Events</a></td>*/}
                            </tr>
                            <tr>
                                {/*<td><div className="event_legend_flag" style={{ background: '#cc6600' }}></div></td><td className="event_legend_flag_name"><a class="flag_label">Service Area Events</a></td>*/}
                                {/*<td><div className="event_legend_flag" style={{ background: '#CC6699' }}></div></td><td className="event_legend_flag_name"><a class="flag_label">Station Events</a></td>*/}
                                <td><div className="event_legend_flag" style={{ background: '#ff9999' }}></div></td><td className="event_legend_flag_name"><a class="flag_label">Offer Events</a></td>
                                <td><div className="event_legend_flag" style={{ background: '#990099' }}></div></td><td className="event_legend_flag_name"><a class="flag_label">Billing Plan Events</a></td>
                                <td><div className="event_legend_flag" style={{ background: '#ff9966' }}></div></td><td className="event_legend_flag_name"><a class="flag_label">Payment Events</a></td>
                                <td><div className="event_legend_flag" style={{ background: '#cc0099' }}></div></td><td className="event_legend_flag_name"><a class="flag_label">Tax Events</a></td>
                                {/*<td><div className="event_legend_flag" style={{ background: '#993366' }}></div></td><td className="event_legend_flag_name"><a class="flag_label">Payment Gateway Events</a></td>*/}
                            </tr>
                            <tr>
                                <td><div className="event_legend_flag" style={{ background: '#ff6666' }}></div></td><td className="event_legend_flag_name"><a class="flag_label">Campaign Events</a></td>
                                <td><div className="event_legend_flag" style={{ background: '#ffcccc' }}></div></td><td className="event_legend_flag_name"><a class="flag_label">Ticket Events</a></td>
                                <td><div className="event_legend_flag" style={{ background: '#cc99ff' }}></div></td><td className="event_legend_flag_name"><a class="flag_label">Billing Events</a></td>
                                <td><div className="event_legend_flag" style={{ background: '#42F9F9' }}></div></td><td className="event_legend_flag_name"><a class="flag_label">Banner Events</a></td>
                                
                            </tr>
                            <tr>
                                <td><div className="event_legend_flag" style={{ background: '#CC6699' }}></div></td><td className="event_legend_flag_name"><a class="flag_label">Marker Events</a></td>
                                <td><div className="event_legend_flag" style={{ background: '#ff3399' }}></div></td><td className="event_legend_flag_name"><a class="flag_label">Feedback Events</a></td>
                                <td><div className="event_legend_flag" style={{ background: '#ff99cc' }}></div></td><td className="event_legend_flag_name"><a class="flag_label">Promo Events</a></td>
                                <td><div className="event_legend_flag" style={{ background: '#CCAA14' }}></div></td><td className="event_legend_flag_name"><a class="flag_label">Order Events</a></td>
                                {/*<td><div className="event_legend_flag" style={{ background: '#ccccff' }}></div></td><td className="event_legend_flag_name"><a class="flag_label">Setting Events</a></td>*/}
                                {/*<td><div className="event_legend_flag" style={{ background: '#6666ff' }}></div></td><td className="event_legend_flag_name"><a class="flag_label">Wallet Events</a></td>*/}
                                
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}

export default events;