import React from 'react';
import './Home.css';
import * as FaIcons from 'react-icons/fa';
import * as IoIcons from 'react-icons/io';
import * as RiIcons from "react-icons/ri";
import RevenueChart from './chart/revenuechart';
import RidesChart from './chart/rideschart';
import UserChart from './chart/userchart';
import 'semantic-ui-css/semantic.min.css';
import { DateRangePickerComponent } from '@syncfusion/ej2-react-calendars';
import "@syncfusion/ej2-base/styles/material.css";
import "@syncfusion/ej2-buttons/styles/material.css";
import "@syncfusion/ej2-lists/styles/material.css";
import "@syncfusion/ej2-inputs/styles/material.css";
import "@syncfusion/ej2-popups/styles/material.css";
import "@syncfusion/ej2-react-calendars/styles/material.css";
import { Trans, withI18n } from 'react-i18next';
import i18n from "i18next";


class Home extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fleetNameData: [],
            fleetFilter: "all_fleets",
            dateFilter: "all",

            totalRevenue: 0,
            
            artist_on_order:0,
	        artist_avalable:0,
	        unavailable_artist:0,
	        total_artist:0,
	        
	        total_order_today:0,
	        order_per_artist:0,
	        pending_order:0,
	        completed_order:0,
	        
	        total_client:0,
	        new_client_today:0,
	        total_active_client:0,
	        total_inactive_client:0,

            chartLabel: ['April', 'May', 'June', 'July'],
            revenueChartData: [0, 0, 0, 0],
            orderChartData: [0, 0, 0, 0],
            userChartData: [0, 0, 0, 0]
        }
        this.handleDataChange = this.handleDataChange.bind(this);
        this.handleChangeDate = this.handleChangeDate.bind(this);
        this.handleAllDate = this.handleAllDate.bind(this);

    }
    handleAllDate(e) {
        const dateValue = e.target.value;
        if (dateValue) {
            var dateValuePOST = dateValue;
        }
        else {
            dateValuePOST = "all";
        }
        const formData = new URLSearchParams();
        formData.append('fleet_param', this.state.fleetFilter);
        formData.append('date_filter_param', dateValuePOST);
        fetch("https://api.connect-ink.com/API/WEB_ADMIN/API/home/home_analytics.php",
            {
                method: 'POST',
                headers: {
                    'Content-Type': "application/x-www-form-urlencoded;charset=UTF-8"
                },
                body: formData.toString()
            }
        )
            .then(res => res.json())
            .then(
                (data) => {
                    if (data) {
                        if (data) {
                            this.setState({
                                totalRevenue: data.total_revenue,
            
                                artist_on_order:data.on_order_artist,
	                            artist_avalable:data.available_artist,
	                            unavailable_artist:data.unavailable_artist,
	                            total_artist:data.total_artist,
	        
	                            total_order:data.total_order_today,
	                            order_per_artist:data.order_per_artist,
	                            pending_order:data.pending_order,
	                            completed_order:data.completed_order,
	        
	                            total_client:data.total_client,
	                            new_client_today:data.new_client_today,
	                            total_active_client:data.total_active_client,
	                            total_inactive_client:data.total_inactive_client,
	                    
	                            chartLabel: data.revenue_date_chart,
                                revenueChartData: data.revenue_data_chart,
                                orderChartData: data.order_data_chart,
                                userChartDataLabel: data.user_signup_date_chart,
                                userChartData: data.user_signup_chart_data,

                                fleetNameData: data.fleet_name,
                                fleetNameSelect: data.fleet_name_select,

                            });
                        }
                    }
                }
            );
    }
    handleChangeDate(e) {
        const dateValue = e.target.value;
        if (dateValue) {
            var dateValuePOST = dateValue;
        }
        else {
            dateValuePOST = "all";
        }
        const formData = new URLSearchParams();
        formData.append('fleet_param', this.state.fleetFilter);
        formData.append('date_filter_param', dateValuePOST);
        fetch("https://api.connect-ink.com/API/WEB_ADMIN/API/home/home_analytics.php",
            {
                method: 'POST',
                headers: {
                    'Content-Type': "application/x-www-form-urlencoded;charset=UTF-8"
                },
                body: formData.toString()
            }
        )
            .then(res => res.json())
            .then(
                (data) => {
                    if (data) {
                        if (data) {
                            this.setState({
                                totalRevenue: data.total_revenue,
            
                                artist_on_order:data.on_order_artist,
	                            artist_avalable:data.available_artist,
	                            unavailable_artist:data.unavailable_artist,
	                            total_artist:data.total_artist,
	        
	                            total_order:data.total_order_today,
	                            order_per_artist:data.order_per_artist,
	                            pending_order:data.pending_order,
	                            completed_order:data.completed_order,
	        
	                            total_client:data.total_client,
	                            new_client_today:data.new_client_today,
	                            total_active_client:data.total_active_client,
	                            total_inactive_client:data.total_inactive_client,
	                    
	                            chartLabel: data.revenue_date_chart,
                                revenueChartData: data.revenue_data_chart,
                                orderChartData: data.order_data_chart,
                                userChartDataLabel: data.user_signup_date_chart,
                                userChartData: data.user_signup_chart_data,

                                fleetNameData: data.fleet_name,
                                fleetNameSelect: data.fleet_name_select,

                            });
                        }
                    }
                }
            );
    }
    handleDataChange(event) {
        const valueFleet = event.target.value;
        const formData = new URLSearchParams();
        formData.append('fleet_param', valueFleet);
        formData.append('date_filter_param', this.state.dateFilter);
        fetch("https://api.connect-ink.com/API/WEB_ADMIN/API/home/home_analytics.php",
            {
                method: 'POST',
                headers: {
                    'Content-Type': "application/x-www-form-urlencoded;charset=UTF-8"
                },
                body: formData.toString()
            }
        )
            .then(res => res.json())
            .then(
                (data) => {
                    if (data) {
                        this.setState({
                            fleetFilter: event.target.value,
                            dateFilter: data.date_filter,

                            totalRevenue: data.total_revenue_all,
                            vehiclesOnRides: data.onride_vehicle,
                            vehiclesAvailable: data.available_vehicle,
                            vehiclesOnMaintenance: data.maintenance_vehicle,
                            totalVehicles: data.total_vehicle,

                            totalRidesToday: data.total_ride_today,
                            totalDurationToday: data.ride_duration_today,
                            averageRidesPerDay: data.total_rides_per_date,
                            avgRidesPerDayPerVehicle: data.total_rides_per_date_per_vehicle,

                            totalActiveUsersToday: data.total_active_today,
                            outstandingIssues: data.total_user_issues,
                            totalNewUsers: data.total_user_signup_today,
                            totalUsersToDate: data.total_users_signup_to_date,

                            chartLabel: data.revenue_date_chart,
                            revenueChartData: data.revenue_data_chart,
                            ridesChartData: data.rides_data_chart,
                            userChartDataLabel: data.user_signup_date_chart,
                            userChartData: data.user_signup_chart_data,

                        });
                    } else {
                      
                    }
                 
                }
            );

    }
    componentDidMount() {
        const formData = new URLSearchParams();
        formData.append('fleet_param', this.state.fleetFilter);
        formData.append('date_filter_param', this.state.dateFilter);
        fetch("https://api.connect-ink.com/API/WEB_ADMIN/API/home/home_analytics.php",
            {
                method: 'POST',
                headers: {
                    'Content-Type': "application/x-www-form-urlencoded;charset=UTF-8"
                },
                body: formData.toString()
            }
        )
            .then(res => res.json())
            .then(
                (data) => {
                    this.setState({
                        totalRevenue: data.total_revenue,
            
                        artist_on_order:data.on_order_artist,
	                    artist_avalable:data.available_artist,
	                    unavailable_artist:data.unavailable_artist,
	                    total_artist:data.total_artist,
	        
	                    total_order:data.total_order_today,
	                    order_per_artist:data.order_per_artist,
	                    pending_order:data.pending_order,
	                    completed_order:data.completed_order,
	        
	                    total_client:data.total_client,
	                    new_client_today:data.new_client_today,
	                    total_active_client:data.total_active_client,
	                    total_inactive_client:data.total_inactive_client,
	                    
	                    chartLabel: data.revenue_date_chart,
                        revenueChartData: data.revenue_data_chart,
                        orderChartData: data.order_data_chart,
                        userChartDataLabel: data.user_signup_date_chart,
                        userChartData: data.user_signup_chart_data,

                        fleetNameData: data.fleet_name,
                        fleetNameSelect: data.fleet_name_select,
                    });
                }
            );
    }
    render() {
        const maxDate = new Date();
        return (
            <div className="div_all">
                <div>
                    <b className="dashboard_title"><Trans>Analytics</Trans></b>
                </div>
                <div>
                    <table className="dashboard_home_table">
                        <tbody>
                            <tr>
                                <td className="dashboard_home_table_td">
                                    <table className='filter_bar'>
                                        <tbody>
                                            <tr>
                                                <td style={{ width: "45%" }}>
                                                    {/*<select className="area_select" onChange={this.handleDataChange} value={this.state.value}>
                                                        <option value="all_fleets" ><Trans>All Zones</Trans></option>
                                                        {this.state.fleetNameData.map((x) =>
                                                            <option value={x}>{x}</option>)}
                                                    </select>*/}
                                                    {/*<input type="date" value="Today" className="date_select" onChange={this.handleDateChange} />*/}
                                                    <div style={{ width: "100%", display: "inline-block", height: "40px", borderRadius: "9px", border: " solid 1px gray" }}>
                                                        <DateRangePickerComponent
                                                            onChange={this.handleChangeDate}
                                                        >
                                                        </DateRangePickerComponent>
                                                    </div>
                                                </td>
                                                <td>
                                                    <button onClick={this.handleAllDate} style={{ marginLeft:"0px", display: "inline-block", height: "40px", borderRadius: "9px", border: " solid 1px gray" }}><Trans>All days</Trans></button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                                <td>
                                    <div className="total_revenue">
                                        <table className="table_revenue">
                                            <tbody>
                                                <tr >
                                                    <td className="td_revenue_title">
                                                        <p className='revenue_title'><Trans>Total Revenue</Trans>:</p>
                                                    </td>
                                                    <td className="td_revenue_value">
                                                        <p className="revenue_value">{this.state.totalRevenue} VND</p>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </td>
                                <td>

                                </td>
                            </tr>
                            <tr>
                                <td className="vehicle_analysis_info">
                                    <div className='vehicle_analysis_info_div'>
                                        <table className="vehicle_table">
                                            <tbody>
                                                <tr>
                                                    <div className='vehicle_icon'>
                                                        <FaIcons.FaPenAlt />
                                                    </div>
                                                </tr>
                                                <tr>
                                                    <td className="td_analysis">
                                                        <div className='td_analysis_contain'>
                                                            <h3>{this.state.total_artist}</h3>
                                                            <p><Trans>Total Artist</Trans></p>
                                                        </div>
                                                    </td>
                                                    <td className="td_analysis">
                                                        <div className='td_analysis_contain'>
                                                            <h3>{this.state.artist_on_order}</h3>
                                                            <p><Trans>Artist on order</Trans></p>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="td_analysis">
                                                        <div className='td_analysis_contain'>
                                                            <h3>{this.state.artist_avalable}</h3>
                                                            <p><Trans>Available Artists</Trans></p>
                                                        </div>
                                                    </td>
                                                    <td className="td_analysis">
                                                        <div className='td_analysis_contain'>
                                                            <h3>{this.state.unavailable_artist}</h3>
                                                            <p><Trans>Unavailable Artists</Trans></p>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </td>

                                <td className="rides_analysis_info">
                                    <div className='rides_analysis_info_div'>
                                        <table className="rides_analysis_table">
                                            <tbody>
                                                <tr>
                                                    <div className='rides_icon'>
                                                        <RiIcons.RiBillFill />
                                                    </div>
                                                </tr>
                                                <tr>
                                                    <td className="td_analysis">
                                                        <div className='td_analysis_contain'>
                                                            <h3>{this.state.total_order_today}</h3>
                                                            <p><Trans>Total Order</Trans></p>
                                                        </div>
                                                    </td>
                                                    <td className="td_analysis">
                                                        <div className='td_analysis_contain'>
                                                            <h3>{this.state.order_per_artist} </h3>
                                                            <p><Trans>Avg Order Per day Per Artist</Trans></p>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="td_analysis">
                                                        <div className='td_analysis_contain'>
                                                            <h3>{this.state.pending_order}</h3>
                                                            <p><Trans>Pending Order</Trans></p>
                                                        </div>
                                                    </td>
                                                    <td className="td_analysis">
                                                        <div className='td_analysis_contain'>
                                                            <h3>{this.state.completed_order}</h3>
                                                            <p><Trans>Completed Order</Trans></p>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </td>

                                <td className="user_analysis_info">
                                    <div className='user_analysis_info_div'>
                                        <table className="user_table">
                                            <tbody>
                                                <tr>
                                                    <div className='rider_icon'>
                                                        <FaIcons.FaUserCircle />
                                                    </div>
                                                </tr>
                                                <tr>
                                                    <td className="td_analysis">
                                                        <div className='td_analysis_contain'>
                                                            <h3>{this.state.total_client}</h3>
                                                            <p><Trans>Total Client</Trans></p>
                                                        </div>
                                                    </td>
                                                    <td className="td_analysis">
                                                        <div className='td_analysis_contain'>
                                                            <h3>{this.state.new_client_today}</h3>
                                                            <p><Trans>Total New Client Today</Trans></p>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="td_analysis">
                                                        <div className='td_analysis_contain'>
                                                            <h3>{this.state.total_active_client}</h3>
                                                            <p><Trans>Total Active Client</Trans></p>
                                                        </div>
                                                    </td>
                                                    <td className="td_analysis">
                                                        <div className='td_analysis_contain'>
                                                            <h3>{this.state.total_inactive_client}</h3>
                                                            <p><Trans>Total Inactive Client</Trans></p>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </td>

                            </tr>
                            <tr>
                                <td className='analysis_chart'>
                                    <div className="revenue_chart">
                                        <RevenueChart revenueData={this.state.revenueChartData} revenueLabel={this.state.chartLabel} />
                                    </div>
                                </td>
                                <td className='analysis_chart'>
                                    <div className="rides_chart">
                                        <RidesChart ridesData={this.state.orderChartData} ridesLabel={this.state.chartLabel} />
                                    </div>
                                </td>
                                <td className='analysis_chart'>
                                    <div className="user_chart">
                                        <UserChart userData={this.state.userChartData} userLabel={this.state.userChartDataLabel} />
                                    </div>
                                </td>

                            </tr>
                        </tbody>
                    </table>
                </div>

            </div >
        );
    }
}

export default Home;