import "./Map.css";
import React, { Component } from "react";
import { MapContainer, TileLayer, useMap, FeatureGroup, Polygon, useMapEvents } from "react-leaflet";
import { EditControl } from "react-leaflet-draw";
import "leaflet-draw/dist/leaflet.draw.css";
import 'leaflet-draw';


function ClickToPan(){
  const map = useMapEvents({
    /*dragend: (e) => {
      //console.log("on click map: " + e.latlng);
      //const onClickLocation = [e.latlng.lat, e.latlng.lng]
      const centerMap = e.target.getCenter();
      map.setView(centerMap, map.getZoom());
    },*/
    click(e) {
      const onClickLocation = [e.latlng.lat, e.latlng.lng];
      map.setView(onClickLocation, map.getZoom());
    }
    
  });
  return null;
}

function MyComponent({location}) {
  const map = useMap()
  map.setView(location, map.getZoom());
  return null
}

function SetTimeOut() {
  const map = useMap();
  setTimeout(() => {
    map.invalidateSize();
  }, 250);
  return null;
}

export default class EditStationMap extends Component {
  constructor(props) {
    super(props);
    this.state = {
      disableState: true,
      lat: 0,
      lng: 0,
    };
  }

  componentDidMount() {
    this.setState({
      lat: this.props.position[0],
      lng: this.props.position[1],
    })
  }

  componentWillReceiveProps(nextProps) {
    if (this.state.lat == nextProps.position[0] && this.state.lng == nextProps.position[1]) {
    }
    else {
      this.setState({
        lat: nextProps.position[0],
        lng: nextProps.position[1],
      })
      const { map } = this.state;
      if (map) {
        map.flyTo(nextProps.position, 16);
      }
    }
  }
  render() {
    const locationArray = [this.state.lat, this.state.lng];
    const purpleOptions = { color: '#318500' }
    const latArray = this.props.latArray;
    const lngArray = this.props.lngArray;
    var location =[];
    const latArrayString = latArray.toString();
    const lngArrayString = lngArray.toString();
    const latPolygonArray = latArrayString.split(",");
    const lngPolygonArray = lngArrayString.split(",");
    
    for( var i = 0; i < latPolygonArray.length; i++){
      location[i] = [parseFloat(latPolygonArray[i]),parseFloat(lngPolygonArray[i])];
    }
    return (
      <>
        <MapContainer style={{ height: "55vh", cursor: "crosshair" }} center={location[0]} zoom={14} whenCreated={map => this.setState({ map })}>
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          />
          <Polygon pathOptions={purpleOptions} positions={location} />
          <FeatureGroup ref={this.props.refFeatureGroup}>
            <EditControl
              position="topright"
              onCreated={this.props.getAreaData}
              edit={{ 
                edit: false
              }}
              draw={{
                polygon: this.props.editState,
                polyline: false,
                rectangle: false,
                circle: false,
                marker: false,
                circlemarker: false
              }}
            />
          </FeatureGroup>
          <SetTimeOut />
          {/*<MyComponent location={location[0]}/>*/}
        </MapContainer>
      </>
    );
  }
}
