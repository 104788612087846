import React from 'react';
import { MDBDataTable } from 'mdbreact';
import './Rides.css';
import RidesTableCSV from './Rides_table_csv';
import { CSVLink } from "react-csv";
import * as FaIcons from 'react-icons/fa';
import { Trans } from 'react-i18next';

class RidestablePage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            csvRideId: [],
            csvVehicleId: [],
            csvUserName: [],
            csvRideFleet: [],
            csvSpeed: [],
            csvAcceleration: [],
            csvBatteryPackage: [],
            csvCellVoltage: [],
            csvTemperature: [],
            csvLocationLat: [],
            csvLocationLng: [],
            csvPowerConsumption: [],
            csvTime: [],
            csvStatus: [],
            csvData: [],
            csvFileName: "",
            complaint_form: false,
            complaint_order_id:"",
            complaint_order_artist_id:"",
            complaint_order_artist_name:"",
            complaint_order_artist_phone:"",
            complaint_order_artist_status:"",
            complaint_order_artist_date:"",
            complaint_order_artist_reason:"",
            complaint_order_client_id:"",
            complaint_order_client_name:"",
            complaint_order_client_phone:"",
            complaint_order_client_status:"",
            complaint_order_client_date:"",
            complaint_order_client_reason:"",
        }
        this.showEvidence = this.showEvidence.bind(this);
    }
    showEvidence(e){
       const order_id = e.target.value;
       const formData = new URLSearchParams();
        formData.append('order_id', order_id);
        fetch("https://admin.connect-ink.com/API/rides/get_complaint_order_info.php",
         {
                method: 'POST',
                headers: {
                    'Content-Type': "application/x-www-form-urlencoded;charset=UTF-8"
                },
                body: formData.toString()
            })
            .then(res => res.json())
            .then(
                (data) => {
                    this.setState({
                        complaint_order_id:data.order_id,
                        complaint_order_artist_id:data.artist_id,
                        complaint_order_artist_name:data.artist_name,
                        complaint_order_artist_phone:data.artist_phone_number,
                        complaint_order_artist_status:data.artist_complain_status,
                        complaint_order_artist_date:data.artist_complain_date,
                        complaint_order_artist_reason:data.artist_complain_reason,
                        complaint_order_client_id:data.client_id,
                        complaint_order_client_name:data.client_name,
                        complaint_order_client_phone:data.client_phone_number,
                        complaint_order_client_status:data.client_complain_status,
                        complaint_order_client_date:data.client_complain_date,
                        complaint_order_client_reason:data.client_complain_reason,
                        complaint_form: !this.state.complaint_form,
                    });
                }
            );
            
            
   }
    render() {
        //===============================
        let complaint_form = this.state.complaint_form ? "show_complaint_form" : "hide_complaint_form";
        let complaint_type_user = "";
        if(this.state.complaint_order_artist_status=="yes"){
            complaint_type_user = "Artist";
        }else if(this.state.complaint_order_client_status=="yes"){
            complaint_type_user = "Client";
        }
        //Img
        let artist_img_1 = "https://api.connect-ink.com/API/TATU/API/data/user/" + this.state.complaint_order_client_id + "/tattoo_picture/artist_complain/" + this.state.complaint_order_id + "_1_artist.PNG";
        let artist_img_2 = "https://api.connect-ink.com/API/TATU/API/data/user/" + this.state.complaint_order_client_id + "/tattoo_picture/artist_complain/" + this.state.complaint_order_id + "_2_artist.PNG";
        let client_img_1 = "https://api.connect-ink.com/API/TATU/API/data/user/" + this.state.complaint_order_client_id + "/tattoo_picture/client_complain/" + this.state.complaint_order_id + "_1_client.PNG";
        let client_img_2 = "https://api.connect-ink.com/API/TATU/API/data/user/" + this.state.complaint_order_client_id + "/tattoo_picture/client_complain/" + this.state.complaint_order_id + "_2_client.PNG";
        //==============Ride Table===============
        const rowData = this.props.tableData;
        const rowDataRideId = rowData[0];
        const rowDataRiderPhoneNumber = rowData[1];
        const rowDataRiderId = rowData[2];
        const rowDataRiderName = rowData[3];
        const rowDataVehicleId = rowData[4];
        const rowDataVehicleQrCode = rowData[5];
        const rowDataVehicleType = rowData[6];
        const rowDataRideFleet = rowData[7];
        const rowDataRideAmount = rowData[8];
        const rowDataRideStartPoint = rowData[9];
        const rowDataRideEndPoint = rowData[10];
        const rowDataRideDate = rowData[11];
        const rowDataRideRating = rowData[12];
        const rowDataRideStatus = rowData[13];
        const rowDataRideDeposit = rowData[14];
        const rowDataRideRemain = rowData[15];
        const rowDataRideArtistCompliant = rowData[16];
        const rowDataRideClientCompliant = rowData[19];

        const numRows = rowDataRideId.length;
        const rowsData = [];
        const complaintValue = [];
        //const complaintValueFunction = [];
        for (var i = 0; i < numRows; i++) {
            const buttonValue = rowDataRideId[i];
            if(rowDataRideStatus[i] == 'complaining'){
                //let complaintValueFunction = [rowDataRideId[i]];
                complaintValue[i] = <>
                                        <button className="complaint_evidence_button" value={rowDataRideId[i]} onClick={this.showEvidence}>Evidence</button>
                                    </>;
            }else if(rowDataRideStatus[i] == 'complained'){
                complaintValue[i] = <button className="complaint_dismiss_button" value={rowDataRideId[i]}><Trans>Completed</Trans></button>;
            }
            else{
                complaintValue[i] = <button className="no_complaint_button" value={rowDataRideId[i]}><Trans>No Complaint</Trans></button>;
            }
            const valueindex = {
                ride_id: rowDataRideId[i],
                phone_number: rowDataRiderPhoneNumber[i],
                user_id: rowDataRiderId[i],
                user_name: rowDataRiderName[i],
                vehicle_id: rowDataVehicleId[i],
                qr_code: rowDataVehicleQrCode[i],
                vehicle_type: rowDataVehicleType[i],
                ride_fleet: rowDataRideFleet[i],
                price: rowDataRideAmount[i],
                deposit: rowDataRideDeposit[i],
                remain: rowDataRideRemain[i],
                //artist_complaint: salaryValue[i],
                client_complaint:complaintValue[i] ,
                start_point: rowDataRideStartPoint[i],
                end_point: rowDataRideEndPoint[i],
                ride_date: rowDataRideDate[i],
                rating: rowDataRideRating[i],
                status: rowDataRideStatus[i],
                action: <><button className="vehicle_action_button" value={[buttonValue]}>
                    {/*<RidesTableCSV tableData={} />*/}
                </button></>,
            }
            rowsData.push(valueindex);
        }
        const data = {
            columns: [
                {
                    label: <Trans>Order ID</Trans>,
                    field: 'ride_id',
                    sort: 'asc',
                    width: 175,
                },
                {
                    label: <Trans>Client ID</Trans>,
                    field: 'user_id',
                    sort: 'asc',
                    width: 150,
                },
                {
                    label: <Trans>Client Name</Trans>,
                    field: 'user_name',
                    sort: 'asc',
                    width: 200,
                },
                {
                    label: <Trans>Client Phone number</Trans>,
                    field: 'phone_number',
                    sort: 'asc',
                    width: 175,
                },
                {
                    label: <Trans>Artist ID</Trans>,
                    field: 'vehicle_id',
                    sort: 'asc',
                    width: 175,
                },
                {
                    label: <Trans>Artist Name</Trans>,
                    field: 'vehicle_type',
                    sort: 'asc',
                    width: 150,
                },
                {
                    label: <Trans>Body Type</Trans>,
                    field: 'start_point',
                    sort: 'asc',
                    width: 200,
                },
                {
                    label: <Trans>Tattoo type</Trans>,
                    field: 'end_point',
                    sort: 'asc',
                    width: 200,
                },
                {
                    label: <Trans>Price</Trans>,
                    field: 'price',
                    sort: 'asc',
                    width: 150,
                },
                {
                    label: <Trans>Deposit</Trans>,
                    field: 'deposit',
                    sort: 'asc',
                    width: 150,
                },
                {
                    label: <Trans>Remain</Trans>,
                    field: 'remain',
                    sort: 'asc',
                    width: 150,
                },
                /*{
                    label: <Trans>Artist Complaint</Trans>,
                    field: 'artist_complaint',
                    sort: 'asc',
                    width: 350,
                },*/
                {
                    label: <Trans>Complaint</Trans>,
                    field: 'client_complaint',
                    sort: 'asc',
                    width: 350,
                },
                {
                    label: <Trans>Order Date</Trans>,
                    field: 'ride_date',
                    sort: 'asc',
                    width: 225,
                },
                {
                    label: <Trans>Rating</Trans>,
                    field: 'rating',
                    sort: 'asc',
                    width: 225,
                },
                {
                    label: <Trans>Status</Trans>,
                    field: 'status',
                    sort: 'asc',
                    width: 175,
                },
            ],
            rows: rowsData,
        };
        
        return (
            <>
                <div className='fleets_table'>
                    <MDBDataTable
                        hover
                        scrollX
                        maxHeight='50vh'
                        entriesOptions={[5, 10, 20]}
                        entries={5}
                        data={data}
                        disableRetreatAfterSorting={true}
                    />
                </div>
                <div className = {complaint_form}>
                    <div className='complaint_form_div'>
                        <table className = "complaint_form_table">
                            <tr className="complaint_form_order_id">
                                <td className = "complaint_form_td"><p><Trans>Complaint Order</Trans> : {this.state.complaint_order_id}</p></td>
                            </tr>
                            <tr className="complaint_form_user_type">
                                <td className = "complaint_form_td"><p>Complaint type: {complaint_type_user}</p></td>
                            </tr>
                            <tr className="user_id">
                                <td className = "complaint_form_td"><b><Trans>Artist id</Trans></b> : {this.state.complaint_order_artist_id}</td>
                                <td className = "complaint_form_td"><b><Trans>Client id</Trans></b> : {this.state.complaint_order_client_id}</td>
                            </tr>
                            <tr className="user_name">
                                <td className = "complaint_form_td"><b><Trans>Artist name</Trans></b> : {this.state.complaint_order_artist_name}</td>
                                <td className = "complaint_form_td"><b><Trans>Client name</Trans></b> : {this.state.complaint_order_client_name}</td>
                            </tr>
                            <tr className="user_phone">
                                <td className = "complaint_form_td"><b><Trans>Artist phone</Trans></b> : {this.state.complaint_order_artist_phone}</td>
                                <td className = "complaint_form_td"><b><Trans>Client phone</Trans></b> : {this.state.complaint_order_client_phone}</td>
                            </tr>
                            <tr className="user_phone">
                                <td className = "complaint_form_td"><b><Trans>Reason</Trans></b> :{this.state.complaint_order_artist_reason}</td>
                                <td className = "complaint_form_td"><b><Trans>Reason</Trans></b> : {this.state.complaint_order_client_reason}</td>
                            </tr>
                             <tr className="user_phone">
                                <td className = "complaint_form_td"><b><Trans>Date</Trans></b> : {this.state.complaint_order_artist_date}</td>
                                <td className = "complaint_form_td"><b><Trans>Date</Trans></b> : {this.state.complaint_order_client_date}</td>
                            </tr>
                            <tr className="user_reason">
                                <td className = "complaint_form_td"><b><Trans>Evidence Image</Trans></b></td>
                                <td className = "complaint_form_td"><b><Trans>Evidence Image</Trans></b></td>
                            </tr>
                            <tr className="user_img_1">
                                <td className = "complaint_form_td"><img src={artist_img_1}/></td>
                                <td className = "complaint_form_td"><img src={client_img_1}/></td>
                            </tr>
                            <tr className="user_img_2">
                                <td className = "complaint_form_td"><img src={artist_img_2}/></td>
                                <td className = "complaint_form_td"><img src={client_img_2}/></td>
                            </tr>
                            <tr className="user_img_1">
                                <td className = "complaint_form_td">
                                    <div className="complaint_button">
                                        <button className="complaint_confirm_button" value={this.state.complaint_order_id} onClick={this.props.dismissArtistComplaint}>Dismiss</button>
                                    </div>
                                </td>
                                <td className = "complaint_form_td">
                                    <div className="complaint_button">
                                        <button className="complaint_dismiss_button" value={this.state.complaint_order_id} onClick={this.props.dismissClientComplaint}>Dismiss</button>
                                    </div>
                                </td>
                            </tr>
                        </table>
                        
                    </div>    
                </div>
            </>
        );
    }
}
export default RidestablePage;