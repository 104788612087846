import "./Notification.css";
import React from "react";
//import NotifyMe from "react-notification-timeline";
import Cookies from 'universal-cookie';

export default class Notification extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      eventAdmin: [],
      eventNotification: [],
      eventTimestamp: []
    }
    //this.handlePostData = this.handlePostData.bind(this);
  }
  /*handlePostData() {
    const cookies = new Cookies();
    const formAdminData = new URLSearchParams();
    formAdminData.append('admin_id_param', cookies.get('adminID'));
    fetch("http://api.elevate-mobility.com/api/event/mark_read_notifications.php",
      {
        method: 'POST',
        headers: {
          'Content-Type': "application/x-www-form-urlencoded;charset=UTF-8"
        },
        body: formAdminData.toString()
      })
      .then(res => res.json())
      .then(
        (data) => {
          this.setState({
            eventAdmin: data.event_admin,
            eventNotification: data.event_notification,
            eventTimestamp: data.event_timestamp
          })
        }
      )
  }*/
  /*componentDidMount() {
    this.interval = setInterval(() => {
      const cookies = new Cookies();
      const formAdminData = new URLSearchParams();
      formAdminData.append('admin_id_param', cookies.get('adminID'));
      fetch("http://api.elevate-mobility.com/api/event/notifications.php",
        {
          method: 'POST',
          headers: {
            'Content-Type': "application/x-www-form-urlencoded;charset=UTF-8"
          },
          body: formAdminData.toString()
        })
        .then(res => res.json())
        .then(
          (data) => {
            this.setState({
              eventAdmin: data.event_admin,
              eventNotification: data.event_notification,
              eventTimestamp: data.event_timestamp
            })
          }
        )
    }, 5000);
  }*/
  /*componentWillUnmount() {
    clearInterval(this.interval);
  }*/
  render() {
    const eventAdmin = this.state.eventAdmin;
    const eventTimestamp = this.state.eventTimestamp;
    const eventNotification = this.state.eventNotification;
    const notificationLength = eventAdmin.length;
    const rowData = [];
    for (var i = 0; i < notificationLength; i++) {
      const index_element = notificationLength - i - 1;
      const valueindex = {
        update: eventAdmin[index_element] + " " + eventNotification[index_element],
        timestamp: eventTimestamp[index_element],
      }
      rowData.push(valueindex);
    }
    const data = rowData;
    return (
      <>
        {/*<NotifyMe
          className="notification"
          data={data}
          storageKey="notific_key"
          notific_key="timestamp"
          notific_value="update"
          heading="Notification"
          sortedByKey={false}
          showDate={true}
          size={32}
          color="#696969"
          markAsReadFn={this.handlePostData}
        />*/}
      </>
    );
  }
}
