import "./Map.css";
import React, { Component } from "react";
import { useState } from 'react';
import { MapContainer, Marker, Popup, TileLayer, useMap, useMapEvents } from "react-leaflet";
import L from "leaflet";
import markerIconPng from "../../img/marker.png";
import {Icon} from 'leaflet';

function AddMarkerToClick(props) {
  const [markers, setMarkers] = useState([]);
  const map = useMapEvents({
    click(e) {
      const newMarker = e.latlng;
      //console.log("on click map: " + e.latlng);
      const onClickLocation = [e.latlng.lat, e.latlng.lng]
      props.parentCallback(onClickLocation);
      setMarkers([...markers, newMarker]);
    },
  })
  return null
}

function ChangeMapView({ coords, zoom }) {
  const map = useMap();
  setTimeout(() => {
    map.invalidateSize();
  }, 250);
  map.panTo(coords, zoom);
  //map.setView(coords);
  return null;
}


export default class MapLeaflet extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lat: 15.748576209260989,
      lng: 30.11316716113033,
      zoom: 2,
      currentPos: [0.0, 0.0],
    };
  }

  render() {
    const dataCountry = this.props.mapDataInfo;
    const position = [parseFloat(dataCountry[0]), parseFloat(dataCountry[1])];
    const zoomValue = parseFloat(dataCountry[2]);
    //const position = [15.990264303653456,108.32260101119351];
    return (
      <MapContainer style={{ height: "55vh", cursor: "crosshair", zIndex:"1" }} center={position} zoom={zoomValue} draggable={1}>
        <AddMarkerToClick parentCallback={this.props.getClickLocation} />
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        />
        {/*<Marker position={position}>
        <Popup>
          A pretty CSS3 popup.
          <br />
          Easily customizable.
        </Popup>
      </Marker>*/}
        <Marker draggable={false} position={position} icon={new Icon({ iconUrl: markerIconPng, iconSize: [25, 41], iconAnchor: [12, 41] })}>
        </Marker>
        <ChangeMapView coords={position} zoom={zoomValue} />
      </MapContainer>
    );
  }
}