import React, { useState, useEffect, useRef } from "react";
import './Messages.css';
import io from 'socket.io-client';
import Cookies from 'universal-cookie';
import { Advertisement } from "semantic-ui-react";
import Avatar from 'react-avatar';

class Messages extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            chat: [],
            chat_date: [],
            msg: '',
            id: [],
            user_name: [],
            support_user_id: '',
            user_name_list: [],
            user_id_list: [],
            user_phone_list: [],
            user_email_list: [],
            user_unread_mes: [],
            user_name_search: [],
            user_id_search: [],
            user_phone_search: [],
            user_email_search: [],

        }
        this.refMsg = React.createRef();
        this.messagesEnd = React.createRef();
        this.chatContainer = React.createRef();;
        this.socket = io('http://admin.elevate-mobility.com:27123');
        this.onTextChange = this.onTextChange.bind(this);
        this.onMessageSubmit = this.onMessageSubmit.bind(this);
    }
    onTextChange = e => {
        this.setState({ msg: e.target.value });
    }
    onMessageSubmit = () => {
        const cookies = new Cookies();
        /*var msg_date = new Date().toLocaleString("en-GB", { timeZone: "Asia/Jakarta" });
        var msg_date_arr = msg_date.split(",");
        var msg_datetime = msg_date_arr[0] + msg_date_arr[1];*/
        if (this.state.msg !== "") {
            const msg = {
                content: this.state.msg,
                id: cookies.get('adminID'),
                name: cookies.get('adminName'),
                support_user_id: this.state.support_user_id,
                /*timestamp: msg_datetime,*/
                chat_user: "admin",
            }
            this.socket.emit('sendDataClient', msg);
            this.setState({ msg: "" });
        }
    }
    onEnterPress(e) {
        if (e.keyCode === 13 && e.shiftKey === false) {
            const cookies = new Cookies();
            if (this.state.msg !== "") {
                const msg = {
                    content: this.state.msg,
                    id: cookies.get('adminID'),
                    name: cookies.get('adminName'),
                    support_user_id: this.state.support_user_id,
                    /*timestamp: msg_datetime,*/
                    chat_user: "admin",
                }
                this.socket.emit('sendDataClient', msg);
                this.setState({ msg: "" });
            }
        }
    }
    startChatUser(e) {
        const user_chat_id = e.currentTarget.value;
        const formGetMessagesData = new URLSearchParams();
        formGetMessagesData.append('support_user_id', user_chat_id);
        fetch("https://admin.connect-ink.com/API/messages/get_user_chat_content.php",
            {
                method: 'POST',
                headers: {
                    'Content-Type': "application/x-www-form-urlencoded;charset=UTF-8"
                },
                body: formGetMessagesData.toString()
            }
        )
            .then(res => res.json())
            .then(
                (data) => {
                    this.setState({
                        //chat: data.message,
                        //chat_date: data.chat_date,
                        //id: data.chat_user_id,
                        //user_name: data.chat_user_name,
                        //support_user_id: user_chat_id,
                    }, () => this.scrollToMyRef())
                }
            );
    }

    scrollToMyRef = () => {
        const scroll =
            this.chatContainer.current.scrollHeight -
            this.chatContainer.current.clientHeight;
        this.chatContainer.current.scrollTo(0, scroll);
    };
    async searchChatUser(e) {
        const search_param = e.currentTarget.value;
        if (search_param) {
            if (e.keyCode === 13 && e.shiftKey === false) {
                const formGetSearchData = new URLSearchParams();
                formGetSearchData.append('search_param', search_param);
                await fetch("https://admin.connect-ink.com/API/messages/get_search_user_chat_list.php",
                    {
                        method: 'POST',
                        headers: {
                            'Content-Type': "application/x-www-form-urlencoded;charset=UTF-8"
                        },
                        body: formGetSearchData.toString()
                    }
                )
                    .then(res => res.json())
                    .then(
                        (data) => {
                            const user_name = Array.from(new Set(data.user_name));
                            const user_id = Array.from(new Set(data.user_id));
                            const user_phone = Array.from(new Set(data.user_phone));
                            const user_email = Array.from(new Set(data.user_email));
                            this.setState({
                                user_name_list: user_name,
                                user_id_list: user_id,
                                user_phone_list: user_phone,
                                user_email_list: user_email,
                                support_user_id: data.support_user_id,
                                chat: data.message,
                                chat_date: data.chat_date,
                                id: data.chat_user_id,
                                user_name: data.chat_user_name,
                            }, () => this.scrollToMyRef())
                        }
                    );

            }
        } else {
            await fetch("https://admin.connect-ink.com/API/messages/get_user_chat_list.php")
                .then(res => res.json())
                .then(
                    (data) => {
                        const user_name = Array.from(new Set(data.user_name));
                        const user_id = Array.from(new Set(data.user_id));
                        const user_phone = Array.from(new Set(data.user_phone));
                        const user_email = Array.from(new Set(data.user_email));
                        this.setState({
                            user_name_list: user_name,
                            user_id_list: user_id,
                            user_phone_list: user_phone,
                            user_email_list: user_email,
                            user_unread_mes: data.unread_mes,
                            support_user_id: data.support_user_id,
                            chat: data.message,
                            chat_date: data.chat_date,
                            id: data.chat_user_id,
                            user_name: data.chat_user_name,
                        }, () => this.scrollToMyRef())
                    }
                );
        }
    }
    async componentDidMount() {
        //get admin id
        const cookies = new Cookies();
        this.setState({
            adminId: cookies.get('adminID')
        })
        //retrive user chat list
        await fetch("https://admin.connect-ink.com/API/messages/get_user_chat_list.php")
            .then(res => res.json())
            .then(
                (data) => {
                    const user_name = Array.from(new Set(data.user_name));
                    const user_id = Array.from(new Set(data.user_id));
                    const user_phone = Array.from(new Set(data.user_phone));
                    const user_email = Array.from(new Set(data.user_email));
                    this.setState({
                        user_name_list: user_name,
                        user_id_list: user_id,
                        user_phone_list: user_phone,
                        user_email_list: user_email,
                        user_unread_mes: data.unread_mes,
                        support_user_id: data.support_user_id,
                        chat: data.message,
                        chat_date: data.chat_date,
                        id: data.chat_user_id,
                        user_name: data.chat_user_name,
                    }, () => this.scrollToMyRef())
                    console.log(data.unread_mes);
                }
            );
        //Get message when receive from serve
        this.socket.on("sendDataServer", async dataGot => {
            // Add new messages to existing messages in "chat"
            console.log(dataGot.data_send.support_user_id);
            if (dataGot.data_send.support_user_id == this.state.support_user_id) {
                this.setState({
                    chat: [...this.state.chat, dataGot.data_send.content],
                    id: [...this.state.id, dataGot.data_send.id],
                    user_name: [...this.state.user_name, dataGot.data_send.name],
                }, () => this.scrollToMyRef());
            }

            await (fetch("https://admin.connect-ink.com/API/messages/get_user_chat_list.php")
                .then(res => res.json())
                .then(
                    (data) => {
                        const user_name = Array.from(new Set(data.user_name));
                        const user_id = Array.from(new Set(data.user_id));
                        const user_phone = Array.from(new Set(data.user_phone));
                        const user_email = Array.from(new Set(data.user_email));
                        this.setState({
                            user_name_list: user_name,
                            user_id_list: user_id,
                            user_phone_list: user_phone,
                            user_email_list: user_email,
                            user_unread_mes: data.unread_mes,
                        }, () => this.scrollToMyRef())
                    }
                )
            )

            const formGetMessagesData = new URLSearchParams();
            formGetMessagesData.append('support_user_id', this.state.support_user_id);
            await fetch("https://admin.connect-ink.com/API/messages/get_user_chat_content.php",
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': "application/x-www-form-urlencoded;charset=UTF-8"
                    },
                    body: formGetMessagesData.toString()
                }
            )
                .then(res => res.json())
                .then(
                    (data) => {
                        this.setState({
                            chat: data.message,
                            chat_date: data.chat_date,
                            id: data.chat_user_id,
                            user_name: data.chat_user_name,
                        }, () => this.scrollToMyRef())
                    }
                );
        });

        //retrive search suggestion
        await fetch("https://admin.connect-ink.com/API/messages/get_search_suggestion.php")
            .then(res => res.json())
            .then(
                (data) => {
                    const user_name_search = Array.from(new Set(data.user_name));
                    const user_id_search = Array.from(new Set(data.user_id));
                    const user_phone_search = Array.from(new Set(data.user_phone));
                    const user_email_search = Array.from(new Set(data.user_email));
                    this.setState({
                        user_name_search: user_name_search,
                        user_id_search: user_id_search,
                        user_phone_search: user_phone_search,
                        user_email_search: user_email_search,
                    }, () => this.scrollToMyRef())
                }
            );
        this.scrollToMyRef();
    }
    render() {
        //Display messages
        const cookies = new Cookies();
        const adminId = cookies.get('adminID');
        const msg_array = this.state.chat;
        const msg_id = this.state.id;
        const msg_user_name = this.state.user_name;
        const msg_user_date = this.state.chat_date;
        const msg_length = msg_array.length;
        const msg_content_array = [];
        for (var i = 0; i < msg_length; i++) {
            const msg_element = {
                msg: msg_array[i],
                id: msg_id[i],
                name: msg_user_name[i],
                date: msg_user_date[i],
            }
            msg_content_array.push(msg_element);
        }
        //user list contact
        const user_name_list = this.state.user_name_list;
        const user_id_list = this.state.user_id_list;
        const user_phone_list = this.state.user_phone_list;
        const user_email_list = this.state.user_email_list;
        const user_unread_mes = this.state.user_unread_mes;
        const user_list_length = user_name_list.length;
        const user_list = [];
        for (var j = 0; j < user_list_length; j++) {
            if (user_unread_mes[j] > 5) {
                var unread_mes_count = '5+';
            } else {
                unread_mes_count = user_unread_mes[j];
            }
            const chat_user_info = {
                name: user_name_list[j],
                id: user_id_list[j],
                phone: user_phone_list[j],
                email: user_email_list[j],
                unread_mes: unread_mes_count
            }
            user_list.push(chat_user_info);
        }

        //Search suggestion
        const user_name_search_suggestion = this.state.user_name_search;
        const add_user_id_search = user_name_search_suggestion.concat(this.state.user_id_search);
        const add_user_phone_search = add_user_id_search.concat(this.state.user_phone_search);
        const user_search_suggestion = add_user_phone_search.concat(this.state.user_email_search);

        return (
            <div className='feedback_options_contain'>
                <p className='feedback_options_title'>Messages</p>
                <div className="messages_contain">
                    <div className="contact_list" >
                        <div className="menu_toolbar">
                            <div className="menu_toolbar_title">
                                <p style={{ fontSize: "1.5vw" }}>Contact</p>
                            </div>
                            <input className="search_chat_user" list="suggestions" type='search' placeholder="Search..." onKeyUp={this.searchChatUser.bind(this)} />
                            <datalist id="suggestions">
                                {user_search_suggestion.map((x) =>
                                    <option value={x} />)}
                            </datalist>
                        </div>
                        <div style={{ overflowY: "auto" }}>
                            {user_list.map((user) => ((user.unread_mes != 0) ?
                                <button value={user.id} className="user_contact" onClick={this.startChatUser.bind(this)}>
                                    <Avatar className="chat_user_avatar" name={user.name} size="60" round="30px" />
                                    <div className="user_contact_name">
                                        <p style={{ marginBottom: "0px" }}>{user.name} <div className="unread_mes">{user.unread_mes}</div></p>
                                        <p style={{ fontSize: "0.75vw", marginBottom: "0px" }}>Email: <i>{user.email}</i></p>
                                        <p style={{ fontSize: "0.75vw", marginBottom: "0px" }}>Phone: <i>{user.phone}</i></p>
                                    </div>
                                </button>
                                :
                                <button value={user.id} className="user_contact" onClick={this.startChatUser.bind(this)}>
                                    <Avatar className="chat_user_avatar" name={user.name} size="60" round="30px" />
                                    <div className="user_contact_name">
                                        <p style={{ marginBottom: "0px" }}>{user.name}</p>
                                        <p style={{ fontSize: "0.75vw", marginBottom: "0px" }}>Email: <i>{user.email}</i></p>
                                        <p style={{ fontSize: "0.75vw", marginBottom: "0px" }}>Phone: <i>{user.phone}</i></p>
                                    </div>
                                </button>

                            ))}
                        </div>
                    </div>
                    <div className="message_region">
                        <div className="chat_box" ref={this.chatContainer}>
                            {msg_content_array.map((m) => ((m.id == adminId) ? <><div className="my_mes">
                                <div className="my_mes_content">{m.msg}<br /><i style={{ fontSize: "10px" }}>{m.date}</i></div>
                                <Avatar style={{ marginLeft: "5px" }} name={m.name} size="50" round="25px" />
                            </div></>
                                : <><div className="orther_one_mes">
                                    <Avatar style={{ marginRight: "5px" }} name={m.name} size="50" round="25px" />
                                    <div className="orther_one_mes_content">{m.msg}<br /><i style={{ fontSize: "10px" }}>{m.date}</i></div>
                                </div></>
                            ))}
                        </div>
                        <div className="message">
                            <textarea className="message_box"
                                id="message"
                                type="text"
                                label="Message"
                                value={this.state.msg}
                                onChange={e => this.onTextChange(e)}
                                onKeyDown={this.onEnterPress.bind(this)}
                                ref={this.refMsg}
                            />
                            <button onClick={this.onMessageSubmit} className="send_button">Send</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Messages;