import React from 'react';
import './Vehicles.css'
import { CSVLink } from "react-csv";
import * as FaIcons from 'react-icons/fa';

class VehiclesTableCSV extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
        this.csvLinkEl = React.createRef();
    }
    render() {
        var currentdate = new Date();  
        var fileName = "Vehicle_Report_" + currentdate.getDate() + "_" + (currentdate.getMonth()+1) + "_" + currentdate.getFullYear() + "_" + currentdate.getHours() + "_" + currentdate.getMinutes() + "_" + currentdate.getSeconds() +".csv";
        const rowData = this.props.tableData;
        const rowDataVehicleId = rowData[0];
        const rowDataVehiclenName = rowData[1];
        const rowDataBattery = rowData[2];
        const rowDataType = rowData[3];
        const rowDataEmei = rowData[4];
        const rowDataQrCode = rowData[5];
        const rowDataVehicleSn = rowData[6];
        const rowDataOdometer = rowData[7];
        const rowDataEnableFlag = rowData[8];
        const rowDataRegisterDate = rowData[9];
        const rowDataLastConnected = rowData[10];
        const rowDataLastLockStatusUpdate = rowData[11];
        const rowDataLastRideTime = rowData[12];
        const rowDataVehicleLat = rowData[13];
        const rowDataVehicleLng = rowData[14];
        
        const numRows = rowDataVehicleId.length;
        const rowsData = [];
        const csvData = [];
        for (var i = 0; i < numRows; i++) {
            const csvDataElement = {
                vehicle_id: rowDataVehicleId[i],
                vehicle_name: rowDataVehiclenName[i],
                battery: rowDataBattery[i],
                type: rowDataType[i],
                imei: rowDataEmei[i],
                qr_code: rowDataQrCode[i],
                vehicle_sn: rowDataVehicleSn[i],
                odo: rowDataOdometer[i],
                ebabled_flags: rowDataEnableFlag[i],
                register_date: rowDataRegisterDate[i],
                last_connected: rowDataLastConnected[i],
                last_loct_status_update: rowDataLastLockStatusUpdate[i],
                last_ride_time: rowDataLastRideTime[i],
                location: rowDataVehicleLat[i] + ';' + rowDataVehicleLng[i],
                action: ''
            }
            csvData.push(csvDataElement);
        }
        const headers = [
            { label: "ID", key: "vehicle_id" },
            { label: "Vehicle Name", key: "vehicle_name" },
            { label: "Battery", key: "battery" },
            { label: "Type", key: "type" },
            { label: "IMEI", key: "imei" },
            { label: "QR Code", key: "qr_code" },
            { label: "Vehicle SN", key: "vehicle_sn" },
            { label: "Odometer Distance (km)", key: "odo" },
            { label: "Enabled Flags", key: "ebabled_flags" },
            { label: "Register Date", key: "register_date" },
            { label: "Last Connected", key: "last_connected" },
            { label: "Last Lock Status Update", key: "last_loct_status_update" },
            { label: "Last ride time", key: "last_ride_time" },
            { label: "Location", key: "location" },
            { label: "Action", key: "action" }
        ];
        const data_csv = csvData;

        return (
            <div>
                <CSVLink
                    headers={headers}
                    filename={fileName}
                    data={data_csv}
                    ref={this.csvLinkEl}
                >
                    <div style={{color:"white"}}><FaIcons.FaFileCsv/>CSV</div>
                </CSVLink>
            </div>
        );
    }


}

export default VehiclesTableCSV;