import React from 'react';
import './Riders.css';
import RiderstablePage from './Riders_table';
import RidersTableCSV from './Riders_csv';
import { Trans, useTranslation } from 'react-i18next';

class Riders extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fleet_filter: [],
            fleet_filter_param: "all_fleet",
            status_param: "all",
            payment_card: "all",
            active_toggle_state: false,
            disable_toggle_state: false,
            riderData: {
                userId: [],
                userName: [],
                balance: [],
                email: [],
                countryCode: [],
                status: [],
                phoneNumber: [],
                registerDate: [],
                lastLogin: [],
                appVersion: [],
                platform: [],
                osVersion: [],
                rfid: [],
            },
            view_card_image: false,
            front_card_image: '',
            back_card_image: '',
            front_license_image: '',
            back_license_image: '',
            verify_user_name: '',
            verify_user_phone: '',
            verify_id_card: '',
        }
        this.handleFleetChange = this.handleFleetChange.bind(this);
        this.handleStatusFilter = this.handleStatusFilter.bind(this);
        this.handlePaymentFilter = this.handlePaymentFilter.bind(this);
        this.unverifyUser = this.unverifyUser.bind(this);
        this.verifyUser = this.verifyUser.bind(this);
        this.editClientStatus = this.editClientStatus.bind(this);
        this.closeView = this.closeView.bind(this);

    }
    render() {
        const ridesTableData = [this.state.riderData.userId, this.state.riderData.userName, this.state.riderData.balance, this.state.riderData.email, this.state.riderData.countryCode, this.state.riderData.status, this.state.riderData.phoneNumber, this.state.riderData.registerDate, this.state.riderData.lastLogin, this.state.riderData.appVersion, this.state.riderData.platform, this.state.riderData.osVersion, this.state.riderData.rfid];
        let view_card_image = this.state.view_card_image ? "view_card_image" : "hide_card_image"
        return (
            <div className='riders_contain'>
                <div>
                    <table className="riders_menu_filter">
                        <tbody>
                            <tr>
                                <td>
                                    <div>
                                        <p className="riders_title"><Trans>Client</Trans></p>
                                    </div>
                                </td>
                                <td className='riders_rightside_menu'>

                                </td>
                            </tr>
                            <tr>
                                <td className='riders_leftside_menu'>
                                    {/* <select className="riders_fleets_select" onChange={this.handleFleetChange}>
                                        <option value='all_fleet'>All Zones</option>
                                        {this.state.fleet_filter.map((x) =>
                                            <option value={x}>{x}</option>)}
                                    </select> */}
                                </td>
                                <td className='riders_rightside_menu'>
                                    <table>
                                        <tr>
                                            <td>
                                                <select className="payment_select" onChange={this.handlePaymentFilter}>
                                                    <option value="all">All</option>
                                                    <option value="available">Payment Card Available</option>
                                                    <option value="unavailable">Payment Card Not Available</option>
                                                </select>
                                            </td>
                                            <td>
                                                <select className="payment_select" onChange={this.handleStatusFilter}>
                                                    <option value="all">User status</option>
                                                    <option value="enable">Enable</option>
                                                    <option value="disable">Disable</option>
                                                </select>
                                            </td>
                                            {/* <td><button className="btn_csv" type="button"><RidersTableCSV tableData={ridesTableData} /></button></td> */}
                                        </tr>
                                    </table>

                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div className='riders_table_div'>
                        <RiderstablePage tableData={ridesTableData} verifyUser={this.verifyUser} unverifyUser={this.unverifyUser} editClientStatus={this.editClientStatus} />
                    </div>
                    <div className={view_card_image}>
                        <div className='name_tag'>
                            <div>
                                <b>Rider name: {this.state.verify_user_name}</b>
                            </div>
                            <div>
                                <b>Rider phone: {this.state.verify_user_phone}</b>
                            </div>
                            <div>
                                <b>ID Card: {this.state.verify_id_card}</b>
                            </div>
                        </div>
                        <div className='id_card_div'>
                            <div className='front_card'>
                                <b>Front Card Image</b>
                                <img
                                    className='front_card_img'
                                    src={this.state.front_card_image}
                                    alt=""
                                />
                            </div>
                            <div className='back_card'>
                                <b>Back Card Image</b>
                                <img
                                    className='back_card_img'
                                    src={this.state.back_card_image}
                                    alt=""
                                />
                            </div>
                        </div>
                        <div className='driver_license_div'>
                            <div className='front_license'>
                                <b>Front Driver License Image</b>
                                <img
                                    className='front_license_img'
                                    src={this.state.front_license_image}
                                    alt=""
                                />
                            </div>
                            <div className='back_license'>
                                <b>Back Driver License Image</b>
                                <img
                                    className='back_license_img'
                                    src={this.state.back_license_image}
                                    alt=""
                                />
                            </div>

                        </div>
                        <div>
                            <button className="closeViewButton" onClick={this.closeView}>Close</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    async closeView() {
        this.setState({ view_card_image: false });
    }
    async editClientStatus(e) {
        console.log("CLIENT EDIT");
        const editClientData = e.target.value.split(",");
        const formPostData = new URLSearchParams();
        console.log(editClientData);
        formPostData.append('status_param', this.state.status_param);
        formPostData.append('payment_card', this.state.payment_card);
        formPostData.append('user_id', editClientData[0]);
        formPostData.append('user_status', editClientData[1]);
        fetch("https://api.connect-ink.com/API/WEB_ADMIN/API/user/change_user_info_api.php",
            {
                method: 'POST',
                headers: {
                    'Content-Type': "application/x-www-form-urlencoded;charset=UTF-8"
                },
                body: formPostData.toString()
            }
        )
            .then(res => res.json())
            .then(
                async (data) => {
                    console.log(data);
                    await this.setState({
                        verify_user_name: data.user_name,
                        verify_user_phone: data.user_phone_number,
                        verify_id_card: data.id_card,
                        riderData: {
                            userId: data.user_id,
                            userName: data.user_name,
                            balance: data.balance,
                            email: data.email,
                            countryCode: data.country_code,
                            status: data.status,
                            phoneNumber: data.phone_number,
                            registerDate: data.register_date,
                            lastLogin: data.last_login,
                            appVersion: data.app_version,
                            platform: data.platform,
                            osVersion: data.os_version,
                            rfid: data.rfid,
                        }

                    });
                }
            );

    }
    async unverifyUser(e) {
        const riderId = e.target.value;
        const formPostData = new URLSearchParams();
        formPostData.append('rider_id', riderId);
        formPostData.append('fleet_filter_param', this.state.fleet_filter_param);
        formPostData.append('status_param', this.state.status_param);
        formPostData.append('payment_card', this.state.payment_card);
        fetch("https://admin.connect-ink.com/API/user/unverify_user.php",
            {
                method: 'POST',
                headers: {
                    'Content-Type': "application/x-www-form-urlencoded;charset=UTF-8"
                },
                body: formPostData.toString()
            }
        )
            .then(res => res.json())
            .then(
                async (data) => {
                    console.log(data);
                    await this.setState({
                        riderData: {
                            userId: data.user_id,
                            userName: data.user_name,
                            balance: data.balance,
                            email: data.email,
                            countryCode: data.country_code,
                            status: data.status,
                            phoneNumber: data.phone_number,
                            registerDate: data.register_date,
                            lastLogin: data.last_login,
                            appVersion: data.app_version,
                            platform: data.platform,
                            osVersion: data.os_version,
                            rfid: data.rfid,
                        }
                    });
                }
            );
    }
    async verifyUser(e) {
        const riderId = e.target.value;
        const formPostData = new URLSearchParams();
        formPostData.append('rider_id', riderId);
        formPostData.append('fleet_filter_param', this.state.fleet_filter_param);
        formPostData.append('status_param', this.state.status_param);
        formPostData.append('payment_card', this.state.payment_card);
        fetch("https://admin.connect-ink.com/API/user/verify_user.php",
            {
                method: 'POST',
                headers: {
                    'Content-Type': "application/x-www-form-urlencoded;charset=UTF-8"
                },
                body: formPostData.toString()
            }
        )
            .then(res => res.json())
            .then(
                (data) => {
                    this.setState({
                        riderData: {
                            userId: data.user_id,
                            userName: data.user_name,
                            balance: data.balance,
                            email: data.email,
                            countryCode: data.country_code,
                            status: data.status,
                            phoneNumber: data.phone_number,
                            registerDate: data.register_date,
                            lastLogin: data.last_login,
                            appVersion: data.app_version,
                            platform: data.platform,
                            osVersion: data.os_version,
                            rfid: data.rfid,
                        }
                    });
                }
            );
    }
    async handlePaymentFilter(e) {
        const userPayment = e.target.value;
        const formPostData = new URLSearchParams();
        formPostData.append('fleet_filter_param', this.state.fleet_filter_param);
        formPostData.append('status_param', this.state.status_param);
        formPostData.append('payment_card', userPayment);
        console.log("handlePaymentFilter");
        console.log(userPayment);
        fetch("https://api.connect-ink.com/API/WEB_ADMIN/API/user/clients_table_filter.php",
            {
                method: 'POST',
                headers: {
                    'Content-Type': "application/x-www-form-urlencoded;charset=UTF-8"
                },
                body: formPostData.toString()
            }
        )
            .then(res => res.json())
            .then(
                (data) => {
                    console.log(data);
                    this.setState({
                        payment_card: userPayment,
                        riderData: {
                            userId: data.user_id,
                            userName: data.user_name,
                            balance: data.balance,
                            email: data.email,
                            countryCode: data.country_code,
                            status: data.status,
                            phoneNumber: data.phone_number,
                            registerDate: data.register_date,
                            lastLogin: data.last_login,
                            appVersion: data.app_version,
                            platform: data.platform,
                            osVersion: data.os_version,
                            rfid: data.rfid,
                        }
                    });

                }
            );
    }
    async handleStatusFilter(e) {
        const userStatus = e.target.value;
        const formPostData = new URLSearchParams();
        formPostData.append('fleet_filter_param', this.state.fleet_filter_param);
        formPostData.append('status_param', userStatus);
        formPostData.append('payment_card', this.state.payment_card);
        fetch("https://api.connect-ink.com/API/WEB_ADMIN/API/user/clients_table_filter.php",
            {
                method: 'POST',
                headers: {
                    'Content-Type': "application/x-www-form-urlencoded;charset=UTF-8"
                },
                body: formPostData.toString()
            }
        )
            .then(res => res.json())
            .then(
                (data) => {
                    this.setState({
                        status_param: userStatus,
                        riderData: {
                            userId: data.user_id,
                            userName: data.user_name,
                            balance: data.balance,
                            email: data.email,
                            countryCode: data.country_code,
                            status: data.status,
                            phoneNumber: data.phone_number,
                            registerDate: data.register_date,
                            lastLogin: data.last_login,
                            appVersion: data.app_version,
                            platform: data.platform,
                            osVersion: data.os_version,
                            rfid: data.rfid,
                        }
                    });

                }
            );

    }
    async handleFleetChange(event) {
        const valueFleetState = event.target.value;
        const formPostData = new URLSearchParams();
        formPostData.append('fleet_filter_param', valueFleetState);
        formPostData.append('status_param', this.state.status_param);
        formPostData.append('payment_card', this.state.payment_card);
        console.log("TEST FLEET CHANGE: " + valueFleetState + " & status: " + this.state.status_param + " & payment: " + this.state.payment_card)
        await fetch("https://api.connect-ink.com/API/WEB_ADMIN/API/user/clients_table_filter.php",
            {
                method: 'POST',
                headers: {
                    'Content-Type': "application/x-www-form-urlencoded;charset=UTF-8"
                },
                body: formPostData.toString()
            }
        )
            .then(res => res.json())
            .then(
                (data) => {
                    this.setState({
                        fleet_filter_param: valueFleetState,
                        riderData: {
                            userId: data.user_id,
                            userName: data.user_name,
                            balance: data.balance,
                            email: data.email,
                            countryCode: data.country_code,
                            status: data.status,
                            phoneNumber: data.phone_number,
                            registerDate: data.register_date,
                            lastLogin: data.last_login,
                            appVersion: data.app_version,
                            platform: data.platform,
                            osVersion: data.os_version,
                            rfid: data.rfid,
                        }
                    });
                }
            );
    }
    async componentDidMount() {
        const formFleetData = new URLSearchParams();
        console.log('status_param');
        console.log(this.state.status_param);
        console.log(this.state.payment_card);
        formFleetData.append('status_param', this.state.status_param);
        formFleetData.append('payment_card', this.state.payment_card);
        fetch("https://api.connect-ink.com/API/WEB_ADMIN/API/user/clients_table_filter.php", {
            method: 'POST',
            headers: {
                'Content-Type': "application/x-www-form-urlencoded;charset=UTF-8"
            },
            body: formFleetData.toString()
        })
            .then(res => res.json())
            .then(
                (data) => {
                    this.setState({
                        fleet_filter: data.fleet_name,
                        riderData: {
                            userId: data.user_id,
                            userName: data.user_name,
                            balance: data.balance,
                            email: data.email,
                            countryCode: data.country_code,
                            status: data.status,
                            phoneNumber: data.phone_number,
                            registerDate: data.register_date,
                            lastLogin: data.last_login,
                            appVersion: data.app_version,
                            platform: data.platform,
                            osVersion: data.os_version,
                            rfid: data.rfid,
                        }
                    });
                    console.log("AAAAAAAAAAAAA");
                    console.log(data);
                }
            );
    }
}
export default Riders;