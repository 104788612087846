import React from 'react';
import './Permissions.css';
import PermissiontablePage from './Permission_table';
import { Trans } from 'react-i18next';

class Permissions extends React.Component {
    constructor(props){
        super(props);
        this.state ={
            permissionData: {
                roleName: [],
                analytics: [],
                applicationBanner: [],
                banner: [],
                campaign: [],
                customZone: [],
                feedbackOption:[],
                fleets: [],
                map: [],
                marker: [],
                offer: [],
                onRide: [],
                operatorHistory: [],
                org: [],
                parking: [],
                payment: [],
                pricing: [],
                promos: [],
                restrictedArea: [],
                riders: [],
                rides: [],
                serviceAreas: [],
                speedZoneAreas: [],
                station: [],
                taxes: [],
                ticket: [],
                timeSlider: [],
                vehicleReserved: [],
                vehicle: [],
            }
        }
    }
    async componentDidMount() {
        fetch("https://admin.connect-ink.com/API/organisations/org_role_list.php")
            .then(res => res.json())
            .then(
                (data) => {
                    this.setState({
                        permissionData:{
                            roleName: data.role_name
                        }
                    });
                    
                }
            );
    }
    render(){
        return (
            <div className='permissions_contain'>
                <div>
                  <p className="permissions_title"><Trans>Roles Permissons</Trans></p>
                </div>
                <div>
                    <PermissiontablePage roleName = {this.state.permissionData.roleName}/>
                </div>
            </div>
        ) 
    }
}
export default Permissions;